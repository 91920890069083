import layout from '../../layouts/locale';
import commonJa from './locale/ja/common.ja';
import commonEn from './locale/ja/common.en';
import auth from '../../features/auth/locale';
import room from '../../features/room/locale';
import roomType from '../../features/room-type/locale';
import marketingChannelGroup from '../../features/marketing-channel-group/locale';
import roomBooking from '../../features/room-booking/locale';
import { yupLocale } from '~plugins/yup/locale';
import facilityType from '~features/facility-type/locale';
import facility from '~features/facility/locale';
import marketingChannel from '../../features/marketing-channel/locale';
import guest from '~features/guest/locale';
import error from '../../features/error/locale';
import hotel from '../../features/hotel/locale';
import facilityBooking from '../../features/facility-booking/locale';
import integrations from '~features/integrations/locale';
import guestClassification from '../../features/guest-classification/locale';
import rankCalendar from '~features/rank-calendar/locale';
import plan from '../../features/plan/locale';
import saleGroup from '~features/sale-group/locale';
import saleItem from '~features/sale-item/locale';
import childrenType from '~features/children-type/locale';
import indicator from '~features/indicator/locale';
import tax from '~features/tax/locale';
import { closingBooking } from '~features/closing-booking/locale';
import { roomManagement } from '~features/room-management/locale';
import roomCleaning from '~features/room-cleaning/locale';
import inventory from '~features/inventory/locale';
import notification from '~features/admin-notification/locale';
import paymentMethod from '~features/payment-method/locale';
import { history } from '~features/fo-operation-history/locale';
import { boHistory } from '~features/bo-operation-history/locale';
import siteController from '~features/site-controller/locale';
import { dashboard } from '~features/dashboard/locale';
import { report } from '~features/report/locale';
import smtpSetting from '~features/smtp-setting/locale';
import { csvBookingUploadLocale } from '~features/csv-booking-upload/locale';
import { kioskAlmexIntegrations } from '~features/kiosk-setting/almex/locale';

const resources = {
    ja: {
        translation: {
            dashboard: dashboard.ja,
            layout: layout.ja,
            common: commonJa,
            yup: yupLocale.localeJa,
            auth: auth.ja,
            room: room.ja,
            roomType: roomType.ja,
            marketingChannelGroup: marketingChannelGroup.ja,
            roomBooking: roomBooking.ja,
            facilityType: facilityType.ja,
            facility: facility.ja,
            marketingChannel: marketingChannel.ja,
            guest: guest.ja,
            error: error.ja,
            hotel: hotel.ja,
            facilityBooking: facilityBooking.ja,
            integrations: integrations.ja,
            guestClassification: guestClassification.ja,
            rankCalendar: rankCalendar.ja,
            plan: plan.ja,
            saleGroup: saleGroup.ja,
            saleItem: saleItem.ja,
            childrenType: childrenType.ja,
            indicator: indicator.ja,
            tax: tax.ja,
            closingBooking: closingBooking.ja,
            roomManagement: roomManagement.ja,
            roomCleaning: roomCleaning.ja,
            inventory: inventory.ja,
            notification: notification.ja,
            paymentMethod: paymentMethod.ja,
            history: history.ja,
            boHistory: boHistory.ja,
            siteController: siteController.ja,
            report: report.ja,
            smtpSetting: smtpSetting.ja,
            csvBookingUploadLocale: csvBookingUploadLocale.ja,
            kioskAlmexIntegrations: kioskAlmexIntegrations.ja,
        },
    },
    en: {
        translation: {
            auth: auth.en,
            layout: layout.en,
            common: commonEn,
            yup: yupLocale.localeEn,
            room: room.en,
            facility: facility.en,
            facilityType: facilityType.en,
            error: error.en,
            facilityBooking: facilityBooking.en,
            integrations: integrations.ja,
            saleGroup: saleGroup.en,
            saleItem: saleItem.en,
            childrenType: childrenType.en,
            closingBooking: closingBooking.en,
            dashboard: dashboard.en,
            hotel: hotel.en,
            history: history.en,
            boHistory: boHistory.en,
            guest: guest.en,
            guestClassification: guestClassification.en,
            inventory: inventory.en,
            marketingChannel: marketingChannel.en,
            marketingChannelGroup: marketingChannelGroup.en,
            paymentMethod: paymentMethod.en,
            plan: plan.en,
            rankCalendar: rankCalendar.en,
            report: report.en,
            roomBooking: roomBooking.en,
            roomManagement: roomManagement.en,
            roomType: roomType.en,
            indicator: indicator.en,
            notification: notification.en,
            roomCleaning: roomCleaning.en,
            siteController: siteController.en,
            smtpSetting: smtpSetting.en,
            tax: tax.en,
            csvBookingUploadLocale: csvBookingUploadLocale.en,
            kioskAlmexIntegrations: kioskAlmexIntegrations.en,
        },
    },
};

export default resources;
