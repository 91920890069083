import { createContext, useState, useContext, ReactNode } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { KeycardModalMode } from './enums/key-card-modal-mode.enums';
import { KeycardIssueStatus } from './enums/key-card-issue-status.enums';
import { KeyCardEvents } from './enums/key-card-event.enums';
import { useMutation } from '@tanstack/react-query';
import { roomKeyRecordService } from '~features/room-booking/services/room-key-record.service';
import queryClient, { CacheKeys } from '~queries/queries';
import { ICreateRoomKeyRecord } from '~features/room-booking/interfaces';

export interface KeyDataInput {
    id?: number;
    roomBookingId: number;
    startDateTime: string;
    endDateTime: string;
    roomNumber: number;
    issueId: number;
    issueCount?: number;
}

interface KeyCardIssueModalContextType {
    isModalOpen: boolean;
    keyDataInput: KeyDataInput | null;
    modalMode: KeycardModalMode;
    issueStatus: KeycardIssueStatus;
    openModal: (mode: KeycardModalMode, input?: KeyDataInput) => void;
    closeModal: () => void;
    issue: () => void;
}

const KeyCardIssueModalContext = createContext<KeyCardIssueModalContextType | undefined>(
    undefined,
);

export const KeyCardIssueModalProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [keyDataInput, setKeyDataInput] = useState<KeyDataInput | null>(null);
    const [modalMode, setModalMode] = useState<KeycardModalMode>(KeycardModalMode.ISSUE);
    const [issueStatus, setIssueStatus] = useState<KeycardIssueStatus>(
        KeycardIssueStatus.PENDING,
    );
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const { mutate } = useMutation({
        mutationFn: (data: ICreateRoomKeyRecord) =>
            roomKeyRecordService.createRecord(data),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [CacheKeys.getRoomKeyRecords, keyDataInput?.roomBookingId],
            });
        },
    });

    const openModal = (mode: KeycardModalMode, input?: KeyDataInput) => {
        setIssueStatus(KeycardIssueStatus.PENDING);
        setModalMode(mode);
        if (input) {
            setKeyDataInput(input);
        }
        setIsModalOpen(true);
    };

    const closeModal = () => {
        abortController?.abort(); // if issuing, cancel the issuing process
        setIsModalOpen(false);
    };

    const issue = async () => {
        const ctrl = new AbortController();

        setIssueStatus(KeycardIssueStatus.SCANNING);
        setAbortController(ctrl);
        try {
            await fetchEventSource(`${process.env.REACT_APP_PC_APP_URL}/sse/issue-card`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(keyDataInput),
                signal: ctrl.signal,
                onmessage({ event, data }) {
                    if (event === KeyCardEvents.ERROR) {
                        throw new Error(data);
                    }
                    if (event === KeyCardEvents.ISSUE_CARD) {
                        setIssueStatus(KeycardIssueStatus.SUCCESS);
                        if (keyDataInput) {
                            mutate({
                                roomBookingId: keyDataInput.roomBookingId,
                                issueId: keyDataInput.issueId,
                                reissueNumber: keyDataInput.issueCount || 0,
                                checkinTimestamp: keyDataInput.startDateTime,
                                checkoutTimestamp: keyDataInput.endDateTime,
                            });
                        }
                    }
                },
                onerror(err) {
                    throw err;
                },
            });
        } catch (error) {
            setIssueStatus(KeycardIssueStatus.FAILED);
        }
    };

    const value = {
        isModalOpen,
        keyDataInput,
        modalMode,
        issueStatus,
        openModal,
        closeModal,
        setModalMode,
        issue,
    };

    return (
        <KeyCardIssueModalContext.Provider value={value}>
            {children}
        </KeyCardIssueModalContext.Provider>
    );
};

export const useKeyCardIssueModalContext = () => {
    const context = useContext(KeyCardIssueModalContext);
    if (context === undefined) {
        throw new Error(
            'useKeyCardIssueModalContext must be used within a KeyCardIssueModalProvider',
        );
    }
    return context;
};
