import { Button, Modal, Popconfirm, notification } from 'antd';
import { useRef, useState } from 'react';
import {
    Control,
    FieldValues,
    UseFormGetValues,
    UseFormSetError,
    UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessageType } from '~common/constants';
import { SingleSelect } from '~components';
import {
    ChangingBookingStatusesMap,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import { getAutoGeneratedCode } from '~features/room-booking/helper';
import {
    IRoomBookingItem,
    IUpdateBookingItemStatus,
} from '~features/room-booking/interfaces';
import { getBookingScheduleDefault } from '~features/room-booking/model';
import { BookingErrorModal } from '~features/room-booking/pages/RoomBookingListPage/components/RoomBookingList/BookingErrorModal';
import {
    selectedRoomBookingDetailSelector,
    updateBookingItemStatus,
} from '~features/room-booking/reducers/room-booking.reducer';
import {
    setSelectedRoomBookingSchedule,
    setShowCheckInForm,
} from '~features/room-booking/reducers/schedule.reducer';
import {
    getNotificationList,
    getReadNotificationTime,
} from '~features/admin-notification/reducers/notification.reducer';
import { roomBookingItemBookingStatusOptions } from '~features/room-booking/util';
import { getListForDropDown } from '~features/room/room.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate, parseTime } from '~plugins/dayjs';

type Props = {
    control: Control;
    setValue: UseFormSetValue<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setError: UseFormSetError<FieldValues>;
    bookingItem: IRoomBookingItem;
    disabled?: boolean;
    closePopover: (visible: boolean, isReload: boolean) => void;
    onUpdateStatusSuccess?: (booking: IRoomBookingItem) => void;
};

function ChangeStatusSelect(props: Props) {
    const {
        control,
        setValue,
        getValues,
        setError,
        bookingItem,
        disabled,
        closePopover,
        onUpdateStatusSuccess,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedBookingStatus = useRef<string>('');

    const [openChangeStatusPopConfirm, setOpenChangeStatusPopConfirm] = useState(false);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);

    const handleChangStatus = (key: string) => {
        setOpenChangeStatusPopConfirm(true);
        selectedBookingStatus.current = key;
    };

    const handleVisibleChange = (isVisible: boolean) => {
        if (openChangeStatusPopConfirm) {
            setOpenChangeStatusPopConfirm(isVisible);
            if (!isVisible) {
                setValue('bookingStatus', bookingItem.bookingStatus);
            }
        } else {
            setOpenChangeStatusPopConfirm(false);
            setValue('bookingStatus', bookingItem.bookingStatus);
        }
    };

    const getGuestNameWithBooking = () => {
        if (!selectedRoomBookingDetail) return '';
        const { autoGeneratedCode, representativeGuest } = selectedRoomBookingDetail;
        if (!representativeGuest) return getAutoGeneratedCode(autoGeneratedCode);
        const { fullName, yomigana } = representativeGuest;
        const guestName = `${getAutoGeneratedCode(autoGeneratedCode)} - ${yomigana}`;
        return fullName ? `${guestName} - ${fullName}` : guestName;
    };

    // PMS-4661 when trying to update splitted booking statuses to all checkedout by updating the parent status,
    // if there is any bookingItem with future CO date in the splitted bookings, user should not be able to do it
    const checkBookingIsSplittedBookingAndAnyBookingsHaveFutureCOdates = () => {
        const targetRoomBookingItems = selectedRoomBookingDetail?.roomBookingItems.filter(
            (roomBookingItem) =>
                roomBookingItem.id === bookingItem.id ||
                roomBookingItem.parentRoomBookingItemId === bookingItem.id,
        );

        const isAnyBookingsHaveFutureCOdates = targetRoomBookingItems?.every(
            (roomBookingItem) =>
                parseDate(roomBookingItem.endDateOfStay).isSameOrBefore(customDayjs()),
        );

        return (
            (targetRoomBookingItems?.length || 0) >= 2 && !isAnyBookingsHaveFutureCOdates
        );
    };

    const _updateBookingItemStatus = async (body: IUpdateBookingItemStatus) => {
        // PMS-4661 if there is any splitted booking which has future CO dates, user should not be able to update status to checked-out
        if (
            body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT &&
            checkBookingIsSplittedBookingAndAnyBookingsHaveFutureCOdates()
        ) {
            notification.warning({
                message: t('roomBooking.list.message.checkoutSplittedBookingsError'),
            });
            return;
        }

        const response = await dispatch(updateBookingItemStatus(body));
        setOpenChangeStatusPopConfirm(false);
        if (updateBookingItemStatus.fulfilled.match(response)) {
            if (response.payload?.success) {
                let message = null;
                if (body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT) {
                    message = t(
                        'facilityBooking.schedule.modalConfirmCheckout.description',
                    );
                }
                notification.success({
                    message: message || t('roomBooking.list.statusModalConfirm.success'),
                });
                onUpdateStatusSuccess?.(response.payload?.data?.items?.[0]);
                const isReload = !onUpdateStatusSuccess;
                closePopover(false, isReload);
                dispatch(getNotificationList());
                dispatch(getReadNotificationTime());
                return;
            }
            if (body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT) {
                if (response.payload?.errors?.length) {
                    BookingErrorModal({
                        title: t('roomBooking.list.checkoutDialog.unpaidTitle'),
                        okText: t('roomBooking.list.statusModalConfirm.okText'),
                        description: t('roomBooking.list.checkoutDialog.content'),
                        errorItems: [getGuestNameWithBooking()],
                    });
                }
                return;
            }
            Modal.error({
                title: t('roomBooking.list.statusPopConfirm.title'),
                content: response.payload?.message,
                okText: t('roomBooking.list.statusModalConfirm.okText'),
            });
        }
    };

    const showCheckInModal = () => {
        const {
            id,
            startDateOfStay,
            endDateOfStay,
            roomBookingId,
            checkInTime,
            checkOutTime,
            isDayUse,
        } = bookingItem;
        if (parseDate(startDateOfStay).isAfter(customDayjs(), 'day')) {
            notification.error({
                message: t('roomBooking.schedule.message.cannotCheckIn.fromNotArrived', {
                    date: parseDate(startDateOfStay)?.fmYYYYMMDD('-'),
                }),
            });
            return;
        }
        dispatch(
            setSelectedRoomBookingSchedule({
                ...getBookingScheduleDefault(),
                roomBookingId: roomBookingId,
                id: Number(id) || 0,
                roomType: bookingItem.roomType || { id: null, name: '' },
                room: {
                    id: bookingItem.room?.id || null,
                    name: bookingItem.room?.name || '',
                },
                stayingStartDate: parseDate(startDateOfStay).fmYYYYMMDD(),
                stayingEndDate: parseDate(endDateOfStay).fmYYYYMMDD(),
                checkInTime: checkInTime,
                checkOutTime: checkOutTime,
                isDayUse: isDayUse,
            }),
        );
        // get room drop down
        dispatch(
            getListForDropDown({
                roomBookingItemId: Number(id) || 0,
                isDayUse,
                roomTypeId: bookingItem.roomType?.id || undefined,
                roomBookingStayPeriod: [
                    `${parseDate(startDateOfStay).fmYYYYMMDD()} ${parseTime(
                        checkInTime,
                    ).fmHHmmss()}`,
                    `${parseDate(endDateOfStay).fmYYYYMMDD()} ${parseTime(
                        checkOutTime,
                    ).fmHHmmss()}`,
                ],
            }),
        );
        dispatch(setShowCheckInForm(true));
    };

    const changeBookingStatusConfirm = async () => {
        const bookingStatus = getValues('bookingStatus');
        if (
            bookingStatus !== bookingItem.bookingStatus &&
            !ChangingBookingStatusesMap[bookingItem.bookingStatus]?.includes(
                bookingStatus as RoomBookingItemBookingStatus,
            )
        ) {
            setError(
                'bookingStatus',
                {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.detail.message.cannotChangeStatus', {
                        status: t(`roomBooking.page.bookingStatus.${bookingStatus}`),
                    }),
                },
                { shouldFocus: true },
            );
            setOpenChangeStatusPopConfirm(false);
            return;
        }
        if (bookingStatus === RoomBookingItemBookingStatus.CHECKED_IN) {
            showCheckInModal();
            return;
        }
        _updateBookingItemStatus({
            bookingStatus: bookingStatus as RoomBookingItemBookingStatus,
            ids: [bookingItem.id as number],
        });
    };

    const changeBookingStatusCancel = () => {
        setOpenChangeStatusPopConfirm(false);
        setValue('bookingStatus', bookingItem.bookingStatus);
    };

    return (
        <div className="change-booking-status-wrapper">
            <SingleSelect
                label=""
                name="bookingStatus"
                placeholder={t('roomBooking.detail.bookingItemCard.status.placeholder')}
                control={control}
                options={roomBookingItemBookingStatusOptions()}
                onChange={handleChangStatus}
                disabled={disabled}
            />
            <Popconfirm
                title={
                    <div className="change-booking-status-title">
                        <span className="title-status-pop-confirm">
                            {t('roomBooking.list.statusPopConfirm.title')}
                        </span>
                        <br />
                        {t('roomBooking.list.statusPopConfirm.content', {
                            status: t(
                                `roomBooking.page.bookingStatus.${selectedBookingStatus.current}`,
                            ),
                        })}
                    </div>
                }
                open={openChangeStatusPopConfirm}
                placement="bottom"
                overlayClassName="change-booking-status-popconfirm"
                okText={t('roomBooking.list.statusPopConfirm.okText')}
                cancelText={t('roomBooking.list.statusPopConfirm.cancelText')}
                onOpenChange={handleVisibleChange}
                onConfirm={changeBookingStatusConfirm}
                onCancel={changeBookingStatusCancel}
            ></Popconfirm>
        </div>
    );
}

export default ChangeStatusSelect;
