import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { mealReportBreadcrumbs } from '~features/report/constants';
import FilterForm from './components/FilterForm/FilterForm';
import MealReportTable from './components/MealReportTable/MealReportTable';

import { useState, useMemo } from 'react';
import customDayjs, { type Dayjs } from '~plugins/dayjs';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '~queries/queries';
import { reportService } from '~features/report/report.service';
import { convertMealReportData } from '~features/report/helper';
import { IMealTableItem } from '~features/report/interfaces';

export type MealReportData = {
    dateHeaders: string[];
    values: IMealTableItem[];
    totalOfAllMealItem: number;
    totalOfAllMealByDay: {
        [key: string]: number;
    };
};

export default function MealReportPage() {
    const { t } = useTranslation();

    const [date, setDate] = useState<[Dayjs, Dayjs]>([
        customDayjs().startOf('day'),
        customDayjs().endOf('day'),
    ]);

    const datePeriod = useMemo(() => {
        return date.map((date: Dayjs) => date?.fmYYYYMMDDHHmmss());
    }, [date]);

    const { refetch, data, isRefetching } = useQuery({
        queryKey: [CacheKeys.mealReport, ...datePeriod],
        queryFn: async () => {
            const res = await reportService.getMealReport({ datePeriod });
            if (res.success) return res.data;
            throw Error(res.message);
        },
        enabled: false,
    });

    const tableData: MealReportData = useMemo(() => {
        if (!data)
            return {
                dateHeaders: [],
                values: [],
                totalOfAllMealItem: 0,
                totalOfAllMealByDay: {},
            };
        return convertMealReportData(data, datePeriod);
    }, [data]);

    return (
        <div>
            <StaffLayoutHeader
                title={t('report.mealReport.title')}
                breadcrumbs={mealReportBreadcrumbs()}
            />
            <FilterForm
                refetch={refetch}
                date={date}
                setDate={setDate}
                tableData={tableData}
                isRefetching={isRefetching}
            />
            {tableData.values.length > 0 && (
                <MealReportTable tableData={tableData} isRefetching={isRefetching} />
            )}
        </div>
    );
}
