import {
    Button,
    Card,
    Drawer,
    Empty,
    notification,
    Radio,
    RadioChangeEvent,
    Spin,
    Switch,
} from 'antd';
import {
    bulkUpdateRoomCleanings,
    fetchRoomCleaningList,
    roomCleaningListQuerySelector,
    roomCleaningListSelector,
    setRoomCleaningListQuery,
    showLoadingSelector,
    totalPageSelector,
} from '~features/room-cleaning/reducers/room-cleaning.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    IUpdateResult,
    IRoomCleaning,
    IRoomCleaningBulkUpdateFormItem,
} from '~features/room-cleaning/interfaces';
import './RoomCleaningListMweb.scss';
import { useTranslation } from 'react-i18next';
import { CleaningStatus } from '~features/room-booking/constants';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { keyBy, merge, values } from 'lodash';
import {
    initRoomCleaningListQuery,
    RoomBookingItemBookingStatus,
} from '~features/room-cleaning/constants';
import dayjs from '~plugins/dayjs';
import { InputTextArea } from '~components';
import { Control, FieldValues, UseFormReset } from 'react-hook-form';
import { showConfirm } from '~common/notification';
import { ReactComponent as UserNoneIcon } from '~assets/images/user-none.svg';
import { IRoomBookingItem } from '~features/room-booking/interfaces';

type Iprops = {
    control: Control<FieldValues, unknown>;
    reset: UseFormReset<FieldValues>;
};

enum StatusBar {
    READY = 'ready',
    NOT_READY = 'not_ready',
}

function RoomCleaningListMweb({ control, reset }: Iprops) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const roomCleaningListQuery = useAppSelector(roomCleaningListQuerySelector);
    const currentPageRoomCleaningData = useAppSelector(roomCleaningListSelector);
    const totalPage = useAppSelector(totalPageSelector);
    const showLoading = useAppSelector(showLoadingSelector);

    const [roomCleaningList, setRoomCleaningList] = useState(currentPageRoomCleaningData);
    const [isShowUncleanedRoom, setIsShowUncleanedRoom] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [memoTextArea, setMemoTextArea] = useState('');
    const [selectedRoomCleaning, setSelectedRoomCleaning] = useState<
        IRoomCleaning | undefined
    >(undefined);

    const fetchData = () => {
        dispatch(fetchRoomCleaningList());
    };

    useEffect(() => {
        dispatch(setRoomCleaningListQuery(initRoomCleaningListQuery));
    }, []);

    useEffect(() => {
        fetchData();
    }, [roomCleaningListQuery]);

    const sortByEarliestArrival = (rooms: IRoomCleaning[]) => {
        return rooms.sort((a, b) => {
            const aCheckInBooking = a.roomBookingItems.find((item) =>
                dayjs().isSame(dayjs(item.startDateOfStay), 'day'),
            );
            const bCheckInBooking = b.roomBookingItems.find((item) =>
                dayjs().isSame(dayjs(item.startDateOfStay), 'day'),
            );
            // sort by booking that has check in today
            if (aCheckInBooking?.id && !bCheckInBooking?.id) return -1;
            if (!aCheckInBooking?.id && bCheckInBooking?.id) return 1;

            const aIsNotArrived =
                aCheckInBooking?.bookingStatus ===
                RoomBookingItemBookingStatus.NOT_ARRIVED;
            const bIsNotArrived =
                bCheckInBooking?.bookingStatus ===
                RoomBookingItemBookingStatus.NOT_ARRIVED;
            // sort by bookings that has status not_arrived
            if (aIsNotArrived && !bIsNotArrived) return -1;
            if (!aIsNotArrived && bIsNotArrived) return 1;

            if (aIsNotArrived && bIsNotArrived) {
                const aCheckInTime = dayjs(aCheckInBooking.checkInTime, 'HH:mm');
                const bCheckInTime = dayjs(bCheckInBooking.checkInTime, 'HH:mm');
                // sort by bookings that has earliest checkIn time
                return aCheckInTime.isBefore(bCheckInTime) ? -1 : 1;
            }
            return 0;
        });
    };

    useEffect(() => {
        // handle edge case when filter uncleaned room, but all room already cleaned
        if (roomCleaningListQuery.page === 1 && !currentPageRoomCleaningData.length) {
            setRoomCleaningList([]);
        } else {
            // merge roomCleaningList with new data from the next page
            const mergedList = merge(
                keyBy(roomCleaningList, 'id'),
                keyBy(currentPageRoomCleaningData, 'id'),
            );
            const sortedRooms = sortByEarliestArrival(values(mergedList));
            setRoomCleaningList(sortedRooms);
        }
    }, [currentPageRoomCleaningData]);

    const onFilterChange = (e: RadioChangeEvent) => {
        setIsShowUncleanedRoom(e.target.value);
    };

    useEffect(() => {
        setRoomCleaningList([]);
        dispatch(
            setRoomCleaningListQuery({
                ...roomCleaningListQuery,
                cleaningStatus: isShowUncleanedRoom
                    ? CleaningStatus.UNCLEANED
                    : undefined,
                page: 1,
            }),
        );
    }, [isShowUncleanedRoom]);

    const onChangePage = () => {
        dispatch(
            setRoomCleaningListQuery({
                ...roomCleaningListQuery,
                page: (roomCleaningListQuery.page || 1) + 1,
            }),
        );
    };

    const updateRoomCleaningList = (roomCleaning: IRoomCleaning) => {
        // merge updated data to roomCleaningList
        const mergedList = merge(
            keyBy(roomCleaningList, 'id'),
            keyBy([roomCleaning], 'id'),
        );
        const sortedRooms = sortByEarliestArrival(values(mergedList));
        setRoomCleaningList(sortedRooms);
    };

    const updateRoomCleaningData = useCallback(
        async (roomCleaning: IRoomCleaningBulkUpdateFormItem) => {
            const response = await dispatch(bulkUpdateRoomCleanings([roomCleaning]));
            const result: IUpdateResult = { success: false };
            if (bulkUpdateRoomCleanings.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('roomCleaning.list.updateSuccessMessage'),
                    });
                    result.success = true;
                } else {
                    notification.error({
                        message: response.payload?.errors?.[0]?.message || '',
                    });
                }
            }
            return result;
        },
        [],
    );

    const openMemoDrawer = (roomCleaning: IRoomCleaning) => {
        reset({ drawer: { memo: roomCleaning?.memo || '' } });
        setSelectedRoomCleaning(roomCleaning);
        setMemoTextArea(roomCleaning?.memo || '');
        setIsDrawerOpen(true);
    };
    const closeMemoDrawer = () => {
        setSelectedRoomCleaning(undefined);
        setMemoTextArea('');
        setIsDrawerOpen(false);
    };
    const submitlUpdateMemo = async () => {
        if (selectedRoomCleaning) {
            const result = await updateRoomCleaningData({
                id: selectedRoomCleaning?.id,
                cleaningStatus: selectedRoomCleaning?.cleaningStatus,
                memo: memoTextArea,
            });
            if (result.success) {
                updateRoomCleaningList({ ...selectedRoomCleaning, memo: memoTextArea });
                closeMemoDrawer();
            }
        }
    };
    const deleteMemo = async (roomCleaning: IRoomCleaning) => {
        showConfirm({
            title: t('roomCleaning.list.confirm.titleUpdateMemo'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            async onOk() {
                const result = await updateRoomCleaningData({
                    id: roomCleaning?.id,
                    cleaningStatus: roomCleaning?.cleaningStatus,
                    memo: '',
                });
                if (result.success) {
                    updateRoomCleaningList({ ...roomCleaning, memo: '' });
                }
            },
        });
    };
    const onChangeCleaningStatus = (value: string, roomCleaning: IRoomCleaning) => {
        showConfirm({
            title: t('roomCleaning.list.confirm.titleSwitchStatus'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            async onOk() {
                const updatedCleaningStatus =
                    value === CleaningStatus.CLEANED
                        ? CleaningStatus.UNCLEANED
                        : CleaningStatus.CLEANED;
                const result = await updateRoomCleaningData({
                    id: roomCleaning?.id,
                    cleaningStatus: updatedCleaningStatus,
                    memo: roomCleaning.memo,
                });
                if (result.success) {
                    if (isShowUncleanedRoom) {
                        // refresh data when toggle room to unclean
                        dispatch(
                            setRoomCleaningListQuery({
                                ...roomCleaningListQuery,
                                page: 1,
                            }),
                        );
                        // remove cleaned data from uncleaned list
                        setRoomCleaningList(
                            roomCleaningList.filter(
                                (room) => room.id !== roomCleaning.id,
                            ),
                        );
                    } else {
                        updateRoomCleaningList({
                            ...roomCleaning,
                            cleaningStatus: updatedCleaningStatus,
                        });
                    }
                }
            },
        });
    };

    const cleaningStatusBar = (
        cleaningStatus: CleaningStatus,
        roomBookings: IRoomBookingItem[],
    ) => {
        if (cleaningStatus === CleaningStatus.CLEANED || !roomBookings.length) {
            return <></>;
        }
        const checkOutBooking = roomBookings.find((item) =>
            dayjs().isSame(dayjs(item.endDateOfStay), 'day'),
        );
        const checkInBooking = roomBookings.find((item) =>
            dayjs().isSame(dayjs(item.startDateOfStay), 'day'),
        );
        // handle case when guest is booking the room for multiple days
        if (!checkOutBooking?.id && !checkInBooking?.id) {
            const stayingGuest = roomBookings[0];
            return (
                <div className="room-cleaning-row">
                    <div className="room-cleaning-status-bar room-cleaning-status-bar-ready">
                        {stayingGuest?.bookingStatus ===
                        RoomBookingItemBookingStatus.CHECKED_IN
                            ? t(`roomCleaning.list.statusBar.stay`)
                            : t(`roomCleaning.list.statusBar.ready`)}
                    </div>
                </div>
            );
        }
        const status =
            checkOutBooking?.bookingStatus === RoomBookingItemBookingStatus.CHECKED_IN
                ? StatusBar.NOT_READY
                : StatusBar.READY;
        return (
            <div className="room-cleaning-row">
                <div
                    className={`room-cleaning-status-bar room-cleaning-status-bar-${status}`}
                >
                    {t(`roomCleaning.list.statusBar.${status}`)}
                </div>
            </div>
        );
    };

    const CheckInCheckOutInfo = (roomBookings: IRoomBookingItem[]) => {
        const checkOutBooking = roomBookings.find((item) =>
            dayjs().isSame(dayjs(item.endDateOfStay), 'day'),
        );
        const checkInBooking = roomBookings.find((item) =>
            dayjs().isSame(dayjs(item.startDateOfStay), 'day'),
        );
        // handle case when guest is booking the room for multiple days
        const stayingGuest = roomBookings[0];
        if (
            !checkOutBooking?.id &&
            !checkInBooking?.id &&
            [
                RoomBookingItemBookingStatus.CHECKED_IN,
                RoomBookingItemBookingStatus.NOT_ARRIVED,
                RoomBookingItemBookingStatus.CHECKED_OUT,
            ].includes(stayingGuest?.bookingStatus as RoomBookingItemBookingStatus)
        ) {
            return (
                <div className="room-cleaning-row">
                    <div className="room-cleaning-cico-box">
                        <div className="room-cleaning-cico-box-header">
                            {stayingGuest?.bookingStatus ===
                            RoomBookingItemBookingStatus.NOT_ARRIVED
                                ? t('roomCleaning.list.scheduledCI')
                                : t('roomCleaning.list.scheduledCO')}
                        </div>
                        <div
                            className={`room-cleaning-cico-box-body room-cleaning-cico-box-body-bg-${stayingGuest.bookingStatus}`}
                        >
                            <div
                                className={`room-cleaning-cico-box-body-status room-cleaning-cico-box-body-status-${stayingGuest.bookingStatus}`}
                            >
                                {t(
                                    `roomCleaning.list.roomStatus.${stayingGuest.bookingStatus}`,
                                )}
                            </div>
                            <div>
                                {dayjs(
                                    stayingGuest?.bookingStatus ===
                                        RoomBookingItemBookingStatus.NOT_ARRIVED
                                        ? stayingGuest.startDateOfStay
                                        : stayingGuest.endDateOfStay,
                                ).format('YYYY-MM-DD')}
                            </div>
                            <div>
                                {stayingGuest?.bookingStatus ===
                                RoomBookingItemBookingStatus.NOT_ARRIVED
                                    ? stayingGuest.checkInTime
                                    : stayingGuest.checkOutTime}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="room-cleaning-row">
                <div className="room-cleaning-cico-box">
                    <div className="room-cleaning-cico-box-header">
                        {t('roomCleaning.list.scheduledCO')}
                    </div>
                    {checkOutBooking?.id &&
                    [
                        RoomBookingItemBookingStatus.CHECKED_IN,
                        RoomBookingItemBookingStatus.NOT_ARRIVED,
                        RoomBookingItemBookingStatus.CHECKED_OUT,
                    ].includes(
                        checkOutBooking?.bookingStatus as RoomBookingItemBookingStatus,
                    ) ? (
                        <div
                            className={`room-cleaning-cico-box-body room-cleaning-cico-box-body-bg-${checkOutBooking.bookingStatus}`}
                        >
                            <div
                                className={`room-cleaning-cico-box-body-status room-cleaning-cico-box-body-status-${checkOutBooking.bookingStatus}`}
                            >
                                {t(
                                    `roomCleaning.list.roomStatus.${checkOutBooking.bookingStatus}`,
                                )}
                            </div>
                            <div>
                                {dayjs(checkOutBooking.endDateOfStay).format(
                                    'YYYY-MM-DD',
                                )}
                            </div>
                            <div>{checkOutBooking.checkOutTime}</div>
                        </div>
                    ) : (
                        <div className="room-cleaning-cico-box-body room-cleaning-cico-box-body-bg-empty">
                            <UserNoneIcon className="user-none-icon" />
                            <div>{t('roomCleaning.list.roomStatus.empty')}</div>
                        </div>
                    )}
                </div>
                <div className="room-cleaning-cico-box">
                    <div className="room-cleaning-cico-box-header">
                        {t('roomCleaning.list.scheduledCI')}
                    </div>
                    {checkInBooking?.id &&
                    [
                        RoomBookingItemBookingStatus.CHECKED_IN,
                        RoomBookingItemBookingStatus.NOT_ARRIVED,
                        RoomBookingItemBookingStatus.CHECKED_OUT,
                    ].includes(
                        checkInBooking?.bookingStatus as RoomBookingItemBookingStatus,
                    ) ? (
                        <div
                            className={`room-cleaning-cico-box-body room-cleaning-cico-box-body-bg-${checkInBooking.bookingStatus}`}
                        >
                            <div
                                className={`room-cleaning-cico-box-body-status room-cleaning-cico-box-body-status-${checkInBooking.bookingStatus}`}
                            >
                                {t(
                                    `roomCleaning.list.roomStatus.${checkInBooking.bookingStatus}`,
                                )}
                            </div>
                            <div>
                                {dayjs(checkInBooking.startDateOfStay).format(
                                    'YYYY-MM-DD',
                                )}
                            </div>
                            <div>{checkInBooking.checkInTime}</div>
                        </div>
                    ) : (
                        <div className="room-cleaning-cico-box-body room-cleaning-cico-box-body-bg-empty">
                            <UserNoneIcon className="user-none-icon" />
                            <div>{t('roomCleaning.list.roomStatus.empty')}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="filter-room-cleaning-wrapper">
                <Radio.Group
                    defaultValue={false}
                    buttonStyle="solid"
                    onChange={onFilterChange}
                    value={isShowUncleanedRoom}
                >
                    <Radio.Button value={false}>
                        {t('roomCleaning.list.filter.all')}
                    </Radio.Button>
                    <Radio.Button value={true}>
                        {t('roomCleaning.list.filter.notCleaned')}
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div className="room-cleaning-list-mweb-wrapper">
                {roomCleaningList.map((roomCleaning) => (
                    <div key={roomCleaning.id}>
                        <Card className="room-cleaning-card">
                            <div className="room-cleaning-content">
                                <div className="room-cleaning-row">
                                    <div className="font-bold text-lg">
                                        {roomCleaning.name}
                                    </div>
                                    <div className="cleaning-status text-md">
                                        <span
                                            className={`cleaning-status-text-${
                                                roomCleaning.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleaned'
                                                    : 'uncleaned'
                                            }`}
                                        >
                                            {roomCleaning.cleaningStatus ===
                                            CleaningStatus.CLEANED
                                                ? t(
                                                      'roomCleaning.list.cleanStatus.cleaned',
                                                  )
                                                : t(
                                                      'roomCleaning.list.cleanStatus.notCleaned',
                                                  )}
                                        </span>
                                        <Switch
                                            checked={
                                                roomCleaning.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                            }
                                            onChange={() => {
                                                onChangeCleaningStatus(
                                                    roomCleaning.cleaningStatus,
                                                    roomCleaning,
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                {cleaningStatusBar(
                                    roomCleaning.cleaningStatus,
                                    roomCleaning?.roomBookingItems,
                                )}
                                {CheckInCheckOutInfo(roomCleaning?.roomBookingItems)}
                                {roomCleaning?.memo ? (
                                    <>
                                        <div>{roomCleaning.memo}</div>
                                        <div className="room-cleaning-memo-action-wrapper">
                                            <Button
                                                type="text"
                                                onClick={() =>
                                                    openMemoDrawer(roomCleaning)
                                                }
                                            >
                                                <EditOutlined className="text-md" />
                                                <span className="text-md">
                                                    {t(
                                                        'roomCleaning.list.action.editMemo',
                                                    )}
                                                </span>
                                            </Button>
                                            <Button
                                                type="text"
                                                onClick={() => deleteMemo(roomCleaning)}
                                                loading={showLoading}
                                            >
                                                <DeleteOutlined className="text-md" />
                                                <span className="text-md">
                                                    {t('roomCleaning.list.action.delete')}
                                                </span>
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <Button
                                            type="text"
                                            onClick={() => openMemoDrawer(roomCleaning)}
                                        >
                                            <PlusOutlined className="text-md" />
                                            <span className="text-md">
                                                {t('roomCleaning.list.action.addMemo')}
                                            </span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </div>
                ))}
                {roomCleaningList.length === 0 && showLoading && (
                    <Spin className="loading-spinner" />
                )}
                {roomCleaningList.length === 0 && !showLoading && (
                    <Empty
                        description={t(`roomCleaning.list.filter.noData`)}
                        style={{ marginTop: '32px' }}
                    />
                )}
                {roomCleaningList.length > 0 &&
                    totalPage > 1 &&
                    (roomCleaningListQuery.page || 1) <= totalPage && (
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => onChangePage()}
                            loading={showLoading}
                            block
                        >
                            {t('roomCleaning.list.action.loadMore')}
                        </Button>
                    )}
                <Drawer
                    title={t(
                        `roomCleaning.list.action.${
                            selectedRoomCleaning?.memo ? 'editMemo' : 'addMemo'
                        }`,
                    )}
                    placement="bottom"
                    onClose={() => closeMemoDrawer()}
                    open={isDrawerOpen}
                >
                    <InputTextArea
                        label=""
                        rows={6}
                        placeholder={t('roomCleaning.list.memoInput')}
                        name="drawer.memo"
                        control={control}
                        onChange={(e) => setMemoTextArea(e.target.value)}
                    />
                    <div className="room-cleaning-drawer-action">
                        <Button
                            className="room-cleaning-drawer-action-btn"
                            size="large"
                            onClick={() => closeMemoDrawer()}
                        >
                            {t('roomCleaning.list.action.cancel')}
                        </Button>
                        <Button
                            className="room-cleaning-drawer-action-btn"
                            type="primary"
                            size="large"
                            onClick={() => submitlUpdateMemo()}
                            loading={showLoading}
                        >
                            {t('roomCleaning.list.action.save')}
                        </Button>
                    </div>
                </Drawer>
            </div>
        </>
    );
}

export default RoomCleaningListMweb;
