import { Card, Layout } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleViewMode, TOP_BAR_HEIGHT } from '~common/constants';
import { RightDrawerLayout, StaffLayoutHeader } from '~components';
import { getChildrenDropdown } from '~features/children-type/reducers/children.reducer';
import {
    MAX_HEIGHT_SCHEDULE_VIEW,
    MIN_HEIGHT_SCHEDULE_VIEW,
} from '~features/facility-booking/constants';
import { getPlanDropdown } from '~features/plan/reducers/plan.reducer';
import {
    drawerBodyPadding,
    drawerFooterHeight,
    drawerHeaderSize,
    FILTER_FORM_SCHEDULE_HEIGHT,
    FILTER_FORM_SCHEDULE_MARGIN,
    RoomBookingEvent,
    RoomBookingItemBookingStatus,
    SCHEDULE_PAGE_PADDING,
    timeControlHeight,
} from '~features/room-booking/constants';
import { convertBooking } from '~features/room-booking/helper';
import {
    IRoomBookingSchedule,
    IRoomBookingStaticItem,
} from '~features/room-booking/interfaces';
import {
    bulkRemoveBookingTmp,
    getRoomList,
    getStatisticByDateV2,
    getStopRoomInDuration,
    getTemporaryBookingItemList,
    setCurrentDate,
    setCurrentStatus,
    setCurrentViewMode,
    setDateHeaderFilterUnassignForm,
    setIsShowUnassignList,
    setShowCreateBookingForm,
    setStoppingRoomList,
    setVisibleCreateBookingPopover,
} from '~features/room-booking/reducers/schedule.reducer';
import { setUnassignRoomList } from '~features/room-management/reducers/room-management.reducer';
import { useEscape } from '~common/useHooks';
import { useAppDispatch } from '~hooks';
import customDayjs from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import { SocketEvents } from '~plugins/socket/socket';
import { useSocket } from '~plugins/socket/socketContext';
import { ScheduleTabPane } from './components/ScheduleTabPane/ScheduleTabPane';
import './SchedulePage.scss';

export function SchedulePage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isExpandSchedule, setIsExpandSchedule] = useState(false);

    const breadcrumbs = () => [
        {
            text: t('roomBooking.schedule.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.schedule.breadcrumbs.schedule'),
        },
        {
            text: t('roomBooking.schedule.breadcrumbs.scheduleSetting'),
        },
    ];

    useEffect(() => {
        dispatch(getRoomList());
        dispatch(getChildrenDropdown());
        dispatch(getPlanDropdown({}));
        dispatch(setUnassignRoomList([]));
        dispatch(getTemporaryBookingItemList());
        dispatch(setCurrentViewMode(ScheduleViewMode.WEEK));
        return () => {
            // Anything in here is fired on component unmount.
            dispatch(setShowCreateBookingForm(false));
            dispatch(setVisibleCreateBookingPopover(false));
        };
    }, []);

    const socket = useSocket();
    const [dialogData, setDialogData] = React.useState<{
        bookings: IRoomBookingSchedule[];
        isOpen: boolean;
    }>({
        isOpen: false,
        bookings: [],
    });
    const [dialogDeletedData, setDialogDeletedData] = React.useState<{
        bookings: IRoomBookingSchedule[];
        isOpen: boolean;
    }>({
        isOpen: false,
        bookings: [],
    });
    const { emitter } = useMitt();

    const updateNumberOfBooking = () => {
        dispatch(getStopRoomInDuration());
        dispatch(getStatisticByDateV2());
    };

    useEffect(() => {
        if (!socket) return;
        const parseBooking = (data: IRoomBookingStaticItem[]) => {
            const _bookingTemporaryList: IRoomBookingSchedule[] = [];
            _.forEach(data, (booking) => {
                const _booking = convertBooking(booking);
                const index = _.findIndex(
                    _bookingTemporaryList,
                    (item) => item.id === _booking.id,
                );
                if (index > -1) {
                    _bookingTemporaryList[index] = _booking;
                } else {
                    _bookingTemporaryList.push(_booking);
                }
            });
            return _bookingTemporaryList;
        };
        socket.on(SocketEvents.WEB_APP_EXPIRED_TEMP_ROOM_BOOKING_NOTIFICATION, (data) => {
            setDialogData({
                isOpen: true,
                bookings: parseBooking(data),
            });
        });
        socket.on(
            SocketEvents.WEB_APP_DELETED_EXPIRED_TEMP_ROOM_BOOKING_NOTIFICATION,
            (data) => {
                const bookings = parseBooking(data);
                setDialogDeletedData({
                    isOpen: true,
                    bookings: bookings,
                });
                const ids = bookings.map((b) => b.id);
                dispatch(bulkRemoveBookingTmp({ ids }));
                emitter.emit(RoomBookingEvent.BOOKING_DELETED, ids);
            },
        );
        socket.on(SocketEvents.WEB_APP_TRIGGER_TLL_ROOM_BOOKING_NOTIFICATION, (data) => {
            updateNumberOfBooking();
        });
        return () => {
            socket?.off(SocketEvents.WEB_APP_TRIGGER_TLL_ROOM_BOOKING_NOTIFICATION);
            socket?.off(SocketEvents.WEB_APP_EXPIRED_TEMP_ROOM_BOOKING_NOTIFICATION);
            socket?.off(
                SocketEvents.WEB_APP_DELETED_EXPIRED_TEMP_ROOM_BOOKING_NOTIFICATION,
            );
        };
    }, [socket]);

    useEffect(() => {
        dispatch(setStoppingRoomList({}));
        return () => {
            dispatch(setCurrentDate(customDayjs().startOf('week')?.fmYYYYMMDD()));
            dispatch(setCurrentStatus(RoomBookingItemBookingStatus.ALL));
        };
    }, []);

    useEffect(() => {
        dispatch(setIsShowUnassignList(false));
        dispatch(setDateHeaderFilterUnassignForm(null));
    }, []);

    const calculateScheduleHeight = () => {
        const scheduleHeight =
            window.innerHeight -
            TOP_BAR_HEIGHT -
            timeControlHeight -
            SCHEDULE_PAGE_PADDING -
            FILTER_FORM_SCHEDULE_HEIGHT -
            FILTER_FORM_SCHEDULE_MARGIN;

        if (scheduleHeight > MAX_HEIGHT_SCHEDULE_VIEW) return MAX_HEIGHT_SCHEDULE_VIEW;
        if (scheduleHeight < MIN_HEIGHT_SCHEDULE_VIEW) return MIN_HEIGHT_SCHEDULE_VIEW;
        return scheduleHeight;
    };

    const onClose = () => {
        setIsExpandSchedule(false);
    };
    useEscape(onClose);

    return (
        <div className="schedule-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('roomBooking.schedule.title')}
            />
            <Layout.Content>
                <div className="schedule-page-wrapper">
                    <Card className="schedule-card">
                        {!isExpandSchedule && (
                            <ScheduleTabPane
                                height={calculateScheduleHeight()}
                                isExpandSchedule={isExpandSchedule}
                                setIsExpandSchedule={setIsExpandSchedule}
                                dialogData={dialogData}
                                setDialogData={setDialogData}
                                dialogDeletedData={dialogDeletedData}
                                setDialogDeletedData={setDialogDeletedData}
                            />
                        )}
                    </Card>
                </div>
                {isExpandSchedule && (
                    <RightDrawerLayout
                        open={isExpandSchedule}
                        onClose={onClose}
                        title={' '}
                        width={'100VW'}
                        footer={<div></div>}
                        className="expand-schedule-drawer"
                    >
                        <ScheduleTabPane
                            height={
                                window.innerHeight -
                                drawerHeaderSize -
                                drawerBodyPadding -
                                timeControlHeight -
                                drawerFooterHeight
                            }
                            isExpandSchedule={isExpandSchedule}
                            setIsExpandSchedule={setIsExpandSchedule}
                            dialogData={dialogData}
                            setDialogData={setDialogData}
                            dialogDeletedData={dialogDeletedData}
                            setDialogDeletedData={setDialogDeletedData}
                        />
                    </RightDrawerLayout>
                )}
            </Layout.Content>
        </div>
    );
}
