import { FieldTimeOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React, { HTMLProps, MutableRefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './BookingCard.scss';
import { DragBox } from './DragBox';
import { IFacilityBookingScheduleItem } from '~features/facility-booking/interfaces';

import { showBookingDetailModal } from '~features/facility-booking/util';
import { useNavigate } from 'react-router-dom';
import { useScheduleEvent } from '~features/room-booking/util';
import { useBookingCardHover } from '~common/useHooks';
import { VariableSizeGrid } from 'react-window';

type BookingCardProps = HTMLProps<HTMLDivElement> & {
    booking: IFacilityBookingScheduleItem;
    endDrag: () => void;
    left: number;
    top: number;
    width: number;
    gridRef: MutableRefObject<VariableSizeGrid>;
    outerRef: MutableRefObject<HTMLElement | undefined>;
    gridViewContentHeightRef?: MutableRefObject<number | undefined>;
};

const WIDTH_OF_CHARACTER = 8; // px

export const BookingCard = ({
    booking,
    endDrag,
    left,
    top,
    width,
    gridRef,
    outerRef,
    gridViewContentHeightRef,
    ...rest
}: BookingCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { triggerScroll } = useScheduleEvent();
    const statusText = useMemo(() => {
        const { status } = booking;
        if (!status) return '';
        return t('facilityBooking.list.bookingStatus.' + status);
    }, [booking, t]);
    const cardClasses = classNames('facility-booking-card-wrapper', {
        ['status-' + booking.status]: true,
    });
    const textDesc = useMemo(() => {
        const _checkOutTime = booking.checkOutTime ? ' ~ ' + booking.checkOutTime : '';
        return booking.checkInTime + _checkOutTime;
    }, [booking]);

    const contentWidth = useMemo(() => {
        return (
            WIDTH_OF_CHARACTER *
                (booking.guest?.yomigana?.length || 0 + statusText.length) +
            10 +
            16 // 10: icon, 16: padding
        );
    }, [booking, statusText]);

    const renderItem = (showStatus = true) => {
        return (
            <>
                <div
                    className="booking-card-title"
                    style={{
                        display: contentWidth < width ? 'flex' : 'inline-block',
                    }}
                >
                    <span className="guest-name">{booking.guest?.yomigana}</span>
                    {showStatus && <span className="status-name">{statusText}</span>}
                </div>
                <div className="booking-card-content">
                    {(booking.rooms?.length || 0) > 0 && (
                        <>
                            <KeyOutlined className="mr-4" />
                            <span className="text-desc mr-10">
                                {booking.rooms?.map((room) => room.name).join(',')}
                            </span>
                        </>
                    )}
                    <FieldTimeOutlined className={classNames('mr-4', {})} />
                    <span className="text-desc mr-10">{textDesc}</span>
                    <UserOutlined className="mr-4" />
                    <span className="text-desc">{booking.numberOfGuests}</span>
                </div>
            </>
        );
    };

    const { onMouseLeave, onMouseEnter, createNewElement, removeElement, onMouseDown } =
        useBookingCardHover(
            renderItem,
            () => {
                triggerScroll(true);
            },
            true,
        );

    const editBooking = (e: React.MouseEvent<HTMLElement>) => {
        const element = createNewElement(e);
        triggerScroll(false);
        showBookingDetailModal({
            booking,
            element: element as HTMLElement,
            navigate: navigate,
            onClose: () => {
                triggerScroll(true);
                removeElement();
            },
            isShowEditBtn: true,
            position: { pageX: e.pageX, pageY: e.pageY },
        });
    };

    return (
        <DragBox
            booking={booking}
            left={left}
            top={top}
            width={width}
            hideSourceOnDrag={true}
            endDrag={endDrag}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            onMouseDown={onMouseDown}
            gridRef={gridRef}
            outerRef={outerRef}
            gridViewContentHeightRef={gridViewContentHeightRef}
        >
            <div className={cardClasses} onClick={editBooking} {...rest}>
                {renderItem()}
            </div>
        </DragBox>
    );
};
