import React from 'react';
import { Checkbox as CheckboxAntd, CheckboxProps, Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import './Checkbox.scss';
import { parseErrorMessage } from '~common/commonFunctions';
import classNames from 'classnames';

interface ICheckbox extends CheckboxProps {
    name: string;
    required: boolean;
    label: string;
    defaultValue: boolean;
    control: any;
    hideHelpText?: boolean;
    style?: React.CSSProperties;
}

export function Checkbox(props: ICheckbox) {
    const {
        control,
        name,
        defaultValue,
        label,
        onChange,
        required,
        hideHelpText,
        style,
        ...rest
    } = props;
    return (
        <div
            className={classNames('checkbox-wrapper', {
                'hide-help-text': hideHelpText,
            })}
            style={style}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={''}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            required={required}
                        >
                            <CheckboxAntd
                                {...rest}
                                checked={field.value}
                                defaultChecked={defaultValue}
                                ref={field.ref}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    field.onChange(...agrs);
                                }}
                            >
                                {label}
                            </CheckboxAntd>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

Checkbox.defaultProps = {
    defaultValue: false,
    required: false,
    options: [],
    disableValues: [],
};
