import { CarryOutOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Popover, Radio, Select, Spin, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';
import { TextTruncate } from '~components';
import {
    setDefaultFacilityBookingOptions,
    setIsShowCreateFacilityBookingForm,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { IndicatorType } from '~features/indicator/constants';
import { PlanType } from '~features/plan/constants';
import { planDropdownSelector } from '~features/plan/reducers/plan.reducer';
import { CleaningStatus, RoomBookingEvent } from '~features/room-booking/constants';
import {
    setDefaultBookingOptions,
    setShowCreateBookingForm,
} from '~features/room-booking/reducers/schedule.reducer';
import { IRoomManagementItem } from '~features/room-management/interfaces';
import {
    getRoomListStatus,
    getRoomStatus,
    roomManagementSelector,
    updateCleaningStatus,
    setSelectedRoomStopSellingCause,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate, todayDayjs } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import './RoomDetail.scss';
import { StopRoomCause } from '~features/room-management/constants';

type Props = {
    room: IRoomManagementItem;
    isOpen: boolean;
    onClose: () => void;
    isReadOnly?: boolean;
};

export const RoomVacancyDetail = (props: Props) => {
    const { room, isReadOnly } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isBlockRoomLoading, currentDate, isChangeRoomCleanStatus } =
        useAppSelector(roomManagementSelector);
    const [planId, setPlanId] = React.useState<number | undefined>(undefined);
    const navigate = useNavigate();
    const plansOptions = useAppSelector(planDropdownSelector);
    const [isDisabled, setIsDisabled] = useState(false);
    const roomType = useMemo(() => {
        return t('roomManagement.list.detail.vacancyTitle', {
            name: room.roomType?.name,
        });
    }, [room, t]);
    const date = useMemo(() => {
        return parseDate(currentDate)?.fmYYYYMMDD('/');
    }, [currentDate]);
    useEscape(props.onClose);

    useEffect(() => {
        setIsDisabled(todayDayjs.diff(parseDate(currentDate), 'day') > 0);
    }, [currentDate]);

    useEffect(() => {
        if (props.isOpen) {
            dispatch(setSelectedRoomStopSellingCause(StopRoomCause.INVENTORY_ADJUSTMENT));
        }
        return () => {
            dispatch(setSelectedRoomStopSellingCause(null));
        };
    }, [props.isOpen]);

    const _showConfirmUpdateCleaningStatus = (val: CleaningStatus) => {
        showConfirm({
            title: t('roomBooking.list.update.cleaningStatusTitle'),
            cancelText: t('roomBooking.list.statusPopConfirm.cancelText'),
            okText: t('roomBooking.list.statusPopConfirm.okText'),
            onOk() {
                _updateCleaningStatus(val);
            },
        });
    };

    const onChangeStatus = async (val: CleaningStatus) => {
        if (val === CleaningStatus.CLEANED) {
            props.onClose();
            _showConfirmUpdateCleaningStatus(val);
        } else _updateCleaningStatus(val);
    };

    const _updateCleaningStatus = async (val: CleaningStatus) => {
        const response = await dispatch(
            updateCleaningStatus({
                id: room.id,
                cleaningStatus: val,
                memo: '',
            }),
        );
        if (updateCleaningStatus.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomManagement.list.update.cleaningStatus'),
                });
                dispatch(getRoomListStatus());
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const { emitter } = useMitt();
    const blockRoom = () => {
        props.onClose();
        dispatch(getRoomStatus({ id: room.id, currentDate }));
        emitter.emit(RoomBookingEvent.TOGGLE_UPDATE_ROOM_STATUS, true);
    };

    const gotoSchedule = () => {
        if (room.type === IndicatorType.ROOM) {
            dispatch(setShowCreateBookingForm(true));
            dispatch(
                setDefaultBookingOptions({
                    planId: planId,
                    roomId: room.id,
                    roomTypeId: room.roomType?.id,
                    isDayUse: false,
                }),
            );
            props.onClose();
        } else {
            dispatch(setIsShowCreateFacilityBookingForm(true));
            dispatch(
                setDefaultFacilityBookingOptions({
                    facilityId: room?.id,
                }),
            );
            navigate({
                pathname: '/facility-booking/schedule',
            });
        }
    };

    const planDropDownOptions = useMemo(() => {
        return plansOptions.filter((plan) => {
            const hasPlanType = plan.type === PlanType.STAY;
            const hasRoomType = room.roomType?.id
                ? plan.roomTypeIds?.includes(room.roomType?.id)
                : false;
            return hasPlanType && hasRoomType;
        });
    }, [plansOptions, room]);

    const statusOptions = useMemo(() => {
        return [
            {
                value: CleaningStatus.CLEANED,
                label: t('roomManagement.list.cleaned'),
            },
            {
                value: CleaningStatus.UNCLEANED,
                label: t('roomManagement.list.notCleaned'),
            },
        ];
    }, [t]);

    return (
        <Popover
            open={props.isOpen}
            content={
                <Spin spinning={isChangeRoomCleanStatus}>
                    <div className="room-detail-popover">
                        <div className="room-name">
                            <TextTruncate text={room.name || ''} />
                        </div>
                        <div className="room-type text-truncate">{roomType}</div>
                        <div className="room-date mb-20">{date}</div>
                        {room.type === IndicatorType.ROOM && (
                            <>
                                <div className="room-plan mb-20">
                                    <div className="label">
                                        {t('roomManagement.list.plan')}
                                    </div>
                                    <Select
                                        options={planDropDownOptions}
                                        showSearch
                                        value={planId}
                                        onChange={(val) => setPlanId(+val)}
                                        className="plan-select"
                                        placeholder={t(
                                            'roomManagement.list.planPlaceholder',
                                        )}
                                        disabled={isReadOnly}
                                    />
                                </div>
                                <div className="status-select mb-20">
                                    <Radio.Group
                                        onChange={(e) => onChangeStatus(e.target.value)}
                                        value={
                                            room.cleaningStatus ||
                                            CleaningStatus.UNCLEANED
                                        }
                                        options={statusOptions}
                                    />
                                </div>
                            </>
                        )}
                        {!isReadOnly && (
                            <div className="actions action-button">
                                {room.type === IndicatorType.ROOM && (
                                    <Button
                                        className="mr-8"
                                        loading={isBlockRoomLoading}
                                        onClick={blockRoom}
                                        disabled={isDisabled}
                                    >
                                        <StopOutlined />
                                        {t('roomManagement.list.stopSelling')}
                                    </Button>
                                )}
                                <Button
                                    onClick={gotoSchedule}
                                    type="primary"
                                    className="action-button"
                                >
                                    <CarryOutOutlined />
                                    {t('roomManagement.list.reserve')}
                                </Button>
                            </div>
                        )}
                    </div>
                </Spin>
            }
            trigger="click"
            placement="bottom"
            onOpenChange={props.onClose}
            overlayClassName="room-detail-popover-wrapper"
        >
            <div></div>
        </Popover>
    );
};
