import _ from 'lodash';
import React, {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    WheelEvent,
} from 'react';
import {
    bookingFilterSelector,
    isDayViewSelector,
    scheduleStateSelector,
    setIsDragging,
} from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useMitt } from '~plugins/mitt';
import { BookingCard } from '../BookingCard/BookingCard';

import { VariableSizeGrid } from 'react-window';
import { calculatePositionLeft, calculateWidth } from '~common/commonFunctions';
import { EmitterGlobalEvent, MINUTES_PER_DAY, MINUTES_PER_HOUR } from '~common/constants';
import {
    CELL_HEADER_HEIGHT,
    CELL_HEIGHT,
    CELL_WIDTH,
    checkInTimeInWeekViewDefault,
    HEADER_HEIGHT,
    HEADER_HEIGHT_DAY_VIEW,
    PADDING_DAY_USE_BOOKING_BADGE,
} from '~features/room-booking/constants';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { Dayjs, parseDate } from '~plugins/dayjs';
import DayUseBookingBadge from '../DayUseBookingBadge/DayUseBookingBadge';

type Props = {
    onMouseUp: () => void;
    cellWidth: number;
    startTime: Dayjs;
    endTime: Dayjs;
    gridRef: MutableRefObject<VariableSizeGrid>;
    outerRef: MutableRefObject<HTMLElement | undefined>;
    gridViewContentHeightRef?: MutableRefObject<number | undefined>;
    onWheel?: (e: WheelEvent<HTMLDivElement>) => void;
};

export const BookingList = ({
    onMouseUp,
    cellWidth,
    startTime,
    endTime,
    gridRef,
    outerRef,
    gridViewContentHeightRef,
    onWheel,
}: Props) => {
    const bookingList = useAppSelector(bookingFilterSelector);
    const isDayView = useAppSelector(isDayViewSelector);
    const { collapseRooms, roomList } = useAppSelector(scheduleStateSelector);
    const time = useMemo(
        () => ({
            start: startTime,
            end: endTime,
        }),
        [startTime, endTime],
    );
    const dispatch = useAppDispatch();
    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    const { emitter } = useMitt();
    const [visibleIndexes, setVisibleIndexes] = React.useState<{
        visibleColumnStartIndex: number;
        visibleColumnStopIndex: number;
        visibleRowStartIndex: number;
        visibleRowStopIndex: number;
    }>({
        visibleColumnStartIndex: 0,
        visibleColumnStopIndex: 0,
        visibleRowStartIndex: 0,
        visibleRowStopIndex: 0,
    });

    const visibleBookingList = useMemo(() => {
        const _bookingList = _.cloneDeep(bookingList);
        _.forEach(_bookingList, (roomBookings) => {
            _.forEach(roomBookings, (bookings, key) => {
                const _booking = bookings.filter((booking) => {
                    const { roomType, room } = booking;
                    return (
                        roomType?.id && !collapseRooms.includes(roomType?.id) && room?.id
                    );
                });
                roomBookings[key] = _booking || [];
            });
        });
        return _bookingList;
    }, [bookingList, collapseRooms]);

    useEffect(() => {
        emitter.on(EmitterGlobalEvent.SCROLL, (scroll: { x: number; y: number }) => {
            setPosition(scroll);
        });
        emitter.on(EmitterGlobalEvent.SCHEDULE_SCROLL, (options) => {
            setVisibleIndexes(options);
        });

        return () => {
            emitter.off(EmitterGlobalEvent.SCROLL);
            emitter.off(EmitterGlobalEvent.SCHEDULE_SCROLL);
        };
    }, []);

    const unit = useMemo(() => {
        return isDayView ? MINUTES_PER_HOUR : MINUTES_PER_DAY;
    }, [isDayView]);

    const roomFilter = useMemo(() => {
        const results = roomList.filter((item) => {
            return (
                !item.parentId ||
                (item.parentId && !collapseRooms.includes(item.parentId))
            );
        });
        return results;
    }, [roomList, collapseRooms]);

    const _calculateTop = useCallback(
        (booking: IRoomBookingSchedule) => {
            const index = roomFilter.findIndex(
                (item) => item.id === booking.room.id && item.parentId,
            );
            if (index === -1) return -HEADER_HEIGHT;
            const cellHeaderHeight = isDayView ? HEADER_HEIGHT_DAY_VIEW : HEADER_HEIGHT;
            let _top = cellHeaderHeight + (!booking.isDayUse ? 8 : 0);
            if (!index) {
                _top += roomFilter[0].parentId ? CELL_HEIGHT : CELL_HEADER_HEIGHT;
                return _top;
            }
            for (let i = 0; i < index; i++) {
                _top += roomFilter[i].parentId ? CELL_HEIGHT : CELL_HEADER_HEIGHT;
            }
            return _top;
        },
        [roomFilter, isDayView],
    );

    const _calculateLeft = useCallback(
        (booking: IRoomBookingSchedule) => {
            let checkInTime = checkInTimeInWeekViewDefault.middle;
            if (isDayView) {
                checkInTime = booking.checkInTime || '';
            }
            return (
                calculatePositionLeft({
                    from: time.start,
                    to: parseDate(`${booking.stayingStartDate} ${checkInTime}`),
                    widthOfColumn: cellWidth / unit,
                }) + CELL_WIDTH
            );
        },
        [cellWidth, time, unit, isDayView],
    );

    const _calculateWidth = useCallback(
        (booking: IRoomBookingSchedule) => {
            const minWidthDayUse = (cellWidth - 24) / 4;
            if (booking.isDayUse && !isDayView) return minWidthDayUse;
            let start = booking.stayingStartDate;
            if (booking.checkInTime && booking.isDayUse) {
                start += ' ' + booking.checkInTime;
            } else {
                start += isDayView ? ' ' + booking.checkInTime : ' 12:00';
            }
            let end = booking.stayingEndDate;
            if (booking.checkOutTime && booking.isDayUse) {
                end += ' ' + booking.checkOutTime;
            } else {
                end += isDayView ? ' ' + booking.checkOutTime : ' 12:00';
            }
            const _width = calculateWidth({
                from: parseDate(start)?.fmYYYYMMDDHHmm('-'),
                to: parseDate(end)?.fmYYYYMMDDHHmm('-'),
                widthOfColumn: cellWidth / unit,
            });
            return _width;
        },
        [cellWidth, isDayView, unit],
    );

    const checkValidBooking = useCallback(
        (option: { left: number; endLeft: number; top: number }) => {
            const { left, endLeft, top } = option;
            const leftPosition = (visibleIndexes.visibleColumnStartIndex - 3) * cellWidth;
            const rightPosition = (visibleIndexes.visibleColumnStopIndex + 3) * cellWidth;
            const topPosition = visibleIndexes.visibleRowStartIndex - 3 * CELL_HEIGHT;
            const bottomPosition = visibleIndexes.visibleRowStopIndex + 3 * CELL_HEIGHT;
            const validLeft =
                (leftPosition <= endLeft && endLeft <= rightPosition) ||
                (leftPosition <= left && left <= rightPosition) ||
                (left <= rightPosition && endLeft >= rightPosition);
            const validTop =
                topPosition * CELL_HEIGHT <= top && top <= bottomPosition * CELL_HEIGHT;
            return validLeft && validTop;
        },
        [visibleIndexes, cellWidth],
    );

    const _bookingListWithPosition = useMemo(() => {
        const _bookingList = _.cloneDeep(visibleBookingList);

        _.forEach(_bookingList, (roomBookings) => {
            _.forEach(roomBookings, (bookings) => {
                _.forEach(bookings, (booking) => {
                    const top = _calculateTop(booking);
                    const left = _calculateLeft(booking);
                    const width = _calculateWidth(booking);
                    booking.top = top;
                    booking.left = left;
                    booking.width = width;
                });
            });
        });
        return _bookingList;
    }, [visibleBookingList, cellWidth, roomFilter]);

    const renderDayUseBookingList = (
        list: React.ReactNode[],
        bookings: IRoomBookingSchedule[],
    ) => {
        const _bookings = bookings.filter((item) => item.isDayUse);
        const dayUseBookings: IRoomBookingSchedule[] = [];
        _.forEach(_bookings, (booking) => {
            const _endLeft = (booking.left || 0) + (booking.width || 0);
            const isValid = checkValidBooking({
                left: booking.left || 0,
                endLeft: _endLeft,
                top: booking.top || 0,
            });
            if (!isValid) return;
            if (isDayView) {
                list.push(
                    <BookingCard
                        booking={booking}
                        key={booking.id}
                        onMouseDown={() => {
                            dispatch(setIsDragging(true));
                        }}
                        endDrag={() => {
                            onMouseUp();
                        }}
                        onWheel={onWheel}
                        time={time}
                        width={booking.width || 0}
                        left={(booking.left || 0) - position.x}
                        top={(booking.top || 0) - position.y}
                        gridRef={gridRef}
                        outerRef={outerRef}
                        gridViewContentHeightRef={gridViewContentHeightRef}
                    />,
                );
                return;
            }
            dayUseBookings.push({
                ...booking,
                top: (booking.top || 0) - position.y,
                left: (booking.left || 0) - position.x - PADDING_DAY_USE_BOOKING_BADGE,
            });
            return;
        });

        if (!isDayView && dayUseBookings.length > 0) {
            list.push(
                <DayUseBookingBadge
                    bookings={dayUseBookings}
                    key={dayUseBookings[0]?.id}
                />,
            );
            return;
        }
    };

    const renderBookingList = () => {
        const list: React.ReactNode[] = [];
        _.forEach(_bookingListWithPosition, (roomBookings) => {
            _.forEach(roomBookings, (bookings) => {
                // render booking day use
                renderDayUseBookingList(list, bookings);

                // render booking over night
                _.forEach(
                    bookings.filter((item) => !item.isDayUse),
                    (booking) => {
                        const _endLeft = (booking.left || 0) + (booking.width || 0);
                        const isValid = checkValidBooking({
                            left: booking.left || 0,
                            endLeft: _endLeft,
                            top: booking.top || 0,
                        });
                        if (!isValid) return;
                        list.push(
                            <BookingCard
                                booking={booking}
                                key={booking.id}
                                onMouseDown={() => {
                                    dispatch(setIsDragging(true));
                                }}
                                endDrag={() => {
                                    onMouseUp();
                                }}
                                onWheel={onWheel}
                                time={time}
                                width={booking.width || 0}
                                left={(booking.left || 0) - position.x}
                                top={(booking.top || 0) - position.y}
                                gridRef={gridRef}
                                outerRef={outerRef}
                                gridViewContentHeightRef={gridViewContentHeightRef}
                            />,
                        );
                    },
                );
            });
        });
        return list;
    };

    return <>{renderBookingList()}</>;
};
