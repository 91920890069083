import { IBodyResponse, ICsvFile, IGetListResponse } from '~common/interfaces';
import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import { parseDate } from '~plugins/dayjs';
import { RoomBookingItemBookingStatus } from '../constants';
import { getChildrenToForm } from '../helper';
import {
    IAdvanceSearchGetListQuery,
    IAutoAssignBookingToRoomItem,
    IBookingStaticTypeResponse,
    ICreateBookingFormData,
    ICreateBookingTmpData,
    ICreateGroupBookingTmpData,
    ICreateGroupBookingTmpDataList,
    ICreateOrUpdateBookingTmp,
    IRoomBooking,
    IRoomBookingDetail,
    IRoomBookingExportCsvQuery,
    IRoomBookingGetListQuery,
    IRoomBookingItem,
    IRoomBookingSchedule,
    IRoomBookingStaticItem,
    IRoomBookingStatisticResponse,
    IRoomGroupBookingSchedule,
    IScheduleResponse,
    ISplitBookingBody,
    IUpdateBookingItemNoShowStatus,
    IUpdateBookingItemStatus,
    IUpdateRoomBookingItemBody,
} from '../interfaces';
import {
    ICheckInRoomBooking,
    IGetBookingPrice,
    IGetPreparationList,
    IImportXmlBody,
    IReceiptResponse,
    IRoomBookingPreparationItem,
    IStatisticBookingItem,
    IUpdateBookingFormData,
    IUpdateRoomBookingReceiptBody,
} from './../interfaces';
import { IRoomDropDown } from '~features/room/interfaces';

class RoomBookingService extends ApiService {
    getList(queryString: IRoomBookingGetListQuery | IAdvanceSearchGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IRoomBooking>>
        >;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IBodyResponse<IRoomBookingDetail>>;
    }

    getReceipt(id: number) {
        return this._get(`${this.baseUrl}/${id}/receipt`) as Promise<
            IBodyResponse<IReceiptResponse>
        >;
    }

    updateReceipt(id: number, body: IUpdateRoomBookingReceiptBody) {
        return this._patch(`${this.baseUrl}/receipt/${id}`, body) as Promise<
            IBodyResponse<boolean>
        >;
    }

    updateBookingItemStatus(body: IUpdateBookingItemStatus) {
        return this._patch(`${this.baseUrl}/item/booking-status`, body) as Promise<
            IBodyResponse<IGetListResponse<IRoomBookingItem>>
        >;
    }

    updateBookingItemNoShowStatus(body: IUpdateBookingItemNoShowStatus) {
        return this._patch(`${this.baseUrl}/item/no-show`, body) as Promise<
            IBodyResponse<IGetListResponse<IRoomBookingItem>>
        >;
    }

    groupByRoomType(startDate: string, endDate: string) {
        return this._get(`${this.baseUrl}/group-by-room-type`, {
            params: {
                startDate,
                endDate,
            },
        }) as Promise<IBodyResponse<IScheduleResponse>>;
    }

    makeBookingForm = (booking: IRoomBookingSchedule, update = false) => {
        const form: ICreateBookingTmpData = {
            roomId: booking.room?.id as number,
            roomTypeId: !booking.room?.id ? booking.roomType?.id || undefined : undefined,
            planId: booking.plan?.id || null,
            isDayUse: booking.isDayUse,
            checkInTime: booking.isDayUse ? booking.checkInTime : null,
            checkOutTime: booking.isDayUse ? booking.checkOutTime : null,
            startDateOfStay: parseDate(booking.stayingStartDate)
                .startOf('day')
                .fmYYYYMMDDHHmmss(),
            endDateOfStay: parseDate(booking.stayingEndDate)
                .startOf('day')
                .fmYYYYMMDDHHmmss(),
            numberOfAdults: booking.numberOfAdults,
            numberOfMale: Number(booking.numberOfMale) || 0,
            numberOfFemale: Number(booking.numberOfFemale) || 0,
            numberOfOtherGenderGuest: Number(booking.numberOfOtherGenderGuest) || 0,
            roomBookingItemChildrenTypes: getChildrenToForm(booking.children, update),
        };
        return form;
    };

    makeGroupBookingForm = (bookings: IRoomGroupBookingSchedule[]) => {
        const list: ICreateGroupBookingTmpData[] = bookings.map((booking) => {
            const singleFormTmpData: ICreateBookingTmpData =
                this.makeBookingForm(booking) ?? {};
            return {
                ...singleFormTmpData,
                numberOfRooms: booking.numberOfRooms,
            };
        });

        return { list };
    };

    makeBookingItemForm = (
        booking: IRoomBookingSchedule,
        isUpdateReceipt?: boolean,
        swapIfOccupied?: boolean,
    ) => {
        return {
            roomId: booking.room?.id,
            roomTypeId: !booking.room?.id ? booking.roomType?.id || undefined : undefined,
            isUpdateReceipt: !!isUpdateReceipt,
            swapIfOccupied: !!swapIfOccupied,
        };
    };

    parseDateForGetBookingPrice = (params: IGetBookingPrice) => {
        params.startDateOfStay = parseDate(params.startDateOfStay)?.fmYYYYMMDDHHmmss();
        params.endDateOfStay = parseDate(params.endDateOfStay)?.fmYYYYMMDDHHmmss();
    };

    makeGetGroupBookingPriceBody = (params: IGetBookingPrice[]) => {
        params.forEach((param) => this.parseDateForGetBookingPrice(param));
        return { list: params };
    };

    createTemporaryBooking(booking: IRoomBookingSchedule, isAcceptOverbooking?: boolean) {
        const formData = this.makeBookingForm(booking, isAcceptOverbooking);
        return this._post(
            `${this.baseUrl}/temporary-item?isAcceptOverbooking=${isAcceptOverbooking}`,
            formData,
        ) as Promise<IBodyResponse<ICreateOrUpdateBookingTmp>>;
    }

    createTemporaryGroupBooking(
        booking: IRoomGroupBookingSchedule[],
        isAcceptOverbooking?: boolean,
    ) {
        const formData: ICreateGroupBookingTmpDataList =
            this.makeGroupBookingForm(booking);
        return this._post(
            `${this.baseUrl}/temporary-item/group?isAcceptOverbooking=${isAcceptOverbooking}`,
            formData,
        ) as Promise<IBodyResponse<ICreateOrUpdateBookingTmp>[]>;
    }

    createBooking(formData: ICreateBookingFormData | FormData) {
        return this._post(`${this.baseUrl}`, formData) as Promise<
            IBodyResponse<ICreateOrUpdateBookingTmp>
        >;
    }

    getBookingPrice(params: IGetBookingPrice) {
        this.parseDateForGetBookingPrice(params);
        return this._post(`${this.baseUrl}/calculate-item-amount`, params) as Promise<
            IBodyResponse<{ amount: number; tax: number }>
        >;
    }

    getGroupBookingPrice(params: IGetBookingPrice[]) {
        const body = this.makeGetGroupBookingPriceBody(params);
        return this._post(`${this.baseUrl}/calculate-item-amount/group`, body) as Promise<
            IBodyResponse<{ amount: number; tax: number; groupBookingId: number }>[]
        >;
    }

    updateBooking(id: number, formData: IUpdateBookingFormData | FormData) {
        return this._patch(`${this.baseUrl}/${id}`, formData) as Promise<
            IBodyResponse<ICreateOrUpdateBookingTmp>
        >;
    }

    updateTemporaryBooking(booking: IRoomBookingSchedule) {
        const formData = this.makeBookingForm(booking, true);
        return this._patch(
            `${this.baseUrl}/temporary-item/${booking.id}`,
            formData,
        ) as Promise<IBodyResponse<ICreateOrUpdateBookingTmp>>;
    }

    updateRoomBookingItemWithBooking(
        booking: IRoomBookingSchedule,
        isUpdateReceipt?: boolean,
        swapIfOccupied?: boolean,
    ) {
        const formData = this.makeBookingItemForm(
            booking,
            isUpdateReceipt,
            swapIfOccupied,
        );
        return this._patch(`${this.baseUrl}/item/${booking.id}`, formData) as Promise<
            IBodyResponse<ICreateOrUpdateBookingTmp>
        >;
    }

    updateRoomBookingItem(id: number, body: IUpdateRoomBookingItemBody) {
        const formatBody = {
            ...body,
            startDateOfStay:
                body.bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED
                    ? parseDate(body.startDateOfStay).startOf('day').fmYYYYMMDDHHmmss()
                    : undefined,
            endDateOfStay: parseDate(body.endDateOfStay)
                .startOf('day')
                .fmYYYYMMDDHHmmss(),
        };
        return this._patch(`${this.baseUrl}/item/${id}`, formatBody) as Promise<
            IBodyResponse<IRoomBookingItem>
        >;
    }

    getRoomBookingStatisticByDate(startDate: string, endDate: string) {
        return this._get(`${this.baseUrl}/statistic/by-date`, {
            params: {
                startDate,
                endDate,
            },
        }) as Promise<IBodyResponse<IRoomBookingStatisticResponse>>;
    }

    getRoomList(startDate: string, endDate: string) {
        return this._get(`${this.baseUrl}/item/statistic/by-room-type`, {
            params: {
                startDate,
                endDate,
            },
        }) as Promise<IBodyResponse<IBookingStaticTypeResponse>>;
    }

    getBookingList(startDate: string, endDate: string) {
        return this._get(`${this.baseUrl}/item/schedule`, {
            params: {
                startDate,
                endDate,
            },
        }) as Promise<IBodyResponse<IRoomBookingStaticItem[]>>;
    }

    exportCsv(body: IRoomBookingExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, body) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    getTemporaryBookingItemList() {
        return this._get(`${this.baseUrl}/temporary-item`) as Promise<
            IBodyResponse<IRoomBookingStaticItem[]>
        >;
    }

    bulkDeleteRoomBookingItems(ids: number[]) {
        return this._delete(`${this.baseUrl}/item/bulk-delete`, {
            data: {
                ids,
            },
        }) as Promise<IBodyResponse<IRoomBookingDetail>>;
    }

    splitRoomBookingItems(
        roomBookingId: number,
        parentRoomBookingItemId: number,
        body: ISplitBookingBody,
    ) {
        return this._post(
            `${this.baseUrl}/${roomBookingId}/item/split-room-booking-items/${parentRoomBookingItemId}`,
            body,
        ) as Promise<IBodyResponse<void>>;
    }

    deleteRoomBooking(id: number) {
        return this._deleteItem(id) as Promise<IBodyResponse<IRoomBooking>>;
    }

    deleteTemporaryItem(id: number) {
        return this._delete(`${this.baseUrl}/temporary-item/${id}`) as Promise<
            IBodyResponse<boolean>
        >;
    }

    checkInRoomBooking(id: number, checkinRoomBooking: ICheckInRoomBooking) {
        return this._patch(
            `${this.baseUrl}/item/${id}/check-in`,
            checkinRoomBooking,
        ) as Promise<IBodyResponse<IRoomBookingItem>>;
    }

    bulkUpdateTempBooking(ids: number[]) {
        return this._patch(`${this.baseUrl}/temporary-item/bulk-extend`, {
            ids,
        }) as Promise<IBodyResponse<{ message: string }>>;
    }

    bulkDeleteReceiptItemDetail(ids: number[]) {
        return this._delete('bulk-delete/receipt-item-detail', {
            data: {
                ids,
            },
        }) as Promise<IBodyResponse<{ message: string }>>;
    }

    unassignBooking(ids: number[]) {
        return this._patch(`${this.baseUrl}/item-and-temporary/bulk-unassign-room`, {
            ids,
        }) as Promise<IBodyResponse<any>>;
    }

    getRoomBookingPreparationList(params: IGetPreparationList) {
        return this._get(`${this.baseUrl}/preparation`, {
            params,
        }) as Promise<IBodyResponse<{ items: IRoomBookingPreparationItem[] }>>;
    }

    importXml(body: IImportXmlBody) {
        return this._post(`${this.baseUrl}/import-xml`, body) as Promise<
            IBodyResponse<any>
        >;
    }

    getStatisticByDate(params: { startDate: string; endDate: string }) {
        return this._get(`${this.baseUrl}/statistic/by-date-and-room-type`, {
            params,
        }) as Promise<IBodyResponse<{ items: IStatisticBookingItem[] }>>;
    }

    getAvailableRooms(ids: number[]) {
        return this._get(`${this.baseUrl}/item-and-temporary/get-available-room`, {
            params: { ids },
        }) as Promise<IBodyResponse<any>>;
    }

    assignBookingToRoom(data: IAutoAssignBookingToRoomItem[]) {
        return this._patch(`${this.baseUrl}/item-and-temporary/bulk-assign-room`, {
            items: data,
        }) as Promise<IBodyResponse<any>>;
    }

    getOriginalBookingId(id: number) {
        return this._get(`${this.baseUrl}/${id}/original-id`) as Promise<
            IBodyResponse<{ id: number }>
        >;
    }

    getAvailableRoomsOfBookingItems(ids: number[]) {
        return this._get(
            `${this.baseUrl}/item-and-temporary/get-available-rooms-of-bookings`,
            {
                params: { ids },
            },
        ) as Promise<IBodyResponse<Record<number, IRoomDropDown[]>>>;
    }
}
export const roomBookingService = new RoomBookingService(
    { baseUrl: '/room-booking' },
    axiosService,
);
