import { ICheckInPaymentMethod } from './interfaces';
import i18next from '~plugins/i18next/i18n';
import { IDropDownOption } from '~common/interfaces';
import { DEFAULT_FIRST_PAGE, DEFAULT_ORDER_DIRECTION } from '~common/constants';

export const FILE_NAME = 'room_booking.csv';
export enum OrderBy {
    ID = 'id',
    NAME = 'name',
}

export enum SearchField {
    ALL = 'keyword',
    CODE = 'autoGeneratedCode',
    GUEST_YOMIGANA = 'guestYomigana',
    GUEST_FULL_NAME = 'guestFullName',
}

export const InputAdultFieldName: Record<string, string> = {
    adult: 'numberOfAdults',
    female: 'numberOfFemale',
    male: 'numberOfMale',
    other: 'numberOfOtherGenderGuest',
};

export const breadcrumbs = () => [
    {
        text: i18next.t('roomBooking.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('roomBooking.list.breadcrumbs.roomBooking'),
    },
    {
        text: i18next.t('roomBooking.list.breadcrumbs.bookingList'),
    },
];

export const breadcrumbsCreateNewReservation = () => [
    ...breadcrumbs(),
    {
        text: i18next.t('roomBooking.form.breadcrumbs.createNewReservation'),
    },
];

export const enum RoomBookingColumn {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    YOMIGANA = 'guestYomigana',
    GUEST_NAME = 'guestFullName',
    ROOM_NAME = 'roomName',
    STATUS = 'status',
    CHECKIN_TIME = 'checkInTime',
    END_DATE_OF_STAY = 'endDateOfStay',
    START_DATE_OF_STAY = 'startDateOfStay',
    PAYMENT = 'payment',
    PAYMENT_NUMBER = 'paymentNumber',
    MEMO = 'memo',
    CLEANING = 'cleaning',
    CATEGORY = 'category',
    BOOKING_DATE = 'bookingDate',
    MARKETING_CHANNEL = 'marketingChannel',
    PLAN_NAME = 'planName',
    CREATED_AT = 'createdAt',
}

export const mockRoomTypeOptions: IDropDownOption[] = [
    {
        label: 'double',
        value: 'double',
    },
];

export enum AdvanceSearchFields {
    AUTO_GENERATE_CODE = 'autoGeneratedCode',
    PLAN_IDS = 'planIds',
    MARKETING_CHANNEL_IDS = 'marketingChannelIds',
    MEMO = 'memo',
    GUEST_YOSIGANA = 'guestYomigana',
    GUEST_MOBILE_PHONE_NUMBER = 'guestMobilePhoneNumber',
    ROOM_BOOKING_ITEM_STATUS = 'roomBookingItemStatus',
    END_DATE_PERIOD = 'endDatePeriod',
    START_DATE_PERIOD = 'startDatePeriod',
    STAY_PERIOD = 'stayPeriod',
    RECEPTION_PERIOD = 'receptionPeriod',
}

export const DEFAULT_LIMIT_FOR_ROOM_BOOKING_PAGINATION = 50;

export enum DateType {
    ALL = 'all',
    TODAY = 'today',
    TOMORROW = 'tomorrow',
}

export const roomBookingListQueryDefault = {
    limit: DEFAULT_LIMIT_FOR_ROOM_BOOKING_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
    keyword: '',
};
export enum RoomBookingItemBookingStatus {
    ALL = 'all',
    NOT_ARRIVED = 'not_arrived',
    CHECKED_IN = 'checked_in',
    CHECKED_OUT = 'checked_out',
    CANCELLED = 'cancelled',
}

export const RoomBookingItemBookingStatusColor = {
    [RoomBookingItemBookingStatus.ALL]: '#212222',
    [RoomBookingItemBookingStatus.NOT_ARRIVED]: '#faad14',
    [RoomBookingItemBookingStatus.CHECKED_IN]: '#3a9554',
    [RoomBookingItemBookingStatus.CHECKED_OUT]: '#d8d9d9',
    [RoomBookingItemBookingStatus.CANCELLED]: '#d8d9d9',
};

export const RoomBookingItemBookingBackgroundColor = {
    [RoomBookingItemBookingStatus.ALL]: '#d8d9d9',
    [RoomBookingItemBookingStatus.NOT_ARRIVED]: '#ffeece',
    [RoomBookingItemBookingStatus.CHECKED_IN]: '#cff1d8',
    [RoomBookingItemBookingStatus.CHECKED_OUT]: '#d8d9d9',
    [RoomBookingItemBookingStatus.CANCELLED]: '#d8d9d9',
};

export enum CleaningStatus {
    CLEANED = 'cleaned',
    UNCLEANED = 'uncleaned',
}

export enum RoomBookingCategory {
    GROUP = 'group',
    INDIVIDUAL = 'individual',
    DAY_USE = 'day_use',
}

export type ScheduleStatusKey =
    | 'checked_in'
    | 'not_arrived'
    | 'checked_out'
    | 'cancelled';

export enum ChildrenType {
    A = 'a',
    B = 'b',
    C = 'c',
    D = 'd',
    E = 'e',
}

export const ChangingBookingStatusesMap: { [key: string]: string[] } = {
    [`${RoomBookingItemBookingStatus.NOT_ARRIVED}`]: [
        RoomBookingItemBookingStatus.CHECKED_IN,
        RoomBookingItemBookingStatus.CANCELLED,
    ],
    [`${RoomBookingItemBookingStatus.CHECKED_IN}`]: [
        RoomBookingItemBookingStatus.NOT_ARRIVED,
        RoomBookingItemBookingStatus.CHECKED_OUT,
    ],
    [`${RoomBookingItemBookingStatus.CHECKED_OUT}`]: [
        RoomBookingItemBookingStatus.CHECKED_IN,
    ],
    [`${RoomBookingItemBookingStatus.CANCELLED}`]: [
        RoomBookingItemBookingStatus.NOT_ARRIVED,
    ],
};

export enum RoomBookingDetailPageTabPane {
    ROOM_BOOKING_LIST_TAB_PANE = 'roomBookingListTabPage',
    ROOM_BOOKING_RECEIPT_TAB_PANE = 'roomBookingReceiptTabPage',
    ROOM_BOOKING_KEYCARD_TAB_PANE = 'roomBookingKeycardTabPage',
    ROOM_BOOKING_CANCELATION_INFO = 'roomBookingCancelationInfo',
    ROOM_BOOKING_AUDIT_LOG_PANE = 'roomBookingAuditLogPane',
}

export enum RoomBookingTab {
    GUEST_INFO = 'guestInfo',
    ATTACHMENT = 'attachment',
    ACCOMPANIER = 'accompanier',
    MEMO = 'memo',
    COMPANION_INFO = 'companionInfo',
    FILE = 'file',
    HOTEL_SMART = 'hotelSmart',
}

export enum FormTab {
    BASIC_INFO = 'basicInfo',
    COMPANION_INFO = 'companionInfo',
    FILE_INFO = 'fileInfo',
}
export enum FieldTab {
    MEMO = 'memo',
    OTA_MEMO = 'OTAMemo',
    FILE = 'file',
}

export const CELL_HEIGHT = 60;
export const CELL_HEADER_HEIGHT = 44;
export const HEADER_HEIGHT = 40;
export const CELL_WIDTH = 130;
export const HEADER_HEIGHT_DAY_VIEW = 40;
export const CELL_HOUR_WIDTH = 30;
export const RoomBookingOccupanyRateRange = {
    empty: [0, 1],
    low: [1, 25],
    average: [25, 50],
    high: [50, 75],
    'extra-high': [75, 100],
};

export const EXPORT_CSV_FILE_NAME = 'room-booking';
export const exportColumns = [
    'autoGeneratedCode',
    'yomigana',
    'fullname',
    'roomName',
    'status',
    'checkInTime',
    'checkOutTime',
    'price',
    'plan',
    'marketingChannel',
    'bookingDate',
    'cleaningStatus',
    'payment',
    'category',
    'memo',
];
export const i18ExportKey = 'roomBooking.list';

export enum ReceiptStatus {
    PAYMENT = 'payment',
    EARNINGS = 'earnings',
}

export enum CreateFacilityBookingField {
    STAYING_DATE = 'stayingDate',
    TIMES = 'time',
    START_DATE_OF_STAY = 'startDateOfStay',
    END_DATE_OF_STAY = 'endDateOfStay',
    DATE_OF_STAY = 'dateOfStay',
    CHECK_OUT_TIME = 'checkOutTime',
    CHECK_IN_TIME = 'checkInTime',
    CHILDREN_COUNT = 'childrenCount',
    BOOKING_TIMES = 'bookingTimes',
}

export enum ReceiptBookingType {
    ROOM_BOOKING = 'room_booking',
    FACILITY_BOOKING = 'facility_booking',
}
export enum ReceiptItemBookingType {
    ROOM_BOOKING = 'room_booking',
    ROOM_BOOKING_ITEM = 'room_booking_item',
    FACILITY_BOOKING = 'facility_booking',
}
export enum ReceiptItemType {
    RECEIPT = 'receipt',
    PAYMENT = 'payment',
}

export enum PaymentSetting {
    ALL_ROOM_FREE = 'representative_room',
    ALL_ITEMS = 'all_rooms',
}

export enum ReceiptFormField {
    UNIT_PRICE = 'unitPrice',
    QUANTITY = 'quantity',
    AMOUNT = 'amount',
}
export const defaultCheckinPaymentMethod: ICheckInPaymentMethod = {
    paymentMethodId: null,
    amount: null,
};

export enum ReceiptItemDetailType {
    STAY_PRICE = 'stay_price',
    SALE_ITEM = 'sale_item',
    PAYMENT = 'payment',
    OTHER_TAX = 'other_tax',
    STAY_PRICE_CHILDREN = 'stay_price_children',
    LOCAL_TAX = 'local_tax',
    BATH_TAX = 'bath_tax',
}

export enum RoomBookingEvent {
    UPDATE_BOOKING_POSITION = 'ROOM_BOOKING_UPDATE_BOOKING_POSITION',
    REMOVE_BOOKING_POSITION = 'ROOM_BOOKING_REMOVE_BOOKING_POSITION',
    CHANGE_TAB = 'ROOM_BOOKING_CHANGE_TAB',
    CHANGE_DATE = 'ROOM_BOOKING_CHANGE_DATE',
    BOOKING_DELETED = 'booking_deleted',
    TOGGLE_AUTO_ASSIGN_MODAL = 'toggle_auto_assign_modal',
    TOGGLE_UPDATE_ROOM_STATUS = 'toggle_update_room_status',
    SHOW_ASSIGN_ROOM_SUCCESS = 'show_assign_room_success',
    SHOW_MORE_BOOKINGS = 'show_more_bookings',
}

export enum AccommodationCardPrintingCheckInOption {
    TODAY = 'today',
    TOMORROW = 'tomorrow',
    THE_DAY_AFTER_TOMORROW = 'theDayAfterTomorrow',
    EMPTY_CARD = 'emptyCard',
}

export enum PreparationListColumn {
    DATE = 'date',
    ROOM_NAME = 'roomName',
    GUEST_NAME = 'guestName',
    SALE_ITEM_NAME = 'saleItemName',
    QUANTITY = 'quantity',
    PRICE = 'price',
}

export enum DisplayedContentMode {
    TOTAL = 'total',
    PER_ROOM = 'perRoom',
}

export const EXPORT_PREPARATION_LIST_FILE_NAME = 'preparation-list';
export const preparationListColumns = ['date', 'saleItemName', 'quantity', 'price'];
export const i18ExportPreparationListKey = 'roomBooking.list.preparationListForm';

export enum SelectSplitBillTypeOptions {
    BASED_ON_ROOM = 'basedOnRoom',
    BASED_ON_GROUP = 'basedOnGroup',
}
export const MAX_CHILDREN = 99;
export const MAX_QUANTITY = 99;
export const MAX_LENGTH_PRICE = 10;

export enum SplitRoomBookingReceiptColumn {
    SPLIT_GROUP = 'splitGroup',
    DATE = 'date',
    BOOKING_DETAILS = 'bookingDetails',
    GUEST_NAME = 'guestName',
    ROOM_NAME = 'roomName',
    UNIT_PRICE = 'unitPrice',
    QUANTITY = 'quantity',
    AMOUNT = 'amount',
    CHECK_OUT_ROOM = 'checkOutRoom',
    PAYMENT_METHOD = 'paymentMethod',
}

export enum ReceiptByGroupMapIndex {
    ALL = 'all',
}

export const MAX_NUMBER_OF_SPLIT_RECEIPT_GROUP = 9;
export const DEFAULT_NUMBER_OF_GROUPS = 1;
export const BOOKING_HORIZONTAL_SCROLL_SPEED = 10;
export enum MarketinGChannelDefault {
    WALK_IN = 'ウォークイン',
    PHONE = '電話',
    DIRECT = '直予約',
}
export const MAX_PRINT_GUEST_CARD = 100;
export const DEFAULT_CHECK_IN_TIME = '00:00';
export const DEFAULT_CHECK_OUT_TIME = '23:59';

export const drawerHeaderSize = 55;
export const drawerBodyPadding = 24;
export const timeControlHeight = 48;
export const drawerFooterHeight = 21;

export const SCHEDULE_CARD_PADDING = 48; // top-padding + bottom-padding
export const SCHEDULE_PAGE_PADDING = 24; // bottom-padding of schedule page
export const FILTER_FORM_SCHEDULE_HEIGHT = 82;
export const FILTER_FORM_SCHEDULE_MARGIN = 48; // bottom-margin + top_margin
export const OTHER_PADDING = 16;

export const DEFAULT_TIME_START_OF_DAY = '00:00:00';
export const DEFAULT_TIME_END_OF_DAY = '23:59:59';

export const PADDING_DAY_USE_BOOKING_BADGE = 15;
export const checkInTimeInWeekViewDefault = {
    start: '00:00',
    middle: '12:00',
};

export const MAX_EDIT_HISTORY_ITEM = 3;
export enum SelfCheckInStatus {
    NOT_ARRIVED = 'NOT_ARRIVED',
    CHECKED_IN = 'CHECKED_IN',
    PMS_MANUAL_CHECK_IN = 'PMS_MANUAL_CHECKIN',
}
export const MAX_NUMBER_OF_FILES_PER_BOOKING = 20;
export const MAX_SIZE_OF_EACH_BOOKING_FILE = 26214400; // 25Mb;
export const ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS = [
    '.jpg',
    '.jpeg',
    '.tiff',
    '.bmp',
    '.pdf',
    '.gif',
    '.png',
    '.svg',
    '.csv',
    '.txt',
];
export const ACCEPTED_BOOKING_FILE_FORMATS = [
    'JPEG',
    'TIFF',
    'BMP',
    'PDF',
    'GIF',
    'PNG',
    'SVG',
    'CSV',
    'TEXT',
];

export const TAX_10_PERCENTAGE = 10;
export const TAX_8_PERCENTAGE = 8;

export enum SplitPaymentMethods {
    PREPAID = '事前入金分',
}

export const DEFAULT_BILL_PROVISO = '宿泊代金';

export const StoppingRoomsActions = {
    BLOCK_ROOM: 'BLOCK_ROOM',
    RELEASE_ROOM: 'RELEASE_ROOM',
} as const;

export const ScheduleCellPopupActions = {
    ACTION_SELECT: 'ACTION_SELECT',
    CREATE_BOOKING: 'CREATE_BOOKING',
    BLOCK_ROOMS: 'BLOCK_ROOMS',
} as const;

export const INVOICE_PATH = 'split-invoice';

export const FILE_TYPE = {
    INVOICE: 'invoice',
    RECEIPT: 'receipt',
};
