import { notification } from 'antd';
import { useCallback, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import {
    IGetBookingPrice,
    IRoomBookingItem,
    IRoomBookingItemUpdateResponse,
} from '~features/room-booking/interfaces';
import { getBookingPrice } from '~features/room-booking/reducers/create-booking.reducer';
import {
    reassignBookingRoom,
    reassignedRoomSuccess,
    roomManagementSelector,
    setAssignRoomLoading,
    setDragData,
    setIsDraggingBooking,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';

interface DragBoxProps {
    children: React.ReactNode;
    bookingId: number;
    currentRoomId: number;
    currentRoomTypeId: number;
    bookingStatus?: RoomBookingItemBookingStatus;
    disabled?: boolean;
}

export const DragBox = ({
    children,
    bookingId,
    currentRoomId,
    currentRoomTypeId,
    bookingStatus,
    disabled,
}: DragBoxProps) => {
    const dispatch = useAppDispatch();
    const { dragData } = useAppSelector(roomManagementSelector);

    const handleGetBookingPrice = useCallback(async (booking: IRoomBookingItem) => {
        const query: IGetBookingPrice = {
            roomTypeId: Number(booking.roomTypeId),
            startDateOfStay: parseDate(booking.startDateOfStay)?.fmYYYYMMDD('-'),
            endDateOfStay: parseDate(booking.endDateOfStay)?.fmYYYYMMDD('-'),
            numberOfAdults: Number(booking.numberOfAdults),
            planId: Number(booking.planId),
            childrenTypeQuantities:
                booking.roomBookingItemChildrenTypes?.map((item) => ({
                    childrenTypeId: item.childrenType?.id,
                    quantity: item.quantity,
                })) || [],
            id: Number(booking.id),
        };
        const response = await dispatch(getBookingPrice(query));
        if (getBookingPrice.fulfilled.match(response)) {
            const { data } = response.payload;
            if (data) {
                return data.amount || 0;
            }
        }
    }, []);

    const [{ isDragging }, drag] = useDrag(
        {
            type: 'box',
            item: { bookingId },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: !disabled,
            isDragging: (monitor) => {
                return (
                    dragData?.bookingId === bookingId ||
                    monitor.getItem().bookingId === bookingId
                );
            },
            end: async (_, monitor) => {
                const dropResult = monitor.getDropResult() || {};
                const { roomId } = dropResult as {
                    roomId: number;
                    x: number;
                    y: number;
                };
                if (!roomId || roomId === currentRoomId) return;

                dispatch(setAssignRoomLoading([currentRoomId, roomId]));
                const response = await dispatch(
                    reassignBookingRoom({ id: bookingId, roomId }),
                );
                if (reassignBookingRoom.fulfilled.match(response)) {
                    if (response.payload?.success) {
                        const data = response.payload
                            .data as IRoomBookingItemUpdateResponse;
                        const price = await handleGetBookingPrice(data);

                        dispatch(
                            reassignedRoomSuccess({
                                bookingId,
                                roomId,
                                price: price || 0,
                            }),
                        );
                    } else {
                        notification.error({
                            message: response.payload?.message || '',
                        });
                    }
                }

                dispatch(setAssignRoomLoading(null));
            },
        },
        [bookingId],
    );

    useEffect(() => {
        dispatch(setIsDraggingBooking(isDragging));
        dispatch(
            setDragData(
                isDragging
                    ? {
                          bookingId,
                          isAssigned: true,
                          roomTypeId: currentRoomTypeId,
                          bookingStatus,
                      }
                    : null,
            ),
        );
    }, [isDragging]);

    return (
        <div
            ref={drag}
            style={{
                width: '100%',
                height: '100%',
                cursor: disabled ? 'default' : 'pointer',
            }}
        >
            {children}
        </div>
    );
};
