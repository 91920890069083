export default {
    membersTitle: 'Accompanion information',
    cancel: 'Back',
    submit: 'Confirm booking',
    representativeRoom: 'Representative room',
    guestSelect: 'Select guest',
    bookingList: 'Booking list',
    autoAssignment: 'Auto-assign',
    label: {
        yomigana: 'Full name (yomigana)',
        name: 'Full name',
        phone: 'Phone number',
        gender: 'Gender',
        birthday: 'Date of Birth',
        emailAddress: 'Email address',
    },
    sameGuest: '* same information as guest',
    memo: 'Memo',
    memoPlaceholder: 'Enter memo',
    file: 'File',
    otaMemo: 'OTA memo',
    otaMemoPlaceholder: 'Enter OTA memo',
    basicInfo: 'Basic information',
    companionInfo: 'Accompanion information',
    next: 'Next',
    other: 'Other',
    memberGuestEmailDuplicated: 'Email is duplicated',
    memberGuestEmailRequired: 'Email is required',
    memberGuestYomiganaRequired: 'Yomigana is required',
    subTotal: 'Subtotal: ¥{{subTotal}}',
    backConfirmDialog: {
        title: 'Unsaved info will be lost',
    },
    message: {
        calculateAmountError: 'Something is wrong when calculating the total amount',
        exceedLimit: 'Exceed number of rooms',
    },
    addRoomBooking: 'Add room booking',
};
