export default {
    membersTitle: '同行者情報',
    cancel: 'キャンセル',
    submit: '保存',
    representativeRoom: '代表部屋',
    guestSelect: '宿泊者を選択',
    bookingList: '予約一覧',
    autoAssignment: '自動アサイン',
    label: {
        yomigana: '名前（ヨミガナ）',
        name: '名前',
        phone: '電話番号',
        gender: '性別',
        birthday: '生年月日',
    },
    sameGuest: '*宿泊者と同じです',
    memo: 'メモ',
    memoPlaceholder: 'メモをご記入ください',
    file: 'ファイル',
    basicInfo: '基本情報',
    companionInfo: '同行者情報',
    next: '次へ',
    other: 'その他',
    memberGuestEmailDuplicated: 'Emailが重複しています',
    update: {
        success: '更新されました',
        error: '更新に失敗しました',
    },
    noAssignedGuest: '未アサインの契約者',
    selectBookingItems: 'データを記入する予約を選択してください。',
    savingAccomodationCardSuccess: '宿泊カードの更新が保存されました',
    savingAccomodationCardError: '宿泊カードの保存に失敗しました: {{error}}',
    savingAccomodationCard: '宿泊カードの保存',
    enterFullScreen: 'フルスクリーン表示',
    exitFullScreen: 'フルスクリーン表示を終了',
};
