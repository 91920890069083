import { HttpStatus } from '~common/constants';

export class OverBookingException extends Error {
    readonly code: HttpStatus;
    constructor(message: string, code: HttpStatus = HttpStatus.CONFLICT) {
        super(message);
        this.code = code;
        this.name = 'OverBookingException';
    }

    static overBookingWarning(): OverBookingException {
        return new OverBookingException('Overbooking warning');
    }
}
