import { Col, Form, FormInstance, Layout, Row, Tabs } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import {
    fetchMarketingChannelDropDown,
    marketingChannelDropDownsSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import { createBookingSchemaResolver } from '~features/room-booking/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './CreateBookingPage.scss';
import { BookingTemporaryList } from './components/BookingTemporaryList/BookingTemporaryList';

import _, { forEach, isNumber, trim, uniq } from 'lodash';
import { DeepRequired, FieldErrorsImpl, FieldValues, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorMessageType, UploadFileStatus, UserGroup } from '~common/constants';
import { validateFileExtension } from '~features/guest/helper';
import { IFile } from '~features/guest/interfaces';
import { getGuestListForDropdown } from '~features/guest/reducers/guest.reducer';
import {
    ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
    FormTab,
    MAX_NUMBER_OF_FILES_PER_BOOKING,
    MAX_SIZE_OF_EACH_BOOKING_FILE,
    MarketinGChannelDefault,
    RoomBookingEvent,
} from '~features/room-booking/constants';
import {
    getCreateBookingFormPageId,
    validateDayUseTimeIn24H,
    validateDayUseTimeInAfterTwoDaysBefore,
    validateFileSize,
    validateGenderBreakdownOfGuest,
} from '~features/room-booking/helper';
import {
    IBookingConfirmDataItem,
    IMemberAttachment,
} from '~features/room-booking/interfaces';
import { guestDefault } from '~features/room-booking/model';
import {
    createBookingStateSelector,
    isSingleBookingSelector,
    numberOfGuestsSelector,
    setBookingConfirmData,
    setBookingInfo,
} from '~features/room-booking/reducers/create-booking.reducer';
import { getDropDownByRoomType } from '~features/room/room.reducer';
import { Dayjs, parseDate } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import BookingTemporaryCard from './components/BookingTemporaryCard/BookingTemporaryCard';
import CreateBookingFooter from './components/CreateBookingFooter/CreateBookingFooter';
import GeneralBookingCard from './components/GeneralBookingCard/GeneralBookingCard';
import { checkUserPermission } from '~common/commonFunctions';
import { useLocation } from 'react-router-dom';

const CreateBookingPage = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useLocation();

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        setError,
        getValues,
        clearErrors,
        formState: { errors, isValid },
    } = useForm({
        resolver: createBookingSchemaResolver,
    });
    const isAdmin = useMemo(() => {
        return checkUserPermission([UserGroup.ADMIN]);
    }, []);
    const { bookingConfirmData, bookingInfo } = useAppSelector(
        createBookingStateSelector,
    );
    const marketingChannels = useAppSelector(marketingChannelDropDownsSelector);
    const numberOfGuests = useAppSelector(numberOfGuestsSelector);

    const dispatch = useAppDispatch();
    const [formTab, setFormTab] = useState<FormTab>(FormTab.BASIC_INFO);

    const changeFormTab = (key: string) => {
        setFormTab(key as FormTab);
    };

    const breadcrumbs = () => [
        {
            text: t('roomBooking.page.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.page.breadcrumbs.schedule'),
        },
        {
            text: t('roomBooking.page.breadcrumbs.createBooking'),
        },
    ];
    const navigate = useNavigate();

    const scrollToErrors = useCallback(
        (errors: FieldErrorsImpl<DeepRequired<FieldValues>>) => {
            const errorKeys = Object.keys(errors);
            if (!errorKeys.length) {
                return;
            }
            if (errors.members) {
                if (formTab !== FormTab.COMPANION_INFO) {
                    setFormTab(FormTab.COMPANION_INFO);
                }
            } else {
                if (formTab !== FormTab.BASIC_INFO) {
                    setFormTab(FormTab.BASIC_INFO);
                }
            }
        },
        [formTab],
    );

    useEffect(() => {
        scrollToErrors(errors);
    }, [errors, isValid]);

    const validateBookingItemDateTime = (
        item: IBookingConfirmDataItem,
        booking: any,
        index: number,
    ) => {
        if (item.booking?.isDayUse) {
            const validAfterTwoDaysBefore = validateDayUseTimeInAfterTwoDaysBefore(
                [
                    parseDate(item.booking?.stayingStartDate),
                    parseDate(item.booking?.stayingEndDate),
                ],
                [parseDate(booking.checkInTime), parseDate(booking.checkOutTime)],
            );
            // Allow only ADMIN user to select dates before 2 days prior
            if (!isAdmin && !validAfterTwoDaysBefore) {
                setError(`bookings.${index}.checkInTime`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.checkInTimeError'),
                });
                return false;
            }
            const validIn24H = validateDayUseTimeIn24H(
                [
                    parseDate(item.booking?.stayingStartDate),
                    parseDate(item.booking?.stayingEndDate),
                ],
                [parseDate(booking.checkInTime), parseDate(booking.checkOutTime)],
            );
            if (!validIn24H) {
                setError(`bookings.${index}.checkOutTime`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.time24hPeriodError'),
                });
                return false;
            }
        }
        return true;
    };

    const checkAttachments = (attachmentsOfGuest: IFile[]) => {
        if (attachmentsOfGuest.length > MAX_NUMBER_OF_FILES_PER_BOOKING) {
            return {
                isError: true,
                message: t('guest.form.uploadFile.message.maxCount', {
                    maxCount: MAX_NUMBER_OF_FILES_PER_BOOKING,
                }),
            };
        }
        const validFileSize = validateFileSize(
            attachmentsOfGuest,
            MAX_SIZE_OF_EACH_BOOKING_FILE,
        );
        if (validFileSize.isError) {
            return { isError: true, message: validFileSize.message };
        }
        const { isError, message } = validateFileExtension(
            attachmentsOfGuest,
            ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
        );
        if (isError) {
            return { isError: true, message };
        }
        return { isError: false, message: '' };
    };

    const submit = () => {
        handleSubmit((data) => {
            const _bookingConfirmData = _.cloneDeep(bookingConfirmData);
            let isValidDateTime = false;
            let genderOfGuestError: number[] = [];
            let allGuestBooking: IMemberAttachment[] = [];

            const attachmentsOfBooking = data.files || [];

            const { isError, message } = checkAttachments(attachmentsOfBooking);

            if (isError) {
                setError(
                    `files`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message,
                    },
                    { shouldFocus: true },
                );
                return;
            }

            const files = attachmentsOfBooking?.filter(
                (file: IFile) => file.status === UploadFileStatus.DONE,
            );

            const hasRoomRepresentativeGuests = data?.bookings.some(
                (item: Record<string, any>) => isNumber(item.guestIndex),
            );
            const guestMembers = data.members.filter(
                (item: IMemberAttachment) => item.yomigana || item.id,
            );
            let representativeGuests = {
                ...data.representativeGuest,
                index:
                    data.members.filter(
                        (item: IMemberAttachment) => item.yomigana || item.id,
                    )?.length + 1,
            };
            const hasRepresentativeGuest = data.members?.some(
                (guest: IMemberAttachment) =>
                    data.representativeGuest.yomigana === guest.yomigana,
            );
            if (hasRoomRepresentativeGuests) {
                if (hasRepresentativeGuest) {
                    allGuestBooking = [...guestMembers];
                } else {
                    allGuestBooking = [...guestMembers, representativeGuests];
                }
            } else {
                allGuestBooking = [...guestMembers];
            }
            _.forEach(_bookingConfirmData, (item, index) => {
                const _bookingForm = data.bookings[index];

                const guest = (allGuestBooking || []).find(
                    (attachment: IMemberAttachment) => {
                        return attachment.index === _bookingForm.guestIndex;
                    },
                );
                if (guest) {
                    item.booking.guest = {
                        ...item.booking.guest,
                        yomigana: guest.yomigana as string,
                        id: guest.id || null,
                        index: guest.index,
                    };
                } else {
                    item.booking.guest = {
                        ...guestDefault,
                    };
                }
                _.forEach(item.booking?.children, (child, index) => {
                    child.quantity = Number(_bookingForm.children[index]) || 0;
                });

                // set room
                item.booking.roomId = _bookingForm.roomId;
                item.booking.room.id = _bookingForm.room?.id;
                item.booking.room.name = _bookingForm.room?.name;
                // set room type
                item.booking.roomTypeId = _bookingForm.roomTypeId;
                item.booking.roomType.id = _bookingForm.roomType?.id;
                item.booking.roomType.name = _bookingForm.roomType?.name;
                // set plan
                item.booking.planId = _bookingForm.planId;
                item.booking.plan.id = _bookingForm.plan?.id;
                item.booking.plan.name = _bookingForm.plan?.name;
                // set number of adults
                item.booking.numberOfAdults = +Number(_bookingForm.numberOfAdults);
                item.booking.numberOfMale = +Number(_bookingForm.numberOfMale) || 0;
                item.booking.numberOfFemale = +Number(_bookingForm.numberOfFemale) || 0;
                item.booking.numberOfOtherGenderGuest =
                    +Number(_bookingForm.numberOfOtherGenderGuest) || 0;
                item.booking.isRepresentativeRoom = _bookingForm.isRepresentativeRoom;
                item.booking.guestIndex = _bookingForm.guestIndex;
                if (typeof _bookingForm.checkInTime === 'object') {
                    item.booking.checkInTime = parseDate(
                        _bookingForm.checkInTime,
                    )?.fmHHmm();
                }
                if (typeof _bookingForm.checkOutTime === 'object') {
                    item.booking.checkOutTime = parseDate(
                        _bookingForm.checkOutTime,
                    )?.fmHHmm();
                }
                isValidDateTime = validateBookingItemDateTime(item, _bookingForm, index);
                if (
                    !validateGenderBreakdownOfGuest({
                        numberOfAdults: _bookingForm.numberOfAdults,
                        numberOfMale: _bookingForm.numberOfMale,
                        numberOfFemale: _bookingForm.numberOfFemale,
                        numberOfOtherGenderGuest: _bookingForm.numberOfOtherGenderGuest,
                    })
                ) {
                    genderOfGuestError.push(index);
                }
            });
            if (!isValidDateTime) return;
            if (genderOfGuestError.length > 0) {
                forEach(genderOfGuestError, (index) => {
                    setError(`bookings.${index}.numberOfAdults`, {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.genderBreakdownError'),
                    });
                });
                return;
            }
            dispatch(setBookingConfirmData([..._bookingConfirmData]));
            const roomBookingInfo = {
                ...bookingInfo,
                representativeGuest: {
                    ...data.representativeGuest,
                    phone: trim(data.representativeGuest?.phone) || null,
                    birthday:
                        (data.representativeGuest?.birthday as Dayjs)?.fmYYYYMMDD('-') ||
                        null,
                },
                reserverGuest: {
                    ...data.reserverGuest,
                    phone: trim(data.reserverGuest?.phone) || null,
                    yomigana:
                        data.reserverGuest?.yomigana instanceof Object
                            ? data.reserverGuest?.yomigana?.yomigana
                            : data.reserverGuest?.yomigana,
                },
                memo: data.memo,
                otaMemo: data.otaMemo,
                isReserverTheRepresentative: data.isReserverTheRepresentative,
                gender: data.representativeGuest?.gender,
                marketingChannelId: data.marketingChannelId,
                birthday:
                    (data.representativeGuest?.birthday as Dayjs)?.fmYYYYMMDD('-') ||
                    null,
                attachments: (allGuestBooking || []).map((member: any) => ({
                    fullName: member.fullName,
                    yomigana: member.yomigana,
                    phone: trim(member.phone) || null,
                    gender: member.gender || undefined,
                    birthday: (member.birthday as Dayjs)?.fmYYYYMMDD('-') || null,
                    id: member.id,
                    index: member.index,
                    emailAddress: member.emailAddress,
                })),
                files,
            };

            dispatch(setBookingInfo(roomBookingInfo));
            navigate(`/room-booking/create-booking/confirm${searchParam}`);
        })();
    };

    useEffect(() => {
        if (bookingConfirmData.length === 0) {
            navigate('/room-booking/schedule');
            return;
        }
        const roomTypeIds = bookingConfirmData
            .filter((item) => item.booking.roomType.id)
            .map((item) => item.booking.roomType.id) as number[];
        dispatch(fetchMarketingChannelDropDown({}));
        dispatch(getDropDownByRoomType(uniq(roomTypeIds)));
        dispatch(getGuestListForDropdown({ withRoomBooking: true }));
    }, []);

    useEffect(() => {
        if (!bookingInfo) {
            return;
        }

        reset({
            ...getValues(),
            memo: bookingInfo.memo,
            otaMemo: bookingInfo.otaMemo,
            files: bookingInfo?.files?.map((file) => ({
                ...file,
                status: UploadFileStatus.DONE,
            })),
            isReserverTheRepresentative: bookingInfo.isReserverTheRepresentative,
            representativeGuest: {
                ...bookingInfo.representativeGuest,
                birthday: bookingInfo.representativeGuest?.birthday
                    ? parseDate(bookingInfo.representativeGuest?.birthday)
                    : null,
            },
            reserverGuest: bookingInfo.reserverGuest,
            members: bookingInfo.attachments
                .filter(
                    (attachment: IMemberAttachment) =>
                        attachment.yomigana !== bookingInfo.representativeGuest.yomigana,
                )
                .map((attachment: IMemberAttachment) => ({
                    ...attachment,
                    birthday: attachment.birthday ? parseDate(attachment.birthday) : null,
                })),
        });
    }, [bookingInfo]);

    useEffect(() => {
        const walkInMarketingChannel = marketingChannels.find((marketingChannel) => {
            return marketingChannel.name === MarketinGChannelDefault.DIRECT;
        });
        setValue(
            'marketingChannelId',
            bookingInfo.marketingChannelId || walkInMarketingChannel?.id,
        );
    }, [marketingChannels, bookingInfo, setValue]);

    const isSingleBooking = useAppSelector(isSingleBookingSelector);
    const isSingleMember = useMemo(() => {
        return numberOfGuests <= 1;
    }, [numberOfGuests]);

    const members = useWatch({
        control,
        name: 'members',
    });

    const formRef = useRef<FormInstance>(null);
    const { emitter } = useMitt();

    useEffect(() => {
        emitter.on(RoomBookingEvent.CHANGE_TAB, (tab: FormTab) => {
            setFormTab(tab);
        });

        return () => {
            emitter.off(RoomBookingEvent.CHANGE_TAB);
        };
    }, []);

    return (
        <div className="create-booking-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('roomBooking.page.reservation.title')}
                isShowButtonBack
            />

            <Layout.Content>
                <div className="create-booking-page-tab-header">
                    <Tabs
                        activeKey={formTab}
                        onChange={changeFormTab}
                        items={[
                            {
                                key: FormTab.BASIC_INFO,
                                label: (
                                    <div id={getCreateBookingFormPageId('basicInfoTab')}>
                                        {t('roomBooking.createBooking.basicInfo')}
                                    </div>
                                ),
                            },
                        ]
                            .concat(
                                isSingleMember && !members?.length
                                    ? []
                                    : [
                                          {
                                              key: FormTab.COMPANION_INFO,
                                              label: (
                                                  <div
                                                      id={getCreateBookingFormPageId(
                                                          'companionInfoTab',
                                                      )}
                                                  >
                                                      {t(
                                                          'roomBooking.createBooking.companionInfo',
                                                      )}
                                                  </div>
                                              ),
                                          },
                                      ],
                            )
                            .concat([
                                {
                                    key: FormTab.FILE_INFO,
                                    label: t('roomBooking.createBooking.file'),
                                },
                            ])}
                    />
                </div>
                <div className="create-booking-page-content">
                    <Form layout="vertical" ref={formRef} scrollToFirstError>
                        <Row gutter={24}>
                            <Col span={isSingleBooking ? 18 : 24}>
                                <GeneralBookingCard
                                    control={control}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                    getValues={getValues}
                                    formTab={formTab}
                                    onChangeFormTab={changeFormTab}
                                    isCreateForm={true}
                                />
                            </Col>
                            {isSingleBooking ? (
                                <Col span={6}>
                                    {bookingConfirmData.map((item, index) => (
                                        <BookingTemporaryCard
                                            control={control}
                                            index={index}
                                            key={index}
                                            watch={watch}
                                            setValue={setValue}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            booking={item.booking}
                                            isShowTitle={false}
                                            getValues={getValues}
                                            isSingleBooking={isSingleBooking}
                                        />
                                    ))}
                                </Col>
                            ) : null}
                        </Row>
                        {!isSingleBooking && (
                            <Row>
                                <Col span={24}>
                                    <BookingTemporaryList
                                        control={control}
                                        watch={watch}
                                        setValue={setValue}
                                        setError={setError}
                                        clearErrors={clearErrors}
                                        getValues={getValues}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Form>
                </div>
            </Layout.Content>
            <Layout.Footer>
                <CreateBookingFooter
                    onSubmit={submit}
                    errors={errors as any}
                    isValid={isValid}
                />
            </Layout.Footer>
        </div>
    );
};

export default CreateBookingPage;
