import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { ICreateGroupTempRoomBooking } from '~features/room-booking/interfaces/temp-room-booking/create-temp-room-booking.interface';
import { tempRoomBookingServiceV2 } from '~features/room-booking/services/temp-room-bookingV2.service';
import { MutationKeys } from '~queries/queries';
import { OverBookingException } from '../../errors/OverBooking.exception';
import { HttpStatus } from '~common/constants';

const createGroupTempRoomBooking = async (
    body: ICreateGroupTempRoomBooking,
    isAcceptOverbooking = false,
) => {
    const response = await tempRoomBookingServiceV2.createGroupTempRoomBooking(
        body,
        isAcceptOverbooking,
    );
    if (response.success) {
        return response.data;
    } else {
        if (response.code === HttpStatus.CONFLICT) {
            throw OverBookingException.overBookingWarning();
        }
        throw new Error(response.message);
    }
};

const useCreateGroupTempRoomBooking = () => {
    const { t } = useTranslation();
    return useMutation({
        mutationKey: [MutationKeys.createGroupTempRoomBooking],
        mutationFn: async ({
            tempRoomBookings,
            isAcceptOverbooking,
        }: {
            tempRoomBookings: ICreateGroupTempRoomBooking;
            isAcceptOverbooking: boolean;
        }) => {
            return createGroupTempRoomBooking(tempRoomBookings, isAcceptOverbooking);
        },
        onSuccess: () => {
            notification.success({
                message: 'Temp Room Booking Created Successfully',
            });
        },
        onError: (error) => {
            const { message } = error;
            if (
                message === t('roomBooking.form.overbooking.warningResponse') ||
                message === t('roomBooking.form.overbooking.warningTLLResponse')
            ) {
                notification.error({
                    message,
                    description: `${error}`,
                });
            }
        },
    });
};

export default useCreateGroupTempRoomBooking;
