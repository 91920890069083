import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReportTableId } from '~features/report/constants';
import { IMealTableItem } from '~features/report/interfaces';
import { parseDate } from '~plugins/dayjs';

import { MealReportData } from '../../MealReportPage';
import './MealReportTable.scss';

type Props = {
    tableData: MealReportData;
    isRefetching: boolean;
};

function MealReportTable({ tableData, isRefetching }: Props) {
    const { t } = useTranslation();

    const dateColumns: ColumnsType<IMealTableItem> = tableData.dateHeaders.map((date) => {
        return {
            title: parseDate(date).format('MM/DD(ddd)'),
            width: 80,
            render: (item: IMealTableItem) => {
                return (
                    <div
                        className={classNames({
                            'summary-cell': item.isSummary,
                        })}
                    >
                        {item.quantityPerDay[date] || 0}
                    </div>
                );
            },
        };
    });

    const mealReportColumns: ColumnsType<IMealTableItem> = [
        {
            title: t('report.mealReport.table.mealName'),
            width: '20%',
            onCell: (item) => {
                return {
                    rowSpan: item.rowSpan,
                };
            },
            render: (item: IMealTableItem) => {
                return (
                    <div
                        className={classNames('text-truncate-cell', {
                            'summary-cell': item.isSummary,
                        })}
                    >
                        {item.name}
                    </div>
                );
            },
        },
        {
            title: t('report.mealReport.table.nightOfStay'),
            width: 80,
            render: (item: IMealTableItem) => {
                return (
                    <div
                        className={classNames('text-truncate-cell', {
                            'summary-cell': item.isSummary,
                        })}
                    >
                        {item.isSummary
                            ? t('report.mealReport.table.total')
                            : item.nightOfStay}
                    </div>
                );
            },
        },
        {
            title: t('report.mealReport.table.subtotal'),
            width: 80,
            render: (item: IMealTableItem) => {
                return (
                    <div
                        className={classNames('text-truncate-cell', {
                            'summary-cell': item.isSummary,
                        })}
                    >
                        {item.subtotal}
                    </div>
                );
            },
        },
        ...dateColumns,
    ];

    const summaryRow = () => {
        return (
            <Table.Summary.Row className="summary-table">
                <Table.Summary.Cell index={0} colSpan={2}>
                    {t('report.mealReport.table.allTotal')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                    {tableData.totalOfAllMealItem}
                </Table.Summary.Cell>
                {tableData.dateHeaders.map((header, index) => (
                    <Table.Summary.Cell index={index + 2} key={`${header}_${index}`}>
                        {tableData.totalOfAllMealByDay[header] || 0}
                    </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
        );
    };

    return (
        <div className="report-meal-table-wrapper">
            <Table
                id={ReportTableId.MEAL_REPORT}
                rowKey="id"
                size="small"
                rowClassName={(i) =>
                    classNames({
                        'summary-row': i.isSummary,
                    })
                }
                columns={mealReportColumns}
                dataSource={tableData.values}
                loading={isRefetching}
                pagination={false}
                summary={summaryRow}
            />
        </div>
    );
}

export default MealReportTable;
