import { MutationKey, useMutation } from '@tanstack/react-query';
import { tempRoomBookingServiceV2 } from '~features/room-booking/services/temp-room-bookingV2.service';

const deleteGroupTempRoomBookings = async (ids: number[]) => {
    const response = await tempRoomBookingServiceV2.deleteGroupTempRoomBookings(ids);
    if (response.success) {
        return response;
    } else {
        throw {
            code: response.code,
            message: response.errors![0].message,
        };
    }
};

const useDeleteGroupTempRoomBookings = (key: MutationKey | undefined) => {
    return useMutation({
        mutationFn: async (tempRoomBookingIds: number[]) => {
            return deleteGroupTempRoomBookings(tempRoomBookingIds);
        },
        mutationKey: key,
    });
};

export default useDeleteGroupTempRoomBookings;
