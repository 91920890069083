import type { UploadFile as IUploadFile } from 'antd/es/upload/interface';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFileStatus } from '~common/constants';
import { UploadFileCustom } from '~components';
import {
    ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
    MAX_NUMBER_OF_FILES_PER_BOOKING,
    MAX_SIZE_OF_EACH_BOOKING_FILE,
} from '~features/room-booking/constants';
import { createBookingStateSelector } from '~features/room-booking/reducers/create-booking.reducer';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { useAppSelector } from '~hooks';
import './FileTabs.scss';

type Props = {
    control: any;
    isFrozen?: boolean;
    isCreateForm?: boolean;
};
function FileTabs(props: Props) {
    const { control, isFrozen, isCreateForm } = props;
    const { t } = useTranslation();
    const { bookingInfo } = useAppSelector(createBookingStateSelector);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);

    const fileList = useMemo(() => {
        if (!selectedRoomBookingDetail?.filesInformation?.length) return [];
        return selectedRoomBookingDetail?.filesInformation?.map((file) => {
            return {
                uid: `${file.id}`,
                name: file.originalName,
                status: UploadFileStatus.DONE,
                url: file.url,
                path: file.path,
                size: file.size,
                fileName: file.fileName,
                type: file.mimetype,
            } as IUploadFile;
        });
    }, [selectedRoomBookingDetail]);

    const fileCreateList = useMemo(() => {
        if (!bookingInfo?.files?.length) return [];
        return bookingInfo?.files?.map((file) => {
            return {
                uid: `${file.id}`,
                name: file.originalName,
                status: UploadFileStatus.DONE,
                url: file.url,
                path: file.path,
                size: file.size,
                fileName: file.fileName,
                type: file.mimetype,
            } as IUploadFile;
        });
    }, [bookingInfo]);

    return (
        <UploadFileCustom
            title={t('common.uploadFile.title')}
            subTitle={t('common.uploadFile.hint')}
            name="files"
            control={control}
            label=""
            pathKey="room-booking"
            maxCount={MAX_NUMBER_OF_FILES_PER_BOOKING}
            maxSize={MAX_SIZE_OF_EACH_BOOKING_FILE}
            multiple={true}
            listType="picture"
            acceptTypes={ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS}
            defaultFileList={isCreateForm ? fileCreateList : fileList}
            disabled={isFrozen}
        />
    );
}

export default FileTabs;
