import { parseDate } from '~plugins/dayjs';
import { t } from 'i18next';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import localStorageAuthService from '~common/authStorage';
import { makeRowWithCellSpan, textToChunksWithMaxLength } from '~common/commonFunctions';
import { dayList, PrintingOrientation } from '~common/constants';
import { CleaningStatus } from '~features/room-cleaning/constants';
import { IPrintRoomCleaning } from '~features/room-cleaning/interfaces';
import {
    getNumberOfGuest,
    getNumberOfNights,
    getRoomStatus,
} from '~features/room-cleaning/help';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';

interface IOptions {
    orientation: PrintingOrientation;
    setTotalPage: (totalPage: number) => void;
}

export const generateRoomCleaningPdf = (
    roomCleaningList: IPrintRoomCleaning[],
    options: IOptions,
): TDocumentDefinitions => {
    const { orientation = PrintingOrientation.LANDSCAPE, setTotalPage } = options;
    const hotel = localStorageAuthService.getSelectedHotel();
    const currentDate = parseDate(new Date());
    const content: Content = generateRoomCleaningContent(
        roomCleaningList,
    ) as unknown as Content;

    return {
        pageSize: 'A4',
        pageOrientation: orientation,
        header: (currentPage, pageCount) => ({
            columns: [
                {
                    columns: [
                        {
                            text: `${t('roomCleaning.printing.cleaningList')} - ${
                                hotel?.name
                            }`,
                            bold: true,
                        },
                        {
                            text: t('roomCleaning.printing.date', {
                                year: currentDate.format('YYYY'),
                                month: currentDate.format('MM'),
                                day: currentDate.format('DD'),
                                time: currentDate.fmHHmm(),
                                dayOfWeek: t(
                                    `roomCleaning.printing.day.${
                                        dayList[currentDate.day()]
                                    }`,
                                ),
                            }) as string,
                        },
                    ],
                },
                {
                    text: `${currentPage}/${pageCount}`,
                    alignment: 'right',
                },
            ],
            style: 'pageHeader',
            margin: [40, 20, 40, 40],
            width: '*',
        }),
        footer: (currentPage, pageCount) => {
            setTotalPage(pageCount);
            return {
                text: '',
            };
        },
        content,
        styles: {
            pageHeader: {
                fontSize: 12,
                color: '#555757',
            },
            header: {
                fontSize: 18,
                bold: true,
            },
            table: {
                fontSize: 12,
            },
            headerText: {
                color: '#FFFFFF',
            },
            contentText: {
                color: '#8E2A2A',
                bold: true,
            },
            cleanedRoomContentText: {
                color: '#576464',
                bold: true,
            },
        },
    };
};

const generateRoomCleaningContent = (roomCleaningList: IPrintRoomCleaning[]) => {
    const NUMBER_OF_COLUMNS = 19;
    return [
        {
            style: 'table',
            margin: [0, 10],
            table: {
                widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                    .map(() => '*')
                    .concat('auto'),
                body: [
                    makeRowWithCellSpan([
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.no`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 1,
                            rowSpan: 2,
                        },
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.roomName`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 2,
                            rowSpan: 2,
                        },
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.roomType`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 4,
                            rowSpan: 2,
                        },
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.numberOfNights`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 2,
                            rowSpan: 2,
                        },
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.numberOfGuests`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 2,
                            rowSpan: 2,
                        },
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.isCleaned`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 1,
                            rowSpan: 2,
                        },
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.currentStatus`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 2,
                            rowSpan: 2,
                        },
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.time`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 2,
                            rowSpan: 2,
                        },
                        {
                            stack: [
                                {
                                    margin: [0, 10, 0, 10],
                                    alignment: 'center',
                                    text: t(`roomCleaning.printing.memo`),
                                    style: 'headerText',
                                },
                            ],
                            colSpan: 3,
                            rowSpan: 2,
                        },
                    ]),
                    [
                        ...makeRowWithCellSpan([
                            {
                                stack: [],
                                colSpan: 16,
                                rowSpan: 1,
                            },
                        ]),
                    ],
                    ...roomCleaningList.map(
                        (roomCleaning: IPrintRoomCleaning, index: number) => {
                            const numberOfNightsStayed = roomCleaning.roomBookingItem
                                ? parseDate(
                                      roomCleaning.roomBookingItem.endDateOfStay,
                                  ).diff(
                                      roomCleaning.roomBookingItem.startDateOfStay,
                                      'day',
                                  )
                                : '';
                            const numberOfNightHave = getNumberOfNights(roomCleaning);
                            const numberOfNights = roomCleaning?.roomBookingItem
                                ?.startDateOfStay
                                ? numberOfNightHave
                                : '';
                            const roomStatus = getRoomStatus(roomCleaning);
                            const numberOfGuest = getNumberOfGuest(roomCleaning);
                            const bookingStatus =
                                roomCleaning.roomBookingItem?.bookingStatus;
                            return makeRowWithCellSpan([
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: index + 1,
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 1,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: textToChunksWithMaxLength(
                                                roomCleaning?.name,
                                                8,
                                                true,
                                            ).join('\n'),
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 2,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: textToChunksWithMaxLength(
                                                roomCleaning?.roomType?.name,
                                                20,
                                                true,
                                            ).join('\n'),
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 4,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text:
                                                numberOfNights &&
                                                numberOfNightsStayed &&
                                                roomCleaning?.isShowNightCount
                                                    ? numberOfNights <=
                                                      numberOfNightsStayed
                                                        ? `${numberOfNights}/${numberOfNightsStayed}`
                                                        : `${numberOfNightsStayed}/${numberOfNightsStayed}`
                                                    : '',
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 2,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text:
                                                bookingStatus !==
                                                    RoomBookingItemBookingStatus.CHECKED_OUT &&
                                                bookingStatus !==
                                                    RoomBookingItemBookingStatus.CANCELLED
                                                    ? numberOfGuest
                                                    : '',
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 2,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: t(
                                                `roomCleaning.printing.${roomCleaning?.cleaningStatus}`,
                                            ),
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 1,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: roomStatus
                                                ? t(
                                                      `roomCleaning.printing.status.${roomStatus}`,
                                                  )
                                                : '',
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 2,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text:
                                                parseDate(
                                                    roomCleaning?.roomBookingItem
                                                        ?.startDateOfStay,
                                                ).fmYYYYMMDD() ===
                                                    parseDate(new Date()).fmYYYYMMDD() &&
                                                roomCleaning?.isShowCheckInTime &&
                                                parseDate(
                                                    roomCleaning?.roomBookingItem
                                                        ?.checkInDateTime,
                                                ).fmYYYYMMDD() ===
                                                    parseDate(new Date()).fmYYYYMMDD()
                                                    ? roomCleaning?.roomBookingItem
                                                          ?.checkInTime
                                                    : '',
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 2,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'left',
                                            text: textToChunksWithMaxLength(
                                                roomCleaning?.memo,
                                                13,
                                                true,
                                            ).join('\n'),
                                            margin: [12, 0, 0, 0],
                                            style:
                                                roomCleaning?.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleanedRoomContentText'
                                                    : 'contentText',
                                        },
                                    ],
                                    colSpan: 3,
                                },
                            ]);
                        },
                    ),
                ],
            },
            layout: {
                hLineWidth: () => 2,
                vLineWidth: () => 2,
                hLineColor: 'white',
                vLineColor: 'white',
                fillColor: (rowIndex: number) => {
                    if (rowIndex === 0 || rowIndex === 1) {
                        return '#00A0C3';
                    }
                    if (
                        roomCleaningList[rowIndex - 2].cleaningStatus ===
                        CleaningStatus.CLEANED
                    ) {
                        return '#f6f6f6';
                    }
                    return '#F9ECEC';
                },
                color: (rowIndex: number) => {
                    return rowIndex === 0 || rowIndex === 1 ? '#FFFFFF' : '#8E2A2A';
                },
                paddingTop: (rowIndex: number) => {
                    return rowIndex === 0 || rowIndex === 1 ? 0 : 10;
                },
                paddingBottom: (rowIndex: number) => {
                    return rowIndex === 0 || rowIndex === 1 ? 0 : 10;
                },
            },
        },
    ];
};
