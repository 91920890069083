import { useMutation } from '@tanstack/react-query';
import { roomBookingServiceV2 } from '~features/room-booking/services/room-bookingV2.service';
import { MutationKeys } from '~queries/queries';

const deleteBooking = async (bookingId: number) => {
    return await roomBookingServiceV2.deleteBooking(bookingId);
};

const useDeleteBooking = () => {
    return useMutation({
        mutationFn: async (bookingId: number) => {
            return deleteBooking(bookingId);
        },
        mutationKey: [MutationKeys.bulkDeleteBooking],
    });
};

export default useDeleteBooking;
