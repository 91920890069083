import { CaretDownOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cellAutoGeneratedCodeStyle, cellTextErrorStyle } from '~common/constants';
import { SingleSelect } from '~components';
import { ReceiptItemType } from '~features/fo-operation-history/constants';
import { IBookingReceiptTableData, IReceipt } from '~features/room-booking/interfaces';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import AmountColumn from './AmountColumn/AmountColumn';
import BookingIdColumn from './BookingIdColumn/BookingIdColumn';
import DateBookingColumn from './DateBookingColumn/DateBookingColumn';
import DetailReceiptColumn from './DetailReceiptColumn/DetailReceiptColumn';
import PaymentMethodColumn from './PaymentMethodColumn/PaymentMethodColumn';
import QuantityColumn from './QuantityColumn/QuantityColumn';
import './ReceiptTable.scss';
import UnitPriceColumn from './UnitPriceColumn/UnitPriceColumn';

type Props = {
    control: any;
    isEditMode: boolean;
    selectedRowKeys: React.Key[];
    receiptTableData: IBookingReceiptTableData[];
    paymentRoomBookingItemOptions: { value: number; label: string | undefined }[];
    onSelectChange: (selectedRowKeys: React.Key[]) => void;
    addSaleItem: (item: IBookingReceiptTableData, type: ReceiptItemType) => void;
    displayBookingId: (item: IBookingReceiptTableData) => string;
    onChangeSaleItem: (saleItem: ISaleItemDropdown, id: string) => void;
    changeUnitPriceOrQuantity: (value: number, id: string, fieldUpdate: string) => void;
    isVirtual?: boolean;
    roomBookingReceipt: IReceipt | undefined;
    isLoading: boolean;
};
function ReceiptTable(props: Props) {
    const {
        control,
        isEditMode,
        selectedRowKeys,
        receiptTableData,
        paymentRoomBookingItemOptions,
        onSelectChange,
        addSaleItem,
        displayBookingId,
        onChangeSaleItem,
        changeUnitPriceOrQuantity,
        isVirtual,
        roomBookingReceipt,
        isLoading,
    } = props;
    const { t } = useTranslation();
    const [expandRowIds, setExpandRowIds] = useState<string[]>([]);
    const isRoomBookingReceiptItem = (id: string) => {
        return id.split('_')?.[0] === 'hol';
    };

    useEffect(() => {
        const roomBookingIds =
            roomBookingReceipt?.roomBooking?.roomBookingItems?.map(
                (item) => `hol_${item.id}`,
            ) || [];
        const facilityBookingIds =
            roomBookingReceipt?.facilityBookings?.map((item) => `fac_${item.id}`) || [];
        setExpandRowIds([...roomBookingIds, ...facilityBookingIds]);
    }, [roomBookingReceipt]);

    const expandRow = (expanded: boolean, record: IBookingReceiptTableData) => {
        if (expanded) {
            setExpandRowIds([...expandRowIds, record.id]);
        } else {
            const _expandRowIds = expandRowIds.filter((id) => {
                return id !== record.id;
            });
            setExpandRowIds([..._expandRowIds]);
        }
    };

    const receiptRowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const isCanEdit = (item: IBookingReceiptTableData) => {
        return isEditMode && !item.isCheckout;
    };

    const getTypeReceiptItem = (item: IBookingReceiptTableData) => {
        const isRoomBooking = isRoomBookingReceiptItem(item.id);
        if (isRoomBooking && item.plan?.name) {
            return t('roomBooking.detail.receipt.plan.status');
        }
        if (!isRoomBooking && item.facility?.name) {
            return t('roomBooking.detail.receipt.facility.status');
        }
        if (item.status) {
            return t(`roomBooking.detail.receiptStatus.${item?.status}`);
        }
        return '';
    };

    const roomBookingReceiptColumns = [
        {
            title: (
                <>
                    {t('roomBooking.detail.receiptTableColumn.booking')}
                    <span className="title-id-cell">
                        {t('roomBooking.detail.receiptTableColumn.id')}
                    </span>
                </>
            ),
            onCell: (item: IBookingReceiptTableData) => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                    colSpan: item.name ? 4 : 1,
                };
            },
            render: (item: IBookingReceiptTableData) => {
                return (
                    <BookingIdColumn
                        item={item}
                        displayBookingId={displayBookingId}
                        isCanEdit={isCanEdit}
                        addSaleItem={addSaleItem}
                    />
                );
            },
            key: 'id',
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.date'),
            onCell: (item: IBookingReceiptTableData) => {
                return {
                    colSpan: item.name ? 0 : 1,
                };
            },
            render: (item: IBookingReceiptTableData) => {
                return (
                    <DateBookingColumn
                        control={control}
                        item={item}
                        isEditMode={isEditMode}
                    />
                );
            },
            key: 'date',
            width: '150px',
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.status'),
            onCell: (item: IBookingReceiptTableData) => {
                return {
                    colSpan: item.name ? 0 : 1,
                };
            },
            render: (item: IBookingReceiptTableData) => {
                const isPlanOrFacilityItem = item?.status && !item.children;
                return (
                    <span
                        className={classNames('receipt-status text-truncate', {
                            [`receipt-status-${item?.status}`]: isPlanOrFacilityItem,
                            'pl-14': isPlanOrFacilityItem,
                        })}
                    >
                        {getTypeReceiptItem(item)}
                    </span>
                );
            },
            key: 'status',
            width: '90px',
            ellipsis: true,
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.plan'),
            onCell: (item: IBookingReceiptTableData) => {
                return {
                    colSpan: item.name ? 0 : 1,
                };
            },
            render: (item: IBookingReceiptTableData) => {
                return (
                    <DetailReceiptColumn
                        control={control}
                        item={item}
                        expanded={expandRowIds.includes(item.id)}
                        isEditMode={isEditMode}
                        onChangeSaleItem={onChangeSaleItem}
                    />
                );
            },
            key: 'plan',
            width: '150px',
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.guest'),
            render: (item: IBookingReceiptTableData) => {
                return <span>{item.representativeGuest?.yomigana}</span>;
            },
            key: 'guest',
            ellipsis: true,
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.roomName'),
            render: (item: IBookingReceiptTableData) => {
                return <span>{item.isCancelled ? '' : item.room?.name}</span>;
            },
            key: 'roomName',
            ellipsis: true,
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.unitPrice'),
            width: '150px',
            render: (item: IBookingReceiptTableData) => {
                return (
                    <UnitPriceColumn
                        item={item}
                        control={control}
                        isEditMode={isEditMode}
                        onChangeUnitPrice={changeUnitPriceOrQuantity}
                    />
                );
            },
            key: 'unitPrice',
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.quantity'),
            width: '100px',
            render: (item: IBookingReceiptTableData) => {
                return (
                    <QuantityColumn
                        item={item}
                        control={control}
                        isEditMode={isEditMode}
                        onChangeQuantity={changeUnitPriceOrQuantity}
                    />
                );
            },
            key: 'quantity',
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.total'),
            width: '150px',
            render: (item: IBookingReceiptTableData) => {
                return (
                    <AmountColumn
                        item={item}
                        control={control}
                        isEditMode={isEditMode}
                        onChangeAmount={changeUnitPriceOrQuantity}
                    />
                );
            },
            key: 'total',
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.checkoutRoom'),
            render: (item: IBookingReceiptTableData) => {
                return isCanEdit(item) && item.level === 1 ? (
                    <>
                        <SingleSelect
                            label=""
                            name={`${item.id}.paymentRoomBookingItem`}
                            control={control}
                            options={paymentRoomBookingItemOptions}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            dropdownStyle={{
                                maxWidth: '200px',
                            }}
                            popupMatchSelectWidth={false}
                        />
                    </>
                ) : (
                    <span>
                        {item.isCancelled ? '' : item.paymentRoomBookingItem?.room?.name}
                    </span>
                );
            },
            key: 'paymentRoomBooking',
            ellipsis: true,
        },
        {
            title: t('roomBooking.detail.receiptTableColumn.payment'),
            render: (item: IBookingReceiptTableData) => {
                return (
                    <PaymentMethodColumn
                        control={control}
                        item={item}
                        isCanEdit={isCanEdit}
                    />
                );
            },
            onCell: (item: IBookingReceiptTableData) => {
                if (item.paymentMethod?.isPullFromTll && !isEditMode) {
                    return {
                        style: cellTextErrorStyle,
                    };
                } else {
                    return {};
                }
            },
            key: 'payment',
            ellipsis: true,
        },
    ];

    const expandable = useMemo(
        () => ({
            expandRowByClick: true,
            expandedRowKeys: expandRowIds,
            onExpand: expandRow,
            expandIcon: ({
                expanded,
                record,
            }: {
                expanded: boolean;
                record: IBookingReceiptTableData;
            }) =>
                record?.level === 0 ? (
                    <span className="expand-icon">
                        <CaretDownOutlined
                            className={classNames({
                                active: !expanded,
                                deactive: expanded,
                            })}
                        />
                    </span>
                ) : (
                    <></>
                ),
        }),
        [expandRowIds],
    );

    return (
        <Table
            rowSelection={
                isEditMode
                    ? {
                          ...receiptRowSelection,
                          checkStrictly: false,
                      }
                    : undefined
            }
            columns={roomBookingReceiptColumns}
            dataSource={receiptTableData}
            pagination={false}
            className="room-booking-receipt-table"
            rowClassName={(record) => {
                return record.children ? 'row-expandable row-sale-item' : 'row-sale-item';
            }}
            expandable={expandable}
            rowKey="id"
            virtual={isVirtual}
            scroll={isVirtual ? { y: 800 } : undefined}
            loading={isLoading}
        />
    );
}

export default ReceiptTable;
