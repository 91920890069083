import './RoomKeycardIssueModal.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Form, Modal, Select } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { InputText } from '~components';
import { parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';
import { KeycardStatus } from '../enums/key-card-status.enums';
import { useKeyCardIssueModalContext } from '../RoomKeycardIssueProvider';
import { KeycardModalMode } from '../enums/key-card-modal-mode.enums';
import { CardIssueStatus } from './CardIssueStatus/CardIssueStatus';
import { KeycardIssueStatus } from '../enums/key-card-issue-status.enums';
import { useMutation } from '@tanstack/react-query';
import { roomKeyRecordService } from '~features/room-booking/services/room-key-record.service';
import queryClient, { CacheKeys } from '~queries/queries';
import { IUpdateRoomKeyRecord } from '~features/room-booking/interfaces';

export function RoomKeycardIssueModal() {
    const { t } = useTranslation();
    const { modalMode, issueStatus, keyDataInput, isModalOpen, closeModal, issue } =
        useKeyCardIssueModalContext();
    const { control } = useForm();
    const [selectedStatus, setSelectedStatus] = useState<KeycardStatus>(
        KeycardStatus.RECYCLE,
    );
    const { mutate } = useMutation({
        mutationFn: (data: IUpdateRoomKeyRecord) =>
            roomKeyRecordService.updateRecord(data),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [CacheKeys.getRoomKeyRecords, keyDataInput?.roomBookingId],
            });
            closeModal();
        },
    });

    const statusOptions = [
        {
            label: t('roomBooking.detail.keycard.status.issue'),
            value: KeycardStatus.ISSUE,
        },
        {
            label: t('roomBooking.detail.keycard.status.recycle'),
            value: KeycardStatus.RECYCLE,
        },
        {
            label: t('roomBooking.detail.keycard.status.lost'),
            value: KeycardStatus.LOST,
        },
    ];

    const getTitle = () => {
        if (modalMode === KeycardModalMode.ISSUE) {
            return t('roomBooking.detail.keycard.addKey');
        }
        if (modalMode === KeycardModalMode.EDIT) {
            return t('roomBooking.detail.keycard.editKey');
        }
        return ''; // define a default title
    };

    const submitKeycard = () => {
        if (modalMode === KeycardModalMode.ISSUE) {
            return issue();
        }
        if (modalMode === KeycardModalMode.EDIT && keyDataInput?.id) {
            return mutate({
                id: keyDataInput.id,
                status: selectedStatus,
            });
        }
    };

    if (!keyDataInput) {
        return null;
    }
    return (
        <Modal
            title={getTitle()}
            open={isModalOpen}
            centered
            onCancel={() => closeModal()}
            maskClosable={issueStatus !== KeycardIssueStatus.SCANNING}
            width={440}
            className="keycard-modal"
            footer={
                issueStatus === KeycardIssueStatus.PENDING
                    ? [
                          <Button
                              key="cancel"
                              onClick={() => closeModal()}
                              size="large"
                              className="keycard-modal__action-btn"
                          >
                              {t('roomBooking.detail.keycard.cancel')}
                          </Button>,
                          <Button
                              key="proceed"
                              type="primary"
                              onClick={() => submitKeycard()}
                              size="large"
                              className="keycard-modal__action-btn"
                          >
                              {t('roomBooking.detail.keycard.proceed')}
                          </Button>,
                      ]
                    : null
            }
        >
            {issueStatus === KeycardIssueStatus.PENDING ? (
                <Form layout="vertical">
                    <div className="keycard-modal__wrapper">
                        <div className="keycard-modal__item">
                            <span className="keycard-modal__item-label">
                                {t('roomBooking.detail.roomItem')}:
                            </span>
                            <strong>{keyDataInput.roomNumber}</strong>
                        </div>
                        <InputText
                            name="keycard-form-id"
                            label="ID"
                            value={keyDataInput.issueId}
                            control={control}
                            required
                            disabled
                        />
                        {modalMode === KeycardModalMode.ISSUE && (
                            <div className="keycard-modal__cico-wrapper">
                                <div>{t('roomBooking.detail.keycard.cico')}</div>
                                <div className="keycard-modal__cico">
                                    <div>
                                        {parseDateTime(
                                            keyDataInput.startDateTime,
                                            DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                                        )}
                                    </div>
                                    <ArrowRightOutlined />
                                    <div>
                                        {parseDateTime(
                                            keyDataInput.endDateTime,
                                            DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {modalMode === KeycardModalMode.EDIT && (
                            <Form.Item
                                label={t('roomBooking.detail.keycard.statusColumn')}
                                name="status"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    onChange={(val) => setSelectedStatus(val)}
                                    defaultValue={selectedStatus}
                                >
                                    {statusOptions.map((status) => (
                                        <Select.Option
                                            key={status.value}
                                            value={status.value}
                                        >
                                            {status.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </div>
                </Form>
            ) : (
                <CardIssueStatus />
            )}
        </Modal>
    );
}
