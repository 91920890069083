import _, { sortBy, sumBy } from 'lodash';
import { compareFormData } from '~common/commonFunctions';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { RoomBookingItemBookingStatus } from './constants';
import {
    IChildBookingAmounts,
    IGuest,
    IRoomBookingDetail,
    IRoomBookingItem,
    IUpdateBookingFormData,
    IUpdateRoomBookingForm,
    IUpdateRoomBookingItemBody,
} from './interfaces';
import {
    guestDefault,
    guestFormDefault,
    representativeGuestFormDefault,
    reserverGuestFormDefault,
    roombookingFormDefault,
} from './model';
import { UploadFileStatus } from '~common/constants';

// Used to transform room booking detail data to corresponding data in useForm
export const transformUpdateBookingForm = (
    roomBookingDetail: IRoomBookingDetail,
): IUpdateRoomBookingForm => {
    const {
        marketingChannelId,
        memo,
        representativeGuest,
        reserverGuest,
        representativeGuestId,
        reserverGuestId,
        guests,
        otaMemo,
        filesInformation = [],
    } = roomBookingDetail;
    const additionGuests = Array.from(
        {
            length:
                sumBy(roomBookingDetail.roomBookingItems, 'numberOfAdults') -
                (guests?.length || 0),
        },
        (_, index) => ({
            ...guestDefault,
            birthday: null,
            index: (guests?.length || 0) + index,
        }),
    );

    const files = filesInformation?.map((file) => ({
        ...file,
        status: UploadFileStatus.DONE,
    }));

    return {
        ...roombookingFormDefault,
        representativeGuest: {
            id: representativeGuestId,
            fullName: representativeGuest?.fullName,
            yomigana: representativeGuest?.yomigana,
            phone:
                representativeGuest?.mobilePhoneNumber ||
                representativeGuest?.phoneNumberLandline ||
                null,
            emailAddress: representativeGuest?.emailAddress || null,
            birthday: representativeGuest?.birthday
                ? parseDate(representativeGuest?.birthday)
                : null,
            gender: representativeGuest?.gender || null,
        },
        reserverGuest: {
            id: reserverGuestId,
            fullName: reserverGuest?.fullName,
            yomigana: reserverGuest?.yomigana,
            phone:
                reserverGuest?.mobilePhoneNumber ||
                reserverGuest?.phoneNumberLandline ||
                null,
            emailAddress: reserverGuest?.emailAddress || null,
            birthday: reserverGuest?.birthday ? parseDate(reserverGuest?.birthday) : null,
            gender: reserverGuest?.gender || null,
        },
        members: [
            ...(guests
                ?.filter((guest) => guest.id !== representativeGuestId)
                ?.map((guest, index) => {
                    const {
                        id,
                        yomigana,
                        fullName,
                        mobilePhoneNumber,
                        phoneNumberLandline,
                        birthday,
                        gender,
                        emailAddress,
                    } = guest;
                    return {
                        ...guestDefault,
                        id: id,
                        index: index + 1,
                        yomigana: yomigana,
                        fullName: fullName,
                        phone: mobilePhoneNumber || phoneNumberLandline || null,
                        birthday: birthday ? parseDate(birthday) : null,
                        gender: gender || null,
                        emailAddress: emailAddress,
                    };
                }) || []),
            ...additionGuests,
        ],
        marketingChannelId: marketingChannelId,
        isReserverTheRepresentative: roomBookingDetail.isReserverTheRepresentative,
        memo: memo,
        otaMemo: otaMemo,
        files,
    };
};

// Used to transform form data in useForm to form body requested to backend
export const transformUpdateBookingFormData = (
    form: IUpdateRoomBookingForm,
): IUpdateBookingFormData | FormData => {
    const representativeGuestId = form.representativeGuest?.id;
    const representativeGuest = !representativeGuestId
        ? {
              representativeGuestId: null,
              representativeGuestFullName:
                  form.representativeGuest?.fullName?.trim() || null,
              representativeGuestYomigana:
                  form.representativeGuest?.yomigana?.trim() || null,
              representativeGuestMobilePhoneNumber:
                  form.representativeGuest?.phone?.trim() || null,
              representativeGuestEmailAddress:
                  form.representativeGuest?.emailAddress?.trim() || null,
              representativeGuestBirthday: form.representativeGuest?.birthday
                  ? parseDate(form.representativeGuest?.birthday)?.fmYYYYMMDD('-') || null
                  : null,
              representativeGuestGender: form.representativeGuest?.gender || null,
          }
        : {
              ...representativeGuestFormDefault,
              representativeGuestId,
          };
    const isReserverTheRepresentative = form.isReserverTheRepresentative;
    const reserverGuestId = !isReserverTheRepresentative ? form.reserverGuest?.id : null;
    const reserverGuest =
        !reserverGuestId && !isReserverTheRepresentative
            ? {
                  reserverGuestId: null,
                  reserverGuestFullName: form.reserverGuest?.fullName?.trim() || null,
                  reserverGuestYomigana: form.reserverGuest?.yomigana?.trim() || null,
                  reserverGuestMobilePhoneNumber:
                      form.reserverGuest?.phone?.trim() || null,
                  reserverGuestEmailAddress:
                      form.reserverGuest?.emailAddress?.trim() || null,
              }
            : {
                  ...reserverGuestFormDefault,
                  reserverGuestId: reserverGuestId || null,
              };
    const guests: IGuest[] =
        form.guests
            ?.filter((member) => {
                return member.yomigana && member.id !== form.representativeGuest?.id;
            })
            ?.map((member) =>
                !member.id
                    ? {
                          id: undefined,
                          fullName: member.fullName || null,
                          yomigana: (member.yomigana as string) || null,
                          mobilePhoneNumber: member.mobilePhoneNumber || null,
                          emailAddress: member.emailAddress?.trim() || null,
                          birthday: member.birthday
                              ? parseDate(member.birthday)?.fmYYYYMMDD('-') || null
                              : null,
                          gender: member.gender || null,
                      }
                    : { ...guestFormDefault, id: member.id || null },
            ) || [];
    const oldFiles = form.files?.filter((file) => !file.isAdditional && !!file.id);
    let fileIds;
    if (oldFiles?.length) {
        fileIds = oldFiles.map((file) => +(file.id || 0));
    }

    const updateBooking = {
        ...representativeGuest,
        ...reserverGuest,
        guests,
        isReserverTheRepresentative,
        marketingChannelId: form.marketingChannelId || null,
        memo: form.memo || null,
        otaMemo: form.otaMemo || null,
        fileIds,
    };

    const newFiles = form.files?.filter(
        (file) => file.isAdditional && !!file.originFileObj,
    );

    if (newFiles?.length) {
        const bodyFormData = new FormData();
        bodyFormData.append('roomBookingData', JSON.stringify(updateBooking));
        newFiles?.forEach((file) => {
            if (file.isAdditional && file?.originFileObj) {
                bodyFormData.append(`files`, file?.originFileObj);
            }
        });
        return bodyFormData;
    }

    return updateBooking;
};

const mapRoomBookingItemChildrenTypes = (childrenTypes: IChildBookingAmounts[]) => {
    return sortBy(childrenTypes, 'childrenTypeId').map((chid) => {
        return {
            quantity: chid.quantity,
            childrenTypeId: chid.childrenTypeId,
        };
    });
};

const mapToCompareData = (data: IUpdateRoomBookingItemBody | IRoomBookingItem) => {
    return {
        startDateOfStay: parseDate(data.startDateOfStay).fmYYYYMMDD(),
        endDateOfStay: parseDate(data.endDateOfStay).fmYYYYMMDD(),
        numberOfAdults: data.numberOfAdults,
        planId: data.planId,
        roomTypeId: data.roomTypeId,
        roomBookingItemChildrenTypes: mapRoomBookingItemChildrenTypes(
            data.roomBookingItemChildrenTypes || [],
        ),
    };
};

export const isChangeReceiptPrice = (
    updateBookingItem: IUpdateRoomBookingItemBody,
    bookingItem: IRoomBookingItem,
) => {
    if (updateBookingItem.bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED) {
        // map number of children by children type
        const _bookingItems = _.cloneDeep(bookingItem);
        const childrenTypeIds =
            updateBookingItem?.roomBookingItemChildrenTypes?.map(
                (item) => item.childrenTypeId,
            ) || [];

        const childrenTypes =
            bookingItem?.roomBookingItemChildrenTypes?.filter((item) =>
                childrenTypeIds?.includes(item.childrenTypeId),
            ) || [];

        if (childrenTypes.length !== childrenTypeIds.length) {
            const diff = _.differenceBy(
                updateBookingItem.roomBookingItemChildrenTypes,
                childrenTypes,
                'childrenTypeId',
            );
            diff.forEach((item) => {
                childrenTypes.push({
                    quantity: 0,
                    childrenTypeId: item.childrenTypeId,
                });
            });
        }
        _bookingItems.roomBookingItemChildrenTypes = childrenTypes;

        return compareFormData(
            mapToCompareData(updateBookingItem),
            mapToCompareData(_bookingItems),
        );
    } else {
        return parseDate(updateBookingItem.endDateOfStay).isSame(
            parseDate(bookingItem.endDateOfStay),
            'day',
        );
    }
};

export const isFrozenBooking = (bookingItems: IRoomBookingItem[]) => {
    const monthOffset =
        process.env.REACT_APP_NUMBER_OF_MONTHS_UNTIL_FREEZING_BOOKING_GROUP || 0;
    const dayOfMonth = process.env.REACT_APP_DATE_OF_MONTH_FREEZE_BOOKING_GROUP;
    const hourOfDate = process.env.REACT_APP_HOUR_OF_DATE_FREEZE_BOOKING_GROUP || 0;
    if (!dayOfMonth) return false;

    const isHasCheckInOrNotArrivedBooking = bookingItems?.some(
        (item) =>
            item.bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED ||
            item.bookingStatus === RoomBookingItemBookingStatus.CHECKED_IN,
    );
    if (isHasCheckInOrNotArrivedBooking) return false;
    const checkoutDates = bookingItems
        .map((item) => customDayjs(item.endDateOfStay).fmYYYYMMDD())
        .sort();
    if (checkoutDates.length < 1) return false;
    const frozenDate = customDayjs(checkoutDates[checkoutDates.length - 1])
        .add(+monthOffset, 'month')
        .date(+dayOfMonth)
        .hour(+hourOfDate);
    return customDayjs().isSameOrAfter(frozenDate, 'hour');
};
