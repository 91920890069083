import {
    DeleteOutlined,
    DownOutlined,
    PrinterOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import {
    Button,
    Dropdown,
    Modal,
    Pagination,
    Popconfirm,
    Popover,
    Space,
    Table,
    notification,
} from 'antd';
import type { TableProps } from 'antd/es/table';
import { ItemType } from 'antd/es/menu/interface';
import { ColumnsType } from 'antd/lib/table';
import { max, uniq } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    downloadFile,
    exportCsvFileWithXlsx,
    filterExportCSVBody,
    formatMoney,
    replaceBreakLine,
} from '~common/commonFunctions';
import {
    AntdOrderDirection,
    CsvOption,
    OrderDirection,
    SupportLanguage,
    cellAutoGeneratedCodeStyle,
    cellTextErrorStyle,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import { showConfirm } from '~common/notification';
import { roomBookingItemSchema, roomBookingSchema } from '~common/validatorSchema';
import { CsvDropdown, ModalConfirmDeletion } from '~components';
import RoomBookingPrintingModal from '~features/room-booking/components/RoomBookingPrintingModal/RoomBookingPrintingModal';
import {
    ChangingBookingStatusesMap,
    EXPORT_CSV_FILE_NAME,
    FILE_NAME,
    RoomBookingCategory,
    RoomBookingColumn,
    RoomBookingItemBookingStatus,
    exportColumns,
    i18ExportKey,
} from '~features/room-booking/constants';
import {
    convertToRoomBookingTableData,
    getAutoGeneratedCode,
} from '~features/room-booking/helper';
import {
    ICheckOutErrorItem,
    IRoomBooking,
    IRoomBookingExportCsvQuery,
    IRoomBookingTable,
    IUpdateBookingItemStatus,
} from '~features/room-booking/interfaces';
import RoomBookingAccommodationCardPrintPopup from '~features/room-booking/pages/RoomBookingListPage/components/RoomBookingAccommodationCardPrintPopup/RoomBookingAccommodationCardPrintPopup';
import { CheckinModal } from '~features/room-booking/pages/SchedulePage/components/CheckinModal/CheckinModal';
import {
    isShowRoomBookingPrintingModalSelector,
    setIsShowRoomBookingPrintingModal,
} from '~features/room-booking/reducers/print-booking.reducer';
import {
    fetchRoomBookingList,
    roomBookingListQuerySelector,
    roomBookingListSelector,
    setIsShowAccommodationCardPrintingModal,
    setRoomBookingListQuery,
    showLoadingSelector,
    totalPageSelector,
    totalRoomBookingsSelector,
    updateBookingItemStatus,
} from '~features/room-booking/reducers/room-booking.reducer';
import {
    getNotificationList,
    getReadNotificationTime,
} from '~features/admin-notification/reducers/notification.reducer';
import { roomBookingService } from '~features/room-booking/services/room-booking.service';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';
import ImportXml from '../ImportXml/ImportXml';
import { BookingErrorModal } from './BookingErrorModal';
import { ReactComponent as NoShowIcon } from '~assets/images/no-show-icon.svg';
import './RoomBookingList.scss';
import classNames from 'classnames';
import { ReactComponent as CleanRoomIcon } from '~assets/images/clean-room.svg';
import { WarningFilled } from '@ant-design/icons';
import localStorageAuthService from '~common/authStorage';
import useBulkDeleteBooking from '~features/room-booking/pages/GroupBookingPage/hooks/Booking/useBulkDeleteBooking';
import { CleaningStatus } from '~features/room-cleaning/constants';

function RoomBookingList() {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [statusOptions, setStatusOptions] = useState<ItemType[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedBookingStatus, setSelectedBookingStatus] = useState<string>('');
    const [openChangeStatusPopConfirm, setOpenChangeStatusPopConfirm] = useState(false);
    const { mutateAsync: mutateAsyncBulkDeleteBooking } = useBulkDeleteBooking();
    const roomBookingList = useAppSelector(roomBookingListSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const totalRoomBookings = useAppSelector(totalRoomBookingsSelector);
    const roomBookingListQuery = useAppSelector(roomBookingListQuerySelector);
    const showLoading = useAppSelector(showLoadingSelector);
    const hotelStored = localStorageAuthService.getSelectedHotel();
    const isShowRoomBookingPrintingModal = useAppSelector(
        isShowRoomBookingPrintingModalSelector,
    );
    const importXmlRef = useRef<{
        onClickImport: () => void;
    }>(null);

    const onChange: TableProps<IRoomBookingTable>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;

        dispatch(
            setRoomBookingListQuery({
                ...roomBookingListQuery,
                orderBy: order ? _field || RoomBookingColumn.ID : RoomBookingColumn.ID,
                orderDirection:
                    order === AntdOrderDirection.ASC
                        ? OrderDirection.ASC
                        : OrderDirection.DESC,
            }),
        );
    };

    const roomBookingTableData: IRoomBookingTable[] = useMemo(() => {
        return convertToRoomBookingTableData(roomBookingList);
    }, [roomBookingList]);

    const selectedRoomBookingItems = useMemo(() => {
        const selectedItemKeys: number[] = [];
        selectedRowKeys.forEach((key) => {
            if (typeof key === 'number') {
                selectedItemKeys.push(key);
            }
        });

        let roomBookingItems: IRoomBookingTable[] = [];
        roomBookingTableData.forEach((roomBooking) => {
            if (selectedItemKeys.includes(roomBooking.id as number)) {
                roomBookingItems = [...roomBookingItems, roomBooking];
            } else {
                const bookingItems = roomBooking?.children?.filter((item) => {
                    if (!item.id) return false;
                    return selectedItemKeys.includes(item.id as number);
                });
                if (bookingItems?.length) {
                    roomBookingItems = [...roomBookingItems, ...bookingItems];
                }
            }
        });
        return roomBookingItems;
    }, [selectedRowKeys, roomBookingTableData]);

    const isJaLanguage = useMemo(() => {
        return i18n.language === SupportLanguage.JA;
    }, [i18n.language]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onConfirmDeletion = async () => {
        let canDelete = true;
        const errorItems = [];
        for (const item of selectedRoomBookingItems) {
            if (
                item.bookingStatus &&
                item.bookingStatus !== RoomBookingItemBookingStatus.CHECKED_OUT &&
                item.bookingStatus !== RoomBookingItemBookingStatus.CANCELLED
            ) {
                canDelete = false;
                errorItems.push(item);
            }
        }

        let errorRoomBookingIds = errorItems.map((item) => item.roomBookingId);
        errorRoomBookingIds = uniq(errorRoomBookingIds);
        const errorRoomBookings = roomBookingList.filter((booking) => {
            return errorRoomBookingIds.includes(booking.id);
        });
        if (!canDelete) {
            BookingErrorModal({
                title: t('roomBooking.list.message.titleDelete'),
                okText: t('roomBooking.list.statusModalConfirm.okText'),
                description: t('roomBooking.list.message.deleteByStatusError'),
                errorItems: errorRoomBookings.map(
                    (item) =>
                        `${getAutoGeneratedCode(item.autoGeneratedCode)} - ${
                            item.representativeGuest?.yomigana
                        }`,
                ),
            });
            return;
        }
        const selectedRoomBookingIds = selectedRoomBookingItems.map((item) =>
            Number(item.roomBookingId),
        );
        const response = await mutateAsyncBulkDeleteBooking(selectedRoomBookingIds);
        if (response.success) {
            notification.success({
                message: t('roomBooking.list.message.bulkDeleteSuccess'),
            });
            refreshData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const getGuestNameWithBooking = (booking: IRoomBooking) => {
        const { autoGeneratedCode, representativeGuest } = booking;
        if (!representativeGuest) return getAutoGeneratedCode(autoGeneratedCode);
        const { fullName, yomigana } = representativeGuest;
        const guestName = `${getAutoGeneratedCode(autoGeneratedCode)} - ${yomigana}`;
        return fullName ? `${guestName} - ${fullName}` : guestName;
    };

    const showConfirmDialog = () => {
        let roomBookingIds = selectedRoomBookingItems.map((item) => item.roomBookingId);
        roomBookingIds = uniq(roomBookingIds);
        const roomBookings = roomBookingList.filter((booking) => {
            return roomBookingIds.includes(booking.id);
        });

        ModalConfirmDeletion({
            deletedItems: roomBookings.map((booking) => getGuestNameWithBooking(booking)),
            buttonDeleteText: t('roomBooking.list.modalConfirmDeletion.deleteButton'),
            buttonCancelText: t('roomBooking.list.modalConfirmDeletion.cancelButton'),
            okButtonProps: { danger: true },
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const showAccommodationCardPrintingPopup = () => {
        dispatch(setIsShowAccommodationCardPrintingModal(true));
    };

    const onChangePage = (page: number) => {
        dispatch(setRoomBookingListQuery({ ...roomBookingListQuery, page }));
    };

    const handleChangStatus = (key: string) => {
        setOpenChangeStatusPopConfirm(true);
        setSelectedBookingStatus(key);
    };

    const handleVisibleChange = (isVisible: boolean) => {
        if (openChangeStatusPopConfirm) {
            setOpenChangeStatusPopConfirm(isVisible);
        } else {
            setOpenChangeStatusPopConfirm(false);
        }
    };

    const showCheckOutErrorModal = (bookingIds: number[]) => {
        const roomBookingIds =
            selectedRoomBookingItems
                .filter((item) => {
                    return bookingIds.includes(+item.id);
                })
                .map((item) => {
                    return item.roomBookingId;
                }) || [];

        uniq(roomBookingIds);
        const roomBookings = roomBookingList.filter((roomBooking) => {
            return roomBookingIds.includes(roomBooking.id);
        });

        BookingErrorModal({
            title: t('roomBooking.list.checkoutDialog.unpaidTitle'),
            okText: t('roomBooking.list.statusModalConfirm.okText'),
            description: t('roomBooking.list.checkoutDialog.content'),
            errorItems: roomBookings.map((item) => getGuestNameWithBooking(item)),
        });
    };

    const _updateBookingItemStatus = async (body: IUpdateBookingItemStatus) => {
        const response = await dispatch(updateBookingItemStatus(body));
        setOpenChangeStatusPopConfirm(false);
        if (updateBookingItemStatus.fulfilled.match(response)) {
            if (response.payload?.success) {
                let message = null;
                if (body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT) {
                    message = t('roomBooking.list.checkoutDialog.checkoutSuccess');
                }
                notification.success({
                    message: message || t('roomBooking.list.statusModalConfirm.success'),
                });
                refreshData();
                dispatch(getNotificationList());
                dispatch(getReadNotificationTime());
            } else {
                if (body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT) {
                    if (response.payload?.errors?.length) {
                        const bookingIds = response.payload?.errors?.map((error) => {
                            return (error as ICheckOutErrorItem).bookingId;
                        });
                        showCheckOutErrorModal(bookingIds);
                    }
                } else {
                    Modal.error({
                        title: t('roomBooking.list.statusPopConfirm.title'),
                        content: response.payload?.message || '',
                        okText: t('roomBooking.list.statusModalConfirm.okText'),
                    });
                }
            }
            setOpenChangeStatusPopConfirm(false);
        }
    };

    const checkBookingCheckIn = () => {
        return selectedRoomBookingItems
            .filter((item) => {
                return parseDate(item.startDateOfStay).isAfter(customDayjs(), 'day');
            })
            ?.map((item) => item.roomBookingId);
    };

    const changeBookingStatusConfirm = async () => {
        for (const item of selectedRoomBookingItems) {
            if (
                item.bookingStatus &&
                !ChangingBookingStatusesMap[item.bookingStatus]?.includes(
                    selectedBookingStatus as RoomBookingItemBookingStatus,
                )
            ) {
                Modal.error({
                    title: t('roomBooking.list.statusPopConfirm.title'),
                    content: t('roomBooking.list.statusModalConfirm.errorChangeStatus', {
                        beforeStatus: t(
                            `roomBooking.page.bookingStatus.${item.bookingStatus}`,
                        ),
                        afterStatus: t(
                            `roomBooking.page.bookingStatus.${selectedBookingStatus}`,
                        ),
                    }),
                    okText: t('roomBooking.list.statusModalConfirm.okText'),
                });
                setOpenChangeStatusPopConfirm(false);
                return;
            }
        }
        if (selectedBookingStatus === RoomBookingItemBookingStatus.CHECKED_IN) {
            const errorBookingIds = checkBookingCheckIn();
            if (errorBookingIds.length) {
                uniq(errorBookingIds);
                const roomBookings = roomBookingList.filter((roomBooking) => {
                    return errorBookingIds.includes(roomBooking.id);
                });
                BookingErrorModal({
                    title: t('roomBooking.list.message.titleDelete'),
                    okText: t('roomBooking.list.statusModalConfirm.okText'),
                    description: t('roomBooking.list.message.cannotCheckIn'),
                    errorItems: roomBookings.map((item) => getGuestNameWithBooking(item)),
                });
                return;
            }
        }
        _updateBookingItemStatus({
            bookingStatus: selectedBookingStatus as RoomBookingItemBookingStatus,
            ids: selectedRoomBookingItems.map((item) => item.id) as number[],
        });
    };

    const changeBookingStatusCancel = () => {
        setOpenChangeStatusPopConfirm(false);
    };

    useEffect(() => {
        const statusMenus = Object.values(RoomBookingItemBookingStatus).filter(
            (status) => {
                return status !== RoomBookingItemBookingStatus.ALL;
            },
        );
        const items = statusMenus.map((status) => {
            return {
                label: t(`roomBooking.page.bookingStatus.${status}`),
                key: status.toString(),
                onClick: () => {
                    handleChangStatus(status.toString());
                },
            };
        });
        setStatusOptions(items);
    }, [t]);

    const fetchData = async () => {
        const modifiedRoomBookingListQuery = { ...roomBookingListQuery };
        const { roomBookingItemStatus } = modifiedRoomBookingListQuery;
        if (roomBookingItemStatus) {
            const filteredRoomBookingStatus = roomBookingItemStatus.filter(
                (status: string) =>
                    status !== 'cancelled_no_show_true' &&
                    status !== 'cancelled_no_show_false',
            );
            if (
                roomBookingItemStatus.includes('cancelled_no_show_true') ||
                roomBookingItemStatus.includes('cancelled_no_show_false')
            ) {
                filteredRoomBookingStatus.push('cancelled');
            }

            if (
                roomBookingItemStatus.includes('cancelled_no_show_true') &&
                roomBookingItemStatus.includes('cancelled_no_show_false')
            ) {
                modifiedRoomBookingListQuery.isNoShow = undefined;
            } else {
                if (roomBookingItemStatus.includes('cancelled_no_show_true')) {
                    modifiedRoomBookingListQuery.isNoShow = true;
                }
                if (roomBookingItemStatus.includes('cancelled_no_show_false')) {
                    modifiedRoomBookingListQuery.isNoShow = false;
                }
            }
            modifiedRoomBookingListQuery.roomBookingItemStatus = [
                ...uniq(filteredRoomBookingStatus),
            ];
        }
        const response = await dispatch(
            fetchRoomBookingList(modifiedRoomBookingListQuery),
        );
        if (fetchRoomBookingList.fulfilled.match(response)) {
            if (
                response.payload?.success &&
                response.payload?.data?.items?.length === 0 &&
                modifiedRoomBookingListQuery.page &&
                modifiedRoomBookingListQuery?.page > 1
            ) {
                dispatch(
                    setRoomBookingListQuery({
                        ...modifiedRoomBookingListQuery,
                        page: modifiedRoomBookingListQuery.page - 1,
                    }),
                );
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [roomBookingListQuery]);

    const showRoomBookingDetail = (roomBooking: IRoomBookingTable) => {
        if (roomBooking.roomBookingId) {
            window.open(`/room-booking/${roomBooking.roomBookingId}/detail`);
        }
    };

    const getRoomBookingCellTextStyle = (roomBooking: IRoomBookingTable) => {
        const schema = roomBooking.children ? roomBookingSchema : roomBookingItemSchema;
        return !roomBooking?.isHasError &&
            !roomBooking?.isHaveErrorPrice &&
            schema.isValidSync(roomBooking)
            ? {}
            : cellTextErrorStyle;
    };

    function renderCleaningStatus(cleaningStatus: string | undefined) {
        if (cleaningStatus === CleaningStatus.CLEANED) {
            return (
                <span className="room-clean text-success">
                    <CleanRoomIcon />
                    {t('roomBooking.list.column.cleaningStatus.cleaned')}
                </span>
            );
        } else if (cleaningStatus === CleaningStatus.UNCLEANED) {
            return (
                <span className="room-clean text-danger">
                    <WarningFilled />
                    {t('roomBooking.list.column.cleaningStatus.uncleaned')}
                </span>
            );
        }
        return <></>;
    }

    const roomBookingColumns: ColumnsType<IRoomBookingTable> = [
        {
            title: t('roomBooking.list.column.id'),
            onCell: () => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                };
            },
            render: (roomBooking: IRoomBookingTable) => {
                return <span>{roomBooking.autoGeneratedCode}</span>;
            },
            width: '200px',
            key: RoomBookingColumn.AUTO_GENERATED_CODE,
            sorter: true,
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: t('roomBooking.list.column.guestYomigana'),
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <div className="text-cuts-dots">
                        {roomBooking?.representativeGuest?.yomigana || ''}
                    </div>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            key: RoomBookingColumn.YOMIGANA,
            sorter: true,
            fixed: 'left',
        },
        {
            title: t('roomBooking.list.column.guestFullName'),
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <div className="text-cuts-dots">
                        {roomBooking.representativeGuest?.fullName || ''}
                    </div>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            key: RoomBookingColumn.GUEST_NAME,
            sorter: true,
        },
        {
            title: t('roomBooking.list.column.roomName'),
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <div className="text-cuts-dots">
                        {roomBooking.bookingStatus !==
                        RoomBookingItemBookingStatus.CANCELLED
                            ? roomBooking.room?.name || ''
                            : ''}
                    </div>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
        },
        {
            title: t('roomBooking.list.column.status'),
            width: '140px',
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <div className="room-booking-status-wrapper">
                        {roomBooking.bookingStatus ? (
                            <>
                                <span
                                    className={`room-booking-status room-booking-status-${roomBooking?.bookingStatus} text-truncate`}
                                >
                                    {t(
                                        `roomBooking.page.bookingStatus.${roomBooking?.bookingStatus}`,
                                    )}
                                </span>
                                {roomBooking.bookingStatus === 'cancelled' &&
                                    roomBooking.isNoShow && (
                                        <div className="no-show-icon-container">
                                            <NoShowIcon />
                                        </div>
                                    )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
        },
        ...(hotelStored?.showCleaningStatus
            ? [
                  {
                      title: t('roomBooking.list.column.cleaning'),
                      render: (roomBooking: IRoomBookingTable) => {
                          return (
                              <span>
                                  {renderCleaningStatus(roomBooking.room?.cleaningStatus)}
                              </span>
                          );
                      },
                      onCell: (roomBooking: IRoomBookingTable) => {
                          return {
                              style: getRoomBookingCellTextStyle(roomBooking),
                          };
                      },
                      width: '150px',
                      ellipsis: true,
                  },
              ]
            : []),
        {
            title: () => {
                return (
                    <span className={classNames({ 'title-time': isJaLanguage })}>
                        {t('roomBooking.list.column.checkInTime')}
                    </span>
                );
            },
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <span>
                        {roomBooking?.startDateOfStay
                            ? parseDate(roomBooking?.startDateOfStay)?.fmYYYYMMDD('-')
                            : ''}
                    </span>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            key: RoomBookingColumn.START_DATE_OF_STAY,
            sorter: true,
        },
        {
            title: () => {
                return (
                    <span className={classNames({ 'title-time': isJaLanguage })}>
                        {t('roomBooking.list.column.endDateOfStay')}
                    </span>
                );
            },
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <span>
                        {roomBooking.endDateOfStay
                            ? parseDate(roomBooking.endDateOfStay)?.fmYYYYMMDD('-')
                            : ''}
                    </span>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            key: RoomBookingColumn.END_DATE_OF_STAY,
            sorter: true,
        },
        {
            title: t('roomBooking.list.column.paymentNumber'),
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    roomBooking.autoGeneratedCode && (
                        <div>￥{formatMoney(roomBooking.price || 0)}</div>
                    )
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            ellipsis: true,
        },
        {
            title: t('roomBooking.list.column.planName'),
            render: (roomBooking: IRoomBookingTable) => {
                return <span>{roomBooking?.plan?.name || ''}</span>;
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            key: RoomBookingColumn.PLAN_NAME,
            ellipsis: true,
        },
        {
            title: t('roomBooking.list.column.marketingChannel'),
            render: (roomBooking: IRoomBookingTable) => {
                return <span>{roomBooking?.marketingChannel?.name || ''}</span>;
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            key: RoomBookingColumn.MARKETING_CHANNEL,
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('roomBooking.list.column.bookingDate'),
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <span>
                        {roomBooking?.createdAt
                            ? parseDate(roomBooking?.createdAt)?.fmYYYYMMDD('-')
                            : ''}
                    </span>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            key: RoomBookingColumn.CREATED_AT,
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('roomBooking.list.column.payment'),
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <span>
                        {(roomBooking.price || 0) <= 0
                            ? t('roomBooking.list.column.advance')
                            : ''}
                    </span>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
        },
        {
            title: t('roomBooking.list.column.category'),
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <div className="text-cuts-dots">
                        {t(`roomBooking.list.bookingCategory.${roomBooking.category}`) ||
                            ''}
                    </div>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
            key: RoomBookingColumn.CATEGORY,
        },
        {
            title: t('roomBooking.list.column.memo'),
            width: '180px',
            dataIndex: RoomBookingColumn.MEMO,
            render: (memo: string, record) => {
                const memoCell = `${memo ?? ''}${memo && record.otaMemo ? ' ' : ''}${
                    record.otaMemo ?? ''
                }`;

                return (
                    <Popover
                        overlayClassName="room-booking-list-popover_overlay"
                        placement="left"
                        trigger="hover"
                        content={
                            <>
                                <p>{memo}</p>
                                {memo && record.otaMemo && <hr />}
                                <p>{record.otaMemo}</p>
                            </>
                        }
                    >
                        <p className={'text-truncate'}>{memoCell}</p>
                    </Popover>
                );
            },
            onCell: (roomBooking: IRoomBookingTable) => {
                return {
                    style: getRoomBookingCellTextStyle(roomBooking),
                };
            },
        },
    ];

    const exportCsv = async (query: IRoomBookingExportCsvQuery) => {
        const response = await roomBookingService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const convertDataToCsv = (items: IRoomBookingTable[]) => {
        const dataOutput: { [key: string]: string | undefined }[] = [];
        items.forEach((item) => {
            const roomName =
                item.bookingStatus !== RoomBookingItemBookingStatus.CANCELLED
                    ? item.room?.name || ''
                    : '';
            const data = {
                autoGeneratedCode: item.autoGeneratedCode,
                yomigana: item.representativeGuest?.yomigana || '',
                fullname: item.representativeGuest?.fullName || '',
                roomName: roomName,
                status: item.bookingStatus
                    ? t(`roomBooking.page.bookingStatus.${item.bookingStatus}`)
                    : '',
                checkInTime: item.startDateOfStay
                    ? parseDate(item.startDateOfStay)?.fmYYYYMMDD()
                    : '',
                checkOutTime: item.endDateOfStay
                    ? parseDate(item.endDateOfStay)?.fmYYYYMMDD()
                    : '',
                price: `￥${max([item.price, 0])}`,
                plan: item.plan?.name || '',
                marketingChannel: item.marketingChannel?.name || '',
                bookingDate: item.createdAt
                    ? parseDate(item.createdAt)?.fmYYYYMMDD()
                    : '',
                payment: !max([item.price, 0])
                    ? t('roomBooking.list.column.advance')
                    : '',
                cleaningStatus:
                    hotelStored?.showCleaningStatus && item.room?.cleaningStatus
                        ? t(
                              `roomBooking.page.bookingCleaningStatus.${item.room?.cleaningStatus}`,
                          )
                        : '',
                category: item.category
                    ? t(`roomBooking.list.bookingCategory.${item.category}`)
                    : '',
                memo: replaceBreakLine(item.memo || ''),
            };
            dataOutput.push(data);
        });
        return dataOutput;
    };

    const exportSelection = async (items: IRoomBookingTable[]) => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        let selectedBookingTable: IRoomBookingTable[] = [];
        // Group selected room booking item by room booking id
        const bookingGroupByRoomBookingId: Record<number, IRoomBookingTable[]> =
            items.reduce((result, booking) => {
                if (booking.roomBookingId) {
                    result[booking.roomBookingId] = result[booking.roomBookingId] || [];
                    result[booking.roomBookingId].push(booking);
                }
                return result;
            }, Object.create(null));

        roomBookingTableData.forEach((roomBookingRow) => {
            if (
                roomBookingRow.roomBookingId &&
                bookingGroupByRoomBookingId[+roomBookingRow.roomBookingId]
            ) {
                if (roomBookingRow.category === RoomBookingCategory.GROUP) {
                    selectedBookingTable = [
                        ...selectedBookingTable,
                        roomBookingRow,
                        ...bookingGroupByRoomBookingId[+roomBookingRow.roomBookingId],
                    ];
                } else {
                    selectedBookingTable = [
                        ...selectedBookingTable,
                        ...bookingGroupByRoomBookingId[+roomBookingRow.roomBookingId],
                    ];
                }
            }
        });

        // Convert to file csv
        const exportData = convertDataToCsv(selectedBookingTable);
        const heading = exportColumns.map((key) =>
            t(`${i18ExportKey}.exportColumns.${key}`),
        );
        exportCsvFileWithXlsx(exportData, filename, heading);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all facility booking
                const body = { ...roomBookingListQuery };
                filterExportCSVBody(body);
                await exportCsv(body);
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                exportSelection(selectedRoomBookingItems);
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            case CsvOption.IMPORT_XML: {
                if (importXmlRef?.current) {
                    importXmlRef.current.onClickImport();
                }
                break;
            }
            default:
                break;
        }
    };

    const refreshData = () => {
        fetchData();
        setSelectedRowKeys([]);
    };

    const onClosePrintingModal = () => {
        showConfirm({
            title: t('roomBooking.printing.cancelTitle'),
            onOk() {
                dispatch(setIsShowRoomBookingPrintingModal(false));
            },
        });
    };

    return (
        <div className="room-booking-list-wrapper">
            <ImportXml ref={importXmlRef} />
            <div className="room-list-header">
                <div className="list-header-left">
                    {selectedRowKeys?.length > 0 && (
                        <>
                            <Button
                                type="text"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={showConfirmDialog}
                            />
                            <Popconfirm
                                title={
                                    <div className="change-booking-status-title">
                                        <span className="title-status-pop-confirm">
                                            {t('roomBooking.list.statusPopConfirm.title')}
                                        </span>
                                        <br />
                                        {t('roomBooking.list.statusPopConfirm.content', {
                                            status: t(
                                                `roomBooking.page.bookingStatus.${selectedBookingStatus}`,
                                            ),
                                        })}
                                    </div>
                                }
                                open={openChangeStatusPopConfirm}
                                placement="bottomRight"
                                overlayClassName="change-booking-status-popconfirm"
                                okText={t('roomBooking.list.statusPopConfirm.okText')}
                                cancelText={t(
                                    'roomBooking.list.statusPopConfirm.cancelText',
                                )}
                                onOpenChange={handleVisibleChange}
                                onConfirm={changeBookingStatusConfirm}
                                onCancel={changeBookingStatusCancel}
                            >
                                <Dropdown
                                    menu={{ items: statusOptions }}
                                    className="status-dropdown"
                                >
                                    <Button>
                                        <Space>
                                            {t('roomBooking.list.dropdown.title')}
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </Popconfirm>
                        </>
                    )}
                </div>

                <div className="list-header-right">
                    <CsvDropdown
                        onChange={onChangeCsvOption}
                        hasSelectedColumns={selectedRowKeys.length > 0}
                        additionalOptions={[
                            {
                                label: t('common.csvDropdown.importXML'),
                                key: CsvOption.IMPORT_XML,
                            },
                        ]}
                        hasImportOption={false}
                    />
                    <Button
                        type="text"
                        shape="circle"
                        icon={
                            <PrinterOutlined
                                onClick={showAccommodationCardPrintingPopup}
                            />
                        }
                    />
                    <ReloadOutlined className="size-icon" onClick={refreshData} />
                </div>
            </div>

            <Table
                rowSelection={{ ...rowSelection, checkStrictly: false }}
                columns={roomBookingColumns}
                dataSource={roomBookingTableData}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                loading={showLoading}
                scroll={{ x: 2000 }}
                rowClassName="row-expandable"
                className="table-scrollbar"
                onRow={(record) => {
                    return {
                        // click row
                        onClick: () => {
                            showRoomBookingDetail(record);
                        },
                    };
                }}
            />

            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="room-pagination"
                    defaultCurrent={roomBookingListQuery.page}
                    current={roomBookingListQuery.page}
                    total={totalRoomBookings}
                    pageSize={roomBookingListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}

            <RoomBookingAccommodationCardPrintPopup />
            <RoomBookingPrintingModal
                isShowRoomBookingPrintingModal={isShowRoomBookingPrintingModal}
                onClose={onClosePrintingModal}
            />
            <CheckinModal onChangeBookingStatusSuccess={refreshData} />
        </div>
    );
}

export default RoomBookingList;
