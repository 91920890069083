import { notification } from 'antd';
import type { CSSProperties, FC } from 'react';
import { useEffect } from 'react';
import type { DragSourceMonitor } from 'react-dnd';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { RoomBookingEvent } from '~features/room-booking/constants';
import {
    assignBookingToRoom,
    assignedRoomSuccess,
    roomManagementSelector,
    setAssignRoomLoading,
    setDragData,
    setIsDraggingBooking,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useMitt } from '~plugins/mitt';

function getStyles(isDragging: boolean): CSSProperties {
    return {
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : '',
    };
}

export interface DraggableBoxProps {
    bookingId: number;
    children: React.ReactNode;
    autoGeneratedCode: string;
}

export const DraggableBox: FC<DraggableBoxProps> = (props) => {
    const { bookingId, autoGeneratedCode } = props;
    const dispatch = useAppDispatch();
    const { dragData } = useAppSelector(roomManagementSelector);
    const { emitter } = useMitt();
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'box',
            item: { bookingId, autoGeneratedCode },
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            isDragging: (monitor: DragSourceMonitor) => {
                return (
                    (monitor.getItem() as { bookingId: number }).bookingId ===
                        bookingId || dragData?.bookingId === bookingId
                );
            },
            end: async (_, monitor) => {
                const dropResult = monitor.getDropResult() || {};
                const { roomId } = dropResult as {
                    roomId: number;
                    x: number;
                    y: number;
                };
                if (!roomId) return;
                dispatch(setAssignRoomLoading([roomId]));
                const response = await dispatch(
                    assignBookingToRoom([{ id: bookingId, roomId }]),
                );
                if (assignBookingToRoom.fulfilled.match(response)) {
                    if (response.payload?.success) {
                        dispatch(
                            assignedRoomSuccess([
                                {
                                    bookingId,
                                    roomId,
                                },
                            ]),
                        );
                        emitter.emit(RoomBookingEvent.SHOW_ASSIGN_ROOM_SUCCESS, true);
                    } else {
                        notification.error({
                            message: response.payload?.message || '',
                        });
                    }
                }
            },
        }),
        [bookingId],
    );

    useEffect(() => {
        dispatch(setIsDraggingBooking(isDragging));
        dispatch(setDragData(isDragging ? { bookingId } : null));
    }, [isDragging, dispatch, bookingId]);

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: false });
    }, [preview]);

    return (
        <div ref={drag} className="drag-box" style={getStyles(isDragging)}>
            <div>{props.children}</div>
        </div>
    );
};
