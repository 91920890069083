import { PrinterOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RangePicker, SingleSelect } from '~components';
import {
    getPaymentMethodDropdown,
    paymentMethodStateSelector,
} from '~features/payment-method/reducers/paymentMethod.reducer';
import {
    ALL_PAYMENT_METHOD_VALUE,
    PaymentDetailsSearchField,
    defaultPaymentDetailsQuery,
} from '~features/report/constants';
import {
    paymentDetailsSelector,
    setExportCsv,
    setIsShowTable,
    setPaymentDetailsQuery,
    setSelectedPaymentMethodIds,
    setIsShowPaymentDetailPrintingModal,
} from '~features/report/reducers/report-payment-details.reducer';
import { paymentDetailsFilterSchema } from '~features/report/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs from '~plugins/dayjs';
import './FilterForm.scss';

export default function FilterForm() {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(paymentDetailsFilterSchema),
    });
    const dispatch = useAppDispatch();
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const { isShowTable } = useAppSelector(paymentDetailsSelector);

    useEffect(() => {
        dispatch(getPaymentMethodDropdown());
    }, []);

    const paymentMethodOptions = useMemo(() => {
        const result = paymentMethodDropDown?.map(({ id, name }) => ({
            label: name,
            value: id,
        }));
        result.unshift({
            label: t('report.paymentDetails.all'),
            value: ALL_PAYMENT_METHOD_VALUE as unknown as number,
        });
        return result;
    }, [paymentMethodDropDown]);

    const onSubmit = () => {
        handleSubmit((values) => {
            const { datePeriod, paymentMethodIds = [] } = values;

            if (
                paymentMethodIds.includes(ALL_PAYMENT_METHOD_VALUE) ||
                !paymentMethodIds.length
            ) {
                delete values.paymentMethodIds;
            }

            dispatch(setSelectedPaymentMethodIds(paymentMethodIds));

            if (datePeriod === null) {
                values.datePeriod = [...defaultPaymentDetailsQuery.datePeriod];
            } else {
                values.datePeriod = [
                    datePeriod[0].startOf('day')?.fmYYYYMMDDHHmmss(),
                    datePeriod[1].endOf('day')?.fmYYYYMMDDHHmmss(),
                ];
            }
            dispatch(setPaymentDetailsQuery(values));
            dispatch(setIsShowTable(true));
        })();
    };

    const showPaymentDetailPrintingModal = () => {
        dispatch(setIsShowPaymentDetailPrintingModal(true));
    };

    const exportCSV = () => {
        dispatch(setExportCsv(true));
    };

    return (
        <section className="filter-form-report-payment-details-wrapper">
            <div className="filter-form-content">
                <RangePicker
                    name={PaymentDetailsSearchField.DATE_PERIOD}
                    label={t('report.paymentDetails.datePeriod.label')}
                    hideHelpText={true}
                    control={control}
                    allowClear={false}
                    defaultValue={[
                        customDayjs().startOf('day'),
                        customDayjs().endOf('day'),
                    ]}
                    placeholder={[
                        t('report.placeholder.rangeDatePicker.start'),
                        t('report.placeholder.rangeDatePicker.end'),
                    ]}
                />
                <SingleSelect
                    mode="multiple"
                    // default option is all methods
                    defaultValue={[ALL_PAYMENT_METHOD_VALUE]}
                    control={control}
                    id="payment-method-ids"
                    required
                    label={t('report.paymentDetails.paymentMethod.label')}
                    name={PaymentDetailsSearchField.PAYMENT_METHOD_IDS}
                    suffixIcon={null}
                    allowClear
                    hideHelpText={true}
                    options={paymentMethodOptions}
                    placeholder={t('report.paymentDetails.paymentMethod.placeholder')}
                />
                <Button type="primary" className="submit-btn" onClick={onSubmit}>
                    {t('report.paymentDetails.datePeriod.button')}
                </Button>
            </div>
            {isShowTable && (
                <Button
                    className="printing-button"
                    type="text"
                    shape="circle"
                    icon={<PrinterOutlined onClick={showPaymentDetailPrintingModal} />}
                />
            )}
            {isShowTable && <Button onClick={exportCSV}>{t('report.exportCSV')}</Button>}
        </section>
    );
}
