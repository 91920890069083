import { Button, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePickerType } from '~common/constants';
import { parseDate, type Dayjs } from '~plugins/dayjs';
import './FilterForm.scss';
import { MealReportData } from '../../MealReportPage';
import { downloadCSVFile } from '~common/commonFunctions';
import { ReportTableCsvFileName } from '~features/report/constants';

type Props = {
    refetch: () => void;
    date: [Dayjs, Dayjs];
    setDate: (date: [Dayjs, Dayjs]) => void;
    tableData: MealReportData;
    isRefetching: boolean;
};

export default function FilterForm({
    refetch,
    date,
    setDate,
    tableData,
    isRefetching,
}: Props) {
    const { t } = useTranslation();

    const exportCSVFile = () => {
        const tableHeader = [
            t('report.mealReport.table.mealName'),
            t('report.mealReport.table.nightOfStay'),
            t('report.mealReport.table.subtotal'),
            ...tableData.dateHeaders.map((date) => parseDate(date).format('MM/DD(ddd)')),
        ];
        let csvContent = `\uFEFF${tableHeader.join()}`;

        tableData.values.forEach((item) => {
            const csvRow: string[] = [];

            csvRow.push(`"${item.rowSpan ? item.name : ''}"`);
            csvRow.push(
                `"${
                    item.isSummary
                        ? t('report.mealReport.table.total')
                        : String(item.nightOfStay)
                }"`,
            );
            csvRow.push(`"${item.subtotal}"`);
            tableData.dateHeaders.forEach((date) => {
                csvRow.push(`"${item.quantityPerDay[date] || 0}"`);
            });

            csvContent = `${csvContent}\n${csvRow.join()}`;
        });

        const sumRow = [
            t('report.mealReport.table.allTotal'),
            '',
            tableData.totalOfAllMealItem,
            ...tableData.dateHeaders.map(
                (date) => `${tableData.totalOfAllMealByDay[date] || 0}`,
            ),
        ];

        csvContent = `${csvContent}\n${sumRow.join()}`;
        downloadCSVFile(csvContent, `${ReportTableCsvFileName.MEAL_REPORT}.csv`);
    };

    return (
        <section className="report-meal-filter-form-wrapper">
            <div className="filter-form-content">
                <span>{t('report.mealReport.filterForm.dateLabel')}</span>
                <DatePicker.RangePicker
                    allowClear={false}
                    placeholder={t('report.placeholder.selectDate')}
                    picker={DatePickerType.DATE}
                    value={date}
                    onChange={(date) => {
                        if (date && date[0] && date[1]) {
                            setDate(date as [Dayjs, Dayjs]);
                        }
                    }}
                />
                <Button
                    type="primary"
                    className="submit-btn"
                    onClick={() => refetch()}
                    disabled={isRefetching}
                    loading={isRefetching}
                >
                    {t('report.mealReport.filterForm.displayButton')}
                </Button>
            </div>
            {tableData.values.length > 0 && (
                <Button disabled={isRefetching} onClick={exportCSVFile}>
                    {t('report.exportCSV')}
                </Button>
            )}
        </section>
    );
}
