import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, notification, Row } from 'antd';
import { isEmpty, sumBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '~common/commonFunctions';
import { ErrorMessageType } from '~common/constants';
import { InputPrice, SelectPaymentMethod, SingleSelect } from '~components';
import {
    getPaymentMethodDropdown,
    paymentMethodStateSelector,
} from '~features/payment-method/reducers/paymentMethod.reducer';
import {
    defaultCheckinPaymentMethod,
    ReceiptItemType,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import { getBookingFromListById } from '~features/room-booking/helper';
import { ICheckInPaymentMethod } from '~features/room-booking/interfaces';
import {
    bookingListSelector,
    checkInRoomBooking,
    scheduleStateSelector,
    setSelectedRoomBookingSchedule,
    setShowCheckInForm,
    updateBookingItemAndTmp,
} from '~features/room-booking/reducers/schedule.reducer';
import { checkInRoomBookingResolver } from '~features/room-booking/schema';
import {
    getRoomTypeListForDropdown,
    roomTypeDropdownOptionsSelector,
} from '~features/room-type/room-type.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './CheckinModal.scss';
import { useRoomOptions } from '~common/useHooks';
import { CASH_PAYMENT_METHODS } from '~features/payment-method/constants';
import {
    getNotificationList,
    getReadNotificationTime,
} from '~features/admin-notification/reducers/notification.reducer';
import { useQuery } from '@tanstack/react-query';
import { roomBookingService } from '~features/room-booking/services/room-booking.service';
import { CacheKeys } from '~queries/queries';

type Prop = {
    onChangeBookingStatusSuccess?: (id: number) => void;
    isLoadedData?: boolean;
};

export const CheckinModal = ({ onChangeBookingStatusSuccess, isLoadedData }: Prop) => {
    const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(
        defaultCheckinPaymentMethod.paymentMethodId,
    );
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const roomTypeDropdownOptions = useAppSelector(roomTypeDropdownOptionsSelector);
    const bookingList = useAppSelector(bookingListSelector);
    const {
        isShowCheckInForm: isShowCheckinForm,
        selectedRoomBookingSchedule,
        isLoadingCheckIn: isLoadingCheckin,
    } = useAppSelector(scheduleStateSelector);

    const { data: roomBookingReceipt, refetch } = useQuery({
        queryKey: [
            CacheKeys.roomBookingReceipt,
            selectedRoomBookingSchedule?.roomBookingId,
        ],
        queryFn: async () => {
            const resp = await roomBookingService.getReceipt(
                Number(selectedRoomBookingSchedule?.roomBookingId),
            );
            return resp.data.roomBookingReceipt;
        },
        retry: 3,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        staleTime: 1000,
        enabled: !!selectedRoomBookingSchedule?.roomBookingId,
    });
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const {
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: checkInRoomBookingResolver,
    });
    const _booking = useMemo(() => {
        if (!selectedRoomBookingSchedule?.id) return null;
        if (isLoadedData) return selectedRoomBookingSchedule;
        return getBookingFromListById(bookingList, {
            id: selectedRoomBookingSchedule?.id || 0,
            date: selectedRoomBookingSchedule?.stayingStartDate,
        });
    }, [bookingList, selectedRoomBookingSchedule, isLoadedData]);

    const paymentMethodCheckIns: ICheckInPaymentMethod[] = useWatch({
        control,
        name: 'paymentMethodCheckIns',
    });

    const [roomOptions, isRoomOptionsLoading, rooms] = useRoomOptions(
        selectedRoomBookingSchedule,
        selectedRoomBookingSchedule?.roomType.id || null,
    );

    useEffect(() => {
        dispatch(
            getRoomTypeListForDropdown({
                isPullFromTll: false,
            }),
        );
        dispatch(getPaymentMethodDropdown());
    }, []);

    useEffect(() => {
        const cashMethodId = paymentMethodDropDown.find(
            (option) => option.name === CASH_PAYMENT_METHODS,
        )?.id;
        if (cashMethodId) {
            setDefaultPaymentMethodId(cashMethodId);
        }
    }, [paymentMethodCheckIns]);

    useEffect(() => {
        if (isShowCheckinForm) {
            reset({
                roomTypeId: selectedRoomBookingSchedule?.roomType?.id || undefined,
                roomId: selectedRoomBookingSchedule?.room?.id || undefined,
                paymentMethodCheckIns: [],
            });
            if (selectedRoomBookingSchedule?.roomBookingId) {
                refetch();
            }
        } else {
            reset();
        }
    }, [selectedRoomBookingSchedule, isShowCheckinForm, dispatch, reset]);

    const totalAmount = useMemo(() => {
        const totalPaymentAmount = (paymentMethodCheckIns || []).reduce(
            (a, b) => a + (Number(b.amount) || 0),
            0,
        );
        let totalSale = 0;
        let totalPayment = 0;
        // room booking total
        roomBookingReceipt?.roomBooking?.roomBookingItems?.forEach((roomBookingItem) => {
            roomBookingItem.receiptItems?.forEach((receiptItem) => {
                if (receiptItem.type === ReceiptItemType.RECEIPT) {
                    totalSale += sumBy(
                        receiptItem.receiptItemDetails,
                        (receiptItemDetail) => {
                            return (
                                receiptItemDetail.quantity * receiptItemDetail.unitPrice
                            );
                        },
                    );
                } else {
                    totalPayment += sumBy(receiptItem.receiptItemDetails, 'amount');
                }
            });
        });
        const totalAmount = totalSale - totalPayment - totalPaymentAmount;
        return {
            totalSale,
            totalPayment,
            unpaidBalance: totalAmount > 0 ? totalAmount : 0,
        };
    }, [roomBookingReceipt, paymentMethodCheckIns, selectedRoomBookingSchedule]);

    const addPaymentMethod = () => {
        const newPaymentMethod = {
            paymentMethodId: defaultPaymentMethodId,
            amount: totalAmount.unpaidBalance,
        };

        setValue('paymentMethodCheckIns', [...paymentMethodCheckIns, newPaymentMethod]);
    };

    const removePaymentMethod = (deletedItemIndex: number) => {
        setValue('paymentMethodCheckIns', [
            ...paymentMethodCheckIns.filter((item, index) => index !== deletedItemIndex),
        ]);
        if (!isEmpty(errors)) trigger('paymentMethodCheckIns');
    };

    const _checkinRoomBooking = () => {
        handleSubmit(async (data) => {
            const body = {
                roomId: data.roomId,
                paymentMethodCheckIns: data.paymentMethodCheckIns?.map(
                    (item: ICheckInPaymentMethod) => {
                        return {
                            paymentMethodId: item.paymentMethodId,
                            price: Number(item.amount),
                        };
                    },
                ),
            };
            if (selectedRoomBookingSchedule?.id) {
                const response = await dispatch(
                    checkInRoomBooking({
                        id: selectedRoomBookingSchedule.id,
                        checkinRoomBooking: { ...body },
                    }),
                );
                if (checkInRoomBooking.fulfilled.match(response)) {
                    if (response.payload?.success) {
                        notification.success({
                            message: t('roomBooking.list.statusModalConfirm.success', {
                                status: t(
                                    `roomBooking.page.bookingStatus.${RoomBookingItemBookingStatus.CHECKED_IN}`,
                                ),
                            }),
                        });

                        dispatch(setShowCheckInForm(false));
                        if (_booking) {
                            dispatch(
                                updateBookingItemAndTmp({
                                    bookings: [
                                        {
                                            ..._booking,
                                            room:
                                                rooms.find((r) => r.id === data.roomId) ??
                                                _booking.room,
                                            status: RoomBookingItemBookingStatus.CHECKED_IN,
                                            price:
                                                totalAmount.unpaidBalance > 0
                                                    ? totalAmount.unpaidBalance
                                                    : 0,
                                        },
                                    ],
                                }),
                            );
                        }
                        if (onChangeBookingStatusSuccess) {
                            onChangeBookingStatusSuccess(selectedRoomBookingSchedule.id);
                        }
                        dispatch(getNotificationList());
                        dispatch(getReadNotificationTime());
                    } else {
                        (response.payload?.errors || []).forEach((error) => {
                            setError(
                                `${error.key}` as any,
                                {
                                    type: ErrorMessageType.MANUAL,
                                    message: error.message,
                                },
                                { shouldFocus: true },
                            );
                        });
                        notification.error({
                            message: response.payload?.message || '',
                        });
                    }
                }
            }
        })();
    };

    const onCancel = () => {
        dispatch(setShowCheckInForm(false));
        dispatch(setSelectedRoomBookingSchedule(null));
    };

    return (
        <Modal
            centered
            title={t('roomBooking.roomBookingCheckin.modal.title')}
            open={isShowCheckinForm}
            footer={[
                <div className="account-receivable" key="account-receivable">
                    <span className="account-receivable-title">
                        {t(
                            'roomBooking.roomBookingCheckin.modal.accountReceivable.title',
                        )}
                    </span>
                    <br />
                    <span className="account-receivable-value">
                        ¥{formatMoney(totalAmount.unpaidBalance)}
                    </span>
                </div>,
                <Button key="cancel" onClick={onCancel}>
                    {t('roomBooking.roomBookingCheckin.modal.button.cancel')}
                </Button>,
                <Button
                    type="primary"
                    key="checkin"
                    onClick={_checkinRoomBooking}
                    loading={isLoadingCheckin || !_booking?.id}
                >
                    {t('roomBooking.roomBookingCheckin.modal.button.checkin')}
                </Button>,
            ]}
            onCancel={onCancel}
            className="checkin-room-booking-modal"
        >
            <Form layout="vertical">
                <SingleSelect
                    label={t('roomBooking.roomBookingCheckin.modal.roomType.label')}
                    name="roomTypeId"
                    control={control}
                    options={roomTypeDropdownOptions}
                    placeholder={t(
                        'roomBooking.roomBookingCheckin.modal.roomType.placeholder',
                    )}
                    required
                    disabled
                />
                <SingleSelect
                    label={t('roomBooking.roomBookingCheckin.modal.room.label')}
                    name="roomId"
                    control={control}
                    options={roomOptions}
                    placeholder={t('roomBooking.roomBookingCheckin.modal.room.label')}
                    required
                    loading={isRoomOptionsLoading}
                />
                <p className="deposit-note">
                    {t('roomBooking.roomBookingCheckin.modal.depositNote')}
                </p>
                <div className="checkin-sale-item-list">
                    {(paymentMethodCheckIns || []).length > 0 && (
                        <Row className="checkin-sale-item-header" gutter={8}>
                            <Col span={11}>
                                {t(
                                    'roomBooking.roomBookingCheckin.modal.checkinPaymentMethod.label',
                                )}
                            </Col>
                            <Col span={11}>
                                {t(
                                    'roomBooking.roomBookingCheckin.modal.checkinPaymentMethod.priceLabel',
                                )}
                            </Col>
                        </Row>
                    )}
                    {(paymentMethodCheckIns || []).map((item, index) => {
                        return (
                            <Row gutter={8} key={index}>
                                <Col span={11}>
                                    <SelectPaymentMethod
                                        label=""
                                        name={`paymentMethodCheckIns.${index}.paymentMethodId`}
                                        placeholder={t(
                                            'roomBooking.roomBookingCheckin.modal.checkinPaymentMethod.placeholder',
                                        )}
                                        allowClear
                                        control={control}
                                        paymentMethodOptions={paymentMethodDropDown}
                                        optionLabelProp="label"
                                        showSearch={true}
                                        required
                                    />
                                </Col>
                                <Col span={11}>
                                    <InputPrice
                                        label={''}
                                        control={control}
                                        name={`paymentMethodCheckIns.${index}.amount`}
                                    />
                                </Col>
                                <Col span={2}>
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            removePaymentMethod(index);
                                        }}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </div>
                <Button type="dashed" onClick={addPaymentMethod} className="button-add">
                    <PlusCircleOutlined className="right-register-form-icon" />
                    <span>{t('roomBooking.roomBookingCheckin.modal.button.add')}</span>
                </Button>
            </Form>
        </Modal>
    );
};
