import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUpdateSingleTempRoomBooking } from '~features/room-booking/interfaces/temp-room-booking/update-temp-room-booking.interface';
import { tempRoomBookingServiceV2 } from '~features/room-booking/services/temp-room-bookingV2.service';
import { MutationKeys } from '~queries/queries';

const updateSingleTempRoomBooking = async (
    body: IUpdateSingleTempRoomBooking,
    isAcceptOverbooking = false,
) => {
    const response = await tempRoomBookingServiceV2.updateSingleTempRoomBooking(
        body.id,
        body,
        isAcceptOverbooking,
    );
    if (response.success) {
        return response.data;
    } else {
        throw new Error(response.errors![0].message);
    }
};

const useUpdateSingleTempRoomBooking = () => {
    const { t } = useTranslation();
    return useMutation({
        mutationKey: [MutationKeys.updateSingleTempRoomBooking],
        mutationFn: async ({
            tempRoomBooking,
            isAcceptOverbooking,
        }: {
            tempRoomBooking: IUpdateSingleTempRoomBooking;
            isAcceptOverbooking: boolean;
        }) => {
            return updateSingleTempRoomBooking(tempRoomBooking, isAcceptOverbooking);
        },
        onSuccess: () => {
            notification.success({
                message: 'Temp Room Booking Updated Successfully',
            });
        },
        onError: (error) => {
            const { message } = error;
            if (
                message === t('roomBooking.form.overbooking.warningResponse') ||
                message === t('roomBooking.form.overbooking.warningTLLResponse')
            ) {
                notification.error({
                    message,
                    description: `${error}`,
                });
            }
        },
    });
};

export default useUpdateSingleTempRoomBooking;
