import { Col, Form, Radio, Row, theme, Tooltip } from 'antd';
import './GuestInfoTabPanel.scss';
import { useTranslation } from 'react-i18next';
import {
    AutoCompleteGuest,
    Checkbox,
    InputPhoneNumber,
    InputText,
    InputTextArea,
    SingleDatePicker,
    SingleSelect,
} from '~components';
import { useAppSelector } from '~hooks';
import { marketingChannelDropDownsSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { Gender } from '~features/guest/constants';
import { guestListForDropDownSelector } from '~features/guest/reducers/guest.reducer';
import { IGuestDropDown } from '~features/guest/interfaces';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { Controller, useWatch } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { IMemberAttachment } from '~features/room-booking/interfaces';
import { getCreateBookingFormPageId } from '~features/room-booking/helper';

type Props = {
    control: any;
    setValue?: any;
    clearErrors?: any;
    getValues?: any;
    disabled?: boolean;
    marketingFromTll?: string;
    additionalMarketingChannel?: {
        id: number;
        name: string;
    };
};

function GuestInfoTabPanel({
    control,
    setValue,
    clearErrors,
    disabled = false,
    marketingFromTll = undefined,
    additionalMarketingChannel,
}: Props) {
    const { token } = theme.useToken();

    const { t } = useTranslation();
    const marketingChannels = useAppSelector(marketingChannelDropDownsSelector);
    const guestDropdownOptions = useAppSelector(guestListForDropDownSelector);
    const [canEditRepresentativeGuest, setCanEditRepresentativeGuest] =
        useState<boolean>(false);
    const [canEditReserverGuest, setCanEditReserverGuest] = useState<boolean>(false);
    const isReserver = useWatch({
        control,
        name: 'isReserverTheRepresentative',
    });
    const representativeGuestId = useWatch({
        control,
        name: 'representativeGuest.id',
    });
    const representativeGuestYomigana = useWatch({
        control,
        name: 'representativeGuest.yomigana',
    });
    const representativeGuestEmail = useWatch({
        control,
        name: 'representativeGuest.emailAddress',
    });
    const representativeGuestFullName = useWatch({
        control,
        name: 'representativeGuest.fullName',
    });
    const representativeGuestPhone = useWatch({
        control,
        name: 'representativeGuest.phone',
    });
    const reserverGuestId = useWatch({
        control,
        name: 'reserverGuest.id',
    });
    const reserverGuestYomigana = useWatch({
        control,
        name: 'reserverGuest.yomigana',
    });
    const members = useWatch({
        control,
        name: 'members',
    });

    useEffect(() => {
        setCanEditRepresentativeGuest(!representativeGuestId);
    }, [representativeGuestId]);

    useEffect(() => {
        setCanEditReserverGuest(!reserverGuestId);
    }, [reserverGuestId]);

    useEffect(() => {
        if (isReserver) {
            clearErrors && clearErrors('reserverGuest');
            setValue(`reserverGuest.yomigana`, representativeGuestYomigana);
            setValue(`reserverGuest.fullName`, representativeGuestFullName);
            setValue(`reserverGuest.phone`, representativeGuestPhone);
            setValue(`reserverGuest.emailAddress`, representativeGuestEmail);
            setValue(`reserverGuest.id`, representativeGuestId);
        }
    }, [
        isReserver,
        representativeGuestYomigana,
        representativeGuestFullName,
        representativeGuestEmail,
        representativeGuestPhone,
        representativeGuestId,
    ]);

    const selectedGuestIds: number[] = useMemo(() => {
        const ids = [];
        if (representativeGuestId) {
            ids.push(representativeGuestId);
        }
        if (members?.length) {
            ids.push(
                ...members
                    .filter((member: IMemberAttachment) => member.id)
                    .map((member: IMemberAttachment) => member.id),
            );
        }
        return ids;
    }, [representativeGuestId, members]);

    const onChangeGuestSelect = (
        value: string | IGuestDropDown,
        typeGuest: string,
        guestId?: number | null,
    ) => {
        if (value instanceof Object) {
            setValue(
                typeGuest,
                {
                    id: value.id,
                    yomigana: value.yomigana,
                    fullName: value.fullName,
                    emailAddress: value.emailAddress,
                    phone: value.mobilePhoneNumber || value.phoneNumberLandline,
                    gender: value.gender,
                },
                { shouldValidate: true },
            );
            setValue(
                `${typeGuest}.birthday`,
                value.birthday && parseDate(value.birthday).isValid()
                    ? parseDate(value.birthday)
                    : null,
            );
            return;
        }
        if (guestId) {
            setValue(typeGuest, {
                id: null,
                yomigana: value,
                fullName: null,
                emailAddress: null,
                phone: null,
                gender: null,
                birthday: null,
            });
        } else {
            setValue(typeGuest, {
                id: null,
                yomigana: value,
            });
        }
    };

    const getMarketingChannelOptions = () => {
        const marketingChannelOptions = marketingChannels.map((item) => ({
            value: item.id,
            label: item.name,
        }));
        if (!additionalMarketingChannel) {
            return marketingChannelOptions;
        }

        const findMarketingChannel = marketingChannels.find(
            (item) => item.id === additionalMarketingChannel.id,
        );
        if (!!findMarketingChannel) {
            return marketingChannelOptions;
        }

        marketingChannelOptions.push({
            value: additionalMarketingChannel.id,
            label: additionalMarketingChannel.name,
        });
        return marketingChannelOptions;
    };

    return (
        <div className="guest-info-tab-panel-wrapper">
            <Row gutter={36}>
                <Col span={12}>
                    <Row style={{ position: 'relative' }}>
                        <Col span={24}>
                            <AutoCompleteGuest
                                label={t('roomBooking.page.guestInfo.guestNameYomigana')}
                                placeholder={t(
                                    'roomBooking.form.placeholder.nameYomigana',
                                )}
                                name="representativeGuest.yomigana"
                                id={getCreateBookingFormPageId(
                                    `representativeGuest.yomigana`,
                                )}
                                overlayClassName="guest-info-tab-panel-wrapper__select-guest"
                                required
                                allowClear
                                suffixIcon={null}
                                showSearch={true}
                                guestOptions={guestDropdownOptions}
                                defaultValue={
                                    representativeGuestId || representativeGuestYomigana
                                }
                                onChange={(value) =>
                                    onChangeGuestSelect(
                                        value,
                                        'representativeGuest',
                                        representativeGuestId,
                                    )
                                }
                                control={control}
                                disabled={disabled}
                                disableValues={selectedGuestIds}
                                isTriggerParentNode={true}
                            />

                            <Checkbox
                                name="isReserverTheRepresentative"
                                control={control}
                                label={t('roomBooking.page.guestInfo.person')}
                                onChange={(event) => {
                                    setValue(
                                        'isReserverTheRepresentative',
                                        event.target.checked,
                                    );
                                }}
                                disabled={disabled}
                                hideHelpText
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: -token.paddingXS,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputText
                                label={t('roomBooking.page.guestInfo.guestName')}
                                placeholder={t('roomBooking.form.placeholder.name')}
                                name="representativeGuest.fullName"
                                id={getCreateBookingFormPageId(
                                    `representativeGuest.fullName`,
                                )}
                                disabled={disabled || !canEditRepresentativeGuest}
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputPhoneNumber
                                label={t('roomBooking.page.guestInfo.phone')}
                                placeholder={t('roomBooking.form.placeholder.phone')}
                                name="representativeGuest.phone"
                                id={getCreateBookingFormPageId(
                                    `representativeGuest.phone`,
                                )}
                                disabled={disabled || !canEditRepresentativeGuest}
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputText
                                label={t('roomBooking.page.guestInfo.email')}
                                placeholder={t('roomBooking.form.placeholder.email')}
                                name="representativeGuest.emailAddress"
                                id={getCreateBookingFormPageId(
                                    `representativeGuest.emailAddress`,
                                )}
                                control={control}
                                disabled={disabled || !canEditRepresentativeGuest}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <SingleDatePicker
                                label={t('roomBooking.createBooking.label.birthday')}
                                placeholder={t('roomBooking.form.placeholder.birthday')}
                                name="representativeGuest.birthday"
                                id={getCreateBookingFormPageId(
                                    `representativeGuest.birthday`,
                                )}
                                control={control}
                                disabledDate={(current) => {
                                    return (
                                        current &&
                                        current > customDayjs().subtract(1, 'day')
                                    );
                                }}
                                disabled={disabled || !canEditRepresentativeGuest}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('roomBooking.createBooking.label.gender')}
                            >
                                <Controller
                                    control={control}
                                    name="representativeGuest.gender"
                                    render={({ field }) => (
                                        <Radio.Group
                                            id={getCreateBookingFormPageId(
                                                `representativeGuest.gender`,
                                            )}
                                            options={Object.values(Gender).map((val) => ({
                                                label: t(`guest.detail.gender.` + val),
                                                value: val,
                                            }))}
                                            disabled={
                                                disabled || !canEditRepresentativeGuest
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <InputTextArea
                        placeholder={t('roomBooking.createBooking.memoPlaceholder')}
                        name="memo"
                        control={control}
                        label={t('roomBooking.createBooking.memo')}
                        disabled={disabled}
                        rows={7}
                    />
                    <InputTextArea
                        placeholder={t('roomBooking.createBooking.otaMemoPlaceholder')}
                        name="otaMemo"
                        control={control}
                        disabled={disabled}
                        label={t('roomBooking.createBooking.otaMemo')}
                        rows={7}
                    />
                </Col>
            </Row>

            <Row gutter={36}>
                <Col span={12}>
                    <div className="form-title mt-12">
                        {t('roomBooking.page.guestInfo.reservationPeron')}
                        {isReserver && (
                            <span className="text-note">
                                {t('roomBooking.createBooking.sameGuest')}
                            </span>
                        )}
                    </div>
                    <Row>
                        <Col span={24}>
                            <AutoCompleteGuest
                                label={t(
                                    'roomBooking.page.guestInfo.reserverGuestNameYomigana',
                                )}
                                placeholder={t(
                                    'roomBooking.form.placeholder.nameYomigana',
                                )}
                                name="reserverGuest.yomigana"
                                overlayClassName="guest-info-tab-panel-wrapper__select-guest"
                                id={getCreateBookingFormPageId(`reserverGuest.yomigana`)}
                                required
                                allowClear
                                suffixIcon={null}
                                showSearch={true}
                                control={control}
                                popupMatchSelectWidth={500}
                                disabled={disabled || isReserver}
                                guestOptions={guestDropdownOptions}
                                defaultValue={reserverGuestId || reserverGuestYomigana}
                                onChange={(value) =>
                                    onChangeGuestSelect(
                                        value,
                                        'reserverGuest',
                                        reserverGuestId,
                                    )
                                }
                                isTriggerParentNode={true}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <InputText
                                label={t('roomBooking.page.guestInfo.reserverGuestName')}
                                placeholder={t('roomBooking.form.placeholder.name')}
                                name="reserverGuest.fullName"
                                id={getCreateBookingFormPageId(`reserverGuest.fullName`)}
                                control={control}
                                disabled={disabled || isReserver || !canEditReserverGuest}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputText
                                label={t('roomBooking.page.guestInfo.phone')}
                                placeholder={t('roomBooking.form.placeholder.phone')}
                                name="reserverGuest.phone"
                                id={getCreateBookingFormPageId(`reserverGuest.phone`)}
                                control={control}
                                disabled={disabled || isReserver || !canEditReserverGuest}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputText
                                label={t('roomBooking.page.guestInfo.email')}
                                placeholder={t('roomBooking.form.placeholder.email')}
                                name="reserverGuest.emailAddress"
                                id={getCreateBookingFormPageId(
                                    `reserverGuest.emailAddress`,
                                )}
                                control={control}
                                disabled={disabled || isReserver || !canEditReserverGuest}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <div className="form-title mt-12">
                        {t('roomBooking.createBooking.other')}
                    </div>
                    {marketingFromTll ? (
                        <div>
                            <div>{t('roomBooking.page.guestInfo.salesChannel')}</div>
                            <Tooltip
                                title={t(
                                    'roomBooking.detail.message.marketingChannelNotExist',
                                )}
                            >
                                <span className="marketing-channel-error">
                                    {marketingFromTll}
                                </span>
                            </Tooltip>
                        </div>
                    ) : (
                        <SingleSelect
                            label={t('roomBooking.page.guestInfo.salesChannel')}
                            placeholder={t('roomBooking.form.placeholder.saleChannel')}
                            name="marketingChannelId"
                            id={getCreateBookingFormPageId(`marketingChannelId`)}
                            control={control}
                            options={getMarketingChannelOptions()}
                            required
                            disabled={disabled}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default GuestInfoTabPanel;
