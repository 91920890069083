import { useEffect, useMemo } from 'react';
import { ScheduleBookingMonthView } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    setCurrentDate,
    scheduleStateSelector,
    getStatisticByDateV2,
    setStartDateMonthView,
    setEndDateMonthView,
} from '~features/room-booking/reducers/schedule.reducer';

import { RoomBookingOccupanyRateRange } from '~features/room-booking/constants';
import { Dayjs, parseDate } from '~plugins/dayjs';

type CalendarStatistic = {
    date: string;
    bookedRoomCount: number;
    numberOfBookings: number;
    numberOfCheckedInBookings: number;
    numberOfCheckedOutBookings: number;
    numberOfRooms: number;
    occupanyRate: number;
}

export const ScheduleMonthView = () => {
    const dispatch = useAppDispatch();
    const { currentDate, bookingStatisticList, isFetchingRoomList } =
        useAppSelector(scheduleStateSelector);

    const onChangeDate = (value: Dayjs) => {
        dispatch(setCurrentDate(value.fmYYYYMMDD()));
    };

    useEffect(() => {
        dispatch(setStartDateMonthView(parseDate(currentDate).startOf('M').subtract(1, 'day').fmYYYYMMDD()));
        dispatch(setEndDateMonthView(parseDate(currentDate).endOf('M').add(1, 'day').fmYYYYMMDD()));
        dispatch(getStatisticByDateV2());
    }, [currentDate]);

    const calendarData = useMemo(() => {
        const bookingStatisticMap = bookingStatisticList.reduce((map, currentStat) => {
            const stat = map.get(currentStat.date);
            if (stat) {
                stat.bookedRoomCount += currentStat.bookedRoomCount;
                stat.numberOfBookings += currentStat.numberOfRoomBookingItems;
                stat.numberOfCheckedInBookings += currentStat.numberOfCheckedInRoomBookingItems;
                stat.numberOfCheckedOutBookings += currentStat.numberOfCheckedOutRoomBookingItems;
                stat.occupanyRate = Math.round(
                    (stat.bookedRoomCount / stat.numberOfRooms) * 100,
                );
            } else {
                map.set(currentStat.date, {
                    date: currentStat.date,
                    bookedRoomCount: currentStat.bookedRoomCount,
                    numberOfBookings: currentStat.numberOfRoomBookingItems,
                    numberOfCheckedInBookings: currentStat.numberOfCheckedInRoomBookingItems,
                    numberOfCheckedOutBookings: currentStat.numberOfCheckedOutRoomBookingItems,
                    numberOfRooms: currentStat.numberOfRooms,
                    occupanyRate: Math.round((currentStat.bookedRoomCount / currentStat.numberOfRooms) * 100),
                })
            }
            return map;
        }, new Map<string, CalendarStatistic>());
        return Array.from(bookingStatisticMap.values());
    }, [bookingStatisticList]);

    return (
        <ScheduleBookingMonthView
            calendarData={calendarData}
            isFetching={isFetchingRoomList}
            onChangeDate={onChangeDate}
            currentDate={currentDate}
            occupanyRateRange={RoomBookingOccupanyRateRange}
        />
    );
};
