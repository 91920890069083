import { axiosInstanceV2 } from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import { IBulkPaymentGroupBookingFormData, ICreateRoomBookingBody, IStatisticBookingItem } from '../interfaces';
import { IBodyResponse, IBodyResponseV2 } from '~common/interfaces';

class RoomBookingServiceV2 extends ApiService {
    createBulkPaymentGroupBooking(
        groupBookingId: number,
        params: IBulkPaymentGroupBookingFormData,
    ) {
        return this._post(
            `${this.baseUrl}/${groupBookingId}/bulk-payment-group-booking`,
            params,
        ) as Promise<IBodyResponse<IBulkPaymentGroupBookingFormData>>;
    }
    addRoomBooking(groupBookingId: number, params: ICreateRoomBookingBody) {
        return this._post(
            `${this.baseUrl}/${groupBookingId}/create-room-booking`,
            params,
        ) as Promise<IBodyResponseV2<ICreateRoomBookingBody>>;
    }
    deleteBooking(bookingId: number) {
        return this._delete(`${this.baseUrl}/${bookingId}`) as Promise<
            IBodyResponseV2<null>
        >;
    }
    bulkDeleteBooking(bookingIds: number[]) {
        return this._delete(`${this.baseUrl}`, {
            data: {
                bookingIds,
            },
        }) as Promise<IBodyResponseV2<null>>;
    }
    getStatisticByDate(params: { startDate: string; endDate: string }) {
        return this._get(
            `${this.baseUrl}/statistic/by-date-and-room-type`,
            { params },
        ) as Promise<IBodyResponseV2<IStatisticBookingItem[]>>;
    }
}

export const roomBookingServiceV2 = new RoomBookingServiceV2(
    {
        baseUrl: '/room-booking',
    },
    axiosInstanceV2,
);
