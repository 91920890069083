import './RoomKeycardIssueRecordList.scss';

import { useQuery } from '@tanstack/react-query';
import { Button, Card, Table, Tag } from 'antd';
import { CreditCardOutlined, EditOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';
import { useTranslation } from 'react-i18next';
import { IRoom } from '~features/room/interfaces';
import { useKeyCardIssueModalContext } from '../RoomKeycardIssueProvider';
import { KeycardStatus } from '../enums/key-card-status.enums';
import { KeycardModalMode } from '../enums/key-card-modal-mode.enums';
import type { IRoomKeyRecord } from '~features/room-booking/interfaces';
import { roomKeyRecordService } from '~features/room-booking/services/room-key-record.service';
import { CacheKeys } from '~queries/queries';
import { useMemo } from 'react';

export interface KeycardIssueRecordListProps {
    roomBookingId: number;
    room: IRoom;
    checkinTimestamp: string;
    checkoutTimestamp: string;
}

export function RoomKeycardIssueRecordList(
    props: KeycardIssueRecordListProps,
): JSX.Element {
    const { t } = useTranslation();
    const { openModal } = useKeyCardIssueModalContext();
    const { isLoading, data } = useQuery({
        queryKey: [CacheKeys.getRoomKeyRecords, props.roomBookingId],
        queryFn: async () => {
            const res = await roomKeyRecordService.getRoomKeyRecords([
                props.roomBookingId,
            ]);
            if (res.data) return res.data;
            throw Error(res.message);
        },
    });

    const latestIssueId = useMemo(
        () => new Set(data?.records.map((item) => item.issueId)).size,
        [data?.records],
    );

    const getKeyStatusColor = (status: string) => {
        if (status === KeycardStatus.ISSUE) {
            return 'green';
        } else if (status === KeycardStatus.RECYCLE) {
            return 'blue';
        } else if (status === KeycardStatus.LOST) {
            return 'red';
        }
    };

    const addKeycard = () => {
        openModal(KeycardModalMode.ISSUE, {
            roomBookingId: props.roomBookingId,
            roomNumber: props.room.roomNumber,
            startDateTime: props.checkinTimestamp,
            endDateTime: props.checkoutTimestamp,
            issueId: latestIssueId,
        });
    };

    const reissueKeycard = (data: IRoomKeyRecord) => {
        openModal(KeycardModalMode.ISSUE, {
            roomBookingId: props.roomBookingId,
            roomNumber: props.room.roomNumber,
            startDateTime: data.checkinTimestamp,
            endDateTime: data.checkoutTimestamp,
            issueId: data.issueId,
            issueCount: data.reissueNumber + 1,
        });
    };

    const editKeycard = (data: IRoomKeyRecord) => {
        openModal(KeycardModalMode.EDIT, {
            id: data.id,
            roomBookingId: props.roomBookingId,
            roomNumber: props.room.roomNumber,
            startDateTime: data.checkinTimestamp,
            endDateTime: data.checkoutTimestamp,
            issueId: data.issueId,
            issueCount: data.reissueNumber,
        });
    };

    const keycardColumns = [
        {
            key: 'id',
            title: 'ID',
            render: (item: IRoomKeyRecord) => <div>{item.issueId}</div>,
            width: 100,
        },
        {
            key: 'reissueNumber',
            title: t('roomBooking.detail.keycard.reissueNumber'),
            render: (item: IRoomKeyRecord) => <div>{item.reissueNumber}</div>,
            width: 180,
        },
        {
            key: 'cico',
            title: 'C/I - C/O',
            render: (item: IRoomKeyRecord) => {
                return item.checkinTimestamp && item.checkoutTimestamp ? (
                    <div className="keycard__table-cico-wrapper">
                        <div className="keycard__table-cico">
                            <strong>{parseDateTime(item.checkinTimestamp)}</strong>
                            <span>
                                {parseDateTime(
                                    item.checkinTimestamp,
                                    DateFormat.HH_MM_COLON,
                                )}
                            </span>
                        </div>
                        <ArrowRightOutlined />
                        <div className="keycard__table-cico">
                            <strong>{parseDateTime(item.checkoutTimestamp)}</strong>
                            <span>
                                {parseDateTime(
                                    item.checkoutTimestamp,
                                    DateFormat.HH_MM_COLON,
                                )}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div></div>
                );
            },
            width: 280,
        },
        {
            key: 'issue',
            title: t('roomBooking.detail.keycard.issueDate'),
            render: (item: IRoomKeyRecord) => <div>{parseDateTime(item.issuedAt)}</div>,
            width: 150,
        },
        {
            key: 'status',
            title: t('roomBooking.detail.keycard.statusColumn'),
            render: (item: IRoomKeyRecord) => (
                <div>
                    {item.status && (
                        <Tag color={getKeyStatusColor(item.status)}>
                            {t(`roomBooking.detail.keycard.status.${item.status}`)}
                        </Tag>
                    )}
                </div>
            ),
        },
        {
            key: 'action',
            title: '',
            render: (item: IRoomKeyRecord) => (
                <div className="keycard__table-action-btn">
                    <Button size="small" type="link" onClick={() => reissueKeycard(item)}>
                        {t('roomBooking.detail.keycard.reIssue')}
                    </Button>
                    <Button size="small" type="text" onClick={() => editKeycard(item)}>
                        <EditOutlined />
                    </Button>
                </div>
            ),
            width: 300,
        },
    ];

    return (
        <Card>
            <div className="keycard__header">
                <div className="keycard__title">{props.room.name}</div>
                <Button type="primary" onClick={() => addKeycard()}>
                    <CreditCardOutlined />
                    <span>{t('roomBooking.detail.keycard.addKey')}</span>
                </Button>
            </div>
            <Table
                columns={keycardColumns}
                loading={isLoading}
                dataSource={data?.records}
                rowKey={(record) => `${record.issueId}_${record.reissueNumber}`}
                className="keycard__table"
                pagination={false}
            />
        </Card>
    );
}
