/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Layout } from 'antd';
import { RefObject, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FC_FRAME_ID, FC_ICON_HEIGHT, FC_ICON_WIDTH } from '~common/constants';
import { FreshChat, Topbar, Sidebar } from '~components';
import { appConfig } from '~config/app';
import { MittProvider } from '~plugins/mitt';
import { useIsMobile } from '~common/useHooks';
import SocketProvider from '~plugins/socket/socketProvider';
import classNames from 'classnames';
import './StaffLayout.scss';
import { useAppSelector } from '~hooks';
import { isFreshChatShowSelector } from '~common/reducers/app.reducer';

const StaffLayout: React.FC = () => {
    const { pathname } = useLocation();
    const isMobile = useIsMobile();
    const isFreshChatShow = useAppSelector(isFreshChatShowSelector);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onScroll = (event: any) => {
        const scrollTop = event.target?.scrollTop || 0;
        if (scrollTop > 10) {
            document.getElementsByClassName('header-page')?.[0]?.classList?.add('shadow');
        } else {
            document
                .getElementsByClassName('header-page')?.[0]
                ?.classList?.remove('shadow');
        }
    };

    const containerRef = useRef<HTMLElement>() as RefObject<HTMLElement>;
    const freshChatWrapperRef = useRef<HTMLDivElement>(null);

    const setChatIconPosition = () => {
        const fcFrame = document.getElementById(FC_FRAME_ID);
        if (!freshChatWrapperRef.current || !fcFrame) return;
        freshChatWrapperRef.current.style.bottom = `${
            window.innerHeight - fcFrame.offsetTop - FC_ICON_HEIGHT
        }px`;
        freshChatWrapperRef.current.style.right = `${
            window.innerWidth - fcFrame.offsetLeft - FC_ICON_WIDTH
        }px`;
    };

    useEffect(() => {
        containerRef.current?.scrollTo(0, 0);
        setChatIconPosition();
    }, [pathname]);

    useEffect(() => {
        if (!isMobile) {
            setIsCollapsed(true);
        } else {
            setIsDrawerOpen(false);
        }
    }, [isMobile]);

    const handleClickDrawer = () => {
        setIsDrawerOpen(true);
    };

    const handleOnCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    return (
        <MittProvider>
            <div
                className="fresh-chat-wrapper"
                ref={freshChatWrapperRef}
                draggable={true}
            >
                {isFreshChatShow && <FreshChat token={appConfig.freshChatToken} />}
            </div>
            <Layout className="staff-layout">
                <Topbar handleClickDrawer={handleClickDrawer} />
                <Layout>
                    <Sidebar
                        isDrawerOpen={isDrawerOpen}
                        handleOnCloseDrawer={handleOnCloseDrawer}
                    />
                    <SocketProvider>
                        <Layout
                            className={classNames('staff-layout-content', {
                                expand: !isMobile && !isCollapsed,
                                isMobile: isMobile,
                            })}
                            onScroll={onScroll}
                            id="staff-layout-content"
                            ref={containerRef}
                        >
                            <Outlet />
                        </Layout>
                    </SocketProvider>
                </Layout>
            </Layout>
        </MittProvider>
    );
};

export default StaffLayout;
