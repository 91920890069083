import { PrinterOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Modal, Tabs, Tooltip, notification } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    FILE_TYPE,
    INVOICE_PATH,
    ReceiptByGroupMapIndex,
    SelectSplitBillTypeOptions,
} from '~features/room-booking/constants';
import { isSplitAllReceiptItemDetails } from '~features/room-booking/helper.split-receipt';
import { IReceipt, IReceiptByGroup } from '~features/room-booking/interfaces';
import {
    selectedSplitBillTypeSelector,
    setIsShowSplitRoomBookingReceiptPrintingModal,
    setPrintingReceiptByGroupList,
    splitReceiptStateSelector,
} from '~features/room-booking/reducers/split-receipt.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import BillToTabItem from '../BillToTabItem/BillToTabItem';
import './BillToTab.scss';
import { ReceiptFilesModal } from './ReceiptFilesModal';
import { roomBookingStateSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { receiptFileService } from '~features/room-booking/services/receipt-file.service';
import { CacheKeys } from '~queries/queries';
import { isAllBookingItemCancelled } from '~features/room-booking/helper';

interface ITab {
    label: string;
    key: string;
    children: JSX.Element;
}

function BillToTab() {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { roomBookingId } = useParams();
    const queryClient = useQueryClient();
    const receipt = queryClient.getQueryData<IReceipt>([
        CacheKeys.roomBookingReceipt,
        roomBookingId,
    ]);
    const { receiptByGroupMap } = useAppSelector(splitReceiptStateSelector);
    const { selectedRoomBookingDetail } = useAppSelector(roomBookingStateSelector);

    const selectedSplitBillType = useAppSelector(selectedSplitBillTypeSelector);

    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

    const isBookingCancelled =
        !!selectedRoomBookingDetail &&
        isAllBookingItemCancelled(selectedRoomBookingDetail?.roomBookingItems);
    const isInvoice = pathname.endsWith(INVOICE_PATH);

    const roomBookingItemTabs = useMemo((): ITab[] => {
        if (isBookingCancelled) {
            return [];
        }

        return Object.keys(receiptByGroupMap).reduce(
            (roomBookingItemTabs: ITab[], roomId: string) => {
                if (roomId === ReceiptByGroupMapIndex.ALL) return roomBookingItemTabs;

                const { group, receipt, guestName } = receiptByGroupMap[roomId];

                return roomBookingItemTabs.concat({
                    label: `${t(
                        `roomBooking.splitReceipt.${
                            selectedSplitBillType ===
                            SelectSplitBillTypeOptions.BASED_ON_ROOM
                                ? 'room'
                                : 'group'
                        }`,
                    )}  ${group?.name}`,
                    key: group?.name || '',
                    children: (
                        <BillToTabItem
                            groupName={`${t(
                                `roomBooking.splitReceipt.${
                                    selectedSplitBillType ===
                                    SelectSplitBillTypeOptions.BASED_ON_ROOM
                                        ? 'room'
                                        : 'group'
                                }`,
                            )}   ${group?.name}`}
                            groupId={group?.id || ''}
                            receipt={receipt}
                            guestName={guestName || ''}
                        />
                    ),
                });
            },
            [],
        );
    }, [receiptByGroupMap, selectedSplitBillType]);

    const count = useQuery({
        queryKey: [CacheKeys.receiptFilesCount, receipt?.id, isInvoice],
        queryFn: async () => {
            if (receipt?.id) {
                const res = await receiptFileService.getReceiptFilesCount(
                    receipt?.id,
                    isInvoice ? FILE_TYPE.INVOICE : FILE_TYPE.RECEIPT,
                );
                if (res.success) return res.data.count;
                throw Error(res.message);
            }
            return 0;
        },
        enabled: !!receipt?.id,
    });

    const onPrintAllReceiptByRoom = async () => {
        const receiptByGroupAllMap = receiptByGroupMap[ReceiptByGroupMapIndex.ALL];
        if (!isSplitAllReceiptItemDetails(receiptByGroupAllMap.receipt)) {
            Modal.warning({
                title: t('roomBooking.splitReceipt.notificationAlert.notSplitDone'),
            });
            return;
        }
        const receiptByGroupList = Object.keys(receiptByGroupMap).reduce(
            (receiptByGroupList: IReceiptByGroup[], key) => {
                if (key === ReceiptByGroupMapIndex.ALL) return receiptByGroupList;
                receiptByGroupList.push(receiptByGroupMap[key]);
                return receiptByGroupList;
            },
            [],
        );

        if (count.error) {
            notification.warning({
                message: t('roomBooking.splitReceipt.files.countError'),
            });
        }
        const receiptByGroupListWithCount = receiptByGroupList.map((data, i) => ({
            ...data,
            logNumber: (count.data || 0) + i + 1,
        }));

        dispatch(setPrintingReceiptByGroupList(receiptByGroupListWithCount));
        dispatch(setIsShowSplitRoomBookingReceiptPrintingModal(true));
    };

    return (
        <div className="split-room-booking-bill-to-tab-wrapper">
            <div className="bill-to-tab-header">
                {selectedSplitBillType === SelectSplitBillTypeOptions.BASED_ON_ROOM
                    ? t('roomBooking.splitReceipt.billToTab.basedOnRoomTitle')
                    : t('roomBooking.splitReceipt.billToTab.basedOnGroupTitle')}
                <Tooltip
                    placement="bottom"
                    title={
                        isBookingCancelled
                            ? t(
                                  'roomBooking.splitReceipt.billToTab.cannotPrintCancelledBooking',
                              )
                            : ''
                    }
                >
                    <Button
                        type="primary"
                        onClick={onPrintAllReceiptByRoom}
                        disabled={
                            (Object.keys(receiptByGroupMap).length === 0 &&
                                receiptByGroupMap.constructor === Object &&
                                !receipt) ||
                            isBookingCancelled
                        }
                    >
                        <PrinterOutlined />
                        {t('roomBooking.splitReceipt.billToTab.printAll')}
                    </Button>
                </Tooltip>

                <Button onClick={() => setIsHistoryModalOpen(true)}>
                    <UnorderedListOutlined />
                    {isInvoice
                        ? t('roomBooking.splitReceipt.billToTab.invoiceIssueHistory')
                        : t('roomBooking.splitReceipt.billToTab.issueHistory')}
                </Button>
            </div>

            <Tabs type="card" items={roomBookingItemTabs} className="tab-list" />

            {receipt && (
                <ReceiptFilesModal
                    isOpen={isHistoryModalOpen}
                    setIsOpen={setIsHistoryModalOpen}
                    receiptId={receipt.id}
                />
            )}
        </div>
    );
}

export default BillToTab;
