import {
    DeleteOutlined,
    EditOutlined,
    IdcardOutlined,
    PlusOutlined,
    PrinterOutlined,
    StopOutlined,
} from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import {
    Button,
    Card,
    Checkbox,
    Modal,
    notification,
    Popover,
    Spin,
    Switch,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import dayjs from 'dayjs';
import { omit, sortBy } from 'lodash';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';
import { UserGroup } from '~common/constants';
import { showConfirm } from '~common/notification';
import { getChildrenDropdown } from '~features/children-type/reducers/children.reducer';
import ChangeStatusDropDown from '~features/room-booking/components/ChangeStatusDropDown/ChangeStatusDropDown';
import RoomBookingPrintingModal from '~features/room-booking/components/RoomBookingPrintingModal/RoomBookingPrintingModal';
import {
    ChangingBookingStatusesMap,
    MAX_PRINT_GUEST_CARD,
    RoomBookingDetailPageTabPane,
    RoomBookingItemBookingStatus,
    roomBookingListQueryDefault,
    RoomBookingTab,
} from '~features/room-booking/constants';
import {
    checkRoomBookingItemCanDelete,
    getAutoGeneratedCode,
    getRoomNameDisplay,
} from '~features/room-booking/helper';
import {
    IAutoAssignBookingToRoomItem,
    ICreateRoomBookingBody,
    ICreateRoomBookingForm,
    IRoomBookingDetail,
    IRoomBookingItem,
    IUpdateBookingItemStatus,
} from '~features/room-booking/interfaces';
import { getBookingScheduleDefault } from '~features/room-booking/model';
import { BookingErrorModal } from '~features/room-booking/pages/RoomBookingListPage/components/RoomBookingList/BookingErrorModal';
import { CreateModal } from '~features/room-booking/pages/SchedulePage/components/CreateModal/CreateModal';
import {
    isShowRoomBookingPrintingModalSelector,
    setIsShowRoomBookingPrintingModal,
    setPrintingRoomBookingList,
    setPrintRoomBookingListQuery,
} from '~features/room-booking/reducers/print-booking.reducer';
import {
    assignBookingToRoom,
    fetchRoomBookingDetail,
    getAvailableRooms,
    roomBookingStateSelector,
    selectedRoomBookingDetailSelector,
    updateBookingItemNoShowStatus,
    updateBookingItemStatus,
} from '~features/room-booking/reducers/room-booking.reducer';
import {
    getRoomList,
    scheduleStateSelector,
} from '~features/room-booking/reducers/schedule.reducer';
import { roomBookingServiceV2 } from '~features/room-booking/services/room-bookingV2.service';
import {
    getNotificationList,
    getReadNotificationTime,
} from '~features/admin-notification/reducers/notification.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';
import RepresentativeGuestInfo from './RepresentativeGuestInfo/RepresentativeGuestInfo';
import './RoomBookingDetailTabPane.scss';
import './RoomBookingDetailTabPane.scss';
import BookingDetailItems from './RoomBookingItemList/RoomBookingItemList';

import { setFreshChatShow } from '~common/reducers/app.reducer';
import useDeleteBooking from '~features/room-booking/pages/GroupBookingPage/hooks/Booking/useDeleteBooking';

const ModalAccomodationCardDraw = lazy(
    () => import('./AccomodationCardDraw/AccomodationModal'),
);

const { Text, Title } = Typography;

type Prop = {
    changeDetailTab: (activeTab: string) => void;
};

function RoomBookingDetailTabPane(props: Prop) {
    const [params, setParams] = useSearchParams();
    const { changeDetailTab } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAdmin, isHotelAdmin } = useMemo(() => {
        return {
            isAdmin: checkUserPermission([UserGroup.ADMIN]),
            isHotelAdmin: checkUserPermission([UserGroup.HOTEL_ADMIN]),
        };
    }, []);
    const { roomBookingId } = useParams();
    const [activeTab, setActiveTab] = useState<string>(RoomBookingTab.GUEST_INFO);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const isBookingWalkIn = !selectedRoomBookingDetail?.marketingChannel?.isPullFromTll;
    const isShowRoomBookingPrintingModal = useAppSelector(
        isShowRoomBookingPrintingModalSelector,
    );
    const { isFetchingBookingList } = useAppSelector(scheduleStateSelector);
    const { mutateAsync: mutateAsyncDeleteBooking } = useDeleteBooking();

    const { isAutoAssignRoomLoading, isBulkUpdateStatusLoading, isUpdatingNoShowStatus } =
        useAppSelector(roomBookingStateSelector);

    const onTabChange = (activeTab: string) => {
        setParams({ ...params, activeTab }, { replace: true });
        setActiveTab(activeTab);
    };

    useEffect(() => {
        const paramActiveTab = params.get('activeTab');
        if (paramActiveTab && paramActiveTab !== activeTab) setActiveTab(paramActiveTab);
    }, [params]);

    useEffect(() => {
        dispatch(getChildrenDropdown());
    }, []);

    const _deleteRoomBooking = useCallback(async () => {
        if (!selectedRoomBookingDetail?.id) return;

        if (
            selectedRoomBookingDetail.roomBookingItems.some(
                (roomBookingItem) =>
                    !checkRoomBookingItemCanDelete(
                        roomBookingItem,
                        isAdmin,
                        isHotelAdmin && isBookingWalkIn,
                    ),
            )
        )
            return BookingErrorModal({
                title: t('roomBooking.list.message.titleDelete'),
                okText: t('roomBooking.list.statusModalConfirm.okText'),
                description: t(
                    'roomBooking.detail.deleteConfirmDialog.deleteByStatusError',
                ),
                errorItems: [],
            });

        const response = await mutateAsyncDeleteBooking(selectedRoomBookingDetail.id);
        if (response?.success) {
            notification.success({
                message: t('roomBooking.detail.message.deleteSuccess'),
            });
            navigate('/room-booking');
        } else {
            notification.error({
                message: response.message || '',
            });
        }
    }, [selectedRoomBookingDetail, t, dispatch, navigate]);

    const _showConfirmDeleteBooking = () => {
        const isSingleBooking =
            (selectedRoomBookingDetail?.roomBookingItems?.length || 0) === 1;
        showConfirm({
            title: t('roomBooking.detail.deleteConfirmDialog.title'),
            content: isSingleBooking
                ? t('roomBooking.detail.deleteConfirmDialog.singleBookingContent')
                : t('roomBooking.detail.deleteConfirmDialog.groupBookingContent'),
            cancelText: t('roomBooking.detail.deleteConfirmDialog.cancelButton'),
            okText: t('roomBooking.detail.deleteConfirmDialog.deleteButton'),
            okButtonProps: { danger: true },
            wrapClassName: 'delete-room-booking-modal',
            onOk() {
                _deleteRoomBooking();
            },
        });
    };

    const onEditGeneralInfo = () => {
        navigate(`/room-booking/${roomBookingId}/edit`);
    };

    const setDataForPrintScreen = (roomBookingItems: IRoomBookingItem[]) => {
        dispatch(
            setPrintingRoomBookingList([
                { ...selectedRoomBookingDetail, roomBookingItems },
            ]),
        );
        dispatch(
            setPrintRoomBookingListQuery({
                ...roomBookingListQueryDefault,
            }),
        );
        dispatch(setIsShowRoomBookingPrintingModal(true));
    };

    const openPrintScreen = () => {
        const roomBookingItems =
            selectedRoomBookingDetail?.roomBookingItems?.filter(
                (item) => item.bookingStatus !== RoomBookingItemBookingStatus.CANCELLED,
            ) || [];
        if (roomBookingItems.length === 0) {
            Modal.warning({
                content: t('roomBooking.printing.notification.noPrintGuestCard', {
                    totalBookings: roomBookingItems?.length,
                }),
                okText: t('common.ok'),
            });
            return;
        }
        if (roomBookingItems?.length > MAX_PRINT_GUEST_CARD) {
            showConfirm({
                title: t('roomBooking.printing.notification.biggerGuestCards', {
                    totalBookings: roomBookingItems?.length,
                }),
                okText: t('common.ok'),
                cancelText: t('common.cancel'),
                onOk() {
                    setDataForPrintScreen(roomBookingItems);
                },
            });
            return;
        }
        setDataForPrintScreen(roomBookingItems);
    };

    const onClosePrintingModal = () => {
        showConfirm({
            title: t('roomBooking.printing.cancelTitle'),
            onOk() {
                dispatch(setIsShowRoomBookingPrintingModal(false));
            },
        });
    };

    const _assignBookingToRoom = useCallback(
        async (items: IAutoAssignBookingToRoomItem[]) => {
            if (!items.length) {
                return;
            }
            const response = await dispatch(assignBookingToRoom(items));
            if (assignBookingToRoom.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('roomManagement.list.assignRoomSuccess'),
                    });
                    if (selectedRoomBookingDetail) {
                        dispatch(fetchRoomBookingDetail(selectedRoomBookingDetail.id));
                    }
                } else {
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [selectedRoomBookingDetail, dispatch, t],
    );

    const _getAvailableRooms = useCallback(
        async (ids: number[]) => {
            if (ids.length === 0) {
                notification.success({
                    message: t('roomBooking.detail.message.assignedAllRoom'),
                });
                return;
            }
            const response = await dispatch(getAvailableRooms(ids));
            if (getAvailableRooms.fulfilled.match(response)) {
                if (response.payload?.success) {
                    const assignRooms = response.payload?.data || [];
                    if (assignRooms.length > 0) {
                        _assignBookingToRoom(assignRooms);
                    }
                    if (assignRooms.length < ids.length) {
                        notification.success({
                            message: t('roomBooking.detail.message.autoAssignRoom', {
                                unassignCount: ids.length - assignRooms.length,
                            }),
                        });
                    }
                } else {
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [dispatch, _assignBookingToRoom],
    );

    const bookingItemWithoutRooms = useMemo(() => {
        const bookingItems = selectedRoomBookingDetail?.roomBookingItems || [];
        const _bookingItemWithoutRooms = bookingItems.filter(
            (bookingItem) =>
                !bookingItem.room?.id &&
                bookingItem.bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED,
        );
        if (!_bookingItemWithoutRooms.length) return [];

        return sortBy(_bookingItemWithoutRooms, 'id');
    }, [selectedRoomBookingDetail]);

    const clickAutoAssignRoomButton = async () => {
        if (bookingItemWithoutRooms.length) {
            _getAvailableRooms(
                bookingItemWithoutRooms.map((item) => {
                    return +item.id;
                }),
            );
        }
    };

    const _updateBookingItemStatus = async (body: IUpdateBookingItemStatus) => {
        const response = await dispatch(updateBookingItemStatus(body));

        if (updateBookingItemStatus.fulfilled.match(response)) {
            if (response.payload?.success) {
                let message = null;
                if (body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT) {
                    message = t('roomBooking.list.checkoutDialog.checkoutSuccess');
                }
                notification.success({
                    message: message || t('roomBooking.list.statusModalConfirm.success'),
                });
                if (selectedRoomBookingDetail?.id) {
                    dispatch(fetchRoomBookingDetail(selectedRoomBookingDetail.id));
                }
                dispatch(getNotificationList());
                dispatch(getReadNotificationTime());
            } else {
                if (body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT) {
                    showConfirm({
                        title: t('roomBooking.list.checkoutDialog.unpaidTitle'),
                        content: t('roomBooking.list.checkoutDialog.content'),
                        okText: t('roomBooking.list.checkoutDialog.okText'),
                        cancelText: t('roomBooking.list.checkoutDialog.cancelText'),
                        onOk() {
                            changeDetailTab(
                                RoomBookingDetailPageTabPane.ROOM_BOOKING_RECEIPT_TAB_PANE,
                            );
                        },
                    });
                } else {
                    Modal.error({
                        title: t('roomBooking.list.statusPopConfirm.title'),
                        content: response.payload?.message || '',
                        okText: t('roomBooking.list.statusModalConfirm.okText'),
                    });
                }
            }
        }
    };

    const roomBookingItems = useMemo(() => {
        return selectedRoomBookingDetail?.roomBookingItems || [];
    }, [selectedRoomBookingDetail]);

    const isShowStatusTab = useMemo(() => {
        if (roomBookingItems.length === 0) return false;
        const currentStatus = roomBookingItems[0].bookingStatus;
        return !roomBookingItems.some((item) => item.bookingStatus !== currentStatus);
    }, [roomBookingItems]);

    const shouldDisplayNoShowToggle = useMemo(() => {
        if (roomBookingItems.length === 0) return false;

        return roomBookingItems.every(
            (item) => item.bookingStatus === RoomBookingItemBookingStatus.CANCELLED,
        );
    }, [roomBookingItems]);

    const isNoShow = useMemo(() => {
        if (roomBookingItems.length === 0) return false;

        return roomBookingItems.every((item) => item.isNoShow);
    }, [roomBookingItems]);

    const onChangeNoShowStatus = async (checked: boolean) => {
        const response = await dispatch(
            updateBookingItemNoShowStatus({
                isNoShow: checked,
                ids: roomBookingItems.map(({ id }) => id) as number[],
            }),
        );
        if (updateBookingItemNoShowStatus.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.list.statusModalConfirm.success'),
                });
                if (selectedRoomBookingDetail?.id) {
                    dispatch(fetchRoomBookingDetail(selectedRoomBookingDetail.id));
                }
            } else {
                Modal.error({
                    title: t('roomBooking.list.statusPopConfirm.title'),
                    content: response.payload?.message || '',
                    okText: t('roomBooking.list.statusModalConfirm.okText'),
                });
            }
        }
    };

    const changeBookingStatusConfirm = (status: string) => {
        for (const item of roomBookingItems) {
            if (
                item.bookingStatus &&
                !ChangingBookingStatusesMap[item.bookingStatus]?.includes(
                    status as RoomBookingItemBookingStatus,
                )
            ) {
                Modal.error({
                    title: t('roomBooking.list.statusPopConfirm.title'),
                    content: t('roomBooking.list.statusModalConfirm.errorChangeStatus', {
                        beforeStatus: t(
                            `roomBooking.page.bookingStatus.${item.bookingStatus}`,
                        ),
                        afterStatus: t(`roomBooking.page.bookingStatus.${status}`),
                    }),
                    okText: t('roomBooking.list.statusModalConfirm.okText'),
                });
                return;
            }
        }
        if (status === RoomBookingItemBookingStatus.CHECKED_IN) {
            const errorBookings = roomBookingItems.filter((item) => {
                // user should be able to check in for all splitted bookings even when splitted childRoomBookingItem's checkIn date is a future date
                if (item.parentRoomBookingItemId) return false;

                return parseDate(item.startDateOfStay).isAfter(customDayjs(), 'day');
            });

            sortBy(errorBookings, 'startDateOfStay');
            if (errorBookings.length > 0) {
                Modal.error({
                    title: t('roomBooking.list.message.titleDelete'),
                    okText: t('roomBooking.list.statusModalConfirm.okText'),
                    content: t('roomBooking.detail.message.cannotCheckInAll', {
                        date: parseDate(
                            errorBookings[errorBookings.length - 1]?.startDateOfStay,
                        ).fmYYYYMMDD(),
                    }),
                });
                return;
            }
        }
        _updateBookingItemStatus({
            bookingStatus: status as RoomBookingItemBookingStatus,
            ids: roomBookingItems.map((item) => item.id) as number[],
        });
    };

    const isSingleBooking = selectedRoomBookingDetail?.roomBookingItems.length === 1;
    const [isAccomodationCardOpen, setIsAccomodationCardOpen] = useState(false);
    const [isPopOpen, setIsPopOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    const itemsReordered = useMemo(() => {
        if (!selectedRoomBookingDetail) return [];
        return getRoomBookingItems(selectedRoomBookingDetail);
    }, [selectedRoomBookingDetail?.roomBookingItems]);

    const itemsSelected = useMemo(() => {
        return itemsReordered.filter((item) => selectedIds.includes(Number(item.id)));
    }, [itemsReordered, selectedIds]);

    useEffect(() => {
        if (selectedRoomBookingDetail && isSingleBooking) {
            setSelectedIds([Number(selectedRoomBookingDetail.roomBookingItems[0].id)]);
        }
    }, [selectedRoomBookingDetail]);

    const openAccomodationCardDraw = () => {
        setIsPopOpen(false);
        setIsAccomodationCardOpen(true);
        dispatch(setFreshChatShow(false));
    };

    const [isShowFormAddBooking, setShowFormAddBooking] = useState(false);

    useEffect(() => {
        dispatch(getRoomList());
    }, []);

    const mutation = useMutation({
        mutationFn: async (data: ICreateRoomBookingBody) => {
            if (!selectedRoomBookingDetail) return undefined;
            const res = await roomBookingServiceV2.addRoomBooking(
                selectedRoomBookingDetail.id,
                data,
            );
            if (res.errors) throw Error(res.errors[0]);
            return res.data;
        },
        onSuccess: () => {
            notification.success({
                message: t('roomBooking.detail.message.createSuccess'),
            });
            if (!selectedRoomBookingDetail) return;
            dispatch(fetchRoomBookingDetail(selectedRoomBookingDetail.id));
        },
        onError: (error) => {
            notification.error({
                message: t('roomBooking.detail.message.createError'),
                description: error.message,
            });
        },
        onSettled: () => {
            setShowFormAddBooking(false);
        },
    });

    const onSubmit = (booking: ICreateRoomBookingForm) => {
        mutation.mutate(
            omit(booking, ['id', 'isTmp', 'roomKey', 'guest', 'status', 'price']),
        );
    };

    const tagColor = useMemo(() => {
        if (!roomBookingItems[0]) return 'default';
        switch (roomBookingItems[0].bookingStatus) {
            case 'not_arrived':
                return 'warning';
            case 'checked_in':
                return 'success';
            default:
                return 'default';
        }
    }, [roomBookingItems]);

    return (
        <div className="room-booking-list-tab-pane">
            <Card
                className="room-booking-detail-card"
                style={{ width: '100%' }}
                title={
                    <Title
                        level={3}
                        style={{ margin: 0 }}
                        copyable={{
                            text: selectedRoomBookingDetail?.autoGeneratedCode,
                            tooltips: [t('common.copy'), t('common.copied')],
                        }}
                    >
                        {t('roomBooking.detail.detailPageCards.resId', {
                            resId: getAutoGeneratedCode(
                                selectedRoomBookingDetail?.autoGeneratedCode || '',
                            ),
                        })}
                    </Title>
                }
                extra={
                    <div className="icon-group">
                        {isShowStatusTab && (
                            <Tag
                                bordered={false}
                                color={tagColor}
                                style={{ padding: '4px 12px', fontSize: '16px' }}
                            >
                                {t(
                                    `roomBooking.page.bookingStatus.${roomBookingItems[0].bookingStatus}`,
                                )}
                            </Tag>
                        )}

                        {shouldDisplayNoShowToggle && (
                            <div className="no-show-status">
                                <span className="label">
                                    {t('roomBooking.page.noShow.label')}
                                </span>
                                <Switch
                                    loading={isUpdatingNoShowStatus}
                                    checked={isNoShow}
                                    onChange={onChangeNoShowStatus}
                                />
                            </div>
                        )}

                        {process.env.REACT_APP_OTA_CANCELATION_ROBOT === 'true' && (
                            <Button
                                type="primary"
                                danger
                                icon={<StopOutlined />}
                                onClick={() => navigate('ota-cancelation-robot')}
                                className="ota-cancelation-btn"
                            >
                                {t(
                                    'roomBooking.detail.detailPagetabs.OtaCancelationRobot',
                                )}
                            </Button>
                        )}
                        <ChangeStatusDropDown
                            onChangeStatus={changeBookingStatusConfirm}
                            type="primary"
                        />
                        <Button
                            icon={<EditOutlined className="icon-size" />}
                            onClick={onEditGeneralInfo}
                        >
                            {t('roomBooking.detail.detailPagetabs.editInfo')}
                        </Button>
                        <Tooltip
                            title={t('roomBooking.detail.detailPagetabs.printGuestCard')}
                            placement="bottom"
                        >
                            <Button
                                icon={<PrinterOutlined className="icon-size" />}
                                onClick={openPrintScreen}
                            />
                        </Tooltip>
                        <Tooltip
                            title={t('roomBooking.detail.detailPagetabs.showGuestCard')}
                            placement="bottom"
                        >
                            {isSingleBooking ? (
                                <Button
                                    icon={<IdcardOutlined className="icon-size" />}
                                    onClick={openAccomodationCardDraw}
                                />
                            ) : (
                                <Popover
                                    placement="bottomRight"
                                    trigger="click"
                                    title={t(
                                        'roomBooking.updateBooking.selectBookingItems',
                                    )}
                                    open={isPopOpen}
                                    content={
                                        <AccomodationSelection
                                            onOpen={openAccomodationCardDraw}
                                            roomBookingItems={itemsReordered}
                                            bookingItemIds={selectedIds}
                                            setBookingItemIds={setSelectedIds}
                                            setIsPopOpen={setIsPopOpen}
                                        />
                                    }
                                >
                                    <Button
                                        onClick={() => setIsPopOpen(true)}
                                        icon={<IdcardOutlined className="icon-size" />}
                                    />
                                </Popover>
                            )}
                        </Tooltip>

                        <Tooltip title={t('common.delete')} placement="bottom">
                            <Button
                                icon={<DeleteOutlined className="icon-size" />}
                                onClick={_showConfirmDeleteBooking}
                            />
                        </Tooltip>
                    </div>
                }
                activeTabKey={activeTab}
                onTabChange={(key) => {
                    onTabChange(key);
                }}
            >
                <RepresentativeGuestInfo />
            </Card>

            <Spin spinning={isBulkUpdateStatusLoading || !selectedRoomBookingDetail}>
                <Card
                    className="booking-item-list-card"
                    title={
                        <div className="d-flex j-between a-center">
                            <div>
                                <span>
                                    {t('roomBooking.detail.detailPageCards.bookingList')}
                                </span>
                                {(selectedRoomBookingDetail?.roomBookingItems?.length ||
                                    0) > 0 && (
                                    <span className="count">
                                        {
                                            selectedRoomBookingDetail?.roomBookingItems
                                                ?.length
                                        }
                                    </span>
                                )}
                                {(selectedRoomBookingDetail?.roomBookingItems?.length ||
                                    0) > 1 &&
                                    bookingItemWithoutRooms.length > 0 && (
                                        <Button
                                            type="primary"
                                            className="ml-34"
                                            onClick={clickAutoAssignRoomButton}
                                            loading={
                                                isFetchingBookingList ||
                                                isAutoAssignRoomLoading
                                            }
                                        >
                                            {t(
                                                'roomBooking.createBooking.autoAssignment',
                                            )}
                                        </Button>
                                    )}
                            </div>
                            <div className="">
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => setShowFormAddBooking(true)}
                                >
                                    {t('roomBooking.createBooking.addRoomBooking')}
                                </Button>
                            </div>
                        </div>
                    }
                >
                    <BookingDetailItems
                        roomBookingDetail={selectedRoomBookingDetail}
                        fetchRoomBookingDetail={(id) =>
                            dispatch(fetchRoomBookingDetail(id))
                        }
                    />
                </Card>
            </Spin>

            <RoomBookingPrintingModal
                isShowRoomBookingPrintingModal={isShowRoomBookingPrintingModal}
                onClose={onClosePrintingModal}
            />

            {selectedRoomBookingDetail && (
                <Modal
                    centered
                    title=""
                    open={isShowFormAddBooking}
                    footer={null}
                    closable={false}
                    className="create-schedule-modal"
                >
                    <CreateModal
                        booking={getBookingScheduleDefault()}
                        usePopover={false}
                        close={() => setShowFormAddBooking(false)}
                        onSubmit={onSubmit}
                        isLoading={mutation.isPending}
                    />
                </Modal>
            )}

            {selectedRoomBookingDetail && (
                <Suspense fallback={<Spin />}>
                    <ModalAccomodationCardDraw
                        items={itemsSelected}
                        resetItems={() => {
                            if (!isSingleBooking) setSelectedIds([]);
                        }}
                        isModalOpen={isAccomodationCardOpen}
                        closeModal={() => {
                            setIsAccomodationCardOpen(false);
                            dispatch(setFreshChatShow(true));
                        }}
                        roomBookingDetail={selectedRoomBookingDetail}
                    />
                </Suspense>
            )}
        </div>
    );
}

const getRoomBookingItems = (roomBookingDetail: IRoomBookingDetail) => {
    const items = [...roomBookingDetail.roomBookingItems];
    items.sort((a, b) => {
        if (!a.representativeGuest) return +1;
        if (!b.representativeGuest) return -1;
        if (a.representativeGuest.yomigana === b.representativeGuest.yomigana) {
            if (dayjs(a.startDateOfStay).isBefore(dayjs(b.startDateOfStay))) {
                return 1;
            } else if (dayjs(a.startDateOfStay).isSame(dayjs(b.startDateOfStay))) {
                return 0;
            }
            return -1;
        }
        return a.representativeGuest.yomigana.localeCompare(
            b.representativeGuest.yomigana,
        );
    });
    return items;
};

interface PropsAccomodationSelection {
    roomBookingItems: IRoomBookingItem[];
    onOpen: () => void;
    bookingItemIds: number[];
    setBookingItemIds: React.Dispatch<React.SetStateAction<number[]>>;
    setIsPopOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccomodationSelection = ({
    roomBookingItems,
    bookingItemIds,
    setBookingItemIds,
    onOpen,
    setIsPopOpen,
}: PropsAccomodationSelection) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex f-col" style={{ minWidth: '280px' }}>
            <Checkbox.Group
                className="draw-item-selection"
                value={bookingItemIds}
                onChange={(values) => {
                    setBookingItemIds(values as number[]);
                }}
            >
                {roomBookingItems.map((item) => (
                    <Checkbox key={item.id} value={item.id}>
                        <Text
                            strong
                            style={{
                                color: !item.representativeGuest ? '#d8d9d9' : 'inherit',
                            }}
                        >
                            {item.representativeGuest?.yomigana ||
                                t('roomBooking.updateBooking.noAssignedGuest')}
                        </Text>
                        <Text italic>
                            {customDayjs(item.startDateOfStay).format('MM/DD')} -{' '}
                            {customDayjs(item.endDateOfStay).format('MM/DD')}
                        </Text>
                        <Text strong>{getRoomNameDisplay(item)}</Text>
                        <Text>{item.plan?.name}</Text>
                    </Checkbox>
                ))}
            </Checkbox.Group>

            <div className="mt-16 d-flex j-end">
                <Button
                    onClick={() => {
                        setIsPopOpen(false);
                        setBookingItemIds([]);
                    }}
                >
                    {t('roomBooking.updateBooking.cancel')}
                </Button>
                <Button
                    className="ml-12"
                    type="primary"
                    onClick={onOpen}
                    disabled={!bookingItemIds.length}
                >
                    {t('roomBooking.updateBooking.submit')}
                </Button>
            </div>
        </div>
    );
};

export default RoomBookingDetailTabPane;
