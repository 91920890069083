import './RoomBookingKeycardTabPane.scss';

import { useMemo } from 'react';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { useAppSelector } from '~hooks';
import { combineISODateAndTime } from '~common/helper';
import { KeyCardIssueModalProvider } from './RoomKeycardIssueProvider';
import { RoomKeycardIssueModal } from './RoomKeycardIssueModal/RoomKeycardIssueModal';
import {
    RoomKeycardIssueRecordList,
    type KeycardIssueRecordListProps,
} from './RoomKeycardIssueRecordList/RoomKeycardIssueRecordList';

function RoomBookingKeycardTabPane() {
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const reservedRooms = useMemo(() => {
        return (selectedRoomBookingDetail?.roomBookingItems || [])
            .map((item) => {
                if (!item.room) {
                    return null;
                }
                return {
                    roomBookingId: item.id,
                    room: item.room,
                    checkinTimestamp: combineISODateAndTime(
                        item.startDateOfStay,
                        item.checkInTime,
                    ),
                    checkoutTimestamp: combineISODateAndTime(
                        item.endDateOfStay,
                        item.checkOutTime,
                    ),
                };
            })
            .filter((item): item is KeycardIssueRecordListProps => !!item);
    }, [selectedRoomBookingDetail]);

    return (
        <KeyCardIssueModalProvider>
            <div className="keycard">
                {reservedRooms.map((room) => (
                    <RoomKeycardIssueRecordList key={room.room.id} {...room} />
                ))}
            </div>
            <RoomKeycardIssueModal />
        </KeyCardIssueModalProvider>
    );
}

export default RoomBookingKeycardTabPane;
