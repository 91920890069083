import dayjs from 'dayjs';
import { Col, Empty, Row, Tooltip, Typography, theme } from 'antd';
import './RepresentativeGuestInfo.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~hooks';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { CSSProperties, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { numberOfStayedGuestSelector } from '~features/guest/reducers/guest.reducer';
import { GuestPageTabPane } from '~features/guest/constants';

const { Text, Paragraph, Link } = Typography;

function RepresentativeGuestInfo() {
    const { t } = useTranslation();
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const numberOfStayedGuest = useAppSelector(numberOfStayedGuestSelector);

    const { phoneOfRepresentativeGuest } = useMemo(() => {
        if (!selectedRoomBookingDetail)
            return { phoneOfRepresentativeGuest: '', phoneOfReserverGuest: '' };

        const { representativeGuest = null, reserverGuest = null } =
            selectedRoomBookingDetail;
        return {
            phoneOfRepresentativeGuest:
                representativeGuest?.mobilePhoneNumber ||
                representativeGuest?.phoneNumberLandline ||
                '',
            phoneOfReserverGuest:
                reserverGuest?.mobilePhoneNumber ||
                reserverGuest?.phoneNumberLandline ||
                '',
        };
    }, [selectedRoomBookingDetail]);

    const represent = selectedRoomBookingDetail?.representativeGuest;
    const reserver = selectedRoomBookingDetail?.reserverGuest;

    return (
        <div className="representative-guest-info">
            <Row gutter={12}>
                <Col span={12} className="guest-info-col">
                    <Row>
                        <Col span={6} className="d-flex f-col" style={{ gap: 4 }}>
                            <Text>{t('roomBooking.detail.guestInfo.name')}</Text>
                            <Text>{t('roomBooking.detail.guestInfo.phoneNumber')}</Text>
                            <Text>{t('roomBooking.detail.guestInfo.email')}</Text>
                            <Text>{t('roomBooking.detail.guestInfo.reserver')}</Text>
                            <Text>{t('roomBooking.detail.guestInfo.numberOfGuest')}</Text>
                            <Text>{t('roomBooking.detail.guestInfo.salesChannel')}</Text>
                            <Text>{t('roomBooking.detail.guestInfo.bookingTime')}</Text>
                            <Text>
                                {t(
                                    'roomBooking.detail.bookingItemCard.numberOfStayedGuest',
                                )}
                            </Text>
                        </Col>
                        <Col span={18} className="d-flex f-col" style={{ gap: 4 }}>
                            <RouterLink
                                to={'/guest/' + represent?.id + '/detail'}
                                style={{ width: 'fit-content' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Link
                                    strong
                                    copyable={{
                                        text:
                                            represent?.fullName &&
                                            represent.fullName !== represent?.yomigana
                                                ? represent.yomigana
                                                      .concat(' ')
                                                      .concat(represent.fullName)
                                                : represent?.yomigana,
                                        tooltips: [t('common.copy'), t('common.copied')],
                                    }}
                                >
                                    {represent?.yomigana}
                                    {represent?.fullName &&
                                        represent.fullName !== represent?.yomigana && (
                                            <span className="ml-16">
                                                {represent.fullName}
                                            </span>
                                        )}
                                </Link>
                            </RouterLink>
                            <Text
                                copyable={
                                    phoneOfRepresentativeGuest
                                        ? {
                                              tooltips: [
                                                  t('common.copy'),
                                                  t('common.copied'),
                                              ],
                                          }
                                        : false
                                }
                            >
                                {phoneOfRepresentativeGuest || '-'}
                            </Text>
                            <Link
                                href={
                                    represent?.emailAddress
                                        ? `mailto:${represent.emailAddress}`
                                        : undefined
                                }
                                style={{ width: 'fit-content' }}
                                copyable={
                                    represent?.emailAddress
                                        ? {
                                              tooltips: [
                                                  t('common.copy'),
                                                  t('common.copied'),
                                              ],
                                          }
                                        : false
                                }
                            >
                                {represent?.emailAddress || '-'}
                            </Link>
                            <Text>
                                {selectedRoomBookingDetail?.isReserverTheRepresentative ? (
                                    t(
                                        'roomBooking.detail.guestInfo.reserverIsTheRepresentative',
                                    )
                                ) : (
                                    <>
                                        <span>{reserver?.yomigana}</span>
                                        {reserver?.fullName !== reserver?.yomigana && (
                                            <span className="ml-16">
                                                {reserver?.fullName}
                                            </span>
                                        )}
                                    </>
                                )}
                            </Text>
                            <Text strong>
                                {selectedRoomBookingDetail?.guests?.length || 1}
                            </Text>
                            {!selectedRoomBookingDetail?.marketingChannel
                                ?.isPullFromTll ? (
                                <Text>
                                    {selectedRoomBookingDetail?.marketingChannel?.name}
                                </Text>
                            ) : (
                                <Tooltip
                                    title={t(
                                        'roomBooking.detail.message.marketingChannelNotExist',
                                    )}
                                >
                                    <Text type="danger">
                                        {
                                            selectedRoomBookingDetail?.marketingChannel
                                                ?.name
                                        }
                                    </Text>
                                </Tooltip>
                            )}
                            <Text>
                                {dayjs(
                                    selectedRoomBookingDetail?.roomBookingItems[0]
                                        .createdAt,
                                ).fmYYYYMMDDHHmm()}
                            </Text>
                            <Tooltip
                                title={t('roomBooking.detail.guestInfo.guestHistory')}
                                placement="bottom"
                            >
                                <RouterLink
                                    to={`/guest/${selectedRoomBookingDetail?.representativeGuestId}/detail?activeTab=${GuestPageTabPane.BOOKING_HISTORY_TAB_PANE}`}
                                    style={{ width: 'fit-content' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {numberOfStayedGuest}&nbsp;
                                    {t('roomBooking.detail.bookingItemCard.times')}
                                </RouterLink>
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="d-flex f-col">
                    <div className="d-flex f-col">
                        <Text style={{ fontSize: 16 }}>
                            {t('roomBooking.detail.detailPagetabs.memo')}
                        </Text>
                        {selectedRoomBookingDetail?.memo ? (
                            <ParagraphExpandable>
                                {selectedRoomBookingDetail.memo}
                            </ParagraphExpandable>
                        ) : (
                            <EmptyMemoPlaceholder
                                description={t(
                                    'roomBooking.detail.detailPagetabs.memoEmpty',
                                )}
                                style={{ marginBottom: 16 }}
                            />
                        )}
                    </div>
                    <div className="d-flex f-col">
                        <Text style={{ fontSize: 16 }}>
                            {t('roomBooking.detail.detailPagetabs.memoOta')}
                        </Text>
                        {selectedRoomBookingDetail?.otaMemo ? (
                            <ParagraphExpandable>
                                {selectedRoomBookingDetail.otaMemo}
                            </ParagraphExpandable>
                        ) : (
                            <EmptyMemoPlaceholder
                                description={t(
                                    'roomBooking.detail.detailPagetabs.memoOtaEmpty',
                                )}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

const ParagraphExpandable = ({ children }: { children: string }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    return (
        <Paragraph
            style={{ minHeight: 48 }}
            ellipsis={{
                rows: 5,
                expandable: 'collapsible',
                expanded,
                onExpand: (_, info) => setExpanded(info.expanded),
                symbol: (expanded) =>
                    expanded ? t('common.showLess') : t('common.showMore'),
            }}
            className="paragraph"
        >
            {children}
        </Paragraph>
    );
};

const EmptyMemoPlaceholder = ({
    description,
    style,
}: {
    description: string;
    style?: CSSProperties;
}) => {
    const { token } = theme.useToken();

    return (
        <div
            style={{
                border: `1px solid ${token.colorBorder}`,
                borderRadius: 6,
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(245, 245, 245, 0.5)',
                ...style,
            }}
        >
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ width: 48, height: 32 }}
                description={description}
                className="d-flex f-col a-center"
            />
        </div>
    );
};

export default RepresentativeGuestInfo;
