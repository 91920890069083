import { AutoComplete, AutoCompleteProps, Form, Input } from 'antd';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { useEffect, useState } from 'react';
import { parseErrorMessage } from '~common/commonFunctions';
import { IGuestDropDown } from '~features/guest/interfaces';
import { Controller } from '~plugins/hook-form';
import i18next from '~plugins/i18next/i18n';
import './AutoCompleteGuest.scss';
import GuestItem from './GuestItem';

interface ISingleSelect extends AutoCompleteProps {
    name: string;
    label: string;
    defaultValue: string | number;
    control: any;
    disableValues?: (string | number)[];
    tooltip?: LabelTooltipType;
    required?: boolean;
    guestOptions: IGuestDropDown[];
    guestRoom?: string;
    placeholder?: string;
    overlayClassName?: string;
    popupMatchSelectWidth?: boolean | number;
    isTriggerParentNode?: boolean;
    allowClear?: boolean | { clearIcon: React.ReactNode; className?: string };
}

export function AutoCompleteGuest(props: ISingleSelect) {
    const {
        tooltip,
        control,
        name,
        defaultValue,
        label,
        onChange,
        guestOptions,
        disableValues,
        required,
        guestRoom,
        placeholder,
        allowClear,
        overlayClassName,
        disabled,
        popupMatchSelectWidth,
        isTriggerParentNode,
        onClear,
        ...rest
    } = props;

    const [keyword, setKeyword] = useState<string>('');
    const [options, setOptions] = useState<IGuestDropDown[]>(guestOptions);

    useEffect(() => {
        if (typeof defaultValue === 'string') {
            setKeyword(defaultValue);
        }
        if (typeof defaultValue === 'number') {
            const value =
                guestOptions.find((guest) => guest.id === defaultValue)?.yomigana || '';
            setKeyword(value);
        }
    }, [defaultValue, guestOptions]);

    return (
        <div className="auto-complete-wrapper">
            <Controller
                {...props}
                {...rest}
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    const id = field.value?.id || field.value;

                    const selectChange = (guest?: IGuestDropDown) => {
                        if (!guest) return;
                        setKeyword(guest?.yomigana || '');

                        if (onChange) {
                            onChange(guest, []);
                        }
                        field.onChange(guest);
                    };

                    const currentItems = options.map((guest) => {
                        const disabled = disableValues?.includes(guest.id) || false;
                        return {
                            value: (
                                <GuestItem
                                    item={guest}
                                    isSelected={guest.id === id}
                                    onClick={selectChange}
                                    disabled={disabled}
                                />
                            ),
                            key: guest.id,
                            disabled,
                        };
                    });

                    const filterGuestOptions = (value: string) => {
                        const _keyword = value?.trim()?.toLowerCase();
                        if (!_keyword) {
                            setOptions(guestOptions);
                            return;
                        }

                        const filterOptions = guestOptions.filter((option) => {
                            if (!_keyword) return true;
                            const {
                                mobilePhoneNumber,
                                yomigana,
                                autoGeneratedCode,
                                phoneNumberLandline,
                            } = option;
                            const phoneGuest =
                                mobilePhoneNumber || phoneNumberLandline || '';
                            return (
                                (phoneGuest &&
                                    phoneGuest?.toLowerCase()?.includes(_keyword)) ||
                                (yomigana &&
                                    yomigana?.toLowerCase()?.includes(_keyword)) ||
                                autoGeneratedCode?.includes(_keyword)
                            );
                        });
                        setOptions(filterOptions);
                    };

                    return (
                        <Form.Item
                            required={required}
                            tooltip={tooltip}
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                        >
                            <AutoComplete
                                popupClassName={
                                    'auto-complete-popup' +
                                    (overlayClassName ? ` ${overlayClassName}` : '')
                                }
                                options={currentItems || []}
                                disabled={disabled}
                                onSearch={(value) => {
                                    filterGuestOptions(value);
                                }}
                                popupMatchSelectWidth={popupMatchSelectWidth}
                                value={keyword}
                                onFocus={() => {
                                    filterGuestOptions(keyword || '');
                                }}
                                getPopupContainer={(triggerNode?: HTMLElement) =>
                                    (isTriggerParentNode &&
                                        (triggerNode?.parentNode as HTMLElement)) ||
                                    document.body
                                }
                            >
                                <Input
                                    allowClear={allowClear}
                                    placeholder={placeholder}
                                    value={keyword}
                                    onChange={(e) => {
                                        const value = e.target?.value;
                                        setKeyword(value);
                                        if (onChange) {
                                            onChange(value, []);
                                        }
                                        field.onChange(value);
                                    }}
                                    onClear={() => {
                                        setKeyword('');
                                        if (onClear) {
                                            onClear();
                                        }
                                    }}
                                />
                            </AutoComplete>

                            {guestRoom && !message && (
                                <span className="room-name text-truncate">
                                    {i18next.t<string>(
                                        'facilityBooking.createForm.roomName',
                                    )}
                                    :{guestRoom}
                                </span>
                            )}
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

AutoCompleteGuest.defaultProps = {
    defaultValue: null,
    required: false,
    options: [],
    disableValues: [],
};
