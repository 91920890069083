import './CardIssueStatus.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ReactComponent as ScanKeycardIcon } from '~assets/images/scan_keycard.svg';
import { ReactComponent as CheckCircleIcon } from '~assets/images/check_circle.svg';
import { ReactComponent as CircleErrorIcon } from '~assets/images/circle_error.svg';
import { useKeyCardIssueModalContext } from '../../RoomKeycardIssueProvider';
import { KeycardIssueStatus } from '../../enums/key-card-issue-status.enums';

export function CardIssueStatus() {
    const { t } = useTranslation();
    const { issueStatus, closeModal, issue } = useKeyCardIssueModalContext();

    const reissueIcon = useMemo(() => {
        if (issueStatus === KeycardIssueStatus.SUCCESS) {
            return <CheckCircleIcon />;
        }
        if (issueStatus === KeycardIssueStatus.FAILED) {
            return <CircleErrorIcon />;
        }
        return <ScanKeycardIcon />;
    }, [issueStatus]);

    const title = useMemo(() => {
        if (issueStatus === KeycardIssueStatus.SUCCESS) {
            return (
                <div className="reissue-modal__title reissue-modal__title-success">
                    {t('roomBooking.detail.keycard.reissueState.success')}
                </div>
            );
        }
        if (issueStatus === KeycardIssueStatus.FAILED) {
            return (
                <div className="reissue-modal__title reissue-modal__title-failed">
                    {t('roomBooking.detail.keycard.reissueState.failed')}
                </div>
            );
        }
        return (
            <div className="reissue-modal__title">
                {t('roomBooking.detail.keycard.reissueState.scanning')}
            </div>
        );
    }, [issueStatus]);

    return (
        <div className="reissue-modal__wrapper">
            {reissueIcon}
            {title}
            {issueStatus === KeycardIssueStatus.SCANNING && (
                <Button size="large" block onClick={() => closeModal()}>
                    {t('roomBooking.detail.keycard.cancel')}
                </Button>
            )}
            {issueStatus === KeycardIssueStatus.FAILED && (
                <Button type="primary" size="large" block onClick={() => issue()}>
                    {t('roomBooking.detail.keycard.tryAgain')}
                </Button>
            )}
            {(issueStatus === KeycardIssueStatus.SUCCESS ||
                issueStatus === KeycardIssueStatus.FAILED) && (
                <Button size="large" block onClick={() => closeModal()}>
                    {t('roomBooking.detail.keycard.close')}
                </Button>
            )}
        </div>
    );
}
