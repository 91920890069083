import { Content } from 'antd/lib/layout/layout';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import {
    INVOICE_PATH,
    SelectSplitBillTypeOptions,
} from '~features/room-booking/constants';
import {
    fetchRoomBookingDetail,
    resetSplitReceiptRoomBooking,
} from '~features/room-booking/reducers/room-booking.reducer';
import {
    selectedSplitBillTypeSelector,
    setIsShowSplitRoomBookingReceiptPrintingModal,
    splitReceiptStateSelector,
} from '~features/room-booking/reducers/split-receipt.reducer';
import { getSaleItemListForDropdown } from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import BottomButtonGroup from './components/BottomButtonGroup/BottomButtonGroup';
import NotificationAlert from './components/NotificationAlert/NotificationAlert';
import SelectSplitBillTypePanel from './components/SelectSplitBillTypePanel/SelectSplitBillTypePanel';
import SplitBillByGroup from './components/SplitBillByGroup/SplitBillByGroup';
import SplitBillByRoom from './components/SplitBillByRoom/SplitBillByRoom';
import SplitRoomBookingReceiptPrintingModal from './components/SplitRoomBookingPrintingModal/SplitRoomBookingReceiptPrintingModal';
import './SplitReceiptPage.scss';
import { useQuery } from '@tanstack/react-query';
import { roomBookingService } from '~features/room-booking/services/room-booking.service';
import { CacheKeys } from '~queries/queries';
import { Spin } from 'antd';

function SplitReceiptPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { roomBookingId } = useParams();
    const { pathname } = useLocation();
    const selectedSplitBillType = useAppSelector(selectedSplitBillTypeSelector);
    const { isShowSplitRoomBookingReceiptPrintingModal } = useAppSelector(
        splitReceiptStateSelector,
    );

    const { data: roomBookingReceipt, refetch } = useQuery({
        queryKey: [CacheKeys.roomBookingReceipt, roomBookingId],
        queryFn: async () => {
            const resp = await roomBookingService.getReceipt(Number(roomBookingId));
            return resp.data.roomBookingReceipt;
        },
        retry: 3,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        staleTime: 1000,
        enabled: !!roomBookingId,
    });

    const isGroupRoomBooking = useMemo(() => {
        const roomBookingItems = roomBookingReceipt?.roomBooking?.roomBookingItems || [];
        return roomBookingItems?.length > 1;
    }, [roomBookingReceipt?.roomBooking?.roomBookingItems]);

    const breadcrumbs = () => [
        {
            text: t('roomBooking.splitReceipt.home'),
        },
        {
            text: t('roomBooking.splitReceipt.roomBookingList'),
        },
        {
            text: t('roomBooking.splitReceipt.splitReceipt'),
        },
    ];

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchRoomBookingDetail(id));
        if (fetchRoomBookingDetail.fulfilled.match(response)) {
            if (!response.payload?.success) {
                navigate('/404');
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof Number(roomBookingId) === 'number') {
            _getDetail(Number(roomBookingId));
            refetch();
        } else {
            navigate('/404');
        }
        return () => {
            dispatch(resetSplitReceiptRoomBooking());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getSaleItemListForDropdown({}));
        return () => {
            dispatch(setIsShowSplitRoomBookingReceiptPrintingModal(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="split-room-booking-receipt-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={
                    pathname.endsWith(INVOICE_PATH)
                        ? t('roomBooking.splitReceipt.titleInvoice')
                        : t('roomBooking.splitReceipt.title')
                }
            />
            <NotificationAlert />
            <Content className="split-receipt-page-content">
                <SelectSplitBillTypePanel isShowSplitByRoom={isGroupRoomBooking} />
                <Spin spinning={!roomBookingReceipt}>
                    {selectedSplitBillType ===
                    SelectSplitBillTypeOptions.BASED_ON_ROOM ? (
                        <SplitBillByRoom receipt={roomBookingReceipt || null} />
                    ) : (
                        <SplitBillByGroup receipt={roomBookingReceipt || null} />
                    )}
                </Spin>
            </Content>
            <BottomButtonGroup />
            {isShowSplitRoomBookingReceiptPrintingModal && (
                <SplitRoomBookingReceiptPrintingModal />
            )}
        </div>
    );
}

export default SplitReceiptPage;
