import { createSlice } from '@reduxjs/toolkit';
import { SupportLanguage } from '~common/constants';
import { IAppState } from '~common/interfaces';
import { storage } from '~common/localStorage';
import { AppState } from '~plugins/redux-toolkit/store';

const initialState: IAppState = {
    currentLanguage:
        (storage.get('currentLanguage') as SupportLanguage) || SupportLanguage.JA,
    isPmsMenuCollapsed: true,
    isFreshChatShow: true,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCurrentLanguage: (state, action) => {
            state.currentLanguage = action.payload;
        },
        setPmsMenuCollapsed: (state, action) => {
            state.isPmsMenuCollapsed = action.payload;
        },
        setFreshChatShow: (state, action) => {
            state.isFreshChatShow = action.payload;
        },
    },
});

export const { setCurrentLanguage, setPmsMenuCollapsed, setFreshChatShow } =
    appSlice.actions;

export const appSelector = (state: AppState) => state.app;
export const currentLanguageSelector = (state: AppState) => state.app.currentLanguage;
export const isFreshChatShowSelector = (state: AppState) => state.app.isFreshChatShow;

export const appReducer = appSlice.reducer;
