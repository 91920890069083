import {
    Button,
    Empty,
    Form,
    Modal,
    notification,
    Radio,
    Space,
    Table,
    theme,
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { IGuest, IUpdateBookingFormData } from '~features/room-booking/interfaces';
import {
    fetchRoomBookingDetail,
    selectedRoomBookingDetailSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import './RoomBookingAccompanierList.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Gender } from '~features/guest/constants';
import {
    AutoCompleteGuest,
    InputPhoneNumber,
    InputText,
    ModalConfirmDeletion,
    SingleDatePicker,
} from '~components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { guestService } from '~features/guest/services/guest.service';
import { CacheKeys } from '~queries/queries';
import dayjs, { Dayjs } from 'dayjs';
import { IGuestDropDown } from '~features/guest/interfaces';
import { roomBookingService } from '~features/room-booking/services/room-booking.service';
import { generateUpdateBookingData } from '~features/room-booking/util';
import { guestFormDefault } from '~features/room-booking/model';
import { Link } from 'react-router-dom';

const guestDropdownSchema = yup.object({
    id: yup.number().positive().required(),
    yomigana: yup.string().required(),
    fullName: yup.string().nullable(),
    emailAddress: yup.string().email().nullable(),
    mobilePhoneNumber: yup.string().nullable(),
    phoneNumberLandline: yup.string().nullable(),
    birthday: yup.string().nullable(),
    gender: yup.mixed<Gender | null>().oneOf([...Object.values(Gender), null]),
});
type GuestDropDown = yup.InferType<typeof guestDropdownSchema>;

function RoomBookingAccompanierList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const roomBooking = useAppSelector(selectedRoomBookingDetailSelector);

    const [page, setPage] = useState(1);
    const [isAddingGuest, setIsAddingGuest] = useState(false);
    const [editedGuestIdx, setEditedGuestIdx] = useState<undefined | number>();
    const isEditingGuest = editedGuestIdx !== undefined;

    const stringOrGuestDropdownSchema = yup
        .mixed<string | GuestDropDown>()
        .test(
            'string-or-guest',
            t('roomBooking.detail.message.guestNameRequired'),
            (value): boolean => {
                if (typeof value === 'string') return true;
                return guestDropdownSchema.isValidSync(value);
            },
        );

    const guestFormSchema = guestDropdownSchema.shape({
        id: yup.number().positive().nullable(),
        yomigana: stringOrGuestDropdownSchema.required(),
        birthday: yup.mixed<Dayjs>().nullable(),
    });
    type GuestForm = yup.InferType<typeof guestFormSchema>;

    const { handleSubmit, control, setValue, reset, watch } = useForm<GuestForm>({
        resolver: yupResolver(guestFormSchema),
        defaultValues: { ...guestFormDefault, yomigana: undefined },
    });
    const guest = watch('yomigana');
    const id = watch('id');

    const { data, refetch } = useQuery({
        queryKey: [CacheKeys.guestDropdown],
        queryFn: async () => {
            const res = await guestService.getListForDropdown({
                withRoomBooking: false,
                keyword: '',
            });

            return res.data.items;
        },
    });

    const changeGuestYomigana = (input: IGuestDropDown | string) => {
        if (typeof input === 'string') {
            if (!id) return;
            return reset({ ...guestFormDefault, yomigana: input });
        }

        setValue('id', input.id);
        setValue('yomigana', input.yomigana);
        setValue('fullName', input.fullName);
        setValue('emailAddress', input.emailAddress);
        setValue(
            'mobilePhoneNumber',
            input.mobilePhoneNumber || input.phoneNumberLandline,
        );
        setValue('birthday', input.birthday ? parseDate(input.birthday) : null);
        setValue('gender', input.gender);
    };

    const getNotificationKey = (
        data: IUpdateBookingFormData,
        result: 'Success' | 'Error',
    ) => {
        if (!roomBooking?.guests) {
            return t(`roomBooking.detail.message.guestEdit${result}`);
        }
        const prevCount = roomBooking.guests.length - 1;
        const nextCount = data.guests.length;

        if (nextCount > prevCount) {
            return t(`roomBooking.detail.message.guestAdd${result}`);
        } else if (nextCount === prevCount) {
            return t(`roomBooking.detail.message.guestUpdate${result}`);
        } else {
            return t(`roomBooking.detail.message.guestDelete${result}`);
        }
    };

    const { mutate, isPending } = useMutation({
        mutationFn: async (data: IUpdateBookingFormData) => {
            const response = await roomBookingService.updateBooking(
                roomBooking!.id,
                data,
            );
            if (response.success) return response.data;
            throw Error(response.message);
        },
        onSuccess: (_, data) => {
            notification.success({
                message: getNotificationKey(data, 'Success'),
            });
            dispatch(fetchRoomBookingDetail(Number(roomBooking!.id)));
            refetch();
            resetAll();
        },
        onError: (error, data) => {
            notification.error({
                message: getNotificationKey(data, 'Error'),
                description: error.message,
            });
        },
    });

    const onSubmit = (guestInput: GuestForm) => {
        const { id, ...guestForm } = guestInput;
        const data = generateUpdateBookingData(roomBooking!);

        let guest: IGuest;
        if (typeof guestForm.yomigana === 'string') {
            guest = {
                yomigana: guestForm.yomigana,
                fullName: guestForm.fullName || null,
                emailAddress: guestForm.emailAddress || null,
                mobilePhoneNumber: guestForm.mobilePhoneNumber || null,
                birthday: guestForm.birthday ? guestForm.birthday.fmYYYYMMDD() : null,
                gender: guestForm.gender,
            };
        } else {
            guest = { ...guestFormDefault, id };
        }

        if (isAddingGuest) {
            data.guests.push(guest);
        } else {
            data.guests[editedGuestIdx!] = guest;
        }

        mutate(data);
    };

    const onEdit = (guest: IGuest, idx: number) => {
        setEditedGuestIdx(idx);
        reset({
            id: guest.id,
            yomigana: guest.yomigana || '',
            fullName: guest.fullName,
            emailAddress: guest.emailAddress,
            mobilePhoneNumber: guest.mobilePhoneNumber || guest.phoneNumberLandline,
            birthday: guest.birthday ? parseDate(guest.birthday) : null,
            gender: guest.gender,
        });
    };

    const onDelete = (idx: number, yomigana: string) => {
        ModalConfirmDeletion({
            title: t('roomBooking.detail.guestInfo.confirmTitle'),
            description: t('roomBooking.detail.guestInfo.confirmDesc', {
                guestName: yomigana,
            }),
            deletedItems: [],
            onClickButtonDelete: () => {
                const data = generateUpdateBookingData(roomBooking!);
                data.guests.splice(idx, 1);
                mutate(data);
            },
            onClickButtonCancel: () => {
                resetAll();
            },
        });
    };

    const guests = useMemo(() => {
        if (!roomBooking?.guests) return [];
        return roomBooking.guests.filter(
            (guest) => guest.id !== roomBooking.representativeGuest?.id,
        );
    }, [roomBooking]);

    const selectedGuestIds: number[] = useMemo(() => {
        const guestIds =
            roomBooking?.guests
                ?.map((guest) => guest.id)
                .filter((id): id is number => id !== undefined && id !== null) ?? [];
        const representativeGuestId = roomBooking?.representativeGuest?.id;
        return [...guestIds, representativeGuestId].filter(
            (id): id is number => typeof id === 'number',
        );
    }, [roomBooking]);

    const columns: ColumnsType<IGuest> = [
        {
            title: '#',
            render: (guest, _, index) => {
                return (
                    <span>{(page - 1) * DEFAULT_LIMIT_FOR_PAGINATION + index + 1}</span>
                );
            },
            width: '50px',
        },
        {
            title: t('roomBooking.createBooking.label.yomigana'),
            render: (guest: IGuest) => {
                return (
                    <Link
                        to={`/guest/${guest.id}/detail`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {guest.yomigana}
                    </Link>
                );
            },
        },
        {
            title: t('roomBooking.createBooking.label.name'),
            render: (guest: IGuest) => {
                return <span>{guest.fullName}</span>;
            },
        },
        {
            title: t('roomBooking.createBooking.label.gender'),
            render: (guest: IGuest) => {
                return (
                    <span>
                        {guest.gender ? t(`guest.detail.gender.${guest.gender}`) : ''}
                    </span>
                );
            },
        },
        {
            title: t('roomBooking.createBooking.label.birthday'),
            render: (guest: IGuest) => {
                return (
                    <span>
                        {guest.birthday ? parseDate(guest.birthday)?.fmYYYYMMDD('/') : ''}
                    </span>
                );
            },
        },
        {
            title: t('roomBooking.createBooking.label.phone'),
            render: (guest: IGuest) => {
                return (
                    <span>
                        {guest.mobilePhoneNumber || guest.phoneNumberLandline || ''}
                    </span>
                );
            },
        },
        {
            title: t('roomBooking.createBooking.label.emailAddress'),
            render: (guest: IGuest) => {
                return <span>{guest.emailAddress}</span>;
            },
        },
        {
            align: 'end',
            render: (_, record, idx) => (
                <Space>
                    <Button
                        type="text"
                        size="middle"
                        className="action-button"
                        icon={<EditOutlined />}
                        onClick={() => onEdit(record, idx)}
                    />
                    <Button
                        type="text"
                        className="action-button"
                        icon={<DeleteOutlined />}
                        onClick={() => onDelete(idx, record.yomigana || '')}
                    />
                </Space>
            ),
        },
    ];

    const resetAll = () => {
        reset({ ...guestFormDefault, yomigana: '' });
        setIsAddingGuest(false);
        setEditedGuestIdx(undefined);
    };

    const isModalOpen = isAddingGuest || isEditingGuest;

    return (
        <div className="room-booking-accompanier-list">
            <Table
                columns={columns}
                dataSource={guests}
                rowKey="id"
                loading={isPending}
                pagination={
                    guests.length > DEFAULT_LIMIT_FOR_PAGINATION
                        ? {
                              position: ['bottomCenter'],
                              pageSize: DEFAULT_LIMIT_FOR_PAGINATION,
                              onChange(current) {
                                  setPage(current);
                              },
                          }
                        : false
                }
                locale={{
                    emptyText: !isModalOpen && (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            className="mt-24 mb-24"
                            description={t(
                                'roomBooking.detail.detailPagetabs.emptyGuest',
                            )}
                        >
                            <AddGuestButton
                                onClick={() => setIsAddingGuest(!isAddingGuest)}
                            />
                        </Empty>
                    ),
                }}
            />

            {!!guests.length && (
                <AddGuestButton
                    className="mt-12"
                    onClick={() => setIsAddingGuest(!isAddingGuest)}
                />
            )}
            <Modal
                className="room-booking-accompanier-list"
                styles={{
                    content: { padding: 0, marginTop: 0 },
                    header: { padding: '20px 0 0 24px', marginBottom: 12 },
                }}
                title={t('roomBooking.detail.detailPagetabs.addGuest')}
                open={isModalOpen}
                onClose={resetAll}
                onCancel={resetAll}
                footer={null}
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    onFinish={handleSubmit(onSubmit)}
                    style={{
                        borderTop: '1px solid #d8d9d9',
                        padding: '12px 24px 20px 24px',
                    }}
                >
                    <AutoCompleteGuest
                        label={t('roomBooking.createBooking.label.yomigana')}
                        name="yomigana"
                        required
                        allowClear
                        showSearch={true}
                        guestOptions={data || []}
                        onChange={changeGuestYomigana}
                        control={control}
                        disableValues={selectedGuestIds}
                        defaultValue={
                            id || (typeof guest === 'string' ? guest : undefined)
                        }
                        placeholder={t(
                            'facilityBooking.createForm.form.guestYomigana.placeholder',
                        )}
                        onClear={() => {
                            reset({ ...guestFormDefault, yomigana: undefined });
                        }}
                    />
                    <InputText
                        label={t('roomBooking.createBooking.label.name')}
                        allowClear
                        placeholder={t('roomBooking.form.placeholder.name')}
                        name={'fullName'}
                        control={control}
                        disabled={!!id}
                    />
                    <Form.Item label={t('roomBooking.createBooking.label.gender')}>
                        <Controller
                            control={control}
                            name="gender"
                            render={({ field }) => {
                                return (
                                    <Radio.Group
                                        options={Object.values(Gender).map((val) => ({
                                            label: t(`guest.detail.gender.` + val),
                                            value: val,
                                        }))}
                                        disabled={!!id}
                                        {...field}
                                    />
                                );
                            }}
                        />
                    </Form.Item>
                    <SingleDatePicker
                        label={t('roomBooking.createBooking.label.birthday')}
                        control={control}
                        name="birthday"
                        allowClear
                        placeholder={t('roomBooking.form.placeholder.birthday')}
                        disabled={!!id}
                        disabledDate={(current) =>
                            current && current > dayjs().subtract(1, 'day')
                        }
                    />
                    <InputPhoneNumber
                        label={t('roomBooking.createBooking.label.phone')}
                        allowClear
                        placeholder={t('roomBooking.form.placeholder.phone')}
                        name="mobilePhoneNumber"
                        control={control}
                        disabled={!!id}
                    />
                    <InputText
                        label={t('roomBooking.createBooking.label.emailAddress')}
                        allowClear
                        placeholder={t('roomBooking.form.placeholder.email')}
                        name="emailAddress"
                        control={control}
                        disabled={!!id}
                    />
                    <Form.Item className="mb-0 pt-8">
                        <div className="d-flex" style={{ gap: '16px' }}>
                            <Button onClick={resetAll} style={{ flex: 1 }}>
                                {t('common.buttonCancelText')}
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isPending}
                                style={{ flex: 1 }}
                            >
                                {t('common.buttonSaveText')}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

const AddGuestButton = ({
    onClick,
    className,
}: {
    onClick: () => void;
    className?: string;
}) => {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Button
            type="text"
            onClick={onClick}
            className={className}
            style={{
                fontWeight: 500,
                color: token.colorPrimary,
                padding: '12px 8px',
            }}
            icon={
                <div
                    style={{
                        backgroundColor: token.colorPrimary,
                        padding: 4,
                        borderRadius: 4,
                    }}
                >
                    <PlusOutlined style={{ color: 'white' }} />
                </div>
            }
        >
            {t('roomBooking.detail.detailPagetabs.addGuest')}
        </Button>
    );
};

export default RoomBookingAccompanierList;
