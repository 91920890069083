import { DownOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, notification, Popover, Row, Switch } from 'antd';
import classNames from 'classnames';
import _, { debounce, intersectionBy } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RoomBooking } from 'src/types/v2/room-booking/room-booking.type';
import { checkUserPermission, compareFormData } from '~common/commonFunctions';
import { ErrorMessageType, ScheduleViewMode, UserGroup } from '~common/constants';
import { IBodyResponseTsRest } from '~common/interfaces';
import { showConfirm } from '~common/notification';
import {
    useEscape,
    usePrevious,
    useRoomOptions,
    useSelectedHotel,
} from '~common/useHooks';
import {
    InputText,
    RangePicker,
    SingleDatePicker,
    SingleSelect,
    TimeRangePickerCustom,
} from '~components';
import { childrenStateSelector } from '~features/children-type/reducers/children.reducer';
import { PlanType } from '~features/plan/constants';
import { planDropdownActiveSelector } from '~features/plan/reducers/plan.reducer';
import { ChildrenInput } from '~features/room-booking/components/ChildrenInput/ChildrenInput';
import InputAdult from '~features/room-booking/components/InputAdult/InputAdult';
import {
    CreateFacilityBookingField,
    DEFAULT_TIME_END_OF_DAY,
    DEFAULT_TIME_START_OF_DAY,
    InputAdultFieldName,
} from '~features/room-booking/constants';
import {
    getChildrenObject,
    getCreateBookingFormId,
    validateGenderBreakdownOfGuest,
    validateStartDateInAfterTwoDaysBefore,
    validateStayingDate,
} from '~features/room-booking/helper';
import {
    IChildBooking,
    ICreateOrUpdateBookingTmp,
    ICreateRoomBookingForm,
    IGetBookingPrice,
    IRoomBookingSchedule,
} from '~features/room-booking/interfaces';
import { ICreateGroupTempRoomBooking } from '~features/room-booking/interfaces/temp-room-booking/create-temp-room-booking.interface';
import { IUpdateSingleTempRoomBooking } from '~features/room-booking/interfaces/temp-room-booking/update-temp-room-booking.interface';
import { OverBookingException } from '~features/room-booking/pages/GroupBookingPage/errors/OverBooking.exception';
import { TempRoomBookingHelper } from '~features/room-booking/pages/GroupBookingPage/helpers/TempRoomBooking/temp-room-booking.helper';
import useCreateGroupTempRoomBooking from '~features/room-booking/pages/GroupBookingPage/hooks/TempRoomBooking/useCreateGroupTempRoomBooking';
import useUpdateSingleTempRoomBooking from '~features/room-booking/pages/GroupBookingPage/hooks/TempRoomBooking/useUpdateSingleTempRoomBooking';
import {
    createBookingStateSelector,
    getBookingPrice,
} from '~features/room-booking/reducers/create-booking.reducer';
import {
    createBookingItem,
    fetchRoomBookingStatisticByDate,
    getStatisticByDateV2,
    scheduleStateSelector,
    setVisibleCreateBookingPopover,
    updateBookingItem,
    updateBookingItemAndTmp,
} from '~features/room-booking/reducers/schedule.reducer';
import { bookingSchemaResolver } from '~features/room-booking/schema';
import { IRoomGetListDropDownQuery } from '~features/room/interfaces';
import {
    getListForDropDown,
    roomDropDownListSelector,
} from '~features/room/room.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate, parseTime } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import './CreateModal.scss';

type Props = {
    close: () => void;
    booking: IRoomBookingSchedule;
    usePopover: boolean;
    onSubmit?: (booking: ICreateRoomBookingForm) => void;
    isLoading?: boolean;
};

type IForm = {
    time?: [Dayjs, Dayjs];
    children?: (string | number)[];
    childrenCount?: string;
    stayingDate?: [Dayjs, Dayjs];
    dateOfStay?: Dayjs;
    plan?: number | null;
    numberOfAdults?: number | null;
    roomId?: number | null;
    roomTypeId?: number | null;
    isDayUse?: boolean;
    standardCapacity?: number;
    numberOfMale?: number | null;
    numberOfFemale?: number | null;
    numberOfOtherGenderGuest?: number | null;
};

export const CreateModal = ({
    close,
    booking,
    usePopover,
    onSubmit,
    isLoading,
}: Props) => {
    const dispatch = useAppDispatch();
    const isAdmin = useMemo(() => {
        return checkUserPermission([UserGroup.ADMIN]);
    }, []);
    const { children } = useAppSelector(childrenStateSelector);
    const { handleSubmit, control, setValue, reset, setError, getValues } =
        useForm<IForm>({
            resolver: bookingSchemaResolver,
        });
    const {
        roomTypesDropdown,
        isCreatingBookingItem,
        currentBookingEditing,
        visibleCreateBookingPopover,
        currentViewMode,
    } = useAppSelector(scheduleStateSelector);
    const { isCheckingPlan } = useAppSelector(createBookingStateSelector);
    const plansOptions = useAppSelector(planDropdownActiveSelector);
    const { t } = useTranslation();
    const roomDropDownList = useAppSelector(roomDropDownListSelector);
    const { mutateAsync: mutateAsyncCreate, isPending: isPendingCreateTempBooking } =
        useCreateGroupTempRoomBooking();
    const { mutateAsync: mutateAsyncUpdate, isPending: isPendingUpdateTempBooking } =
        useUpdateSingleTempRoomBooking();
    const selectedHotel = useSelectedHotel();

    const makeFormData = () => {
        const { plan, roomTypeId, roomId, numberOfAdults, stayingDate, time } =
            getValues();

        return {
            plan: plan || null,
            roomTypeId: roomTypeId || null,
            roomId: roomId || null,
            numberOfAdults: Number(numberOfAdults),
            stayingStartDate: stayingDate
                ? parseDate(stayingDate[0])?.fmYYYYMMDD()
                : null,
            stayingEndDate: stayingDate ? parseDate(stayingDate[1])?.fmYYYYMMDD() : null,
            checkInTime: dayUse && time ? parseDate(time[0])?.fmHHmm() : null,
            checkOutTime: dayUse && time ? parseDate(time[1])?.fmHHmm() : null,
            isDayUse: dayUse,
            children: childrenList || null,
        };
    };

    const prevFormData = useRef<Record<string, unknown>>({});

    const showConfirmDialog = () => {
        // compare Form Data
        const result: (string | number)[] = [];
        if (booking.children?.length) {
            booking.children?.forEach((item, index) => {
                result[index] = item.quantity;
            });
        }

        if (compareFormData(prevFormData.current, makeFormData())) {
            onClose();
            return;
        }
        showConfirm({
            title: t('roomBooking.form.popupCancel.cancelTitle'),
            cancelText: t('roomBooking.form.popupCancel.buttonCancelText'),
            okText: t('roomBooking.form.popupCancel.buttonDeleteText'),
            zIndex: 1200,
            onOk() {
                onClose();
            },
        });
    };

    const showConfirmOverbookingDialog = (bookingPayload: IRoomBookingSchedule) => {
        showConfirm({
            title: t('roomBooking.form.overbooking.title'),
            cancelText: t('roomBooking.form.popupCancel.buttonCancelText'),
            okText: t('roomBooking.form.popupCancel.buttonDeleteText'),
            async onOk() {
                _createBookingItem(bookingPayload, true);
            },
        });
    };

    const showConfirmOverbookingDialogV2 = (
        _booking: IRoomBookingSchedule,
        tempRoomBookings: ICreateGroupTempRoomBooking,
    ) => {
        showConfirm({
            title: t('roomBooking.form.overbooking.title'),
            cancelText: t('roomBooking.form.popupCancel.buttonCancelText'),
            okText: t('roomBooking.form.popupCancel.buttonDeleteText'),
            async onOk() {
                _createBookingItemV2(_booking, tempRoomBookings, true);
            },
        });
    };

    const previousVisibleCreateBookingPopover = usePrevious<boolean>(
        visibleCreateBookingPopover,
    );

    useEffect(() => {
        if (previousVisibleCreateBookingPopover && !visibleCreateBookingPopover) {
            onClose();
        }
    }, [visibleCreateBookingPopover]);

    const onClose = () => {
        reset();
        setCurrentRoomType(null);
        close();
        dispatch(setVisibleCreateBookingPopover(false));
    };
    useEscape(onClose);

    const [isOpen, setIsOpen] = React.useState(false);
    const [dayUse, setDayUse] = React.useState(booking.isDayUse);
    const [childrenList, setChildrenList] = React.useState<Record<string, number>>();
    const [currentRoomType, setCurrentRoomType] = React.useState<number | null>(
        (booking.roomType.id || booking.roomTypeId) as number,
    );
    const [currentPlan, setCurrentPlan] = React.useState<number | null>(
        booking.plan.id || (booking.planId as number),
    );

    const _createBookingItem = async (
        booking: IRoomBookingSchedule,
        isAcceptOverbooking = false,
    ) => {
        const response = await dispatch(
            createBookingItem({ booking, isAcceptOverbooking }),
        );
        if (createBookingItem.fulfilled.match(response)) {
            if (response.payload?.success) {
                if (currentViewMode === ScheduleViewMode.MONTH) {
                    dispatch(fetchRoomBookingStatisticByDate());
                }
                _updateBookingList(response.payload.data, booking);
                onClose();
                return;
            }

            if (
                response.payload?.message ===
                    t('roomBooking.form.overbooking.warningResponse') ||
                response.payload?.message ===
                    t('roomBooking.form.overbooking.warningTLLResponse')
            ) {
                showConfirmOverbookingDialog(booking);
                if (dayUse) {
                    setError(
                        CreateFacilityBookingField.DATE_OF_STAY,
                        {
                            type: ErrorMessageType.WARNING,
                            message: response.payload?.message,
                        },
                        { shouldFocus: true },
                    );
                } else {
                    setError(
                        CreateFacilityBookingField.STAYING_DATE,
                        {
                            type: ErrorMessageType.WARNING,
                            message: response.payload?.message,
                        },
                        { shouldFocus: true },
                    );
                }
                return;
            }

            notification.error({
                message: t('common.somethingWentWrong'),
                description:
                    response.payload.errors?.[0]?.message ||
                    response.payload?.message ||
                    '',
            });

            (response.payload?.errors || []).forEach((error) => {
                if (
                    error.key === CreateFacilityBookingField.CHECK_IN_TIME ||
                    error.key === CreateFacilityBookingField.CHECK_OUT_TIME
                ) {
                    setError(
                        CreateFacilityBookingField.TIMES,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                }
                if (
                    error.key === CreateFacilityBookingField.START_DATE_OF_STAY ||
                    error.key === CreateFacilityBookingField.END_DATE_OF_STAY ||
                    error.key === CreateFacilityBookingField.DATE_OF_STAY
                ) {
                    setError(
                        CreateFacilityBookingField.STAYING_DATE,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                }
                setError(
                    error.key as keyof IForm,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
    };

    const _createBookingItemV2 = async (
        _booking: IRoomBookingSchedule,
        tempRoomBookings: ICreateGroupTempRoomBooking,
        isAcceptOverbooking = false,
    ) => {
        try {
            const data = await mutateAsyncCreate({
                tempRoomBookings,
                isAcceptOverbooking,
            });
            if (currentViewMode === ScheduleViewMode.MONTH) {
                dispatch(fetchRoomBookingStatisticByDate());
            }
            const updatedBookingData = TempRoomBookingHelper.buildUpdateBookingData(
                data[0],
            );
            dispatch(getStatisticByDateV2());
            _updateBookingList(updatedBookingData, _booking);
            onClose();
        } catch (error) {
            if (error instanceof OverBookingException) {
                showConfirmOverbookingDialogV2(_booking, tempRoomBookings);
            } else {
                notification.error({
                    message: (error as IBodyResponseTsRest<RoomBooking>).message,
                });
            }
        }
    };

    const _updateBookingList = (
        data: ICreateOrUpdateBookingTmp,
        _booking: IRoomBookingSchedule,
    ) => {
        const { id, roomBookingItemChildrenTypes } = data;
        const bookingChildren = _booking.children.map((child) => {
            const childId =
                roomBookingItemChildrenTypes?.find(
                    (childAmount) => childAmount.childrenTypeId === child.typeId,
                )?.id || null;
            return {
                ...child,
                id: childId,
            };
        });
        const selectedPlan = planDropDownOptions.find(
            (plan) => plan.value === _booking.plan?.id,
        );
        if (
            !_booking.isDayUse &&
            selectedPlan?.checkInTime &&
            selectedPlan?.checkOutTime
        ) {
            _booking.checkInTime = selectedPlan.checkInTime;
            _booking.checkOutTime = selectedPlan.checkOutTime;
        }

        dispatch(
            updateBookingItemAndTmp({
                bookings: [{ ..._booking, id, children: bookingChildren }],
            }),
        );
    };

    const _updateBookingItemV2 = async (
        booking: IRoomBookingSchedule,
        updateTempRoomBookingBody: IUpdateSingleTempRoomBooking,
    ) => {
        try {
            const data = await mutateAsyncUpdate({
                tempRoomBooking: updateTempRoomBookingBody,
                isAcceptOverbooking: false,
            });
            if (currentViewMode === ScheduleViewMode.MONTH) {
                dispatch(fetchRoomBookingStatisticByDate());
            }
            const updatedBookingData = TempRoomBookingHelper.buildUpdateBookingData(data);
            _updateBookingList(updatedBookingData, booking);
            dispatch(getStatisticByDateV2());
            onClose();
        } catch (error) {
            notification.error({
                message: (error as IBodyResponseTsRest<RoomBooking>).message,
            });
        }
    };

    const _updateBookingItem = async (booking: IRoomBookingSchedule) => {
        const response = await dispatch(updateBookingItem({ booking }));

        if (updateBookingItem.fulfilled.match(response)) {
            if (response.payload?.success) {
                if (currentViewMode === ScheduleViewMode.MONTH) {
                    dispatch(fetchRoomBookingStatisticByDate());
                }
                _updateBookingList(
                    response.payload.data as ICreateOrUpdateBookingTmp,
                    booking,
                );
                onClose();
                return;
            }
            notification.error({
                message: t('common.somethingWentWrong'),
                description: response.payload?.errors?.[0]?.message,
            });

            (response.payload?.errors || []).forEach((error) => {
                if (error.key === 'checkOutTime' || error.key === 'checkInTime') {
                    setError(
                        'time',
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                }
                if (
                    error.key === 'startDateOfStay' ||
                    error.key === 'endDateOfStay' ||
                    error.key === 'dateOfStay'
                ) {
                    setError(
                        'stayingDate',
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                }
                setError(
                    error.key as keyof IForm,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
        return;
    };

    const checkNumberOfAdults = (data: IForm) => {
        const selectedRoomType = roomTypesDropdown.find((roomType) => {
            return data.roomTypeId === roomType.id;
        });
        const numberOfAdults = data.numberOfAdults || 0;
        if (Number(numberOfAdults) > (selectedRoomType?.standardCapacity || 0)) {
            return false;
        }
        return true;
    };

    const checkDateTimeOfBooking = (data: IForm) => {
        if (data.stayingDate) {
            const validStartDate = validateStartDateInAfterTwoDaysBefore(
                data.stayingDate,
            );
            if (!validStartDate) {
                setError('stayingDate', {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.startDateError'),
                });
                return false;
            }

            if (!dayUse) {
                const validStayingDate = validateStayingDate(data.stayingDate);
                if (!validStayingDate) {
                    setError('stayingDate', {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.datePeriodError'),
                    });
                    return false;
                }
            }
        }
        return true;
    };

    const submit = async () => {
        handleSubmit(async (data) => {
            // check number of adults
            if (!checkNumberOfAdults(data)) {
                setError(
                    'numberOfAdults',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.exceedPeople'),
                    },
                    { shouldFocus: true },
                );
                return;
            }
            if (
                !validateGenderBreakdownOfGuest({
                    numberOfAdults: data.numberOfAdults?.toString() || '',
                    numberOfMale: data.numberOfMale?.toString() || '',
                    numberOfFemale: data.numberOfFemale?.toString() || '',
                    numberOfOtherGenderGuest:
                        data.numberOfOtherGenderGuest?.toString() || '',
                })
            ) {
                setError(
                    'numberOfAdults',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.genderBreakdownError'),
                    },
                    { shouldFocus: true },
                );
                return;
            }
            // allow only ADMIN users to create with past dates
            if (!isAdmin && !checkDateTimeOfBooking(data)) return;

            // check booking price
            const { isValid: check, amount } = await _getBookingPrice();
            if (!check) {
                setError('plan', {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.planInvalidPrice'),
                });
                return;
            }

            const _children: IChildBooking[] = [];
            _.forEach(data.children, (val, index) => {
                const id =
                    booking?.children?.find(
                        (child) => child.typeId === children[index]?.id,
                    )?.id || null;
                _children.push({
                    id: id,
                    quantity: Number(val),
                    typeId: children[index]?.id,
                });
            });
            const plan = plansOptions.find((item) => item.value === data.plan);
            const _booking: IRoomBookingSchedule = {
                ...booking,
                numberOfAdults: Number(data.numberOfAdults) || null,
                stayingStartDate: dayUse
                    ? data.dateOfStay?.fmYYYYMMDD('-') || ''
                    : data.stayingDate?.[0]?.fmYYYYMMDD('-') || '',
                stayingEndDate: dayUse
                    ? data.dateOfStay?.fmYYYYMMDD('-') || ''
                    : data.stayingDate?.[1]?.fmYYYYMMDD('-') || '',
                checkInTime: dayUse ? data.time?.[0]?.fmHHmm() || null : null,
                checkOutTime: dayUse ? data.time?.[1]?.fmHHmm() || null : null,
                isDayUse: dayUse || false,
                children: _children,
                plan: { id: data.plan || null, name: plan?.label || '' },
                room: {
                    id: data.roomId || null,
                    name:
                        roomDropDownList.find((item) => item.id === data.roomId)?.name ||
                        '',
                },
                roomType: {
                    id: data.roomTypeId || null,
                    name:
                        roomsTypes.find((item) => item.value === data.roomTypeId)
                            ?.label || '',
                },
                numberOfMale: Number(data.numberOfMale) || 0,
                numberOfFemale: Number(data.numberOfFemale) || 0,
                numberOfOtherGenderGuest: Number(data.numberOfOtherGenderGuest) || 0,
                price: amount,
            };
            if (onSubmit) {
                onSubmit(_booking as ICreateRoomBookingForm);
                return;
            }
            if (!_booking.id) {
                if (selectedHotel?.useNewTempRoomBookingLib) {
                    const tempRoomBookings =
                        TempRoomBookingHelper.buildCreateTempRoomBookingBody([_booking]);
                    await _createBookingItemV2(_booking, tempRoomBookings);
                } else {
                    await _createBookingItem(_booking);
                }
            } else {
                if (selectedHotel?.useNewTempRoomBookingLib) {
                    const updateTempRoomBookingBody =
                        TempRoomBookingHelper.buildUpdateSingleTempRoomBookingBody(
                            _booking,
                        );
                    await _updateBookingItemV2(_booking, updateTempRoomBookingBody);
                } else {
                    await _updateBookingItem(_booking);
                }
            }
        })();
    };

    const [roomOptionsEdit, isRoomOptionsEditLoading] = useRoomOptions(
        booking,
        currentRoomType || booking.roomType?.id || booking.roomTypeId || null,
    );

    const roomsOptionsCreate = useMemo(() => {
        const roomTypeId = currentRoomType || booking.roomType?.id || booking.roomTypeId;
        const _rooms =
            roomTypesDropdown?.find((item) => item.id === roomTypeId)?.rooms || [];
        const rooms = intersectionBy(_rooms, roomDropDownList, 'id');
        return rooms.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [roomTypesDropdown, currentRoomType, booking, roomDropDownList]);

    const roomsTypes = useMemo(() => {
        if (currentPlan) {
            const plan = plansOptions.find((option) => {
                return option.value === currentPlan;
            });
            return roomTypesDropdown
                .filter((roomType) => {
                    return plan?.roomTypeIds?.includes(roomType.id);
                })
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
        }

        return roomTypesDropdown.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [currentPlan, roomTypesDropdown, plansOptions]);

    const planDropDownOptions = useMemo(() => {
        const _planOptions = (plansOptions || []).filter((plan) => {
            const hasPlanType = dayUse
                ? plan.type === PlanType.DAY_USE
                : plan.type === PlanType.STAY;
            return hasPlanType;
        });
        if (currentRoomType) {
            return (_planOptions || []).filter((plan) =>
                plan.roomTypeIds?.includes(currentRoomType),
            );
        }
        return _planOptions;
    }, [plansOptions, currentRoomType, dayUse]);

    const fetchRoomDropDown = debounce((query: IRoomGetListDropDownQuery) => {
        dispatch(getListForDropDown(query));
    }, 500);

    useEffect(() => {
        reset({
            plan: booking.plan?.id || booking.planId,
            roomTypeId: booking.roomType?.id || booking.roomTypeId,
            roomId: booking.room?.id || booking.roomId,
            standardCapacity: 0,
            numberOfAdults: booking.numberOfAdults || null,
            numberOfMale: booking.numberOfMale || null,
            numberOfFemale: booking.numberOfFemale || null,
            numberOfOtherGenderGuest: booking.numberOfOtherGenderGuest || null,
            stayingDate: [
                booking.stayingStartDate
                    ? parseDate(booking.stayingStartDate)
                    : undefined,
                booking.stayingEndDate ? parseDate(booking.stayingEndDate) : undefined,
            ],
            time:
                booking.checkInTime && booking.checkOutTime
                    ? [parseTime(booking.checkInTime), parseTime(booking.checkOutTime)]
                    : undefined,
        });

        const result: (string | number)[] = [];
        if (booking.children?.length) {
            booking.children?.forEach((item, index) => {
                result[index] = item.quantity;
            });
        }
        setDayUse(booking.isDayUse);
        prevFormData.current = makeFormData();
        let roomBookingStayPeriod = [
            `${booking.stayingStartDate} ${parseTime(booking.checkInTime).fmHHmmss()}`,
            `${booking.stayingEndDate} ${parseTime(booking.checkOutTime).fmHHmmss()}`,
        ];
        if (!booking.id) {
            roomBookingStayPeriod = [
                parseDate(booking.stayingStartDate).startOf('day').fmYYYYMMDDHHmmss(),
                parseDate(booking.stayingEndDate).endOf('day').fmYYYYMMDDHHmmss(),
            ];
        }
        fetchRoomDropDown({
            roomBookingItemId: Number(booking.id) || undefined,
            roomBookingStayPeriod,
            isDayUse: !!booking.isDayUse,
        });
    }, []);

    useEffect(() => {
        const selectedRoomType = roomTypesDropdown.find(
            (roomType) => roomType.id === currentRoomType,
        );
        setValue('standardCapacity', selectedRoomType?.standardCapacity || 0);
    }, [roomTypesDropdown, currentRoomType, setValue, booking]);

    const _getBookingPrice = async () => {
        const roomTypeId = getValues('roomTypeId');
        const planId = getValues('plan');
        const numberOfAdults = getValues('numberOfAdults');
        const [start, end] = getValues('stayingDate') as [Dayjs, Dayjs];
        if (!roomTypeId || !planId || !numberOfAdults || !start || !end)
            return { isValid: false };

        const query: IGetBookingPrice = {
            roomTypeId: roomTypeId,
            startDateOfStay: start.fmYYYYMMDD(),
            endDateOfStay: end.fmYYYYMMDD(),
            numberOfAdults: Number(numberOfAdults),
            planId: planId,
            childrenTypeQuantities: children.map((item) => ({
                childrenTypeId: item.id,
                quantity: childrenList?.['id_' + item.id] || 0,
            })),
        };
        const response = await dispatch(getBookingPrice(query));
        if (getBookingPrice.fulfilled.match(response)) {
            const { data } = response.payload;
            if (!data) {
                notification.error({
                    message:
                        response?.payload?.message ||
                        t('roomBooking.createBooking.message.calculateAmountError'),
                });
                return { isValid: false };
            }
            return { isValid: true, amount: data.amount || 0 };
        }
        return { isValid: true, amount: 0 };
    };

    const setStayingDateWhenChangeDayUse = (isDayUse: boolean) => {
        // set check-in and check-out time for form
        if (!booking.checkInTime || !booking.checkOutTime) return;
        setValue('time', [
            parseTime(booking.checkInTime),
            parseTime(booking.checkOutTime),
        ]);

        // set staying date when change day use mode
        if (!booking.stayingStartDate || !booking.stayingEndDate) return;

        if (!isDayUse) {
            const selectedDate = getValues('dateOfStay');
            setValue('stayingDate', [
                parseDate(selectedDate),
                parseDate(selectedDate).add(1, 'day'),
            ]);
        } else {
            const selectedDate = getValues('stayingDate');
            setValue('dateOfStay', parseDate(selectedDate?.[0]));
        }
    };

    const onChangeDayUse = () => {
        const newValue = !dayUse;
        setDayUse(newValue);
        setValue('isDayUse', newValue);
        setStayingDateWhenChangeDayUse(newValue);
        const { plan: planId, stayingDate, time } = getValues();
        if (!stayingDate) {
            fetchRoomDropDown({
                roomBookingItemId: Number(booking.id) || undefined,
                isDayUse: newValue,
            });
            return;
        }
        const plan = plansOptions.find((item) => item.value === planId);
        if (
            (newValue && plan?.type !== PlanType.DAY_USE) ||
            (!newValue && plan?.type !== PlanType.STAY)
        ) {
            setValue('plan', null);
        }
        const query: IRoomGetListDropDownQuery = {
            roomBookingItemId: Number(booking.id) || undefined,
            isDayUse: newValue,
            roomBookingStayPeriod: [
                parseDate(stayingDate?.[0]).startOf('day').fmYYYYMMDDHHmmss(),
                parseDate(stayingDate?.[1]).endOf('day').fmYYYYMMDDHHmmss(),
            ],
        };
        if (newValue) {
            query.roomBookingStayPeriod = [
                `${parseDate(stayingDate?.[0]).fmYYYYMMDD()} ${
                    time?.[0]?.fmHHmmss() || DEFAULT_TIME_START_OF_DAY
                }`,
                `${parseDate(stayingDate?.[1]).fmYYYYMMDD()} ${
                    time?.[1]?.fmHHmmss() || DEFAULT_TIME_END_OF_DAY
                }`,
            ];
        }
        fetchRoomDropDown(query);
    };

    const changeStayingDate = (values: unknown, formatString: [string, string]) => {
        if (!values) {
            fetchRoomDropDown({
                roomBookingItemId: Number(booking.id) || undefined,
                isDayUse: dayUse,
            });
            return;
        }
        if (getValues('plan')) {
            // fetch room drop down by check in/out time of plan
            const planId = getValues('plan');
            const selectedPlan = planDropDownOptions.find(
                (plan) => plan.value === planId,
            );
            fetchRoomDropDown({
                roomBookingItemId: Number(booking.id) || undefined,
                roomBookingStayPeriod: [
                    `${formatString[0]} ${parseTime(
                        selectedPlan?.checkInTime,
                    ).fmHHmmss()}`,
                    `${formatString[1]} ${parseTime(
                        selectedPlan?.checkOutTime,
                    ).fmHHmmss()}`,
                ],
                isDayUse: dayUse,
            });
            return;
        }
        // fetch room drop down by check in/out time of selected booking
        fetchRoomDropDown({
            roomBookingItemId: Number(booking.id) || undefined,
            roomBookingStayPeriod: [
                parseDate(formatString[0]).startOf('day').fmYYYYMMDDHHmmss(),
                parseDate(formatString[1]).endOf('day').fmYYYYMMDDHHmmss(),
            ],
            isDayUse: dayUse,
        });
    };

    const changeStayingDateDayUse = (values: Dayjs, formatString: string) => {
        // fetch room drop down by time picker
        if (!values) {
            fetchRoomDropDown({
                roomBookingItemId: Number(booking.id) || undefined,
                isDayUse: dayUse,
            });
            return;
        }
        const { time } = getValues();
        fetchRoomDropDown({
            roomBookingItemId: Number(booking.id) || undefined,
            roomBookingStayPeriod: [
                `${formatString} ${time?.[0]?.fmHHmmss() || DEFAULT_TIME_START_OF_DAY}`,
                `${formatString} ${time?.[1]?.fmHHmmss() || DEFAULT_TIME_END_OF_DAY}`,
            ],
            isDayUse: dayUse,
        });
    };

    const changeBookingTime = (formatString: Dayjs[] | null) => {
        const dateOfStaying = getValues(CreateFacilityBookingField.STAYING_DATE);
        if (!dateOfStaying) {
            fetchRoomDropDown({
                roomBookingItemId: Number(booking.id) || undefined,
                isDayUse: dayUse,
            });
            return;
        }
        fetchRoomDropDown({
            roomBookingItemId: Number(booking.id) || undefined,
            roomBookingStayPeriod: [
                `${parseDate(dateOfStaying[0]).fmYYYYMMDD()} ${
                    formatString?.[0] ? formatString?.[0]?.fmHHmmss() : ''
                }`,
                `${parseDate(dateOfStaying[1]).fmYYYYMMDD()} ${
                    formatString?.[1] ? formatString?.[1]?.fmHHmmss() : ''
                }`,
            ],
            isDayUse: dayUse,
        });
    };

    const onChangePlan = (value: number | null) => {
        setCurrentPlan(value);
        const { stayingDate, time } = getValues();
        const selectedPlan = planDropDownOptions.find((plan) => plan.value === value);
        if (!selectedPlan) return;
        if (!stayingDate) {
            fetchRoomDropDown({
                roomBookingItemId: Number(booking.id) || undefined,
                isDayUse: dayUse,
            });
            return;
        }
        const query = {
            roomBookingItemId: Number(booking.id) || undefined,
            roomBookingStayPeriod: [
                `${parseDate(stayingDate?.[0]).fmYYYYMMDD()} ${
                    parseTime(selectedPlan?.checkInTime).fmHHmmss() ||
                    DEFAULT_TIME_START_OF_DAY
                }`,
                `${parseDate(stayingDate?.[1]).fmYYYYMMDD()} ${
                    parseTime(selectedPlan?.checkOutTime).fmHHmmss() ||
                    DEFAULT_TIME_END_OF_DAY
                }`,
            ],
            isDayUse: dayUse,
        };
        if (dayUse) {
            query.roomBookingStayPeriod = [
                `${parseDate(stayingDate?.[0]).fmYYYYMMDD()} ${
                    time?.[0]?.fmHHmmss() || DEFAULT_TIME_START_OF_DAY
                }`,
                `${parseDate(stayingDate?.[1]).fmYYYYMMDD()} ${
                    time?.[1]?.fmHHmmss() || DEFAULT_TIME_END_OF_DAY
                }`,
            ];
        }

        fetchRoomDropDown(query);
    };

    const disableDate = (current: Dayjs) => {
        // Allow ADMIN user to select any dates
        if (isAdmin) return false;

        return current.isBefore(customDayjs().subtract(2, 'day'), 'day');
    };

    const renderContent = () => {
        return (
            <Card
                title={
                    !booking.id
                        ? t('roomBooking.form.scheduleBooking.createTitle')
                        : t('roomBooking.form.scheduleBooking.updateTitle')
                }
                className="create-booking-card"
            >
                <div className="booking-card-content">
                    <Form layout="vertical">
                        <Row>
                            <Col span={24}>
                                <SingleSelect
                                    label={t('roomBooking.form.scheduleBooking.plan')}
                                    placeholder={t(
                                        'roomBooking.form.placeholder.planName',
                                    )}
                                    name="plan"
                                    control={control}
                                    required
                                    allowClear
                                    defaultValue={booking?.plan?.id || booking?.planId}
                                    options={planDropDownOptions}
                                    onChange={(value) => {
                                        onChangePlan(value);
                                    }}
                                    id={getCreateBookingFormId('plan')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <SingleSelect
                                    label={t('roomBooking.form.scheduleBooking.roomType')}
                                    placeholder={t(
                                        'roomBooking.form.placeholder.roomType',
                                    )}
                                    name="roomTypeId"
                                    id={getCreateBookingFormId('roomTypeId')}
                                    control={control}
                                    required
                                    allowClear
                                    defaultValue={booking?.roomType?.id || ''}
                                    options={roomsTypes}
                                    disabled={usePopover}
                                    onChange={(value) => {
                                        setCurrentRoomType(value);
                                        setValue('roomId', null);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <SingleSelect
                                    label={t('roomBooking.form.scheduleBooking.roomName')}
                                    placeholder={t(
                                        'roomBooking.form.placeholder.roomName',
                                    )}
                                    name="roomId"
                                    id={getCreateBookingFormId('roomId')}
                                    control={control}
                                    disabled={usePopover}
                                    defaultValue={booking?.room?.id || ''}
                                    options={
                                        currentBookingEditing
                                            ? roomOptionsEdit
                                            : roomsOptionsCreate
                                    }
                                    loading={
                                        currentBookingEditing
                                            ? isRoomOptionsEditLoading
                                            : false
                                    }
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col span={12}>
                                <InputAdult
                                    label={t('roomBooking.form.scheduleBooking.adult')}
                                    control={control}
                                    setValue={setValue}
                                    totalFieldId={getCreateBookingFormId(
                                        'numberOfAdults',
                                    )}
                                    required={true}
                                    fieldName={InputAdultFieldName}
                                    autoSetTotalGuest={true}
                                />
                            </Col>
                            <Col span={12}>
                                <InputText
                                    label={t('roomBooking.form.scheduleBooking.child')}
                                    placeholder={t('roomBooking.form.placeholder.adults')}
                                    name="childrenCount"
                                    control={control}
                                    defaultValue={
                                        booking.children?.length
                                            ? _.sumBy(
                                                  booking.children,
                                                  (child) => child.quantity,
                                              )
                                            : undefined
                                    }
                                    id={getCreateBookingFormId('childrenCount')}
                                    itemClassName="children-count"
                                />
                                <div
                                    className="input-overlay"
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    <DownOutlined
                                        className={classNames({
                                            'expand-icon': true,
                                            active: isOpen,
                                            deactive: !isOpen,
                                        })}
                                    />
                                </div>
                                <ChildrenInput
                                    isOpen={isOpen}
                                    control={control}
                                    name={(index) => `children.${index}`}
                                    defaultValue={getChildrenObject(booking.children)}
                                    onChange={(value, total) => {
                                        setValue('childrenCount', total.toString());
                                        setChildrenList(value);
                                    }}
                                    className="booking-form-children-input"
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={18}>
                                {dayUse ? (
                                    <>
                                        <SingleDatePicker
                                            required
                                            label={`${t(
                                                'roomBooking.form.scheduleBooking.lengthOfStay',
                                            )} (${t('common.standardTimeTitle')})`}
                                            placeholder={t(
                                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.start',
                                            )}
                                            name={CreateFacilityBookingField.DATE_OF_STAY}
                                            defaultValue={parseDate(
                                                booking.stayingStartDate,
                                            )}
                                            control={
                                                control as unknown as Control<FieldValues>
                                            }
                                            disabledDate={disableDate}
                                            onChange={changeStayingDateDayUse}
                                        />
                                        <TimeRangePickerCustom
                                            placeholder={[
                                                t(
                                                    'roomBooking.form.placeholder.checkInTime',
                                                ),
                                                t(
                                                    'roomBooking.form.placeholder.checkOutTime',
                                                ),
                                            ]}
                                            labelStart=""
                                            labelEnd=""
                                            useDayjs={true}
                                            name={CreateFacilityBookingField.TIMES}
                                            control={control}
                                            onChange={changeBookingTime}
                                        />
                                    </>
                                ) : (
                                    <RangePicker
                                        required
                                        label={`${t(
                                            'roomBooking.form.scheduleBooking.lengthOfStay',
                                        )} (${t('common.standardTimeTitle')})`}
                                        placeholder={[
                                            t(
                                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.start',
                                            ),
                                            t(
                                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.end',
                                            ),
                                        ]}
                                        name={CreateFacilityBookingField.STAYING_DATE}
                                        defaultValue={[
                                            parseDate(booking.stayingStartDate),
                                            parseDate(booking.stayingEndDate),
                                        ]}
                                        control={control}
                                        disabledDate={disableDate}
                                        onChange={changeStayingDate}
                                    />
                                )}
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={t('roomBooking.form.scheduleBooking.dayUse')}
                                >
                                    <Switch checked={dayUse} onChange={onChangeDayUse} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="booking-card-footer">
                    <Button onClick={showConfirmDialog}>
                        {t('common.buttonCancelText')}
                    </Button>
                    <Button
                        onClick={submit}
                        className="btn-submit"
                        type="primary"
                        loading={
                            isCreatingBookingItem ||
                            isCheckingPlan ||
                            isLoading ||
                            isPendingCreateTempBooking ||
                            isPendingUpdateTempBooking
                        }
                        id={getCreateBookingFormId('submit')}
                    >
                        {booking.id
                            ? t('roomBooking.form.scheduleBooking.caveButton')
                            : t('roomBooking.form.scheduleBooking.submitButton')}
                    </Button>
                </div>
            </Card>
        );
    };
    if (!usePopover) {
        return renderContent();
    }
    return (
        <Popover
            content={renderContent()}
            trigger="click"
            open={visibleCreateBookingPopover}
            overlayClassName="create-booking-modal"
            placement="left"
        ></Popover>
    );
};

CreateModal.defaultProps = {
    usePopover: true,
};
