import { QueryClient } from '@tanstack/react-query';

export const CacheKeys = {
    getReportTokyoTaxKey: 'getReportTokyoTaxKey',
    getReportKutchanTaxKey: 'getReportKutchanTaxKey',
    getReportBathTaxKey: 'getReportBathTaxKey',
    getPaymentMethodDropdownKey: 'getPaymentMethodDropdownKey',

    // Reports
    getSalesByRoomTypesKey: 'getSalesByRoomTypesKey',
    getOperationStatusReportKey: 'getOperationStatusReportKey',
    getSalesSummaryReportKey: 'getSalesSummaryReportKey',
    getSalesByPlansReportKey: 'getSalesByPlansReportKey',
    getSalesByOtaReportKey: 'getSalesByOtaReportKey',

    // Dropdowns
    getPlanDropdown: 'getPlanDropdownKey',
    getMarketingChannelDropdown: 'getMarketingChannelDropdownKey',
    guestDropdown: 'guestDropdown',

    hotel: 'hotel',
    receiptFilesCount: 'receiptFilesCount',
    receiptFiles: 'receiptFiles',
    facilitySalesReport: 'facilitySalesReport',
    paymentSummaryReport: 'paymentSummaryReport',
    mealReport: 'mealReport',
    kioskAlmexSetting: 'kioskAlmexSetting',
    getSalesSummaryReceiptDetails: 'getSalesSummaryReceiptDetails',
    getRoomList: 'getRoomList',
    bankAccounts: 'bankAccounts',

    getInventories: 'getInventories',

    // Room key records
    getRoomKeyRecords: 'getRoomKeyRecords',
    roomBookingReceipt: 'roomBookingReceipt',
};

export const MutationKeys = {
    uploadingAccomodationCard: 'uploadingAccomodationCard',

    // temp room booking
    createGroupTempRoomBooking: 'createGroupTempRoomBooking',
    updateSingleTempRoomBooking: 'updateSingleTempRoomBooking',
    deleteSingleTempRoomBooking: 'deleteSingleTempRoomBooking',
    deleteGroupTempRoomBookings: 'deleteGroupTempRoomBookings',

    // booking
    bulkDeleteBooking: 'bulkDeleteBooking',
    deleteBooking: 'deleteBooking',
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: Infinity,
        },
    },
});

export const clearCache = (queryKey: string[], exact = true) => {
    queryClient.removeQueries({ queryKey, exact });
    queryClient.invalidateQueries({ queryKey, exact });
};

export default queryClient;
