import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RightDrawerLayout } from '~components';
import {
    roomFilterSelector,
    scheduleStateSelector,
    setDateHeaderFilterUnassignForm,
    setIsShowUnassignList,
    updateBookingItemAndTmp,
} from '~features/room-booking/reducers/schedule.reducer';
import { BookingUnassignedList } from '~features/room-management/pages/components/BookingUnassignedList/BookingUnassignedList';
import {
    removeBookingFromUnassignList,
    assignBookingToRoom,
    roomManagementSelector,
    setUnassignRoomList,
} from '~features/room-management/reducers/room-management.reducer';
import { getStatisticByDateV2 } from '~features/room-booking/reducers/schedule.reducer';
import { useAppSelector, useAppDispatch } from '~hooks';
import './UnassignListSidebar.scss';
import { notification } from 'antd';
import { planDropdownActiveSelector } from '~features/plan/reducers/plan.reducer';
import { RoomBookingEvent } from '~features/room-booking/constants';
import { useMitt } from '~plugins/mitt';
import FilterUnassignForm from './FilterUnassignForm/FilterUnassignForm';

export const UnassignListSidebar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isShowUnassignList, bookingGroupById, stoppingRoomDates } =
        useAppSelector(scheduleStateSelector);
    const plans = useAppSelector(planDropdownActiveSelector);
    const { isAssignLoading, unassignedList } = useAppSelector(roomManagementSelector);
    const { emitter } = useMitt();

    const onClose = () => {
        dispatch(setIsShowUnassignList(false));
        dispatch(setDateHeaderFilterUnassignForm(null));
        dispatch(setUnassignRoomList([]));
    };
    const roomList = useAppSelector(roomFilterSelector);

    const rooms = useMemo(() => {
        return roomList
            .filter((item) => item.parentId !== null)
            .map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                    parentId: item.parentId as number,
                    disabledDates: stoppingRoomDates['room_' + item.id] || {},
                };
            });
    }, [roomList]);

    const onSelectRoom = async (id: number, bookingId: number) => {
        const booking = bookingGroupById['booking_' + bookingId];
        const room = roomList.find((item) => item.id === id);
        if (!room) return;
        const response = await dispatch(
            assignBookingToRoom([{ id: bookingId, roomId: id }]),
        );
        if (assignBookingToRoom.fulfilled.match(response)) {
            if (response.payload?.success) {
                dispatch(
                    updateBookingItemAndTmp({
                        bookings: [
                            {
                                ...booking,
                                room: {
                                    id: room.id,
                                    name: room.name,
                                },
                                roomId: room.id,
                            },
                        ],
                    }),
                );
                dispatch(removeBookingFromUnassignList({ ids: [bookingId] }));
                dispatch(getStatisticByDateV2());
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const handleAutoAssign = () => {
        emitter.emit(RoomBookingEvent.TOGGLE_AUTO_ASSIGN_MODAL, true);
    };

    if (!unassignedList.length) {
        return (
            <RightDrawerLayout
                open={isShowUnassignList}
                onClose={onClose}
                title={' '}
                mask={false}
                className="room-booking-unassign-list-drawer"
                width={350}
                loading={isAssignLoading}
                isCloseOutside={true}
                footer={<div></div>}
            >
                <FilterUnassignForm />
                <BookingUnassignedList
                    rooms={rooms}
                    onSetRoom={onSelectRoom}
                    draggable={false}
                    plans={plans as any}
                />
            </RightDrawerLayout>
        );
    }
    return (
        <RightDrawerLayout
            open={isShowUnassignList}
            onClose={onClose}
            title={' '}
            mask={false}
            className="room-booking-unassign-list-drawer"
            width={350}
            loading={isAssignLoading}
            isCloseOutside={true}
            onSubmit={handleAutoAssign}
            submitText={t('roomManagement.list.autoAssignment')}
        >
            <FilterUnassignForm />
            <BookingUnassignedList
                rooms={rooms}
                onSetRoom={onSelectRoom}
                draggable={false}
                plans={plans as any}
            />
        </RightDrawerLayout>
    );
};
