import { useMemo } from 'react';
import { DatePicker, DatePickerProps, Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import { Dayjs } from 'dayjs';
import { parseErrorMessage } from '~common/commonFunctions';
import { useAppSelector } from '~hooks';
import { currentLanguageSelector } from '~common/reducers/app.reducer';
import { ErrorMessageType, SupportLanguage } from '~common/constants';
import localeJa from 'antd/es/date-picker/locale/ja_JP';
import localeEn from 'antd/es/date-picker/locale/en_US';
import './SingleDatePicker.scss';

export type ISingleDatePicker = Omit<DatePickerProps, 'onChange'> & {
    name: string;
    label?: string;
    control: any;
    required?: boolean;
    onChange?: (date: Dayjs, dateString: string) => void;
};

export function SingleDatePicker(props: ISingleDatePicker) {
    const { required, control, name, defaultValue, label, onChange, ...rest } = props;

    const currentLang = useAppSelector(currentLanguageSelector);
    const DatePickerLanguage = useMemo(() => {
        if (currentLang === SupportLanguage.EN) return localeEn;
        return localeJa;
    }, [currentLang]);

    return (
        <div className="single-date-picker-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message
                        ? fieldState.error?.type === ErrorMessageType.WARNING
                            ? 'warning'
                            : 'error'
                        : '';
                    return (
                        <Form.Item
                            required={required}
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                        >
                            <DatePicker
                                {...field}
                                locale={DatePickerLanguage}
                                onChange={(date, dateString) => {
                                    if (onChange) {
                                        onChange(
                                            date,
                                            Array.isArray(dateString)
                                                ? dateString[0]
                                                : dateString,
                                        );
                                    }
                                    field.onChange(date);
                                }}
                                {...(rest as DatePickerProps)}
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

SingleDatePicker.defaultProps = {
    defaultValue: null,
};
