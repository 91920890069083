import { axiosInstanceV2 } from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import type {
    IBulkRoomKeyRecords,
    ICreateRoomKeyRecord,
    IRoomKeyRecord,
    IUpdateRoomKeyRecord,
} from '../interfaces';
import type { IBodyResponse } from '~common/interfaces';

class RoomKeyRecordService extends ApiService {
    getRoomKeyRecords(
        roomBookingIds: number[],
    ): Promise<IBodyResponse<IBulkRoomKeyRecords>> {
        const roomBookingIdsStr = roomBookingIds.join(',');
        return this._get(`${this.baseUrl}`, {
            params: { roomBookingIds: roomBookingIdsStr },
        });
    }

    createRecord(record: ICreateRoomKeyRecord): Promise<IBodyResponse<IRoomKeyRecord>> {
        return this._post(`${this.baseUrl}`, record);
    }

    updateRecord({
        id,
        ...rest
    }: IUpdateRoomKeyRecord): Promise<IBodyResponse<IRoomKeyRecord>> {
        return this._patch(`${this.baseUrl}/${id}`, rest);
    }
}

export const roomKeyRecordService = new RoomKeyRecordService(
    { baseUrl: '/room-key-record' },
    axiosInstanceV2,
);
