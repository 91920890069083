export default {
    membersTitle: 'Accompanion information',
    cancel: 'Cancel',
    submit: 'Save',
    representativeRoom: 'Representative room',
    guestSelect: 'Select guest',
    bookingList: 'Booking list',
    autoAssignment: 'Auto-assign',
    label: {
        yomigana: 'Full name (yomigana)',
        name: 'Full name',
        phone: 'Phone number',
        gender: 'Gender',
        birthday: 'Date of Birth',
    },
    sameGuest: '*(same information as guest)',
    memo: 'Memo',
    memoPlaceholder: 'Enter memo',
    file: 'File',
    basicInfo: 'Basic information',
    companionInfo: 'Accompanion information',
    next: 'Next',
    other: 'Other',
    memberGuestEmailDuplicated: 'Email is duplicated',
    update: {
        success: 'Updated successfully',
        error: 'Updated unsuccessfully',
    },
    noAssignedGuest: 'No assigned guest ',
    selectBookingItems: 'Select the bookings to be filled',
    savingAccomodationCardSuccess: 'Accomodation card has been saved',
    savingAccomodationCardError: 'Error saving accommodation card: {{error}}',
    savingAccomodationCard: 'Saving accommodation card',
    enterFullScreen: 'Display on full screen',
    exitFullScreen: 'Exit full screen',
};
