import { axiosInstanceV2 } from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';

class TllXmlServiceV2 extends ApiService {
    importXml(xmlJson: string) {
        return this._post(`${this.baseUrl}/import-travel-xml`, xmlJson, {
            headers: {
                'Content-Type': 'application/xml',
            },
        });
    }
}

export const tllXmlServiceV2 = new TllXmlServiceV2(
    {
        baseUrl: '/tll-xml',
    },
    axiosInstanceV2,
);
