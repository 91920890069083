import { forEach, groupBy, sortBy, sumBy, uniqBy } from 'lodash';
import { utils, writeFile } from 'xlsx';
import { getDateFromDateStringCsv, getFirstNumberFromStringCsv } from '~common/helper';
import { ReceiptItemDetailType } from '~features/room-booking/constants';
import customDayjs, { parseDate } from '~plugins/dayjs';
import i18next from '~plugins/i18next/i18n';
import { AggregationMethod, CurrentGuestAdultsType } from './constants';
import {
    ICurrentGuestSalePayment,
    IMealReport,
    IMealTableItem,
    IPaymentMethod,
    IPaymentSummaryTable,
    IReceiptItemDetail,
    ISalesPayment,
} from './interfaces';
import { notification } from 'antd';

export function convertTimeStringToMinutes(time: string) {
    if (!time) return 0;
    const [hour, minute] = time.split(':');
    return +hour * 60 + +minute;
}

export const reopenNUmberOfGuest = (
    numberOfGuests: Record<string, number | string | boolean>[] = [],
    totalOfGuest: number,
    isChildren?: boolean,
) => {
    if (isChildren) {
        const childrenGuests = numberOfGuests.filter((item) => {
            return item.quantity !== 0;
        });
        if (totalOfGuest > 0) {
            childrenGuests.unshift({
                name: i18next
                    .t('report.currentGuest.guestTable.totalChildren')
                    .toString(),
                quantity: totalOfGuest,
                isBold: true,
            });
        }
        return childrenGuests.map((item) => {
            if (item.isBold) {
                return {
                    name: `${item.name}: ${item.quantity}`,
                    isBold: true,
                };
            }
            return { name: `${item.name}: ${item.quantity}`, isBold: false };
        });
    } else {
        const adultGuest = numberOfGuests.filter((item) => {
            return item.quantity !== 0;
        });
        if (totalOfGuest >= 1 && adultGuest.length > 1) {
            return adultGuest.map((item) => {
                if (item.name === CurrentGuestAdultsType.NUMBER_OF_ADULTS) {
                    return {
                        name:
                            i18next.t(
                                `report.currentGuest.guestTable.guestAultsKey.${item.name}`,
                            ) + item.quantity,
                        isBold: true,
                    };
                }
                return {
                    name:
                        i18next.t(
                            `report.currentGuest.guestTable.guestAultsKey.${item.name}`,
                        ) + item.quantity,
                    isBold: false,
                };
            });
        }
        return adultGuest.map((item) => {
            return {
                name:
                    i18next.t(
                        `report.currentGuest.guestTable.guestAultsKey.${item.name}`,
                    ) + item.quantity,
                isBold: false,
            };
        });
    }
};

export const getCurrentGuestSaleItem = (salesItem: ICurrentGuestSalePayment[]) => {
    const saleItemByDate: Record<string, Record<string, ICurrentGuestSalePayment[]>> = {};
    salesItem.forEach((saleItem: ICurrentGuestSalePayment) => {
        const boughtAt = parseDate(saleItem.date).fmYYYYMMDD();
        if (!saleItemByDate[boughtAt]) {
            saleItemByDate[boughtAt] = {};
        }
        if (saleItem.saleItemId) {
            if (!saleItemByDate[boughtAt][saleItem.saleItemId]) {
                saleItemByDate[boughtAt][saleItem.saleItemId] = [];
            }
            saleItemByDate[boughtAt][saleItem.saleItemId].push(saleItem);
        }
    });
    const saleItems: ICurrentGuestSalePayment[] = [];
    Object.keys(saleItemByDate).forEach((date) => {
        Object.keys(saleItemByDate[date]).forEach((saleItemId: string) => {
            const items = saleItemByDate[date]?.[saleItemId] || [];
            const groutItems = groupBy(items, 'unitPrice');
            Object.keys(groutItems).forEach((unitPrice) => {
                const totalQuantity = sumBy(groutItems[unitPrice], 'quantity');
                saleItems.push({
                    ...groutItems[unitPrice][0],
                    quantity: totalQuantity,
                });
            });
        });
    });
    return saleItems;
};

export const getChildrenTypeTitle = (title: string) => {
    if (title.length > 22) {
        const newTitle = title.slice(0, 22);
        return `${newTitle}...`;
    }
    return title;
};
export function exportCSVReport(tableId: string, fileName: string) {
    /* Create worksheet from HTML DOM TABLE */
    const table = document.getElementById(tableId);
    const wb = utils.table_to_book(table, { raw: true, display: true });
    Object.values(wb.Sheets.Sheet1).forEach(
        (cell: { [key: string]: string | number }) => {
            if (cell) {
                const value = cell.v;
                if (
                    typeof value === 'string' &&
                    customDayjs(value, 'MM/DD/YYYY', true).isValid()
                ) {
                    cell.v = getDateFromDateStringCsv(value);
                }
                if (
                    typeof value === 'string' &&
                    !customDayjs(value, 'MM/DD/YYYY', true).isValid()
                ) {
                    const firstNumber = getFirstNumberFromStringCsv(value);
                    if (firstNumber !== null) {
                        cell.v = firstNumber;
                    }
                }
            }
        },
    );
    /* Export to file (start a download) */
    writeFile(wb, fileName, {
        type: 'string',
        bookType: 'csv',
    });
}

export function exportTableToCSV(tableId: string, fileName: string) {
    const table = document.getElementById(tableId);
    if (!table) {
        notification.error({
            message: 'Error exporting CSV',
            description: 'The table with the specified ID could not be found.',
        });
        return;
    }

    // Remove rows with aria-hidden="true" from antd Table
    const clonedTable = table.cloneNode(true) as HTMLElement;
    const hiddenRows = clonedTable.querySelectorAll('tr[aria-hidden="true"]');
    hiddenRows.forEach((row) => row.parentElement?.removeChild(row));

    const wb = utils.table_to_book(clonedTable, { raw: true, display: true });

    Object.values(wb.Sheets.Sheet1).forEach(
        (cell: { [key: string]: string | number }) => {
            if (cell) {
                const value = cell.v;
                if (value === '-') {
                    cell.v = '';
                }
            }
        },
    );

    writeFile(wb, fileName, {
        type: 'string',
        bookType: 'csv',
    });
}

const initHeader = (selectedDate: string[]) => {
    const headers = [];
    const limitDaysInMonth = parseDate(selectedDate[0]).daysInMonth();
    const listDayInMonth = [];
    const month =
        parseDate(selectedDate[0]).month() + 1 < 10
            ? `0${parseDate(selectedDate[0]).month() + 1}`
            : parseDate(selectedDate[0]).month() + 1;

    for (let d = 1; d <= limitDaysInMonth; d++) listDayInMonth.push(d < 10 ? `0${d}` : d);
    for (let d = 0; d < limitDaysInMonth; d++) {
        headers.push(
            `${parseDate(selectedDate[0]).year()}-${month}-${listDayInMonth[d]}`,
        );
    }

    return headers;
};

export function convertPaymentSummaryReportData(
    data: IPaymentSummaryTable[],
    selectedDate: string[],
    selectedAggregationMethod: string,
) {
    const values: Record<string, Record<string, IPaymentSummaryTable>> = {}; // { paymentMethod: {date: item}}
    const headers: Record<string, boolean> = {};
    const paymentMethods: IPaymentMethod[] = [];
    let totalOfPaymentMethodByMonth: Record<string, number> = {};
    const totalOfPaymentMethodByDay: Record<string, number> = {};
    const totalOfPaymentMethodByMonthInDailyMode: Record<string, number> = {}; // get total values from 1st day to the current day
    const totalOfPaymentMethodByMonthInMonthlyMode: Record<string, number> = {}; // get total values from 1st day to the last day
    let totalValuesOfMonth = 0;

    forEach(data, (item) => {
        paymentMethods.push(item.paymentMethod);
        const paymentMethodId = `payment_method_${item.id}`;
        if (!values[paymentMethodId]) {
            values[paymentMethodId] = {};
        }
        item.date = item?.date || '';
        values[paymentMethodId][item?.date] = item;
        totalOfPaymentMethodByDay[item?.date] =
            Number(totalOfPaymentMethodByDay[item?.date] || 0) +
            Number(item.daylyAccumulative || 0);
        if (
            parseDate(item.date).isSameOrBefore(parseDate(selectedDate[0])) &&
            selectedAggregationMethod === AggregationMethod.DAILY
        ) {
            totalOfPaymentMethodByMonthInDailyMode[paymentMethodId] =
                Number(totalOfPaymentMethodByMonthInDailyMode[paymentMethodId] || 0) +
                Number(item.daylyAccumulative || 0);
        } else {
            totalOfPaymentMethodByMonthInMonthlyMode[paymentMethodId] =
                Number(totalOfPaymentMethodByMonthInMonthlyMode[paymentMethodId] || 0) +
                Number(item.daylyAccumulative || 0);
        }
    });

    if (selectedAggregationMethod === AggregationMethod.MONTH) {
        forEach(initHeader(selectedDate), (date) => {
            headers[date] = true;
        });
        totalOfPaymentMethodByMonth = totalOfPaymentMethodByMonthInMonthlyMode;
    } else {
        headers[selectedDate[0]] = true;
        totalOfPaymentMethodByMonth = totalOfPaymentMethodByMonthInDailyMode;
    }
    Object.values(totalOfPaymentMethodByMonth).forEach((paymentMethodByMonth) => {
        totalValuesOfMonth += Number(paymentMethodByMonth) || 0;
    });

    return {
        values,
        headers: Object.keys(headers).sort(),
        paymentMethods: sortBy(uniqBy(paymentMethods, 'id'), 'name'),
        totalOfPaymentMethodByMonth,
        totalOfPaymentMethodByDay,
        totalValuesOfMonth,
    };
}

const initDayHeader = (selectedDate: string[]) => {
    const headers = [];
    const limitDays =
        parseDate(selectedDate[1]).diff(parseDate(selectedDate[0]), 'day') + 1;

    for (let d = 0; d < limitDays; d++) {
        const day = parseDate(selectedDate[0]).add(d, 'day');
        headers.push(day.fmYYYYMMDD());
    }

    return headers;
};

const createCountersMap = (list: string[]): { [key: string]: number } => {
    return list.reduce((acc, day) => ({ ...acc, [day]: 0 }), {});
};

export function convertMealReportData(data: IMealReport[], selectedDate: string[]) {
    const _tableData: { [key: string]: IMealTableItem } = {};
    const dateHeaders = initDayHeader(selectedDate);
    const totalOfAllMealByDay = createCountersMap(dateHeaders);
    let totalOfAllMealItem = 0;

    const groupedMealItems = groupBy(data, 'saleItemId');
    for (const [saleId, items] of Object.entries(groupedMealItems)) {
        const totalOfMealByDay = createCountersMap(dateHeaders);
        let totalOfMealItem = 0;

        items.forEach((item, index) => {
            const parsedDate = parseDate(item.boughtAt).fmYYYYMMDD();

            totalOfMealItem += item.quantity;
            totalOfMealByDay[parsedDate] += item.quantity;
            totalOfAllMealByDay[parsedDate] += item.quantity;

            const mealId = `meal_${item.saleItemId}_${item.nightOfStay}`;
            if (!_tableData[mealId]) {
                _tableData[mealId] = {
                    id: mealId,
                    name: item.saleItemName,
                    rowSpan: index ? 0 : uniqBy(items, 'nightOfStay').length + 1,
                    nightOfStay: item.nightOfStay,
                    isSummary: false,
                    subtotal: 0,
                    quantityPerDay: createCountersMap(dateHeaders),
                };
            }
            _tableData[mealId].subtotal += item.quantity;
            _tableData[mealId].quantityPerDay[parsedDate] += item.quantity;
        });

        totalOfAllMealItem += totalOfMealItem;

        const mealSummaryId = `meal_${saleId}_summary`;
        _tableData[mealSummaryId] = {
            id: mealSummaryId,
            rowSpan: 0,
            isSummary: true,
            subtotal: totalOfMealItem,
            quantityPerDay: totalOfMealByDay,
        };
    }

    return {
        dateHeaders,
        values: Object.values(_tableData),
        totalOfAllMealItem,
        totalOfAllMealByDay,
    };
}

export const groupReceiptItemDetails = (receiptItemDetails: IReceiptItemDetail[]) => {
    const groupedReceiptItemDetails: IReceiptItemDetail[] = receiptItemDetails.reduce(
        (prev: IReceiptItemDetail[], curr) => {
            const currentReceiptItemIndex = prev.findIndex(
                (prevItem) => prevItem.type === curr.type && curr.type === 'stay_price',
            );
            if (currentReceiptItemIndex !== -1) {
                prev[currentReceiptItemIndex] = {
                    ...prev[currentReceiptItemIndex],
                    amount:
                        Number(prev[currentReceiptItemIndex].amount) +
                        Number(curr.amount),
                };
            } else {
                prev.push(curr);
            }
            return prev;
        },
        [],
    );
    return groupedReceiptItemDetails;
};

export const groupSalesPaymentList = (salesPaymentList: ISalesPayment[]) => {
    const groupedSalesPaymentList: ISalesPayment[] = salesPaymentList.reduce(
        (prev: ISalesPayment[], curr) => {
            const currentSalesPaymentIndex = prev.findIndex(
                (prevItem) => prevItem.autoGeneratedCode === curr.autoGeneratedCode,
            );
            if (currentSalesPaymentIndex !== -1) {
                const concatRoomNames =
                    prev[currentSalesPaymentIndex].roomName === null
                        ? ''
                        : prev[currentSalesPaymentIndex].roomName.concat(
                              ', ',
                              curr.roomName || '',
                          );
                const removeEmptyRoomNames = concatRoomNames.split(', ');
                const joinedReceiptItemDetails = [
                    ...prev[currentSalesPaymentIndex].receiptItemDetails,
                    ...curr.receiptItemDetails,
                ];
                const groupedReceiptItemDetails = groupReceiptItemDetails(
                    joinedReceiptItemDetails,
                );
                prev[currentSalesPaymentIndex] = {
                    ...prev[currentSalesPaymentIndex],
                    roomName: removeEmptyRoomNames
                        .filter((roomName) => Boolean(roomName))
                        .join(', '),
                    receiptItemDetails: groupedReceiptItemDetails,
                    balanceAmount:
                        prev[currentSalesPaymentIndex].balanceAmount + curr.balanceAmount,
                    unpaidAmount:
                        prev[currentSalesPaymentIndex].unpaidAmount + curr.unpaidAmount,
                    totalAmount:
                        Number(prev[currentSalesPaymentIndex].totalAmount) +
                        Number(curr.totalAmount),
                    totalRevenue:
                        Number(prev[currentSalesPaymentIndex].totalRevenue) +
                        Number(curr.totalRevenue),
                };
            } else {
                prev.push(curr);
            }
            return prev;
        },
        [],
    );
    return groupedSalesPaymentList;
};

export const getReceiptDetailSalesName = (receiptItem: IReceiptItemDetail) => {
    let salesName = i18next.t('report.salesPayment.table.sales.adult');
    switch (receiptItem.type) {
        case ReceiptItemDetailType.LOCAL_TAX:
            salesName = i18next.t('report.salesPayment.table.sales.localTax');
            break;
        case ReceiptItemDetailType.BATH_TAX:
            salesName = i18next.t('report.salesPayment.table.sales.bathTax');
            break;
        case ReceiptItemDetailType.STAY_PRICE_CHILDREN:
            salesName = receiptItem.childrenType?.name || receiptItem.planName;
            break;
        case ReceiptItemDetailType.SALE_ITEM:
            salesName = receiptItem.saleItemName || receiptItem.planName;
            break;
        default:
            break;
    }
    return salesName;
};
