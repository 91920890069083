import { useTranslation } from 'react-i18next';
import { InputSearch, RangePicker } from '~components';
import SelectField from './SelectField/SelectField';
import { useDispatch } from 'react-redux';
import './FilterForm.scss';
import { setBookingHistorySearchQuery } from '~features/guest/reducers/guest.reducer';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { useForm } from '~plugins/hook-form';
import { useEffect, useState } from 'react';
import { GuestPageTabPane } from '~features/guest/constants';
import { useSearchParams } from 'react-router-dom';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState('');
    const { control, getValues } = useForm();
    const [params] = useSearchParams();

    useEffect(() => {
        if (params.get('activeTab') === GuestPageTabPane.BOOKING_HISTORY_TAB_PANE) {
            setKeyword('');
        }
    }, [params]);

    const onSearch = (keyword: string) => {
        const checkInTime = getValues().checkInTime;
        const checkOutTime = getValues().checkOutTime;
        dispatch(
            setBookingHistorySearchQuery({
                keyword,
                checkInDateTimePeriod: checkInTime
                    ? [
                          checkInTime[0].startOf('day')?.fmYYYYMMDDHHmmss(),
                          checkInTime[1].endOf('day')?.fmYYYYMMDDHHmmss(),
                      ]
                    : undefined,
                checkOutDateTimePeriod: checkOutTime
                    ? [
                          checkOutTime[0].startOf('day')?.fmYYYYMMDDHHmmss(),
                          checkOutTime[1].endOf('day')?.fmYYYYMMDDHHmmss(),
                      ]
                    : undefined,
            }),
        );
    };

    return (
        <div className="filter-history-list-wrapper">
            <InputSearch
                addonBefore={SelectField()}
                onSearch={onSearch}
                onChange={(e) => {
                    setKeyword(e.target.value);
                }}
                placeholder={t('common.searchPlaceholder')}
                max={INPUT_TEXT_MAX_LENGTH}
                value={keyword}
            />
            <RangePicker
                control={control}
                name="checkInTime"
                label={t(`guest.detail.searchColumn.checkInTime`)}
                hideHelpText={true}
            />
            <RangePicker
                control={control}
                name="checkOutTime"
                label={t(`guest.detail.searchColumn.checkOutTime`)}
                hideHelpText={true}
            />
        </div>
    );
}

export default FilterForm;
