const roomBookingDetail = {
    title: '宿泊予約の明細',
    breadcrumbs: {
        roomBookingDetail: '宿泊予約の明細',
        OtaCancelationRobot: 'OTAキャンセルロボ',
    },
    detailPagetabs: {
        resInfo: '予約詳細',
        receipt: 'レシート',
        keycards: 'カードキー',
        auditLog: '操作履歴',
        representativeGuestInfo: '代表ゲスト情報',
        hotelSmart: 'Hotel Smart',
        attachment: 'ファイル',
        memo: 'メモ',
        memoEmpty: 'メモなし',
        memoOta: 'OTAメモ',
        memoOtaEmpty: 'OTAメモなし',
        accompanier: '同行者',
        OtaCancelationRobot: 'OTAキャンセルロボ',
        cancelationInfo: 'キャンセル情報',
        addGuest: '同行者を追加',
        emptyGuest: '同行者なし',
        editInfo: '情報を編集',
        printGuestCard: '宿泊カードの印刷',
        showGuestCard: '宿泊カードの表示',
    },
    detailPageCards: {
        resId: '予約番号: {{resId}}',
        bookingList: '予約一覧',
    },
    otaCancelation: {
        cancelResId: 'CANCEL RESERVATION ID: {{resId}}',
        AdminSetting: 'OTA 管理パネルの設定 (settings)',
        AdminSettingBtn: '変更',
        completed: '完了',
        memoLabel: 'OTA キャンセルメモ',
        memoPlaceholder: '天候により無償キャンセル',
        fee: 'キャンセル料金',
        free: '無償',
        paid: '有償',
        cancelBtn: 'キャンセル',
        submitBtn: '予約をキャンセルする',
        history: '履歴',
        success: 'キャンセル成功しました',
        account: 'アカウント',
        usernameEmail: 'ユーザー名またはメールアドレス',
        password: 'パスワード',
        close: '閉じる',
        save: '保存',
    },
    roomBookingItemColumns: {
        stayTime: '宿泊期間',
        yomigana: '宿泊者',
        bookingStatus: 'ステータス',
        roomTypeName: '部屋タイプ',
        planName: 'プラン名',
        roomName: '部屋名',
        action: '編集',
        numberOfAdults: '大人',
        numberOfChildren: '子供',
    },
    guestInfo: {
        name: '名前',
        phoneNumber: '電話番号',
        email: 'メールアドレス',
        reserver: '予約者名',
        numberOfGuest: '宿泊人数',
        salesChannel: '販売チャネル',
        representativeGuest: '宿泊者',
        reserverIsTheRepresentative: '(宿泊者と同じ)',
        roomDetail: '詳細',
        bookingTime: '受付日',
        multipleBooking: '複数予約',
        confirmTitle: '宿泊者を削除',
        confirmDesc: '{{guestName}}を削除してもよろしいですか？',
        guestHistory: '宿泊者履歴',
    },
    bookingItemCard: {
        personNumber: '大人: {{adult}}  子: {{child}}',
        buttonEdit: '内容を編集',
        representativeGuest: '(代表部屋)',
        guestYomigana: {
            label: 'ヨミガナ',
            placeholder: 'ヨミガナ選択',
        },
        status: {
            label: 'ステータス',
            placeholder: 'ステータス選択',
        },
        dateOfStayPeriod: {
            label: 'C/I:',
            placeholder: {
                start: 'チェックイン',
                end: 'チェックアウト',
            },
        },
        checkInTime: {
            label: 'C/I:',
            placeholder: 'C/I',
        },
        checkOutTime: {
            label: 'C/O:',
            placeholder: 'C/O',
        },
        roomTypeId: {
            label: '部屋タイプ',
            placeholder: '部屋タイプ選択',
        },
        roomId: {
            label: '部屋名を',
            placeholder: '部屋名を選択',
        },
        planId: {
            label: 'プラン名',
            placeholder: 'プラン名選択',
        },
        numberOfAdult: {
            label: '大人:',
            placeholder: '0',
        },
        numberOfChild: {
            label: '子:',
            placeholder: '0',
        },
        representativeRoom: '代表部屋',
        roomType: {
            notExist: '不明な部屋タイプ',
        },
        numberOfStayedGuest: '当施設利用回数',
        times: '回',
    },
    message: {
        createSuccess: '部屋予約項目が作成されました',
        updateSuccess: '部屋予約項目が更新されました',
        deleteSuccess: '部屋予約項目が削除されました',
        deleteFailed: '部屋予約項目が削除できませんでした',
        cannotChangeStatus: '{{status}}には変更できません',
        availableRoomsNegative: '予約可能な部屋がマイナスになっています',
        marketingChannelNotExist: '販売チャネルが存在しません',
        roomTypeNotExist: '部屋タイプが存在しません',
        planNotExist: 'プランが存在しません',
        paymentMethodNotExist: '支払い方法が存在しません',
        autoAssignRoom:
            '部屋数不足で自動アサインが完了できません。自動アサインできない部屋が{{unassignCount}} 部屋(s) あります',
        assignedAllRoom: 'すべての予約に対して部屋がアサインされています',
        cannotCheckInAll: '{{date}}以前にはチェックインできません',
        confirmSave:
            'この変更処理を行うとレシートの内容が上書きされます。変更処理を行う前に現状のレシート内容を確認してください。レシート内容を正確にもとに戻せない場合、Tabistにご連絡ください。',
        notAssignedAllBookings:
            'リスト内の各予約に対して部屋が選択されていないため、すべての予約に部屋が割り当てられていません。',
        createError: '部屋予約項目が作成できませんでした',
        guestAddSuccess: '同行者が追加されました。',
        guestAddError: '同行者の追加に失敗しました。',
        guestDeleteSuccess: '同行者が削除されました',
        guestDeleteError: '同行者の削除に失敗しました。',
        guestUpdateSuccess: '同行者情報が更新されました。',
        guestUpdateError: '同行者情報の更新に失敗しました。',
        guestNameRequired: '同行者名は必須です',
    },
    confirmUpdateReceipt: {
        updateButton: '更新する',
        notUpdateButton: '更新しない',
        confirmTitle: '変更後の予約情報をもとにして、売上情報を更新しますか？',
        changeRoomTypeConfirmTitle: '部屋タイプの変更に伴い、宿泊料を変更しますか？',
    },
    detailBookingSchedule: {
        dayUse: 'デイユース',
        checkInBtn: 'チェックイン',
        checkOutBtn: 'チェックアウト',
        cancelReservationBtn: '予約をキャンセル',
        unassignRoomBtn: '割当解除',
        showTheDetails: '詳細を見る',
        cleaned: '清掃済み',
        uncleaned: '未清掃',
        guestName: '山田 太郎',
        plan: '朝食・夕食付きプラン',
        price: '￥25,000',
        notArriveBtn: '未到着に変更',
    },
    deleteTitle: '削除してもよろしいですか？',
    deleteConfirmDialog: {
        content: 'こどもを含む予約をすべてキャンセルします。削除してもよろしいですか？',
        groupBookingContent:
            'こどもを含むすべての予約をすべてキャンセルします。削除してもよろしいですか？',
        singleBookingContent: '削除してもよろしいですか？',
        title: '削除の確認',
        deleteButton: '削除',
        cancelButton: 'キャンセル',
        deleteByStatusError: 'ステータスがキャンセル以外の予約は削除できません。',
        deleteAssociatedBookingsFirstError: '関連する予約を先に削除してください。',
    },
    receiptTableColumn: {
        id: 'ID',
        booking: '予約',
        date: '日付',
        status: 'タイプ',
        plan: '詳細',
        guest: '宿泊者',
        roomName: '部屋名',
        unitPrice: '単価',
        quantity: '数量',
        total: '金額',
        checkoutRoom: '精算部屋',
        payment: '支払い方法',
    },
    receipt: {
        title: '詳細',
        button: {
            edit: '編集',
            splitReceipt: '領収書発行',
            splitInvoice: '請求書発行',
            calculate: '精算',
            split: 'リセット',
            payment: '入金',
            earnings: '売上',
            yes: 'はい',
            no: 'いいえ',
            bulkPayment: '団体予約一括支払い作成ツール',
        },
        status: {
            payment: '入金',
            earnings: '売上',
        },
        sumaryTotal: {
            totalReceivable: '未収金',
            totalSales: '売上合計（消費税込）',
            otherTaxes: 'その他税金',
            payment: '入金',
            change: '預かり余剰金',
        },
        paymentSetting: {
            title: '支払い設定変更',
            representative_room: '室料を代表部屋に支払い設定',
            all_rooms: '全てを代表部屋に支払い設定',
            allDetails: '全ての詳細',
            allRoomCharges: '全ての室料',
            questionChangeConfirm:
                'は代表部屋の支払いに設定しますが、よろしいでしょうか？',
        },
        discardEdit: '編集内容を破棄してもよろしいでしょうか？',
        paymentMethod: {
            placeholder: '支払方法',
        },
        saleItem: {
            placeholder: '科目を選択',
        },
        saleItemDefault: '宿泊',
        adult: '大人',
        paymentModal: {
            title: '精算確認',
            calculateButton: '精算',
            bookingID: {
                label: '予約ID',
                placeholder: '予約ID',
            },
            paymentMethod: {
                label: '入金科目',
                placeholder: '入金科目を選択してください',
            },
            paymentAmount: {
                label: '金額',
                placeholder: '0',
            },
        },
        bookingCheckedOut: 'こちらの予約はチェックアウト済み、もしくはキャンセル済みです',
        item: {
            notExist: '不明な科目',
        },
        plan: {
            notExist: '不明なプラン',
            status: '宿泊料',
        },
        facility: {
            status: '設備利用料',
        },
        message: {
            bookingDateError: '日付範囲外の領収書項目詳細があります C/I, C/O',
            dateOutOfBookingTime: '日付が範囲外です C/I, C/O',
            amountTooLarge: '数値が大き過ぎます',
            amountTooSmall: '数値が小さ過ぎます',
            priceOfAmountToLarge: 'セールアイテムの値段か数量が大き過ぎます',
        },
        bulkPaymentGroupBookingModal: {
            paymentDate: '支払日',
            paymentMethod: '入金科目',
            createBulkPayment: '一括支払い作成',
            cancel: 'キャンセル',
            updateSuccessMessage: '一括支払いが作成されました',
            overpaymentWarning:
                '事前入金またはポイント払いがある場合、預かり余剰金が発生する場合があるので、必ずご確認ください。',
            overpaymentProceed: '実行',
        },
    },
    receiptStatus: {
        payment: '入金',
        receipt: '売上',
    },
    roomItem: '宿泊',
    facilityItem: '設備',
    updateSuccessMessage: '領収書項目詳細が更新されました',
    deleteSuccessMessage: '領収書項目詳細が削除されました',
    cancelEditReceiptTitle: 'キャンセルしてもよろしいですか？',
    deleteReceiptErrorModal: {
        title: 'エラー',
        description: '以下の部屋を削除できませんでした。',
    },
    accommodationCardPrinting: {
        modal: {
            title: '宿泊カード印刷',
            ok: '印刷',
            cancel: 'キャンセル',
            todayFirstPart: '今日',
            todaySecondPart: '({{month}}月{{date}}日チェックイン予定)',
            tomorrowFirstPart: '明日',
            tomorrowSecondPart: '({{month}}月{{date}}日チェックイン予定)',
            theDayAfterTomorrowFirstPart: '明後日',
            theDayAfterTomorrowSecondPart: '({{month}}月{{date}}日チェックイン予定)',
            emptyCardFirstPart: '空のカード',
            emptyCardSecondPart: '',
        },
    },
    hotelSmartCard: {
        message: '送信済み連絡情報',
        myPage: 'マイページデータ編集',
        selfCheckIn: 'セルフチェックイン',
        keyCodes: '鍵コード',
        addSalesPayment: '売上・入金の追加',
        mail: 'メール',
        phone: '電話',
        done: '済',
        sent: '送信済み',
        valid: 'あり',
        beforeChange: '編集前',
        afterChange: '編集後',
        billings: '売上',
        payments: '支払い',
        showMore: 'もっと見る',
        showLess: '折りたたむ',
        notArrived: '未',
        checkedIn: '済',
        pmsManualCheckedIn: 'PMS手動チェックイン',
        guestId: 'ゲストID',
        prefecture: '都道府県',
        nameKana: 'ヨミガナ',
        country: '国籍',
        zipCode: '郵便番号',
        name: 'お客様名',
        adrr: '住所',
        dob: '生年月日',
        email: 'メールアドレス',
        sex: '性別',
        other: 'その他',
        female: '女性',
        male: '男性',
        null: 'データなし',
        0: 'データなし',
        error: {
            syncWithHS: 'HOTEL SMARTとの予約情報同期に失敗しました',
        },
    },
    splitBooking: {
        error: {
            checkOutDate: 'チェックアウト日は今日以降である必要があります。',
        },
    },
    keycard: {
        issueDate: '発行日',
        statusColumn: 'ステータス',
        reIssue: '再発行',
        reissueNumber: '再発行数',
        status: {
            issue: 'アクティブ',
            recycle: '返却済み',
            lost: '紛失',
        },
        addKey: 'カードキーを発行',
        editKey: 'カードキーを編集',
        cancel: 'キャンセル',
        proceed: '進む',
        cico: 'チェックイン / チェックアウト',
        reIssueCard: 'カードキーを再発行',
        reissueState: {
            scanning: 'カードキーをスキャナーに置いてください',
            success: 'キー カードはアクティブです',
            failed: 'キー カードが読み取れませんでした',
        },
        tryAgain: 'もう一度やり直してください',
        close: '閉じる',
    },
};

export default roomBookingDetail;
