import { Button } from 'antd';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '~common/constants';
import { SingleDatePicker } from '~components';
import {
    getUnpaidDetailList,
    setSelectDate,
    setShowUnpaidDetailTable,
    unpaidDetailSelector,
} from '~features/report/reducers/unpaid-detail.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import './FilterForm.scss';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { unpaidDetailFilterSchema } from '~features/report/schema';
import { IUnpaidDetailTableData } from '~features/report/interfaces';

import { utils, writeFile } from 'xlsx';
import { ReportTableCsvFileName } from '~features/report/constants';

function FilterForm({ tableData }: { tableData: IUnpaidDetailTableData[] }) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { showLoadingUnpaidDetailTable, showUnpaidDetailTable } =
        useAppSelector(unpaidDetailSelector);
    const [selectedDate, setSelectedDate] = useState<Dayjs>(customDayjs());
    const { control, setValue } = useForm({
        resolver: yupResolver(unpaidDetailFilterSchema),
    });
    useEffect(() => {
        dispatch(setShowUnpaidDetailTable(false));
        setValue(`day`, customDayjs());
    }, []);

    const changeDate = (date: Dayjs) => {
        setSelectedDate(date);
    };

    const onClickButtonFilter = async () => {
        await dispatch(
            getUnpaidDetailList({
                startTime: selectedDate?.startOf('day')?.fmYYYYMMDDHHmmss(),
                endTime: selectedDate?.endOf('day')?.fmYYYYMMDDHHmmss(),
            }),
        );
        dispatch(setSelectDate(parseDate(selectedDate).fmYYYYMMDD()));
        if (!showUnpaidDetailTable) {
            dispatch(setShowUnpaidDetailTable(true));
        }
    };

    const exportCSV = () => {
        if (tableData.length) {
            const header = [
                t('report.unpaidDetail.unpaidDetailColumn.roomName'),
                t('report.unpaidDetail.unpaidDetailColumn.guestName'),
                t('report.unpaidDetail.unpaidDetailColumn.checkIn'),
                t('report.unpaidDetail.unpaidDetailColumn.checkOut'),
                t('report.unpaidDetail.unpaidDetailColumn.unpaid'),
            ];

            const data = tableData.map(
                ({ roomName, guestName, checkInTime, checkOutTime, unpaidAmount }) => [
                    roomName,
                    guestName,
                    checkInTime?.format('MM/DD/YYYY'),
                    checkOutTime?.format('MM/DD/YYYY'),
                    unpaidAmount,
                ],
            );

            const wb = utils.book_new();
            const ws = utils.aoa_to_sheet([
                header,
                ...data,
                [
                    '',
                    '',
                    '',
                    t('report.unpaidDetail.total'),
                    tableData.reduce(
                        (total, item) => total + (item.unpaidAmount || 0),
                        0,
                    ),
                ],
            ]);
            utils.book_append_sheet(wb, ws);

            writeFile(wb, `${ReportTableCsvFileName.UNPAID_DETAIL}.csv`, {
                type: 'string',
                bookType: 'csv',
            });
        }
    };

    return (
        <div className="report-unpaid-detail-filter-form-wrapper">
            <div className="filter-form-content">
                <span>{t('report.unpaidDetail.filterForm.dateLabel')}</span>
                <SingleDatePicker
                    value={selectedDate}
                    format={DateFormat.YYYY_MM_DD_HYPHEN}
                    allowClear={false}
                    onChange={changeDate}
                    placeholder={t('report.placeholder.selectDate')}
                    name={'day'}
                    control={control}
                />
                <Button
                    type="primary"
                    onClick={onClickButtonFilter}
                    loading={showLoadingUnpaidDetailTable}
                >
                    {t('report.unpaidDetail.filterForm.filterButton')}
                </Button>
            </div>
            {showUnpaidDetailTable && (
                <Button onClick={exportCSV}>{t('report.exportCSV')}</Button>
            )}
        </div>
    );
}

export default FilterForm;
