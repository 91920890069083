import { Badge, Card, Spin, Tabs } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getPlanDropdown } from '~features/plan/reducers/plan.reducer';
import {
    RoomBookingDetailPageTabPane,
    RoomBookingTab,
} from '~features/room-booking/constants';
import {
    fetchHotelSmartUrlDetail,
    fetchRoomBookingDetail,
    roomBookingStateSelector,
    selectedRoomBookingDetailSelector,
    setActiveDetailTabPane,
    showLoadingSelector,
    setHsUrlDetail,
    fetchHotelSmartEditHitsory,
    setHsEditHistory,
    setIsHsUrlDetailSync,
} from '~features/room-booking/reducers/room-booking.reducer';
import { getRoomTypeListForDropdown } from '~features/room-type/room-type.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { CheckinModal } from '../SchedulePage/components/CheckinModal/CheckinModal';
import RoomBookingDetailTabPane from './components/RoomBookingDetailTabPane/RoomBookingDetailTabPane';
import RoomBookingReceiptTabPane from './components/RoomBookingReceiptTabPane/RoomBookingReceiptTabPane';
import RoomBookingCancelationInfoTabPane from './components/RoomBookingCancelationInfoTabPane/RoomBookingCancelationInfoTabPane';
import './RoomBookingDetailPage.scss';
import { getCountStayedGuest } from '~features/guest/reducers/guest.reducer';
import {
    getHotelSmartConfig,
    integrationstStateSelector,
    setHotelSmart,
} from '~features/integrations/reducers/integrations.reducer';
import RoomBookingKeycardTabPane from './components/RoomBookingKeycardTabPane/RoomBookingKeycardTabPane';
import HistoryListPage from '~features/fo-operation-history/pages/HistoryPage/HistoryPage';
import localStorageAuthService from '~common/authStorage';
import RoomBookingAccompanierList from './components/RoomBookingDetailTabPane/RoomBookingAccompanierList/RoomBookingAccompanierList';
import RoomBookingAttachment from './components/RoomBookingDetailTabPane/RoomBookingAttachment/RoomBookingAttachment';
import HotelSmart from './components/RoomBookingDetailTabPane/HotelSmart/HotelSmart';
import { CacheKeys } from '~queries/queries';
import { useQueryClient } from '@tanstack/react-query';
import { IIntegrationsState } from '~features/integrations/interfaces';

function RoomBookingDetailPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoading = useAppSelector(showLoadingSelector);
    const { roomBookingId } = useParams();
    const [params, setParams] = useSearchParams();
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const { activeDetailTabPane } = useAppSelector(roomBookingStateSelector);
    const hotelStored = localStorageAuthService.getSelectedHotel();
    const query = useQueryClient();

    const numberOfBookingItems = useMemo(() => {
        return selectedRoomBookingDetail?.roomBookingItems?.length || 0;
    }, [selectedRoomBookingDetail]);
    const parsedRoomBookingId = Number(roomBookingId);

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchRoomBookingDetail(id));
        if (
            fetchRoomBookingDetail.fulfilled.match(response) &&
            response.payload?.success
        ) {
            const booking = response.payload?.data;
            const isFromTll = !!booking?.tllDataId;
            dispatch(getPlanDropdown({ includeDataFromTll: isFromTll }));
            dispatch(getRoomTypeListForDropdown({}));
            dispatch(getCountStayedGuest(booking.representativeGuest?.id));
        }
    }, []);

    const changeDetailTab = (activeKey: string) => {
        if (activeKey === RoomBookingDetailPageTabPane.ROOM_BOOKING_RECEIPT_TAB_PANE) {
            query.refetchQueries({
                queryKey: [CacheKeys.roomBookingReceipt, roomBookingId],
            });
        }
        dispatch(setActiveDetailTabPane(activeKey));
        setParams({ ...params, activeTab: activeKey }, { replace: true });
    };

    useEffect(() => {
        const paramActiveTab = params.get('activeTab');
        if (paramActiveTab && paramActiveTab !== activeDetailTabPane) {
            dispatch(setActiveDetailTabPane(paramActiveTab));
        }
    }, [params]);

    const fetchHSUrlData = async (id: number) => {
        const response = await dispatch(fetchHotelSmartUrlDetail(id));
        if (
            fetchHotelSmartUrlDetail.fulfilled.match(response) &&
            response.payload?.data
        ) {
            dispatch(setIsHsUrlDetailSync(true));
            dispatch(setHsUrlDetail(response.payload.data));
        }
    };

    const fetchHSEditHistoryData = async (id: number) => {
        const response = await dispatch(fetchHotelSmartEditHitsory(id));
        if (
            fetchHotelSmartEditHitsory.fulfilled.match(response) &&
            response.payload?.data?.data?.length > 0
        ) {
            dispatch(setHsEditHistory(response.payload?.data));
        }
    };

    const fetchHSData = async () => {
        const response = await dispatch(getHotelSmartConfig());
        if (getHotelSmartConfig.fulfilled.match(response) && response.payload?.success) {
            dispatch(setHotelSmart(response.payload.data));
        }
    };

    useEffect(() => {
        if (isNaN(parsedRoomBookingId)) {
            navigate('/404');
        }
        fetchHSData();
        _getDetail(parsedRoomBookingId);
        fetchHSUrlData(parsedRoomBookingId);
        fetchHSEditHistoryData(parsedRoomBookingId);

        return () => {
            dispatch(
                setActiveDetailTabPane(
                    RoomBookingDetailPageTabPane.ROOM_BOOKING_LIST_TAB_PANE,
                ),
            );
        };
    }, [roomBookingId]);

    const getRoomBookingDetail = () => {
        _getDetail(parsedRoomBookingId);
    };

    const storedIntegrations = useAppSelector<IIntegrationsState>(
        integrationstStateSelector,
    );

    return (
        <div className="room-booking-detail-page">
            <Spin spinning={isLoading}>
                <Tabs
                    onChange={changeDetailTab}
                    activeKey={activeDetailTabPane}
                    tabBarStyle={{ paddingTop: 8 }}
                    items={[
                        {
                            key: RoomBookingDetailPageTabPane.ROOM_BOOKING_LIST_TAB_PANE,
                            label: (
                                <span className="room-booking-detail-tab-pane">
                                    {t('roomBooking.detail.detailPagetabs.resInfo')}
                                    <Badge
                                        className="number-of-booking-items"
                                        count={numberOfBookingItems}
                                        style={{
                                            backgroundColor: '#F6F6F6',
                                            color: '#555757',
                                        }}
                                    />
                                </span>
                            ),
                            children: (
                                <RoomBookingDetailTabPane
                                    changeDetailTab={changeDetailTab}
                                />
                            ),
                        },
                        {
                            key: RoomBookingTab.ACCOMPANIER,
                            label: t('roomBooking.detail.detailPagetabs.accompanier'),
                            children: (
                                <Card className="room-booking-detail-card ml-24 mr-24">
                                    <RoomBookingAccompanierList />
                                </Card>
                            ),
                        },
                        {
                            key: RoomBookingDetailPageTabPane.ROOM_BOOKING_RECEIPT_TAB_PANE,
                            label: t('roomBooking.detail.detailPagetabs.receipt'),
                            children: <RoomBookingReceiptTabPane />,
                        },
                        {
                            key: RoomBookingTab.ATTACHMENT,
                            label: t('roomBooking.detail.detailPagetabs.attachment'),
                            children: (
                                <Card className="room-booking-detail-card ml-24 mr-24">
                                    {selectedRoomBookingDetail && (
                                        <RoomBookingAttachment />
                                    )}
                                </Card>
                            ),
                        },
                        ...(process.env.REACT_APP_HOTEL_SMART === 'true' &&
                        storedIntegrations.hotelSmart.enableIntegration
                            ? [
                                  {
                                      key: RoomBookingTab.HOTEL_SMART,
                                      label: t(
                                          'roomBooking.detail.detailPagetabs.hotelSmart',
                                      ),
                                      children: (
                                          <Card className="room-booking-detail-card ml-24 mr-24">
                                              <HotelSmart />
                                          </Card>
                                      ),
                                  },
                              ]
                            : []),

                        ...(process.env.REACT_APP_KEYCARDS === 'true' &&
                        // (temporary solution) PMS-4827 Enable key card feature only for Tabata (Tokyo City View) hotel. hotel id == 88
                        hotelStored?.id === 88
                            ? [
                                  {
                                      key: RoomBookingDetailPageTabPane.ROOM_BOOKING_KEYCARD_TAB_PANE,
                                      label: t(
                                          'roomBooking.detail.detailPagetabs.keycards',
                                      ),
                                      children: <RoomBookingKeycardTabPane />,
                                  },
                              ]
                            : []),
                        ...(process.env.REACT_APP_OTA_CANCELATION_ROBOT === 'true'
                            ? [
                                  {
                                      key: RoomBookingDetailPageTabPane.ROOM_BOOKING_CANCELATION_INFO,
                                      label: t(
                                          'roomBooking.detail.detailPagetabs.cancelationInfo',
                                      ),
                                      children: <RoomBookingCancelationInfoTabPane />,
                                  },
                              ]
                            : []),
                        {
                            key: RoomBookingDetailPageTabPane.ROOM_BOOKING_AUDIT_LOG_PANE,
                            label: t('roomBooking.detail.detailPagetabs.auditLog'),
                            children: <HistoryListPage withoutHeader />,
                        },
                    ]}
                />
                <CheckinModal
                    onChangeBookingStatusSuccess={getRoomBookingDetail}
                    isLoadedData={true}
                />
            </Spin>
        </div>
    );
}

export default RoomBookingDetailPage;
