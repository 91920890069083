import React from 'react';
import './ModalConfirmDeletion.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import i18next from '~plugins/i18next/i18n';

interface IProp {
    title?: string;
    description?: string;
    buttonCancelText?: string;
    buttonDeleteText?: string;
    onClickButtonDelete: () => void;
    onClickButtonCancel?: () => void;
    deletedItems: string[];
    okButtonProps?: { danger: boolean };
    wrapClassName?: string;
}

export function ModalConfirmDeletion(props: IProp) {
    return Modal.confirm({
        wrapClassName: props.wrapClassName,
        title: props.title || i18next.t<string>('common.modalConfirmDeletion.title'),
        cancelText: props.buttonCancelText || i18next.t<string>('common.cancel'),
        okText: props.buttonDeleteText || i18next.t<string>('common.ok'),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okButtonProps: props.okButtonProps,
        content: (
            <>
                <span>
                    {props.description ||
                        i18next.t<string>('common.modalConfirmDeletion.description')}
                </span>
                <div className="vertical-delete-list">
                    <ul>
                        {props.deletedItems.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </>
        ),
        onOk() {
            props.onClickButtonDelete();
        },
        onCancel() {
            if (props.onClickButtonCancel) props.onClickButtonCancel();
        },
    });
}
