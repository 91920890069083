import { Spin } from 'antd';
import { max, min } from 'lodash';
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    WheelEvent,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GridOnItemsRenderedProps, VariableSizeGrid } from 'react-window';
import { addMoreColumnsForDay, getHoursColumnsForDay } from '~common/commonFunctions';
import { EmitterGlobalEvent } from '~common/constants';
import { IDirection, IScrollStatus } from '~common/interfaces';
import { useHandleScroll, usePrevious, useThrottle } from '~common/useHooks';
import { AutoSizer, GridView, ICell } from '~components';
import { MAX_HEIGHT_SCHEDULE_VIEW } from '~features/facility-booking/constants';
import { CollapseBtn } from '~features/room-booking/components/CollapseBtn/CollapseBtn';
import {
    CELL_HEADER_HEIGHT,
    CELL_HEIGHT,
    CELL_HOUR_WIDTH,
    CELL_WIDTH,
    HEADER_HEIGHT_DAY_VIEW,
    RoomBookingEvent,
} from '~features/room-booking/constants';
import {
    calculateBookingScrollData,
    maxHeightRoomList,
} from '~features/room-booking/helper';
import {
    fetchMoreBookingList,
    getBookingList,
    getStatisticByDateV2,
    getStopRoomInDuration,
    roomFilterSelector,
    scheduleStateSelector,
    setCollapseRooms,
    setCurrentDate,
    setStartDateWeekView,
    setEndDateWeekView,
    resetDayViewState,
    setRoomViewId,
} from '~features/room-booking/reducers/schedule.reducer';
import { showLoadingSelector } from '~features/inventory/reducers/inventory.reducer';
import { updateStyleForCellSelected } from '~features/room-booking/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import { BookingList } from '../ScheduleWeekView/BookingList';
import { DropBoxList } from '../ScheduleWeekView/DropBoxList';
import { RoomHeader } from '../ScheduleWeekView/RoomHeader';
import { CellItem } from './CellItem';
import { Header } from './Header';
import './ScheduleDayView.scss';

export const ScheduleDayView = (props: { height?: number }) => {
    const today = useMemo(() => {
        return customDayjs().fmYYYYMMDD('-');
    }, []);
    const {
        isSelecting,
        startPosition,
        panelId,
        isFetchingRoomList,
        collapseRooms,
        isDraggingBooking,
        currentStatus,
        currentDragData,
        currentDate,
        isFetchingBookingList,
        stoppingRoomList,
        roomViewId,
    } = useAppSelector(scheduleStateSelector);
    const isLoadingUpdateInventory = useAppSelector(showLoadingSelector);

    const { height } = props;
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState(getHoursColumnsForDay(currentDate));
    const roomList = useAppSelector(roomFilterSelector);
    const outerRef = useRef<HTMLElement>();
    const gridRef = useRef<VariableSizeGrid>() as MutableRefObject<VariableSizeGrid>;
    const gridViewContentHeightRef = useRef<number>();
    const scrollPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
    const { throttle } = useThrottle();
    const containerRef = useRef<HTMLDivElement>(null);
    const { emitter } = useMitt();
    const isLoadMore = useRef(false);

    // handle scroll
    const onStopScroll = useCallback(
        (direction: IDirection, status: IScrollStatus) => {
            if (isFetchingBookingList) {
                return;
            }
            if (status === 'start' && direction === 'right') {
                throttle(() => {
                    loadMorePast();
                }, 1000);
            } else if (status === 'end' && direction === 'left') {
                throttle(() => {
                    loadMoreFuture();
                }, 1000);
            }
        },
        [isFetchingBookingList, columns],
    );

    const {
        scrollDirectionX,
        onMouseDown,
        onMouseUp,
        onMouseMove,
        onMouseLeave,
        elementRef,
        isScrollXAble,
        onWheel,
        scrollStatus,
    } = useHandleScroll({ onStopScroll });

    useEffect(() => {
        isScrollXAble.current = !isDraggingBooking;
    }, [isDraggingBooking, isScrollXAble]);
    // end handle scroll

    // handle select day for create booking
    useEffect(() => {
        const _updateStyleForCellSelected = (x: number) => {
            if (!startPosition) return;
            updateStyleForCellSelected(panelId, startPosition, x);
        };

        const onMouseMove = (event: MouseEvent) => {
            if (!isSelecting) {
                return;
            }
            const element = event.target as HTMLElement;
            const x = element.dataset.x;
            const y = element.dataset.y;
            const roomId = element.dataset.room_id;
            const typeId = element.dataset.room_type_id;
            const date = element.dataset.day;
            if (x && y) {
                if (roomId && typeId && date) {
                    if (
                        stoppingRoomList[date]?.['roomType_' + typeId]?.[
                            'room_' + roomId
                        ] ||
                        date < today
                    ) {
                        return;
                    }
                }
                _updateStyleForCellSelected(Number(x));
            }
        };
        document.addEventListener('mousemove', onMouseMove);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
        };
    }, [isSelecting, dispatch, startPosition, panelId, stoppingRoomList]);

    // end handle select day for create booking

    const renderHeader = useCallback(
        ({ columnIndex, style }: ICell) => {
            const column = columns[columnIndex];
            return <Header column={column} style={style} today={today} />;
        },
        [columns, today],
    );

    const renderCell = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            const row = roomList[rowIndex];
            const _date = columns[columnIndex].day;
            const disabled =
                stoppingRoomList[_date]?.['roomType_' + row.parentId]?.['room_' + row.id];
            return (
                <CellItem
                    isToday={false}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    item={row}
                    day={_date}
                    hours={columns[columnIndex].hours}
                    style={style}
                    disabled={!!disabled}
                ></CellItem>
            );
        },
        [roomList, currentStatus, currentDragData, columns, today, dispatch],
    );

    const renderRowHeader = useCallback(
        ({ rowIndex, style }: ICell) => {
            const row = roomList[rowIndex];
            return <RoomHeader item={row} style={style} />;
        },
        [collapseRooms, roomList],
    );

    const getRowContentHeight = useCallback(
        (rowIndex: number) => {
            if (roomList[rowIndex].parentId) {
                return CELL_HEIGHT;
            }
            return CELL_HEADER_HEIGHT;
        },
        [roomList],
    );

    const isExpand = useMemo(() => {
        const ids = roomList.filter((item) => !item.parentId).map((item) => item.id);
        return collapseRooms.length === ids.length;
    }, [collapseRooms, roomList]);

    const togglePanelAll = useCallback(() => {
        const ids = roomList.filter((item) => !item.parentId).map((item) => item.id);
        if (collapseRooms.length === ids.length) {
            dispatch(setCollapseRooms([]));
        } else {
            dispatch(setCollapseRooms(ids));
        }
    }, [collapseRooms, roomList]);

    useEffect(() => {
        emitter.on(RoomBookingEvent.CHANGE_DATE, (date: string) => {
            const _columns = getHoursColumnsForDay(date);
            setColumns(_columns);
            fetchBookingList(_columns[0].id, _columns[_columns.length - 1].id);
            updateNumberOfBooking(_columns[0].id, _columns[_columns.length - 1].id);
        });
        return () => {
            emitter.off(RoomBookingEvent.CHANGE_DATE);
        };
    }, [columns]);

    const isFetchingBookingListOld = usePrevious<boolean>(isFetchingBookingList);
    useEffect(() => {
        if (isFetchingBookingListOld && !isFetchingBookingList && !isLoadMore.current) {
            const todayIndex = columns.findIndex((item) => item.day === currentDate);
            if (todayIndex > -1) {
                setTimeout(() => {
                    gridRef.current?.scrollToItem({
                        columnIndex: todayIndex,
                        align: 'start',
                    });
                }, 0);
            }
        }
    }, [isFetchingBookingList]);

    useEffect(() => {
        const rowIndex = roomList.findIndex(
            (room) => room.id === roomViewId && room.parentId,
        );
        if (rowIndex > -1) {
            setTimeout(() => {
                gridRef.current?.scrollToItem({
                    rowIndex: rowIndex,
                    align: 'start',
                });
            }, 0);
        }
        return () => {
            dispatch(setRoomViewId(0));
        };
    }, [roomViewId, dispatch]);

    const updateNumberOfBooking = (start: string, end: string) => {
        dispatch(setStartDateWeekView(start));
        dispatch(setEndDateWeekView(end));
        dispatch(getStopRoomInDuration());
        dispatch(getStatisticByDateV2());
    };

    useEffect(() => {
        fetchBookingList(columns[0].id, columns[columns.length - 1].id);
        updateNumberOfBooking(columns[0].id, columns[columns.length - 1].id);
        return () => {
            dispatch(resetDayViewState());
        };
    }, []);

    // handle load more item
    const fetchBookingList = async (start: string, end: string) => {
        isLoadMore.current = false;
        await dispatch(
            getBookingList({
                start,
                end,
            }),
        );
    };
    const loadMoreFuture = async () => {
        isLoadMore.current = true;
        const lastDay = columns[columns.length - 1].id;
        const moreColumns = addMoreColumnsForDay(
            parseDate(lastDay).add(1, 'day'),
            false,
            1,
        );
        setColumns([...columns, ...moreColumns]);
        updateNumberOfBooking(moreColumns[0].id, moreColumns[moreColumns.length - 1].id);
        await dispatch(
            fetchMoreBookingList({
                start: moreColumns[0].id,
                end: moreColumns[moreColumns.length - 1].id,
            }),
        );
    };
    const loadMorePast = async () => {
        isLoadMore.current = true;
        const firstDay = columns[0].id;
        const moreColumns = addMoreColumnsForDay(parseDate(firstDay), true);
        setColumns([...moreColumns, ...columns]);
        dispatch(setCurrentDate(moreColumns[0].id));
        updateNumberOfBooking(moreColumns[0].id, moreColumns[moreColumns.length - 1].id);
        await dispatch(
            fetchMoreBookingList({
                start: moreColumns[0].id,
                end: moreColumns[moreColumns.length - 1].id,
            }),
        );
    };

    const _onScroll = useCallback(
        ({ scrollLeft, scrollTop }: any) => {
            const scrollWidth = outerRef.current?.scrollWidth || 0;
            const clientWidth = containerRef.current?.clientWidth || 0;
            const scrollData = { x: scrollLeft, y: scrollTop };
            emitter.emit(EmitterGlobalEvent.SCROLL, scrollData);
            scrollPosition.current = scrollData;
            if (!scrollLeft) {
                scrollStatus.current = 'start';
            } else if (scrollWidth === scrollLeft + clientWidth - CELL_WIDTH) {
                scrollStatus.current = 'end';
            } else {
                scrollStatus.current = 'middle';
            }
        },
        [outerRef, gridRef, containerRef, columns],
    );

    const onItemsRendered = useCallback(
        (options: GridOnItemsRenderedProps) => {
            emitter.emit(EmitterGlobalEvent.SCHEDULE_SCROLL, options);
            const { visibleColumnStartIndex } = options;
            if (!elementRef.current) {
                elementRef.current = outerRef.current;
            }
            const column = columns[visibleColumnStartIndex];
            if (scrollDirectionX.current && column?.day) {
                dispatch(setCurrentDate(column.day));
            }
        },
        [columns, currentDate],
    );

    const onWheelBooking = (e: WheelEvent<HTMLDivElement>) => {
        const { deltaX, deltaY, shiftKey } = e;
        const { x, y } = scrollPosition.current;
        const maxHeight = maxHeightRoomList(roomList, collapseRooms);
        const maxScrollTop = max([
            maxHeight - MAX_HEIGHT_SCHEDULE_VIEW + HEADER_HEIGHT_DAY_VIEW,
            0,
        ]);
        const { scrollLeft, scrollTop } = calculateBookingScrollData(
            { x, y },
            { deltaX, deltaY, shiftKey },
        );
        gridRef.current.scrollTo({
            scrollLeft,
            scrollTop: max([min([scrollTop, maxScrollTop]), 0]),
        });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                className="room-booking-schedule-table-day-view-wrapper"
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseLeave}
                onWheel={onWheel}
                ref={containerRef}
            >
                <Spin
                    spinning={
                        isFetchingRoomList ||
                        isFetchingBookingList ||
                        isLoadingUpdateInventory
                    }
                    style={{ minHeight: 400 }}
                >
                    <AutoSizer disableHeight>
                        {({ width }) => {
                            let cellWidth = (width - CELL_WIDTH) / 24;
                            if (cellWidth < CELL_HOUR_WIDTH) {
                                cellWidth = CELL_HOUR_WIDTH;
                            }
                            return (
                                <>
                                    <GridView
                                        style={{
                                            fontSize: 12,
                                        }}
                                        outerRef={outerRef}
                                        gridViewContentHeightRef={
                                            gridViewContentHeightRef
                                        }
                                        gridRef={gridRef}
                                        className={'schedule-table'}
                                        height={height}
                                        width={width}
                                        columns={columns}
                                        recordset={roomList}
                                        rowHeaderWidth={CELL_WIDTH}
                                        columnHeaderWidth={cellWidth}
                                        columnHeaderHeight={HEADER_HEIGHT_DAY_VIEW}
                                        columnHeaderRenderer={renderHeader}
                                        rowHeaderRenderer={renderRowHeader}
                                        cellRenderer={renderCell}
                                        getRowContentHeight={getRowContentHeight}
                                        onScroll={_onScroll}
                                        bodyProps={{
                                            onItemsRendered,
                                        }}
                                    />
                                    <BookingList
                                        cellWidth={cellWidth}
                                        onMouseUp={onMouseUp}
                                        startTime={parseDate(columns[0].id)}
                                        endTime={parseDate(
                                            columns[columns.length - 1].id,
                                        )}
                                        onWheel={onWheelBooking}
                                        gridRef={gridRef}
                                        outerRef={outerRef}
                                        gridViewContentHeightRef={
                                            gridViewContentHeightRef
                                        }
                                    />
                                    <DropBoxList
                                        cellWidth={cellWidth}
                                        startTime={parseDate(columns[0].id)}
                                        endTime={parseDate(
                                            columns[columns.length - 1].id,
                                        )}
                                    />
                                </>
                            );
                        }}
                    </AutoSizer>
                    <div
                        className="header-action"
                        style={{ width: CELL_WIDTH, height: HEADER_HEIGHT_DAY_VIEW }}
                    >
                        <CollapseBtn isExpand={isExpand} onClick={togglePanelAll} />
                    </div>
                </Spin>
            </div>
        </DndProvider>
    );
};
