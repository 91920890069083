import './RoomBookingAttachment.scss';
import { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Spin, notification } from 'antd';
import type { UploadFile as IUploadFile } from 'antd/es/upload/interface';
import { UploadFileCustom } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import { UploadFileStatus } from '~common/constants';
import {
    fetchRoomBookingDetail,
    isUpdatingBookingSelector,
    selectedRoomBookingDetailSelector,
    updateBooking,
} from '~features/room-booking/reducers/room-booking.reducer';
import { transformUpdateBookingFormData } from '~features/room-booking/helper.update-booking';
import {
    IUpdateBookingFormData,
    IUpdateRoomBookingForm,
} from '~features/room-booking/interfaces';
import {
    ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
    MAX_NUMBER_OF_FILES_PER_BOOKING,
    MAX_SIZE_OF_EACH_BOOKING_FILE,
} from '~features/room-booking/constants';
import { errorMessagesBookingFile } from '~features/room-booking/helper';
import { UPLOAD_FILE_ERROR_I18N_KEY } from '~features/guest/constants';

function RoomBookingAttachment() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const isUpdatingBooking = useAppSelector(isUpdatingBookingSelector);

    const fileList = useMemo(() => {
        if (!selectedRoomBookingDetail?.filesInformation?.length) return [];
        return selectedRoomBookingDetail?.filesInformation?.map((file) => ({
            uid: `${file.id}`,
            name: file.originalName,
            status: UploadFileStatus.DONE,
            url: file.url,
            path: file.path,
            size: file.size,
            fileName: file.fileName,
            type: file.mimetype,
        }));
    }, [selectedRoomBookingDetail]);

    const {
        control,
        formState: { isDirty },
    } = useForm({
        defaultValues: { files: fileList },
        shouldUnregister: true,
    });
    const files = useWatch({ control, name: 'files' });

    const _updateBooking = async (formData: IUpdateBookingFormData | FormData) => {
        const response = await dispatch(
            updateBooking({ id: Number(selectedRoomBookingDetail?.id), formData }),
        );
        if (updateBooking.fulfilled.match(response)) {
            if (response.payload?.success) {
                const messageKey = response.payload
                    ?.message as keyof typeof errorMessagesBookingFile;
                if (
                    messageKey &&
                    Object.keys(errorMessagesBookingFile).includes(messageKey)
                ) {
                    notification.error({
                        message: errorMessagesBookingFile[messageKey],
                    });
                }
                if (response.payload?.message === UPLOAD_FILE_ERROR_I18N_KEY) {
                    notification.error({
                        message: t(`guest.update.${UPLOAD_FILE_ERROR_I18N_KEY}`),
                    });
                }
                notification.success({
                    message: t('roomBooking.updateBooking.update.success'),
                });
                dispatch(fetchRoomBookingDetail(Number(selectedRoomBookingDetail?.id)));
                return;
            }

            const errorKey = response.payload
                ?.message as keyof typeof errorMessagesBookingFile;
            if (errorKey && Object.keys(errorMessagesBookingFile).includes(errorKey)) {
                notification.error({
                    message: errorMessagesBookingFile[errorKey],
                });
            } else {
                notification.error({
                    message: t('roomBooking.updateBooking.update.error'),
                    description: response.payload?.message || '',
                });
            }
        }
    };

    useEffect(() => {
        if (
            isDirty &&
            files?.every((file: IUploadFile) => file.status === UploadFileStatus.DONE)
        ) {
            const updateRoomBookingFormData = transformUpdateBookingFormData({
                ...selectedRoomBookingDetail,
                files,
            } as unknown as IUpdateRoomBookingForm);
            _updateBooking(updateRoomBookingFormData);
        }
    }, [isDirty, files]);

    return (
        <Spin spinning={isUpdatingBooking}>
            <UploadFileCustom
                title={t('common.uploadFile.title')}
                subTitle={t('common.uploadFile.hint')}
                name="files"
                control={control}
                label=""
                pathKey="room-booking"
                maxCount={MAX_NUMBER_OF_FILES_PER_BOOKING}
                maxSize={MAX_SIZE_OF_EACH_BOOKING_FILE}
                multiple={true}
                listType="picture"
                acceptTypes={ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS}
                defaultFileList={files}
                style={{ marginTop: fileList.length ? 0 : 24 }}
            />
        </Spin>
    );
}

export default RoomBookingAttachment;
