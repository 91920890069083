import { todayDayjs } from '~plugins/dayjs';
import i18next from '~plugins/i18next/i18n';
export const reportTypes = {
    Weekly: 'Weekly',
    Monthly: 'Monthly',
};

export enum OccupancyHeatMapLevel {
    WHITE = 'white',
    LIGHT_YELLOW = 'light-yellow',
    LIGHT_RED = 'light-red',
    LIGHT_PURPLE = 'light-purple',
    DARK_RED = 'dark-red',
}

export const OCCUPANCY_HEAT_MAP_LEVELS = [
    { color: OccupancyHeatMapLevel.WHITE, rate: '~25%' },
    { color: OccupancyHeatMapLevel.LIGHT_YELLOW, rate: '~50%' },
    { color: OccupancyHeatMapLevel.LIGHT_PURPLE, rate: '~75%' },
    { color: OccupancyHeatMapLevel.LIGHT_RED, rate: '~100%' },
    { color: OccupancyHeatMapLevel.DARK_RED, rate: '101%~' },
];

export const topSectionTypes = {
    totalAmount: 'Sales',
    adr: 'ADR',
    averageOccupancyRoom: 'Average occupancy',
    bookingCount: 'Number of bookings',
};

export enum SalesSummaryType {
    STAY_PRICE = 'stayPrice',
}

export const salesPaymentBreadcrumbs = () => {
    return [
        {
            text: i18next.t('report.salesPayment.breadcrumbs.report'),
        },
        {
            text: i18next.t('report.salesPayment.breadcrumbs.salesPayment'),
        },
    ];
};
export const salesSummaryBreadcrumbs = () => [
    {
        text: i18next.t('report.salesSummary.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.salesSummary.breadcrumbs.salesSummary'),
    },
];

export const unpaidDetailBreadcrumbs = () => [
    {
        text: i18next.t('report.unpaidDetail.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.unpaidDetail.breadcrumbs.unpaidDetail'),
    },
];

export const localTaxDetailBreadcrumbs = () => [
    {
        text: i18next.t('report.localTax.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.localTax.breadcrumbs.localTax'),
    },
];

export const paymentSummaryBreadcrumbs = () => [
    {
        text: i18next.t('report.paymentSummary.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.paymentSummary.breadcrumbs.paymentSummary'),
    },
];

export const cancellationBreadcrumbs = () => [
    {
        text: i18next.t('report.cancellation.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.cancellation.breadcrumbs.cancellation'),
    },
];
export const operationStatusBreadcrumbs = () => [
    {
        text: i18next.t('report.operationStatus.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.operationStatus.breadcrumbs.operationStatus'),
    },
];
export const paymentDetailsBreadcrumbs = () => [
    {
        text: i18next.t('report.paymentDetails.report'),
    },
    {
        text: i18next.t('report.paymentDetails.title'),
    },
];

export const salesByCategoryBreadcrumbs = () => [
    {
        text: i18next.t('report.salesByCategory.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.salesByCategory.breadcrumbs.salesByCategory'),
    },
];

export const salesByPlansBreadcrumbs = () => [
    {
        text: i18next.t('report.salesByPlans.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.salesByPlans.breadcrumbs.salesByPlans'),
    },
];

export const salesByOtaBreadcrumbs = () => [
    {
        text: i18next.t('report.salesByOta.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.salesByOta.breadcrumbs.salesByOta'),
    },
];

export const currentGuestBreadcrumbs = () => [
    {
        text: i18next.t('report.currentGuest.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.currentGuest.breadcrumbs.currentGuest'),
    },
];

export const mealReportBreadcrumbs = () => [
    {
        text: i18next.t('report.mealReport.breadcrumbs.report'),
    },
    {
        text: i18next.t('report.mealReport.breadcrumbs.meal'),
    },
];

export const defaultTopSectionOverviewQuery = {
    datePeriod: [
        todayDayjs.startOf('month')?.fmYYYYMMDDHHmmss(),
        todayDayjs.endOf('month')?.fmYYYYMMDDHHmmss(),
    ],
};

export const defaultPaymentDetailsQuery = {
    datePeriod: [
        todayDayjs.year(1970)?.fmYYYYMMDDHHmmss(),
        todayDayjs.endOf('month')?.fmYYYYMMDDHHmmss(),
    ],
};

export enum AggregationMethod {
    DAILY = 'daily',
    MONTH = 'month',
}

export enum SalesSummaryColumn {
    NAME = 'name',
    CURRENT_QUANTITY = 'currentQuantity',
    CURRENT_PRICE = 'currentPrice',
    LAST_YEAR_QUANTITY = 'lastYearQuantity',
    LAST_YEAR_PRICE = 'lastYearPrice',
    COMPARISON_QUANTITY = 'comparisonQuantity',
    COMPARISON_PRICE = 'comparisonPrice',
}

export enum FacilitySalesSummaryColumn {
    NAME = 'name',
    QUANTITY = 'quantity',
    AMOUNT = 'amount',
    QUANTITY_PREV_YEAR = 'quantityPrevYear',
    AMOUNT_PREV_YEAR = 'amountPrevYear',
    QUANTITY_COMPARED = 'quantityCompared',
    AMOUNT_COMPARED = 'amountCompared',
}

export enum SaleType {
    SALE_ITEM = 'saleItem',
    SALE_GROUP = 'saleGroup',
}

export enum SalesByCategoryColumn {
    NUMBER_OF_ROOMS = 'NumberOfRooms',
    ADULTS = 'Adults',
    CHILDREN = 'Children',
    AMOUNT = 'Amount',
    AVAREGE_PRICE = 'AvaregePrice',
    OCCUPANCY_RATE = 'OccupancyRate',
}

export enum ShowMonth {
    LAST_MONTH = 'lastMonth',
    CURRENT_MONTH = 'currentMonth',
    NEXT_MONTH = 'nextMonth',
}

export enum SalesByCategoryDisplayMode {
    DAILY = 'daily',
    MONTHLY = 'monthly',
}

export enum UnpaidDetailColumn {
    ROOM_NAME = 'roomName',
    GUEST_NAME = 'guestName',
    CHECK_IN_TIME = 'checkInTime',
    CHECK_OUT_TIME = 'checkOutTime',
    NUMBER_OF_NIGHTS = 'numberOfNights',
    UNPAID_AMOUNT = 'unpaidAmount',
}

export enum ReceiptItemType {
    PAYMENT = 'payment',
    RECEIPT = 'receipt',
}

export enum PaymentSummaryColumn {
    PAYMENT_METHOD = 'paymentMethod',
    DAYLY_ACCUMULATIVE = 'daylyAccumulative',
    MONTHLY_ACCUMULATIVE = 'monthlyAccumulative',
}

export enum CancellationViewMode {
    DAY = 'day',
    MONTH = 'month',
}

export const cancellationViewModeOptions = () => [
    {
        label: i18next.t('report.cancellation.filterForm.viewMode.day'),
        value: CancellationViewMode.DAY,
    },
    {
        label: i18next.t('report.cancellation.filterForm.viewMode.month'),
        value: CancellationViewMode.MONTH,
    },
];

export enum CancelReason {
    noShow = 'noShow',
    cancelled = 'cancelled',
}

export enum UpdateCancelBookingHistoryAttributes {
    MEMO = 'memo',
    OTA_NOTE = 'otaNote',
}
export enum OperationStatusChannel {
    ALL = 'all',
}

export enum PaymentDetailsSearchField {
    DATE_PERIOD = 'datePeriod',
    PAYMENT_METHOD_IDS = 'paymentMethodIds',
}

export enum DiagramChartEvent {
    TOGGLE_TOOLTIP = 'toggle_tooltip',
}

export const ALL_PAYMENT_METHOD_VALUE = 'all';

export const PRINTED_PAYMENT_METHOD_SHOW_LIMIT = 4;

export const PAYMENT_COLUMN_INDEX_START = 5;

export const marketingChannelPieChartDefaultColors = {
    電話: '#7CC4D3',
    ウォークイン: '#3A9554',
};

export const marketingChannelPieChartColors = [
    '#E2EB98',
    '#ECBA82',
    '#5E5768',
    '#3A445D',
    '#003249',
    '#420C14',
    '#732C2C',
    '#253031',
    '#315659',
    '#EEC643',
    '#D4B483',
    '#C1666B',
    '#832161',
    '#C95D63',
    '#EE8434',
    '#393A10',
    '#FF312E',
    '#2C423F',
    '#4C5B61',
    '#34435E',
    '#B88E8D',
    '#6369D1',
    '#DC9E82',
    '#030027',
    '#151E3F',
    '#361D2E',
    '#E5BE9E',
    '#E1B07E',
    '#3C153B',
    '#8B1E3F',
];

export enum PredefinedDayRange {
    DAYS_30 = 0,
    DAYS_90 = 2,
    DAYS_180 = 5,
}
/* start - Current guest */
export enum CurrentGuestOrderBy {
    ID = 'id',
    GUEST_NAME = 'guestName',
    ROOM_GUEST_NAME = 'roomGuestName',
    CHECK_IN = 'checkInTime',
    ROOM_NAME = 'roomName',
}

export enum CurrentGuestAdultsType {
    NUMBER_OF_ADULTS = 'numberOfAdults',
    NUMBER_OF_MALE = 'numberOfMale',
    NUMBER_OF_FEMALE = 'numberOfFemale',
    NUMBER_OF_OTHER = 'numberOfOtherGenderGuest',
}

export const EXPORT_CSV_CURRENT_GUEST_FILE_NAME = 'current_guest';
export const exportCurrentGuestColumns = [
    'roomName',
    'guestName',
    'roomGuestName',
    'numberOfGuestInRoom',
    'nightOfStay',
    'checkInTime',
    'plan',
    'salePaymentItem',
    'date',
    'numberOfItem',
    'priceOfItem',
    'mealItem',
    'dateMeal',
    'numberOfMealItem',
    'memo',
];

export const i18ExportCurrentGuestKey = 'report.currentGuest';
/* end - Current guest */

// export const EXPORT_CSV_FILE_NAME = 'room-booking';
export const exportSalesPaymentReportColumns = [
    'autoGeneratedCode',
    'roomName',
    'yomigana',
    'saleName',
    'saleAmount',
    'paymentName',
    'paymentAmount',
];

export const exportCancellationReportColumns = [
    'date',
    'time',
    'bookingId',
    'createDate',
    'bookingMethod',
    'checkInDate',
    'numberOfLodgingNights',
    'guestName',
    'cancellationReason',
    'peopleInCharge',
    'memo',
    'daysToCheckInDate',
];

export enum ReportTableId {
    FACILITY_SALES_SUMMARY = 'facility-sales-summary-report-table',
    FACILITY_PAYMENT_SUMMARY = 'facility-payment-summary-report-table',
    SALES_SUMMARY = 'sales-summary-report-table',
    UNPAID_DETAIL = 'unpaid-detail-report-table',
    PAYMENT_SUMMARY = 'payment-summary-report-table',
    LOCAL_TAX = 'local-tax-report-table',
    OPERATION_STATUS = 'operation-status-report-table',
    SALE_BY_CATEGORY = 'sale-by-category-report-table',
    SALE_BY_CATEGORY_DAILY = 'sale-by-category-daily-report-table',
    SALE_BY_CATEGORY_MONTHLY = 'sale-by-category-monthly-report-table',
    CANCELLATION = 'cancellation-report-table',
    PAYMENT_DETAIL = 'payment-detail-report-table',
    SALES_PAYMENT = 'sales-payment-report-table',
    MEAL_REPORT = 'meal-report-table',
    KUTCHAN_LOCAL_TAX = 'kutchan-local-tax-report-table',
    BATH_TAX = 'bath-tax-report-table',
    SALES_BY_PLANS_DAILY = 'sales-by-plans-daily-report-table',
    SALES_BY_PLANS_MONTHLY = 'sales-by-plans-monthly-report-table',
    SALES_BY_OTA_DAILY = 'sales-by-ota-daily-report-table',
    SALES_BY_OTA_MONTHLY = 'sales-by-ota-monthly-report-table',
}

export enum ReportTableCsvFileName {
    FACILITY_SALES_SUMMARY = 'facility-sales-summary-report',
    FACILITY_PAYMENT_SUMMARY = 'facility-payment-summary-report',
    SALES_SUMMARY = 'sales-summary-report',
    UNPAID_DETAIL = 'unpaid-detail-report',
    PAYMENT_SUMMARY = 'payment-summary-report',
    LOCAL_TAX = 'local-tax-report',
    OPERATION_STATUS = 'operation-status-report',
    SALE_BY_CATEGORY_DAILY = 'sale-by-category-report-daily',
    SALE_BY_CATEGORY_MONTHLY = 'sale-by-category-report-monthly',
    CANCELLATION = 'cancellation-report',
    PAYMENT_DETAIL = 'payment-detail-report',
    SALES_PAYMENT = 'sales-payment-report',
    CURRENT_GUEST = 'current-guest-report',
    MEAL_REPORT = 'meal-report',
    KUTCHAN_LOCAL_TAX = 'kutchan-local-tax-report-table',
    BATH_TAX = 'bath-tax-report-table',
    SALES_BY_PLANS_DAILY = 'sales-by-plans-daily-report',
    SALES_BY_PLANS_MONTHLY = 'sales-by-plans-monthly-report',
    SALES_BY_OTA_DAILY = 'sales-by-ota-daily-report',
    SALES_BY_OTA_MONTHLY = 'sales-by-ota-monthly-report',
}

export enum TaxTypes {
    TOKYO_TAX = 'tokyo_tax',
    KUTCHAN_TAX = 'kutchan_tax',
    BATH_TAX = 'bath_tax',
}

export const taxTypeDropdownOptions = () => [
    {
        value: TaxTypes.TOKYO_TAX,
        label: i18next.t(
            'report.localTax.filterForm.taxTypeDropdownOptions.tokyoLocalTax',
        ),
    },
    {
        value: TaxTypes.KUTCHAN_TAX,
        label: i18next.t(
            'report.localTax.filterForm.taxTypeDropdownOptions.kutchanLocalTax',
        ),
    },
    {
        value: TaxTypes.BATH_TAX,
        label: i18next.t('report.localTax.filterForm.taxTypeDropdownOptions.bathTax'),
    },
];
