import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { unpaidDetailBreadcrumbs } from '~features/report/constants';
import { unpaidDetailSelector } from '~features/report/reducers/unpaid-detail.reducer';
import { useAppSelector } from '~hooks';
import FilterForm from './components/FilterForm/FilterForm';
import UnpaidDetailTable from './components/UnpaidDetailTable/UnpaidDetailTable';
import { IUnpaidDetailTableData } from '~features/report/interfaces';
import { convertTimeStringToMinutes } from '~features/report/helper';
import { useMemo } from 'react';
import { parseDate } from '~plugins/dayjs';

function UnpaidDetailPage() {
    const { t } = useTranslation();
    const { unpaidDetailList, showUnpaidDetailTable, selectDate } =
        useAppSelector(unpaidDetailSelector);

    const tableData: IUnpaidDetailTableData[] = useMemo(() => {
        return unpaidDetailList.map((unpaidDetail) => {
            const startDateOfStay = parseDate(unpaidDetail?.startDateOfStay).add(
                convertTimeStringToMinutes(unpaidDetail?.checkInTime),
                'minute',
            );
            const endDateOfStay = parseDate(unpaidDetail?.endDateOfStay).add(
                convertTimeStringToMinutes(unpaidDetail?.checkOutTime),
                'minute',
            );

            return {
                id: unpaidDetail?.id,
                roomName: unpaidDetail?.roomName || '',
                guestName: unpaidDetail?.representativeGuest?.yomigana,
                checkInTime: startDateOfStay,
                checkOutTime: endDateOfStay,
                unpaidAmount: unpaidDetail?.unpaidAmount || 0,
            };
        });
    }, [unpaidDetailList, selectDate]);

    return (
        <div className="unpaid-detail-page">
            <StaffLayoutHeader
                breadcrumbs={unpaidDetailBreadcrumbs()}
                title={t('report.unpaidDetail.title')}
            />
            <Layout.Content>
                <div className="unpaid-detail-page-wrapper">
                    <div className="unpaid-detail-page-content">
                        <FilterForm tableData={tableData} />
                        {showUnpaidDetailTable && (
                            <UnpaidDetailTable tableData={tableData} />
                        )}
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default UnpaidDetailPage;
