import { useMutation } from '@tanstack/react-query';
import { roomBookingServiceV2 } from '~features/room-booking/services/room-bookingV2.service';
import { MutationKeys } from '~queries/queries';

const bulkDeleteBooking = async (bookingIds: number[]) => {
    return await roomBookingServiceV2.bulkDeleteBooking(bookingIds);
};

const useBulkDeleteBooking = () => {
    return useMutation({
        mutationFn: async (bookingIds: number[]) => {
            return bulkDeleteBooking(bookingIds);
        },
        mutationKey: [MutationKeys.bulkDeleteBooking],
    });
};

export default useBulkDeleteBooking;
