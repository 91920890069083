import { MutationKey, useMutation } from '@tanstack/react-query';
import { tempRoomBookingServiceV2 } from '~features/room-booking/services/temp-room-bookingV2.service';

const deleteSingleTempRoomBooking = async (id: number) => {
    const response = await tempRoomBookingServiceV2.deleteSingleTempRoomBooking(id);
    if (response.success) {
        return response;
    } else {
        throw new Error(response.errors![0].message);
    }
};

const useDeleteSingleTempRoomBooking = (key: MutationKey | undefined) => {
    return useMutation({
        mutationFn: async (tempRoomBookingId: number) => {
            return deleteSingleTempRoomBooking(tempRoomBookingId);
        },
        mutationKey: key,
    });
};

export default useDeleteSingleTempRoomBooking;
