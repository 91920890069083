/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from 'antd';
import { debounce } from 'lodash';
import {
    useState,
    useEffect,
    useLayoutEffect,
    useCallback,
    useRef,
    useMemo,
} from 'react';
import ReactDOM from 'react-dom/client';
import { useMitt } from '~plugins/mitt';
import { EmitterGlobalEvent } from './constants';
import { IDirection, IScrollStatus } from './interfaces';
import {
    IRoomBookingItem,
    IRoomBookingSchedule,
} from '~features/room-booking/interfaces';
import { IRoomDropDown } from '~features/room/interfaces';
import { useAppSelector } from '~hooks';
import { roomDropDownListSelector } from '~features/room/room.reducer';
import localStorageAuthService from './authStorage';

const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useSwipeScroll({
    sliderRef,
    reliants = [],
    momentumVelocity = 0.9,
}: any) {
    const [hasSwiped, setHasSwiped] = useState(false);

    const init = useCallback(() => {
        if (!sliderRef.current) return;
        const slider = sliderRef.current as HTMLElement;
        let isDown = false;
        let startX = 0;
        let scrollLeft = 0;
        let startY = 0;
        let scrollTop = 0;

        const onMouseMove = (e: MouseEvent) => {
            if (!isDown) return;
            e.preventDefault();
            // scroll x
            const x = e.pageX - slider.offsetLeft;
            const walkX = (x - startX) * 3; //scroll-fast
            const prevScrollLeft = slider.scrollLeft;
            slider.scrollLeft = scrollLeft - walkX;
            velX = slider.scrollLeft - prevScrollLeft;
            // scroll y
            const y = e.pageY - slider.offsetTop;
            const prevScrollTop = slider.scrollTop;
            const walkY = (y - startY) * 3; //scroll-fast
            slider.scrollTop = scrollTop - walkY;
            velY = slider.scrollTop - prevScrollTop;

            if (slider.scrollLeft - prevScrollLeft && !hasSwiped) {
                setHasSwiped(true);
            }
        };
        const onMouseDown = (e: MouseEvent) => {
            isDown = true;
            slider.classList.add('active');
            // scroll x
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            // scroll y
            startY = e.pageY - slider.offsetTop;
            scrollTop = slider.scrollTop;

            cancelMomentumTracking();
        };
        const onMouseUp = (e: MouseEvent) => {
            isDown = false;
            slider.classList.remove('active');
            beginMomentumTracking();
            setTimeout(() => setHasSwiped(false), 0);
        };
        const onMouseLeave = (e: MouseEvent) => {
            isDown = false;
            slider.classList.remove('active');
        };

        const addMouseEvents = () => {
            slider.addEventListener('mousedown', onMouseDown);
            slider.addEventListener('mouseleave', onMouseLeave);
            slider.addEventListener('mouseup', onMouseUp);
            slider.addEventListener('mousemove', onMouseMove);
        };

        const removeMouseEvents = () => {
            slider.removeEventListener('mousedown', onMouseDown);
            slider.removeEventListener('mouseleave', onMouseLeave);
            slider.removeEventListener('mouseup', onMouseUp);
            slider.removeEventListener('mousemove', onMouseMove);
        };

        // Momentum
        let velX = 0;
        let velY = 0;
        let momentumID = 0;

        slider.addEventListener('wheel', () => {
            cancelMomentumTracking();
        });

        function beginMomentumTracking() {
            cancelMomentumTracking();
            momentumID = requestAnimationFrame(momentumLoop);
        }
        function cancelMomentumTracking() {
            cancelAnimationFrame(momentumID);
        }
        function momentumLoop() {
            // scroll x
            slider.scrollLeft += velX;
            velX *= momentumVelocity;

            // scroll y
            slider.scrollTop += velY;
            velY *= momentumVelocity;

            if (Math.abs(velY) > 0.5 || Math.abs(velX) > 0.5) {
                momentumID = requestAnimationFrame(momentumLoop);
            }
        }

        addMouseEvents();
    }, [sliderRef, reliants, momentumVelocity, hasSwiped]);

    useIsomorphicLayoutEffect(() => {
        init();
    }, [...reliants]);

    const removeListen = () => {
        if (!sliderRef.current) return;
        const slider = sliderRef.current as HTMLElement;
        slider.removeEventListener('mousedown', init);
        slider.removeEventListener('mouseleave', init);
        slider.removeEventListener('mouseup', init);
        slider.removeEventListener('mousemove', init);
        slider.removeEventListener('wheel', init);
    };

    return {
        hasSwiped,
        removeListen,
    };
}

export const useThrottle = () => {
    const busy = useRef(false);

    const throttle = async (callback: () => void, timer = 1000) => {
        setTimeout(() => {
            busy.current = false;
        }, timer);

        if (!busy.current) {
            busy.current = true;
            callback();
        }
    };

    return { throttle };
};

export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

export function useHandleScroll({
    onStopScroll,
}: {
    onStopScroll: (direction: IDirection, status: IScrollStatus) => void;
}) {
    const isDown = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const startY = useRef(0);
    const scrollTop = useRef(0);
    const velX = useRef(0);
    const velY = useRef(0);
    const momentumID = useRef(0);
    const elementRef = useRef<any>();
    const isScrollXAble = useRef(true);
    const momentumVelocity = 0.9;
    const scrollDirectionX = useRef<IDirection>(null);
    const scrollStatus = useRef<IScrollStatus>(null);
    const { emitter } = useMitt();

    useEffect(() => {
        emitter.on(EmitterGlobalEvent.TRIGGER_SCROLL, (val = true) => {
            isScrollXAble.current = val;
        });
        return () => {
            emitter.off(EmitterGlobalEvent.TRIGGER_SCROLL);
        };
    }, [emitter]);

    const onMouseMove = (e: React.MouseEvent<HTMLElement>) => {
        const slider = elementRef?.current as HTMLElement;
        if (!slider) return;
        if (!isDown.current || !isScrollXAble.current) return;
        e.preventDefault();
        // scroll x
        const x = e.pageX - slider.offsetLeft;
        const walkX = (x - startX.current) * 1.5; //scroll-fast
        const prevScrollLeft = slider.scrollLeft;
        slider.scrollLeft = scrollLeft.current - walkX;
        velX.current = slider.scrollLeft - prevScrollLeft;
        scrollDirectionX.current = startX.current >= x ? 'left' : 'right';

        // scroll y
        const y = e.pageY - slider.offsetTop;
        const prevScrollTop = slider.scrollTop;
        const walkY = (y - startY.current) * 1.5; //scroll-fast
        slider.scrollTop = scrollTop.current - walkY;
        velY.current = slider.scrollTop - prevScrollTop;
    };

    const onMouseDown = (e: React.MouseEvent<HTMLElement>) => {
        const slider = elementRef?.current as HTMLElement;
        if (!slider) return;
        if (!isScrollXAble.current) return;
        isDown.current = true;
        slider.classList.add('active');
        // scroll x
        startX.current = e.pageX - slider.offsetLeft;
        scrollLeft.current = slider.scrollLeft;
        // scroll y
        startY.current = e.pageY - slider.offsetTop;
        scrollTop.current = slider.scrollTop;

        cancelMomentumTracking();
    };
    const onMouseUp = () => {
        isDown.current = false;
        const slider = elementRef?.current as HTMLElement;
        if (!slider) return;
        if (!isScrollXAble.current) return;
        slider.classList.remove('active');
        beginMomentumTracking();
    };
    const onMouseLeave = () => {
        const slider = elementRef?.current as HTMLElement;
        if (!slider) return;
        if (!isScrollXAble.current) return;
        // if (!canSwipe) return;
        isDown.current = false;
        slider.classList.remove('active');
    };

    function beginMomentumTracking() {
        cancelMomentumTracking();
        momentumID.current = requestAnimationFrame(momentumLoop);
    }
    function cancelMomentumTracking() {
        cancelAnimationFrame(momentumID.current);
    }

    const stopScroll = debounce(() => {
        onStopScroll(scrollDirectionX.current, scrollStatus.current);
        scrollDirectionX.current = null;
    }, 50);

    function momentumLoop() {
        const slider = elementRef?.current as HTMLElement;
        if (!slider) return;
        // scroll x
        slider.scrollLeft += velX.current;
        velX.current *= momentumVelocity;
        stopScroll();

        // scroll y
        slider.scrollTop += velY.current;
        velY.current *= momentumVelocity;

        if (Math.abs(velY.current) > 0.5 || Math.abs(velX.current) > 0.5) {
            momentumID.current = requestAnimationFrame(momentumLoop);
        }
    }

    function onWheel(event: any) {
        if (!isScrollXAble.current) return;
        if (event.deltaY > 0 || event.deltaY < 0) {
            cancelMomentumTracking();
        }
        if (event.deltaX > 0) {
            scrollDirectionX.current = 'left';
        }
        if (event.deltaX < 0) {
            scrollDirectionX.current = 'right';
        }
        stopScroll();
    }

    return {
        onMouseMove,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        elementRef,
        isScrollXAble,
        onWheel,
        scrollStatus,
        scrollDirectionX,
    };
}

export const useBookingCardHover = (
    renderItem: (val: boolean) => React.ReactNode,
    _onMouseLeave: () => void,
    useToolTip: boolean,
) => {
    const bookingTooltipRef = useRef<any>();
    const editElement = useRef<any>();
    const isHovering = useRef(false);

    const onMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
        if (isHovering.current) return;
        isHovering.current = true;
        if (!useToolTip) return;
        const id = 'booking-detail-tooltip';
        if (bookingTooltipRef.current) {
            bookingTooltipRef.current?.unmount();
        }
        let element = document.getElementById(id);
        if (!element) {
            element = document.createElement('div');
            element.setAttribute('id', id);
            element.style.position = 'absolute';
            document.body.appendChild(element);
        }
        const top = (e.target as HTMLElement)?.getBoundingClientRect()?.top || 0;
        element.style.left = e.pageX + 'px';
        element.style.top = top + 'px';
        bookingTooltipRef.current = ReactDOM.createRoot(element);
        const toolTipElement = () => {
            return (
                <Tooltip open={true} title={renderItem(false)}>
                    <div></div>
                </Tooltip>
            );
        };
        bookingTooltipRef.current?.render(toolTipElement());
    };

    const onMouseLeave = () => {
        bookingTooltipRef.current?.unmount();
        isHovering.current = false;
        _onMouseLeave();
    };

    const createNewElement = (e: React.MouseEvent<HTMLElement>) => {
        const element = document.createElement('div');
        element.style.left = e.pageX + 'px';
        element.style.top = e.pageY + 'px';
        element.style.position = 'absolute';
        document.body.appendChild(element);
        editElement.current = element;
        return element;
    };

    const removeElement = () => {
        editElement.current?.remove();
        editElement.current = undefined;
    };

    const onMouseDown = () => {
        removeElement();
        bookingTooltipRef.current?.unmount();
        isHovering.current = false;
    };

    useEffect(() => {
        return () => {
            onMouseLeave();
            removeElement();
            _onMouseLeave();
        };
    }, []);

    return {
        onMouseEnter,
        onMouseLeave,
        editElement,
        createNewElement,
        removeElement,
        onMouseDown,
    };
};

export const useRoomOptions = (
    item: IRoomBookingItem | IRoomBookingSchedule | null,
    roomTypeId: number | null,
) => {
    const rooms = useAppSelector(roomDropDownListSelector);
    const roomInit = {
        id: item?.room?.id || NaN,
        name: item?.room?.name || '',
        roomTypeId: item?.roomType?.id || NaN,
        roomTypeName: '',
        abbreviation: '',
    };

    const [state, setState] = useState<'init' | 'loading' | 'finished'>('init');

    const roomOptsMemoized = useMemo(() => {
        let roomOpts: IRoomDropDown[] = [];

        if (state === 'init' && item) {
            setState('loading');
            if (rooms.length === 0) {
                roomOpts = [roomInit];
            } else {
                roomOpts = rooms.filter((i) => i.roomTypeId === roomTypeId);
                const idx = rooms.findIndex((i) => i.id === item.room?.id);
                if (idx === -1) {
                    roomOpts = [...roomOpts, roomInit];
                }
            }
        } else if (state === 'loading') {
            setState('finished');
            roomOpts = rooms.filter((item) => item.roomTypeId === roomTypeId);
        }

        return roomOpts.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [rooms, roomTypeId]);

    useEffect(() => {
        if (state === 'finished') {
            setState('init');
        }
    }, [item, state]);

    return [roomOptsMemoized, state !== 'finished', rooms] as const;
};

export function useEscape(onClose: Function) {
    useEffect(() => {
        const closeModal = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keyup', closeModal);
        return () => window.removeEventListener('keyup', closeModal);
    }, []);
}

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useLayoutEffect(() => {
        const updateSize = (): void => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', debounce(updateSize, 250));
        return (): void => window.removeEventListener('resize', updateSize);
    }, []);

    return isMobile;
}

export function useSelectedHotel() {
    const [selectedHotel, setSelectedHotel] = useState(() =>
        localStorageAuthService.getSelectedHotel(),
    );

    return selectedHotel;
}
