import { notification } from 'antd';
import get from 'lodash/get';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import convert from 'xml-js';
import { parseErrorMessage, paseXmlObject } from '~common/commonFunctions';
import {
    EXCEL_ACCEPTED_FILE_TYPES,
    MAX_FILE_SIZE_IN_BYTE,
    XML_ACCEPTED_FILE_TYPES,
} from '~common/constants';
import { useSelectedHotel } from '~common/useHooks';
import { mapDataToImportXMLBody } from '~features/room-booking/helper';
import { IImportXmlBody } from '~features/room-booking/interfaces';
import {
    fetchRoomBookingList,
    roomBookingListQuerySelector,
    setRoomBookingListQuery,
} from '~features/room-booking/reducers/room-booking.reducer';
import { importXmlSchema } from '~features/room-booking/schema';
import { roomBookingService } from '~features/room-booking/services/room-booking.service';
import { tllXmlServiceV2 } from '~features/room-booking/services/tll-xml.service';
import { useAppDispatch, useAppSelector } from '~hooks';

function ImportXml(_: unknown, ref: React.Ref<{ onClickImport: () => void }>) {
    const { t } = useTranslation();
    const [importErrorMessage, setImportErrorMessage] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [fileContent, setFileContent] = useState<string>('');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useAppDispatch();
    const roomBookingListQuery = useAppSelector(roomBookingListQuerySelector);
    const hotel = useSelectedHotel();

    useImperativeHandle(ref, () => ({
        onClickImport() {
            if (inputRef.current) {
                setFile(null);
                setFileContent('');
                inputRef.current.click();
            }
        },
    }));

    // Validate file before import
    const validateFileData = (file: File) => {
        const isValidFileSize = file?.size < MAX_FILE_SIZE_IN_BYTE;
        if (!file) {
            setImportErrorMessage(t('common.importCsv.rules.empty') as string);
            return false;
        }
        if (file.name) {
            const finalFileName = file.name.split('.');
            if (
                !XML_ACCEPTED_FILE_TYPES.includes(finalFileName[finalFileName.length - 1])
            ) {
                setImportErrorMessage(
                    t('common.importCsv.rules.invalidType.xml') as string,
                );
                return false;
            }
        }
        if (!isValidFileSize) {
            setImportErrorMessage(t('common.importCsv.rules.tooBig') as string);
            return false;
        }
        return true;
    };

    const handleUploadSuccess = async () => {
        notification.success({
            message: t('roomBooking.list.importXml.success'),
        });
        // Reload room booking list
        const roomBookingListResponse = await dispatch(
            fetchRoomBookingList(roomBookingListQuery),
        );
        if (fetchRoomBookingList.fulfilled.match(roomBookingListResponse)) {
            if (
                roomBookingListResponse.payload?.success &&
                roomBookingListResponse.payload?.data?.items?.length === 0 &&
                roomBookingListQuery.page &&
                roomBookingListQuery?.page > 1
            ) {
                dispatch(
                    setRoomBookingListQuery({
                        ...roomBookingListQuery,
                        page: roomBookingListQuery.page - 1,
                    }),
                );
            }
        }
    };

    const _importXml = async (fileJson: IImportXmlBody) => {
        const response = await roomBookingService.importXml(fileJson);

        if (response.success) {
            await handleUploadSuccess();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    useEffect(() => {
        if (file && fileContent?.length && validateFileData(file)) {
            if (hotel && hotel.useNewTllLib) {
                tllXmlServiceV2
                    .importXml(fileContent)
                    .then(() => {
                        handleUploadSuccess();
                    })
                    .catch((error) => {
                        notification.error({
                            message: parseErrorMessage(error.message),
                        });
                    });
            } else {
                const jsonContent = convert.xml2json(fileContent, {
                    compact: true,
                    alwaysChildren: true,
                    ignoreComment: true,
                    ignoreDeclaration: true,
                    alwaysArray: true,
                });
                const fileJson = JSON.parse(jsonContent);
                paseXmlObject(fileJson);
                const importXMLBody = mapDataToImportXMLBody(fileJson);
                importXmlSchema
                    .validate(importXMLBody)
                    .then(() => {
                        // Import XML
                        _importXml(importXMLBody);
                    })
                    .catch((error) => {
                        notification.error({ message: parseErrorMessage(error.message) });
                    });
            }
        }
    }, [file, fileContent]);

    const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = get(event.target, 'files[0]', '') as File;
        const reader = new FileReader();
        if (file?.size) {
            reader.readAsText(file);
            reader.addEventListener(
                'load',
                () => {
                    setFileContent(reader.result as string);
                },
                false,
            );
            setFile(file);
            event.target.value = '';
        }
    };

    useEffect(() => {
        if (importErrorMessage) {
            notification.error({
                message: importErrorMessage,
            });
        }
        setImportErrorMessage('');
    }, [importErrorMessage]);

    return (
        <div className="import-csv-wrapper">
            <input
                type="file"
                ref={inputRef}
                accept={EXCEL_ACCEPTED_FILE_TYPES.join(',')}
                onChange={selectFile}
                className="custom-file-input"
                hidden
            />
        </div>
    );
}

export default forwardRef(ImportXml);
