const hotelForm = {
    peCsrHotelCode: 'ホテルID',
    peCsrHotelCodeInput: '例 : 0001',
    hotelName: 'ホテル名',
    hotelNameInput: '例 : Tabist Hotel',
    picName: '担当者',
    picNameInput: '例：山田 太郎',
    postalCode: '郵便番号',
    postalCodeInput: '例：１０５７５０１',
    address: '住所',
    addressInput: '例 : 東京都港区海岸1-7-1 東京ポートシティ竹芝 WeWork11F',
    picPhoneNumber: '連絡先電話番号',
    picPhoneNumberInput: '例 : 0701234567',
    picEmailAddress: '連絡先メールアドレス',
    picEmailAddressInput: '例 : yamadatarou@gmail.com',
    tllSystemId: 'システム識別ID',
    tllSystemIdInput: '例 : 0701234533',
    tllPmsUserId: '連動用ID',
    tllPmsUserIdInput: '例 : 0001',
    tllPmsPassword: '連動用パスワード',
    tllPmsPasswordInput: 'パスワードを入力してください',
    numberOfRoom: '部屋数',
    numberOfRoomInput: '例：１０００',
    standardCheckInTime: '標準チェックイン',
    standardCheckInTimeInput: '時間を選択',
    standardCheckOutTime: '標準チェックアウト',
    standardCheckOutTimeInput: '時間を選択',
    active: 'ステータス',
    create: {
        title: '新しいホテルを作成',
        success: '新しいホテルの登録されしました。',
        error: '新しいホテルの登録ができませんでした',
    },
    update: {
        title: 'ホテル詳細',
        active: 'アクティブ',
        success: 'ホテルが更新されました',
        error: 'ホテルの更新ができませんでした',
    },
    confirm: {
        cancelTitle: 'キャンセルしてもよろしいですか？',
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
    },
    nameDuplicated: '名前が重複しています',
    submitButton: '登録',
    cancelButton: 'キャンセル',
    cleaningStatus: '清掃ステータス表示',
    cardKey: 'カードキー',
};
export default hotelForm;
