import { RoomBooking } from 'src/types/v2/room-booking/room-booking.type';
import { IBodyResponseTsRest } from '~common/interfaces';
import { axiosInstanceV2 } from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import { ICreateGroupTempRoomBooking } from '../interfaces/temp-room-booking/create-temp-room-booking.interface';
import { IUpdateSingleTempRoomBooking } from '../interfaces/temp-room-booking/update-temp-room-booking.interface';

class TempRoomBookingServiceV2 extends ApiService {
    createGroupTempRoomBooking(
        params: ICreateGroupTempRoomBooking,
        isAcceptOverbooking: boolean,
    ) {
        return this._post(
            `${this.baseUrl}?isAcceptOverbooking=${isAcceptOverbooking}`,
            params,
        ) as Promise<IBodyResponseTsRest<RoomBooking[]>>;
    }

    deleteSingleTempRoomBooking(tempRoomBookingId: number) {
        return this._delete(`${this.baseUrl}/${tempRoomBookingId}`) as Promise<
            IBodyResponseTsRest<null>
        >;
    }

    deleteGroupTempRoomBookings(tempRoomBookingIds: number[]) {
        return this._delete(`${this.baseUrl}`, {
            data: { tempRoomBookingIds },
        }) as Promise<IBodyResponseTsRest<null>>;
    }

    updateSingleTempRoomBooking(
        tempRoomBookingId: number,
        params: IUpdateSingleTempRoomBooking,
        isAcceptOverbooking?: boolean,
    ) {
        return this._patch(
            `${this.baseUrl}/${tempRoomBookingId}?isAcceptOverbooking=${isAcceptOverbooking}`,
            params,
        ) as Promise<IBodyResponseTsRest<RoomBooking>>;
    }
}

export const tempRoomBookingServiceV2 = new TempRoomBookingServiceV2(
    {
        baseUrl: '/temp-room-booking',
    },
    axiosInstanceV2,
);
