import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Button, Typography, notification, Row, Col } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    CalendarOutlined,
    ArrowRightOutlined,
} from '@ant-design/icons';

import dayjs from 'dayjs';
import { parseDate } from '~plugins/dayjs';

import { ModalConfirmDeletion } from '~components';
import { BookingErrorModal } from '~features/room-booking/pages/RoomBookingListPage/components/RoomBookingList/BookingErrorModal';

import { useAppDispatch } from '~hooks';
import {
    bulkDeleteRoomBookingItems,
    setSelectedRoomBookingDetail,
} from '~features/room-booking/reducers/room-booking.reducer';
import { setSelecting } from '~features/room-booking/reducers/schedule.reducer';

import { checkUserPermission } from '~common/commonFunctions';
import {
    checkRoomBookingItemCanDelete,
    getRoomTypeName,
} from '~features/room-booking/helper';
import { isFrozenBooking } from '~features/room-booking/helper.update-booking';
import { showRoomBookingItemDetailModal } from '~features/room-booking/util';

import {
    RoomBookingItemBookingStatus,
    RoomBookingItemBookingStatusColor,
} from '~features/room-booking/constants';
import { UserGroup } from '~common/constants';
import { IRoomBookingDetail, IRoomBookingItem } from '~features/room-booking/interfaces';
import { checkHasOverlappingDatesInRoomBookingItems } from '~common/helper';
import localStorageAuthService from '~common/authStorage';
import { ReactComponent as CleanRoomIcon } from '~assets/images/clean-room.svg';
import { WarningFilled } from '@ant-design/icons';

import './RoomBookingItemList.scss';
import { CleaningStatus } from '~features/room-cleaning/constants';

const { Text } = Typography;

interface BookingDetailItemsProps {
    roomBookingDetail: IRoomBookingDetail | null;
    fetchRoomBookingDetail: (id: number) => void;
}
export default function BookingDetailItems({
    roomBookingDetail,
    fetchRoomBookingDetail,
}: BookingDetailItemsProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const hotelStored = localStorageAuthService.getSelectedHotel();

    // PMS-4661 Room Booking Item list order should be ascending order by checkin date.
    // also parent/children room bookings should be ascending order by checkin date and then roomId
    const bookingItems = useMemo(() => {
        if (!roomBookingDetail) return [];

        const bookingItems = getRoomBookingItems(roomBookingDetail);

        // putting parent room bookings first in array
        bookingItems.sort((a, b) =>
            (a?.parentRoomBookingItemId || 0) < (b?.parentRoomBookingItemId || 0)
                ? -1
                : 1,
        );

        const groupedBookingItems = bookingItems.reduce((acc, bookingItem) => {
            if (!bookingItem.parentRoomBookingItemId) {
                acc.push({ parent: bookingItem, children: [] });
            } else {
                const parent = acc.find(
                    (parentItem) =>
                        Number(parentItem.parent.id) ===
                        bookingItem.parentRoomBookingItemId,
                );
                if (parent) {
                    parent.children.push(bookingItem);
                }
            }

            return acc;
        }, [] as { parent: IRoomBookingItem; children: IRoomBookingItem[] }[]);

        // sort booking items as parent/children groups
        groupedBookingItems.sort((a, b) => {
            // first sort parents by startDate first and then endDate
            const parentStartDateA = parseDate(a.parent.startDateOfStay);
            const parentStartDateB = parseDate(b.parent.startDateOfStay);

            if (parentStartDateA.isSame(parentStartDateB)) {
                const parentEndDateA = parseDate(a.parent.endDateOfStay);
                const parentEndDateB = parseDate(b.parent.endDateOfStay);

                if (parentEndDateA.isSame(parentEndDateB)) {
                    const maxChildrenItemCount = Math.max(
                        a.children.length,
                        b.children.length,
                    );

                    // if there is no difference in start/end dates, then compare children's start/end dates and roomId one by one
                    if (maxChildrenItemCount) {
                        for (let i = 0; i <= maxChildrenItemCount; i++) {
                            const childStartDateA = parseDate(
                                a.children[i]?.startDateOfStay,
                            );
                            const childStartDateB = parseDate(
                                b.children[i]?.startDateOfStay,
                            );

                            if (childStartDateA.isBefore(childStartDateB)) return -1;
                            if (childStartDateA.isAfter(childStartDateB)) return 1;

                            const childEndDateA = parseDate(a.children[i]?.endDateOfStay);
                            const childEndDateB = parseDate(b.children[i]?.endDateOfStay);

                            if (childEndDateA.isBefore(childEndDateB)) return -1;
                            if (childEndDateA.isAfter(childEndDateB)) return 1;

                            const childRoomIdA = a.children[i]?.room?.id;
                            const childRoomIdB = b.children[i]?.room?.id;

                            if (
                                childRoomIdA &&
                                childRoomIdB &&
                                childRoomIdA < childRoomIdB
                            )
                                return -1;
                            if (
                                childRoomIdA &&
                                childRoomIdB &&
                                childRoomIdA > childRoomIdB
                            )
                                return 1;
                        }
                    } else {
                        // if there is no children, sort by roomId as well
                        const parentRoomIdA = a.parent.room?.id;
                        const parentRoomIdB = b.parent.room?.id;

                        if (
                            parentRoomIdA &&
                            parentRoomIdB &&
                            parentRoomIdA < parentRoomIdB
                        )
                            return -1;
                        if (
                            parentRoomIdA &&
                            parentRoomIdB &&
                            parentRoomIdA > parentRoomIdB
                        )
                            return 1;
                    }
                } else {
                    if (parentEndDateA.isBefore(parentEndDateB)) return -1;
                    if (parentEndDateA.isAfter(parentEndDateB)) return 1;
                }
            } else {
                if (parentStartDateA.isBefore(parentStartDateB)) return -1;
                if (parentStartDateA.isAfter(parentStartDateB)) return 1;
            }

            // if there is no difference in both parent and children, keep the order
            return 0;
        });

        const sortedBookingItems = groupedBookingItems.reduce((acc, group) => {
            acc.push(group.parent);
            acc.push(
                ...group.children.sort((a, b) => {
                    const childStartDateA = parseDate(a.startDateOfStay);
                    const childStartDateB = parseDate(b.startDateOfStay);

                    if (childStartDateA.isBefore(childStartDateB)) return -1;
                    if (childStartDateA.isAfter(childStartDateB)) return 1;

                    const childEndDateA = parseDate(a.endDateOfStay);
                    const childEndDateB = parseDate(b.endDateOfStay);

                    if (childEndDateA.isBefore(childEndDateB)) return -1;
                    if (childEndDateA.isAfter(childEndDateB)) return 1;

                    return 0;
                }),
            );

            return acc;
        }, [] as IRoomBookingItem[]);

        return sortedBookingItems;
    }, [roomBookingDetail?.roomBookingItems]);

    const handleEditRoomBookingItem = (roomBookingItem: IRoomBookingItem) => {
        const element = document.getElementById(
            `room-booking-item-grid-id-${roomBookingItem.id}`,
        );
        if (!element) return;
        dispatch(setSelecting(false));
        showRoomBookingItemDetailModal({
            bookingItem: roomBookingItem,
            roomBookingId: roomBookingDetail?.id || 0,
            isFromTll: !!roomBookingDetail?.tllDataId,
            element,
            isSingleBooking: bookingItems?.length === 1,
            isFrozen: isFrozenBooking(bookingItems),
        });
    };

    const deleteBookingItem = (roomBookingItem: IRoomBookingItem) => {
        ModalConfirmDeletion({
            deletedItems: [],
            buttonCancelText: t('common.buttonCancelText'),
            buttonDeleteText: t('common.buttonDeleteText'),
            okButtonProps: { danger: true },
            onClickButtonDelete: () => onConfirmDeletion(roomBookingItem),
        });
    };

    const onConfirmDeletion = async (booking: IRoomBookingItem) => {
        const roomBookingItemToDeleteFirst = bookingItems.filter(
            (bookingItem) => bookingItem.parentRoomBookingItemId === booking.id,
        );

        if (roomBookingItemToDeleteFirst.length) {
            BookingErrorModal({
                title: t('roomBooking.list.message.titleDelete'),
                okText: t('roomBooking.list.statusModalConfirm.okText'),
                description: t(
                    'roomBooking.detail.deleteConfirmDialog.deleteAssociatedBookingsFirstError',
                ),
                errorItems: [],
            });
            return;
        }

        const isBookingWalkIn = !roomBookingDetail?.marketingChannel?.isPullFromTll;
        if (
            !checkRoomBookingItemCanDelete(
                booking,
                checkUserPermission([UserGroup.ADMIN]),
                checkUserPermission([UserGroup.HOTEL_ADMIN]) && isBookingWalkIn,
            )
        ) {
            BookingErrorModal({
                title: t('roomBooking.list.message.titleDelete'),
                okText: t('roomBooking.list.statusModalConfirm.okText'),
                description: t(
                    'roomBooking.detail.deleteConfirmDialog.deleteByStatusError',
                ),
                errorItems: [],
            });
            return;
        }
        if (!Number(booking.id)) return;
        const response = await dispatch(
            bulkDeleteRoomBookingItems([booking.id as number]),
        );
        if (bulkDeleteRoomBookingItems.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.detail.message.deleteSuccess'),
                });
                if (roomBookingDetail && bookingItems?.length - 1 > 0) {
                    fetchRoomBookingDetail(roomBookingDetail.id);
                } else {
                    navigate('/room-booking');
                    dispatch(setSelectedRoomBookingDetail(null));
                }
                return;
            }
            notification.error({
                message: response.payload?.message || '',
            });
        }
    };

    const showCalendarIcon = (
        targetBookingItem: IRoomBookingItem,
        bookingItems: IRoomBookingItem[],
    ) => {
        // show calender icon for nakanuke/swicth rooms, only when
        // 1) booking item is a parent booking item
        // 2) room is assigned to all parent/children target booking items
        // 3) booking item is not day use
        // 4) checkout date is a today or future date
        // 5) there is no cancelled children booking items
        return (
            !targetBookingItem.parentRoomBookingItemId &&
            bookingItems
                .filter(
                    (bookingItem) =>
                        bookingItem.id === targetBookingItem.id ||
                        bookingItem.parentRoomBookingItemId === targetBookingItem.id,
                )
                .every((bookingItem) => bookingItem?.room?.id) &&
            !targetBookingItem.isDayUse &&
            dayjs(targetBookingItem.endDateOfStay)
                .startOf('day')
                .isSameOrAfter(dayjs()) &&
            bookingItems
                ?.filter(
                    (bookingItem) =>
                        bookingItem.id === targetBookingItem.id ||
                        bookingItem.parentRoomBookingItemId === targetBookingItem.id,
                )
                ?.every(
                    (bookingItem) =>
                        bookingItem.bookingStatus !==
                        RoomBookingItemBookingStatus.CANCELLED,
                )
        );
    };

    const renderCleaningStatus = (cleaningStatus: string | undefined) => {
        if (cleaningStatus === CleaningStatus.CLEANED) {
            return (
                <span className="room-clean text-success">
                    <CleanRoomIcon />
                    {t('roomManagement.list.cleaned')}
                </span>
            );
        } else if (cleaningStatus === CleaningStatus.UNCLEANED) {
            return (
                <span className="room-clean text-danger">
                    <WarningFilled />
                    {t('roomManagement.list.notCleaned')}
                </span>
            );
        }
        return <></>;
    };

    return (
        <>
            {bookingItems.map((bookingItem) => (
                <Row
                    key={bookingItem.id}
                    className={`booking-item-row ${
                        bookingItem.parentRoomBookingItemId ? 'child' : 'parent'
                    }`}
                >
                    <Col span={3}>
                        <Text
                            strong
                            style={{
                                color: RoomBookingItemBookingStatusColor[
                                    bookingItem.bookingStatus as RoomBookingItemBookingStatus
                                ],
                                display: 'list-item',
                                listStyle: 'disc',
                                listStylePosition: 'inside',
                                paddingLeft: '8px',
                            }}
                        >
                            {t(
                                `roomBooking.page.bookingStatus.${bookingItem.bookingStatus}`,
                            )}
                        </Text>
                    </Col>
                    <Col span={3}>
                        <Text strong>
                            {bookingItem.representativeGuest?.yomigana || ''}
                        </Text>
                        {bookingItem.representativeGuest?.yomigana !==
                            bookingItem.representativeGuest?.fullName && (
                            <Text style={{ paddingLeft: 6 }}>
                                {bookingItem.representativeGuest.fullName}
                            </Text>
                        )}
                    </Col>
                    <Col span={6}>
                        <div className="d-flex a-center">
                            <div className="d-flex f-col">
                                <Text strong>
                                    {parseDate(bookingItem.startDateOfStay).fmYYYYMMDD()}
                                </Text>
                                <Text>{bookingItem.checkInTime}</Text>
                            </div>
                            <ArrowRightOutlined className="ml-8 mr-8" />
                            <div className="d-flex f-col">
                                <Text strong>
                                    {parseDate(bookingItem.endDateOfStay).fmYYYYMMDD()}
                                </Text>
                                <Text>{bookingItem.checkOutTime}</Text>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="d-flex f-col">
                            <Text strong>{formatRoomName(bookingItem)}</Text>
                            <Text>{bookingItem.plan?.name}</Text>
                            {hotelStored?.showCleaningStatus && (
                                <div>
                                    {renderCleaningStatus(
                                        bookingItem?.room?.cleaningStatus,
                                    )}
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="d-flex">
                            <div className="d-flex f-col">
                                <Text>{t('roomBooking.schedule.adult')}</Text>
                                <Text className="mt-4">
                                    {t('roomBooking.schedule.kid')}
                                </Text>
                            </div>
                            <div className="d-flex f-col">
                                <Text>
                                    :&nbsp;{bookingItem.numberOfAdults}&nbsp;
                                    {bookingItem.numberOfMale ||
                                    bookingItem.numberOfFemale ||
                                    bookingItem.numberOfOtherGenderGuest
                                        ? t('roomBooking.schedule.genderBreakdown', {
                                              male: bookingItem.numberOfMale || 0,
                                              female: bookingItem.numberOfFemale || 0,
                                              other:
                                                  bookingItem.numberOfOtherGenderGuest ||
                                                  0,
                                          })
                                        : ''}
                                </Text>
                                <Text className="mt-4">
                                    :&nbsp;{getChildrenNumber(bookingItem)}
                                </Text>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="d-flex j-end">
                            {showCalendarIcon(bookingItem, bookingItems) && (
                                <Button
                                    type="text"
                                    size="middle"
                                    icon={
                                        <CalendarOutlined
                                            style={{
                                                fontSize: '20px',
                                            }}
                                        />
                                    }
                                    onClick={() => {
                                        // PMS-4661 if booking is splitted booking and has overlapped booking dates, do not allow to split booking anymore
                                        const hasOverlappingDates =
                                            checkHasOverlappingDatesInRoomBookingItems(
                                                bookingItems.filter(
                                                    (b) =>
                                                        b.id === bookingItem.id ||
                                                        b.parentRoomBookingItemId ===
                                                            bookingItem.id,
                                                ),
                                            );

                                        if (hasOverlappingDates) {
                                            return notification.warning({
                                                message: t(
                                                    'roomBooking.page.splipBookingProtected.reason',
                                                ),
                                            });
                                        }

                                        return navigate(
                                            `/room-booking/${roomBookingDetail?.id}/detail/${bookingItem?.id}`,
                                        );
                                    }}
                                >
                                    <div style={{ position: 'absolute' }} />
                                </Button>
                            )}
                            <Button
                                type="text"
                                size="middle"
                                icon={<EditOutlined style={{ fontSize: '20px' }} />}
                                onClick={() => handleEditRoomBookingItem(bookingItem)}
                            >
                                <div
                                    id={`room-booking-item-grid-id-${bookingItem.id}`}
                                    style={{ position: 'absolute' }}
                                />
                            </Button>
                            <Button
                                type="text"
                                size="middle"
                                icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
                                onClick={() => deleteBookingItem(bookingItem)}
                            >
                                <div style={{ position: 'absolute' }} />
                            </Button>
                        </div>
                    </Col>
                </Row>
            ))}
        </>
    );
}

const getRoomBookingItems = (roomBookingDetail: IRoomBookingDetail) => {
    const items = [...roomBookingDetail.roomBookingItems];
    items.sort((a, b) => {
        if (!a.representativeGuest) return +1;
        if (!b.representativeGuest) return -1;
        if (a.representativeGuest.yomigana === b.representativeGuest.yomigana) {
            if (dayjs(a.startDateOfStay).isBefore(dayjs(b.startDateOfStay))) {
                return 1;
            } else if (dayjs(a.startDateOfStay).isSame(dayjs(b.startDateOfStay))) {
                return 0;
            }
            return -1;
        }
        return a.representativeGuest.yomigana.localeCompare(
            b.representativeGuest.yomigana,
        );
    });
    return items;
};

function formatRoomName(detail: IRoomBookingItem) {
    let roomName = detail.room ? `/ ${detail.room.name}` : '';
    if (detail.bookingStatus === RoomBookingItemBookingStatus.CANCELLED) {
        roomName = '';
    }
    return `${getRoomTypeName(detail.roomType)} ${roomName}`;
}

const getChildrenNumber = (detail: IRoomBookingItem) =>
    detail.roomBookingItemChildrenTypes?.length
        ? detail.roomBookingItemChildrenTypes.reduce(
              (sum, item) => sum + item.quantity,
              0,
          )
        : 0;
