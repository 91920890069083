import { Modal } from 'antd';
import {
    CSSProperties,
    FC,
    ReactNode,
    MutableRefObject,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    IFacilityBookingScheduleItem,
    IGetCalculatedAmountQuery,
} from '~features/facility-booking/interfaces';
import { getCalculatedAmount } from '~features/facility-booking/reducers/facility-booking.reducer';
import { useDrag, useDragDropManager } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { VariableSizeGrid } from 'react-window';
import {
    facilityBookingStateSelector,
    setCurrentDragData,
    setIsDragging,
    updateFacilityBookingFacility,
    updateBooking,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { RoomBookingEvent } from '~features/room-booking/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';

const styleDefault: CSSProperties = {
    position: 'absolute',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    zIndex: 10,
};

type Props = {
    booking: IFacilityBookingScheduleItem;
    hideSourceOnDrag?: boolean;
    children?: ReactNode;
    style?: CSSProperties;
    left: number;
    width: number;
    top: number;
    endDrag: () => void;
    onMouseLeave?: () => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
    onMouseDown?: (e: React.MouseEvent<HTMLElement>) => void;
    gridRef: MutableRefObject<VariableSizeGrid>;
    outerRef: MutableRefObject<HTMLElement | undefined>;
    gridViewContentHeightRef?: MutableRefObject<number | undefined>;
};

export const DragBox: FC<Props> = ({
    booking,
    hideSourceOnDrag,
    children,
    style,
    width,
    left,
    top,
    onMouseLeave,
    onMouseEnter,
    onMouseDown,
    endDrag,
    gridRef,
    outerRef,
    gridViewContentHeightRef,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { currentDragData } = useAppSelector(facilityBookingStateSelector);
    const [isClickHold, setIsClickHold] = useState(false);
    const _getCalculatedAmount = useCallback(
        async (bookingTimes: string[], facilityId: number, bookingId: number) => {
            if (bookingTimes && facilityId) {
                const query: IGetCalculatedAmountQuery = {
                    bookingId,
                    facilityId,
                    startDatetime: parseDate(bookingTimes[0])?.fmYYYYMMDDHHmmss('-'),
                    endDatetime: parseDate(bookingTimes[1])?.fmYYYYMMDDHHmmss('-'),
                };
                const response = await dispatch(getCalculatedAmount(query));
                if (getCalculatedAmount.fulfilled.match(response)) {
                    return response.payload?.data?.amount || 0;
                }
            }
        },
        [],
    );
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'box',
            item: booking,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            isDragging: (monitor) => {
                return (
                    currentDragData?.booking?.id === booking.id ||
                    booking.id === monitor.getItem().id
                );
            },
            end: async (item, monitor) => {
                dispatch(setIsDragging(false));
                dispatch(setCurrentDragData(null));
                setIsClickHold(false);
                endDrag();
                const dropResult = monitor.getDropResult();
                if (!dropResult) return;

                const { facilityId: toFacilityId } = dropResult as {
                    facilityId: number;
                };

                if (toFacilityId === booking.facilityId) {
                    return;
                }

                if (toFacilityId) {
                    const oldFacilityId = booking.facilityId;
                    const newBooking = { ...item, facilityId: toFacilityId };
                    dispatch(updateBooking({ booking: newBooking }));
                    emitter.emit(RoomBookingEvent.UPDATE_BOOKING_POSITION, {
                        bookingId: booking.id,
                        left,
                        width,
                        roomId: toFacilityId,
                    });
                    emitter.emit(RoomBookingEvent.REMOVE_BOOKING_POSITION, {
                        bookingId: booking.id,
                        roomId: booking.facilityId,
                    });
                    const response = await dispatch(
                        updateFacilityBookingFacility({
                            id: item.id,
                            facilityId: toFacilityId,
                        }),
                    );
                    if (updateFacilityBookingFacility.fulfilled.match(response)) {
                        if (response.payload?.success) {
                            const totalAmount = await _getCalculatedAmount(
                                [item.checkInDateTime, item.checkOutDateTime],
                                toFacilityId,
                                booking.id,
                            );
                            dispatch(
                                updateBooking({
                                    booking: { ...newBooking, totalAmount },
                                }),
                            );
                            return;
                        }
                        Modal.error({
                            title: t('common.somethingWentWrong'),
                            content: response.payload?.message || '',
                            centered: true,
                        });
                        dispatch(
                            updateBooking({
                                booking: { ...item, facilityId: oldFacilityId },
                            }),
                        );
                        emitter.emit(RoomBookingEvent.UPDATE_BOOKING_POSITION, {
                            bookingId: booking.id,
                            left,
                            width,
                            roomId: booking.facilityId,
                        });
                        emitter.emit(RoomBookingEvent.REMOVE_BOOKING_POSITION, {
                            bookingId: booking.id,
                            roomId: toFacilityId,
                        });
                    }
                }
            },
        }),
        [booking],
    );

    const dragDropManager = useDragDropManager();
    const monitor = dragDropManager.getMonitor();

    // in charge of scroll when dragging from top or bottom
    useEffect(
        () =>
            monitor.subscribeToOffsetChange(() => {
                const offset = monitor.getClientOffset();
                const GRID_VIEW_HEIGHT = gridViewContentHeightRef?.current ?? 0;
                const LOC_ON_TOP_TO_SCROLL = window.innerHeight - GRID_VIEW_HEIGHT; // top of grid view
                const LOC_ON_BTM_TO_SCROLL = window.innerHeight - 24; // padding from bottom
                let IS_MAX_BOTTOM_SCROLL = false;

                const {
                    scrollTop,
                    scrollLeft,
                }: {
                    scrollTop: number;
                    scrollLeft: number;
                } = {
                    scrollLeft: 0,
                    scrollTop: 0,
                    ...gridRef.current.state,
                };

                if (GRID_VIEW_HEIGHT && GRID_VIEW_HEIGHT && outerRef.current) {
                    IS_MAX_BOTTOM_SCROLL =
                        outerRef.current.scrollHeight - GRID_VIEW_HEIGHT <= scrollTop;
                }

                if (offset && offset.y <= LOC_ON_TOP_TO_SCROLL) {
                    gridRef.current.scrollTo({
                        scrollLeft: scrollLeft,
                        scrollTop: scrollTop - (LOC_ON_TOP_TO_SCROLL - offset.y), // higher the position of drag, faster it scrolls upwards
                    });
                } else if (
                    offset &&
                    !IS_MAX_BOTTOM_SCROLL &&
                    offset.y >= LOC_ON_BTM_TO_SCROLL
                ) {
                    gridRef.current.scrollTo({
                        scrollLeft: scrollLeft,
                        scrollTop: scrollTop + 15, // rate of downward scroll set to constant
                    });
                }
            }),
        [gridRef, outerRef, gridViewContentHeightRef, monitor],
    );

    useEffect(() => {
        dispatch(setIsDragging(isDragging));
        dispatch(
            setCurrentDragData(
                isDragging
                    ? {
                          facilityId: booking.facilityId,
                          booking,
                      }
                    : null,
            ),
        );
    }, [isDragging, booking]);
    const { emitter } = useMitt();
    useEffect(() => {
        emitter.emit(RoomBookingEvent.UPDATE_BOOKING_POSITION, {
            bookingId: booking.id,
            left,
            width,
            roomId: booking.facilityId,
        });
    }, [left, width]);

    if (isDragging && hideSourceOnDrag) {
        return <div ref={drag} />;
    }
    return (
        <div
            className="drag-box"
            ref={drag}
            style={{
                ...styleDefault,
                ...style,
                width: width,
                display: !width ? 'none' : 'block',
                left: left,
                top: top,
                cursor: isClickHold ? 'move' : 'pointer',
            }}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            onMouseDown={(e) => {
                setIsClickHold(true);
                onMouseDown?.(e);
            }}
            onMouseUp={() => {
                setIsClickHold(false);
            }}
        >
            {children}
        </div>
    );
};
