import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Select, Table } from 'antd';
import _, { forEach, intersectionBy } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { RoomBookingEvent } from '~features/room-booking/constants';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import {
    getAvailableRooms,
    roomBookingStateSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import {
    getStatisticByDateV2,
    roomFilterSelector,
    scheduleStateSelector,
    updateBookingItemAndTmp,
} from '~features/room-booking/reducers/schedule.reducer';
import { IUnassignedBookingItem } from '~features/room-management/interfaces';
import {
    assignBookingToRoom,
    getBookingUnassignedList,
    removeBookingFromUnassignList,
    roomManagementSelector,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import './AutoAssignModal.scss';

type Props = {};

const { Column } = Table;

export const AutoAssignModal = (props: Props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        unassignedList,
        isAssignLoading,
        availableRoomsByBooking,
        currentUnassignPage,
        dateFilterUnassignBookingList,
    } = useAppSelector(roomManagementSelector);
    const { emitter } = useMitt();
    const roomList = useAppSelector(roomFilterSelector);
    const { isAutoAssignRoomLoading } = useAppSelector(roomBookingStateSelector);
    const { bookingGroupById } = useAppSelector(scheduleStateSelector);

    const [selectedRoom, setSelectedRoom] = React.useState<
        Record<string, number | undefined>
    >({});

    const _unassignedList = useMemo(() => {
        const list = _.chunk(unassignedList, DEFAULT_LIMIT_FOR_PAGINATION);
        return list?.[currentUnassignPage - 1] || [];
    }, [currentUnassignPage, unassignedList]);

    const unassignedBookingById: Record<string, IUnassignedBookingItem> = useMemo(() => {
        return (_unassignedList || []).reduce((result, booking) => {
            const tmp = {
                [`booking_${booking.id}`]: booking,
            };
            Object.assign(result, tmp);
            return result;
        }, {});
    }, [_unassignedList]);

    const _getAvailableRooms = useCallback(async () => {
        const ids = _unassignedList.map((item) => item.id);
        if (ids.length === 0) {
            notification.success({
                message: t('roomBooking.detail.message.assignedAllRoom'),
            });
            return;
        }
        const response = await dispatch(getAvailableRooms(ids));
        if (getAvailableRooms.fulfilled.match(response)) {
            if (response.payload?.success) {
                const assignRooms = response.payload?.data || [];
                if (assignRooms.length < ids.length) {
                    notification.success({
                        message: t('roomBooking.detail.message.autoAssignRoom', {
                            unassignCount: ids.length - assignRooms.length,
                        }),
                    });
                }
                return assignRooms;
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
        return [];
    }, [_unassignedList, dispatch, t]);

    useEffect(() => {
        emitter.on(RoomBookingEvent.TOGGLE_AUTO_ASSIGN_MODAL, async (val) => {
            setIsOpen(val);
            if (val) {
                const _list: Record<string, number | undefined> = {};
                const data = await _getAvailableRooms();
                forEach(data, (item) => {
                    _list[`booking_${item.id}`] = item.roomId;
                });
                setSelectedRoom(_list);
            }
        });
        return () => {
            emitter.off(RoomBookingEvent.TOGGLE_AUTO_ASSIGN_MODAL);
        };
    }, [emitter, selectedRoom, _getAvailableRooms]);

    const onOk = async () => {
        const list: { bookingId: number; roomId: number }[] = [];
        forEach(selectedRoom, (roomId, key) => {
            if (roomId) {
                list.push({
                    bookingId: parseInt(key.split('_')[1]),
                    roomId: roomId,
                });
            }
        });
        const bookings = list.map((item) => {
            const booking = bookingGroupById['booking_' + item.bookingId];
            return {
                ...booking,
                room: {
                    id: item.roomId,
                    name: roomList.find((room) => room.id === item.roomId)?.name,
                },
                roomId: item.roomId,
            };
        }) as IRoomBookingSchedule[];

        const assignBookingToRoomBody = list.map((item) => ({
            id: item.bookingId,
            roomId: item.roomId,
        }));
        if (assignBookingToRoomBody.length === 0) {
            notification.error({
                message: t('roomBooking.detail.message.notAssignedAllBookings'),
            });
            return;
        }
        const response = await dispatch(assignBookingToRoom(assignBookingToRoomBody));
        if (assignBookingToRoom.fulfilled.match(response)) {
            if (response.payload?.success) {
                dispatch(
                    updateBookingItemAndTmp({
                        bookings: bookings,
                    }),
                );
                dispatch(
                    removeBookingFromUnassignList({
                        ids: list.map((item) => item.bookingId),
                    }),
                );
                dispatch(
                    getBookingUnassignedList({
                        start: dateFilterUnassignBookingList?.[0],
                        end: dateFilterUnassignBookingList?.[1],
                        isFromBookingSchedule: false,
                    }),
                );
                dispatch(getStatisticByDateV2());
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
        onCancel();
    };

    const onCancel = () => {
        setIsOpen(false);
        setSelectedRoom({});
    };

    const checkTimeOverlap = (
        bookingOne: IUnassignedBookingItem,
        bookingTwo: IUnassignedBookingItem,
    ) => {
        const startDateOfStay = parseDate(bookingOne.startDateOfStay);
        const endDateOfStay = parseDate(bookingOne.endDateOfStay);
        const startDate = parseDate(bookingTwo?.startDateOfStay);
        const endDate = parseDate(bookingTwo?.endDateOfStay);
        if (
            endDateOfStay.isAfter(startDate, 'day') &&
            startDateOfStay.isBefore(endDate, 'day')
        ) {
            return true;
        }
        return false;
    };

    const getRoomOptions = (booking: IUnassignedBookingItem) => {
        const _roomList = roomList.filter((room) => room.parentId === booking.roomTypeId);
        const roomOptions = intersectionBy(
            _roomList,
            availableRoomsByBooking[booking.id] || [],
            'id',
        );
        return (roomOptions || []).map((item) => {
            const bookingKeys =
                Object.keys(selectedRoom).filter((key) => {
                    return selectedRoom[key] && selectedRoom[key] === item.id;
                }) || [];

            let disabled = false;
            for (let key of bookingKeys) {
                disabled = checkTimeOverlap(booking, unassignedBookingById[key]);
                if (disabled) break;
            }
            return {
                value: item.id,
                label: item.name,
                disabled: disabled && selectedRoom[`booking_${booking.id}`] !== item.id,
            };
        });
    };

    return (
        <Modal
            className="auto-assigned-modal-wrapper"
            width={925}
            open={isOpen}
            onCancel={onCancel}
            footer={
                <div className="auto-assigned-modal-footer">
                    <Button onClick={onCancel}>{t('roomManagement.list.cancel')}</Button>
                    <Button
                        loading={isAssignLoading || isAutoAssignRoomLoading}
                        onClick={onOk}
                        type="primary"
                    >
                        {t('roomManagement.list.assignment')}
                    </Button>
                </div>
            }
        >
            <div className="warning-icon">
                <ExclamationCircleOutlined />
            </div>
            <div className="title">{t('roomManagement.list.autoAssign.title')}</div>
            <div className="desc mt-8">{t('roomManagement.list.autoAssign.desc')}</div>

            <Table
                className="mt-30"
                dataSource={_unassignedList}
                rowKey={'id'}
                pagination={false}
                loading={isAutoAssignRoomLoading}
                scroll={{ y: 400 }}
            >
                <Column
                    title={`${t('roomManagement.list.columns.period')}`}
                    key={'period'}
                    render={(_, item: IUnassignedBookingItem, index) => {
                        return (
                            <div key={'period' + index}>{`${parseDate(
                                item.startDateOfStay,
                            )?.fmYYYYMMDD('/')} - ${parseDate(
                                item.endDateOfStay,
                            )?.fmYYYYMMDD('/')}`}</div>
                        );
                    }}
                />
                <Column
                    title={`${t('roomManagement.list.columns.guests')}`}
                    key={'guests'}
                    ellipsis={true}
                    render={(_, item: IUnassignedBookingItem, index) => {
                        return (
                            <div key={'guests' + index} className="text-truncate">
                                {item.representativeGuest?.yomigana}
                            </div>
                        );
                    }}
                />
                <Column
                    title={`${t('roomManagement.list.columns.roomType')}`}
                    key={'roomType'}
                    ellipsis={true}
                    render={(_, item: IUnassignedBookingItem, index) => {
                        return (
                            <div key={'roomType' + index} className="text-truncate">
                                {item.roomType?.name}
                            </div>
                        );
                    }}
                />
                <Column
                    title={`${t('roomManagement.list.columns.room')}`}
                    key={'room'}
                    render={(_, item: IUnassignedBookingItem, index) => {
                        return (
                            <Select
                                className="select-room"
                                key={'room' + index}
                                value={selectedRoom['booking_' + item.id]}
                                onChange={(val) => {
                                    const _list = { ...selectedRoom };
                                    _list['booking_' + item.id] = val;
                                    setSelectedRoom(_list);
                                }}
                                options={getRoomOptions(item)}
                            />
                        );
                    }}
                />
                <Column
                    title={`${t('roomManagement.list.columns.remarks')}`}
                    key={'remarks'}
                    ellipsis={true}
                    render={(_, item: IUnassignedBookingItem, index) => {
                        return (
                            <div key={'remarks' + index} className="text-truncate">
                                {item.memo ? item.memo : t('roomManagement.list.empty')}
                            </div>
                        );
                    }}
                />
            </Table>
        </Modal>
    );
};
