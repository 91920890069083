export const scheduleEn = {
    title: 'Room schedule',
    statusTitle: 'Status',
    remaining: 'Remaining rooms',
    breadcrumbs: {
        home: 'Home',
        schedule: 'Room booking',
        scheduleSetting: 'Room schedule',
    },
    createReservation: 'New booking',
    confirmCloseBookingTitle:
        'All Temporary bookings will be remove after {{minutes}} seconds',
    confirmCloseBooking: 'Do you want to keep bookings?',
    bookingsDeleted: 'All bookings have been deleted',
    bookingMoreList: 'Booking list',
    hover: {
        expandBookingList: 'Display full screen',
        unassignList: 'Display Unassigned',
    },
    status: {
        all: 'All',
        not_arrived: 'Reserved',
        checked_in: 'Checked in',
        checked_out: 'Checked out',
        cancelled: 'Cancelled',
    },
    tab: {
        schedule: 'Room schedule',
    },
    viewType: {
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
    },
    collapseTitle: 'Collapse all',
    collapseShowLess: 'Expand all',
    notAssigned: 'Unassigned',
    unspecified: 'Unspecified',
    resevationOverview: 'To booking overview',
    currentBooking: 'Booking list',
    selectChildTitle: 'Select children type',
    withoutMeal: 'Plan without meal',
    adult: 'Adult',
    genderBreakdown: '({{male}} M, {{female}} F, {{other}} O)',
    kid: 'Children',
    childType: 'Children type',
    person: 'Guest',
    dateRageInvalid: 'Date range is invalid',
    bookingOccupied: 'Room is not available for current night',
    message: {
        deleteTmpSuccess: 'Delete booking temporary success',
        deleteTmpFailed: 'Delete booking temporary failed',
        updateTmpSuccess: 'Update booking temporary success',
        checkinSuccess: 'Checkin room booking success',
        cannotCheckIn: {
            fromNotArrived: 'This booking can not check in before {{date}}',
            fromCheckOut:
                'Cannot check in because the check out date for this booking has already passed',
        },
    },
    remainingRoom: 'Remaining rooms',
    sync: 'Sync booking with TLL',
    syncBookingStart:
        'We are synchronizing bookings with TLL, please wait a few minutes and reload again.',
    createBookingOptions: {
        generalBooking: 'New General Booking',
        groupBooking: 'New Group Booking',
    },
    groupBookingTable: {
        id: '#',
        roomType: 'Room Type',
        numberOfRooms: 'No. of Rooms',
        plan: 'Plan',
        adult: 'Adult (per room)',
        children: 'Children',
        dayUse: 'Day Use',
        lengthOfStay: 'Length of Stay',
        delete: 'Delete',
        addRowButton: 'Add Booking',
        placeHolder: {
            roomType: 'Select a Room Type',
            planName: 'Select a Plan',
        },
    },
    scheduleCellPopup: {
        title: 'Please select Create booking or Stop rooms',
        createBooking: 'Create Booking',
        stopRooms: 'Stop Rooms',
    },
    stoppingRoomsModal: {
        stoppingRooms: 'Stopping Rooms',
        releasingRooms: 'Releasing Rooms',
        form: {
            roomType: 'Room Type',
            stoppingPeriod: {
                label: 'Stopping Period',
                start: 'Start Date',
                end: 'End Date',
            },
            cause: 'Cause',
            reason: 'Reason',
        },
    },
};
