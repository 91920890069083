import {
    Button,
    Form,
    FormInstance,
    Layout,
    Spin,
    notification,
    theme,
    Typography,
    Card,
} from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DeepRequired, FieldErrorsImpl, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { isDataDetailFetched } from '~common/commonFunctions';
import { ErrorMessageType, UploadFileStatus } from '~common/constants';
import { getChildrenDropdown } from '~features/children-type/reducers/children.reducer';
import {
    MAX_NUMBER_OF_FILES_PER_GUEST,
    UPLOAD_FILE_ERROR_I18N_KEY,
} from '~features/guest/constants';
import { validateFileExtension } from '~features/guest/helper';
import { IFile } from '~features/guest/interfaces';
import { getGuestListForDropdown } from '~features/guest/reducers/guest.reducer';
import { getPlanDropdown } from '~features/plan/reducers/plan.reducer';
import {
    ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
    FormTab,
    MAX_NUMBER_OF_FILES_PER_BOOKING,
    MAX_SIZE_OF_EACH_BOOKING_FILE,
    RoomBookingEvent,
} from '~features/room-booking/constants';
import {
    errorMessagesBookingFile,
    validateFileSize,
} from '~features/room-booking/helper';
import {
    isFrozenBooking,
    transformUpdateBookingForm,
    transformUpdateBookingFormData,
} from '~features/room-booking/helper.update-booking';
import { IUpdateBookingFormData } from '~features/room-booking/interfaces';
import {
    fetchMarketingChannelDropDown,
    fetchRoomBookingDetail,
    selectedRoomBookingDetailSelector,
    showLoadingSelector,
    updateBooking,
} from '~features/room-booking/reducers/room-booking.reducer';
import { updateBookingSchemaResolver } from '~features/room-booking/schema';
import { getRoomTypeListForDropdown } from '~features/room-type/room-type.reducer';
import { getListForDropDown } from '~features/room/room.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useMitt } from '~plugins/mitt';
import { CheckinModal } from '../SchedulePage/components/CheckinModal/CheckinModal';
import './RoomBookingEditPage.scss';
import GuestInfoTabPanel from '../CreateBookingPage/components/GuestInfoTabPanel/GuestInfoTabPanel';

const { Title } = Typography;

function RoomBookingEditPage() {
    const { token } = theme.useToken();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { roomBookingId } = useParams();

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        clearErrors,
        setError,
        formState: { errors },
    } = useForm({
        resolver: updateBookingSchemaResolver,
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const [formTab, setFormTab] = useState<FormTab>(FormTab.BASIC_INFO);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const isFetching = useAppSelector(showLoadingSelector);
    const [isPatching, setIsPatching] = useState(false);

    const formRef = useRef<FormInstance>(null);

    const isFrozen = useMemo(() => {
        return isFrozenBooking(selectedRoomBookingDetail?.roomBookingItems || []);
    }, [selectedRoomBookingDetail?.roomBookingItems]);

    const marketingChannel = useMemo(() => {
        return selectedRoomBookingDetail?.marketingChannel;
    }, [selectedRoomBookingDetail]);

    const fetchData = () => {
        dispatch(fetchRoomBookingDetail(Number(roomBookingId)));
    };

    useEffect(() => {
        dispatch(getGuestListForDropdown({}));
        dispatch(fetchMarketingChannelDropDown({}));
        dispatch(getListForDropDown({}));
        dispatch(getChildrenDropdown());
        if (!isDataDetailFetched(selectedRoomBookingDetail, roomBookingId)) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (selectedRoomBookingDetail) {
            reset({
                ...transformUpdateBookingForm(selectedRoomBookingDetail),
            });
            const isFromTll = !!selectedRoomBookingDetail?.tllDataId;
            dispatch(getPlanDropdown({ includeDataFromTll: isFromTll }));
            dispatch(getRoomTypeListForDropdown({ isPullFromTll: isFromTll }));
        }
    }, [selectedRoomBookingDetail, reset, setValue, dispatch]);

    const goBack = () => {
        navigate(`/room-booking/${roomBookingId}/detail`);
    };

    const scrollToErrors = (errors: FieldErrorsImpl<DeepRequired<FieldValues>>) => {
        const errorKeys = Object.keys(errors);
        if (!errorKeys.length) {
            return;
        }
        if (errors.members) {
            if (formTab !== FormTab.COMPANION_INFO) {
                setFormTab(FormTab.COMPANION_INFO);
            }
        } else {
            if (formTab !== FormTab.BASIC_INFO) {
                setFormTab(FormTab.BASIC_INFO);
            }
        }
    };

    const _updateBooking = async (formData: IUpdateBookingFormData | FormData) => {
        setIsPatching(true);
        const response = await dispatch(
            updateBooking({ id: Number(roomBookingId), formData }),
        );
        if (updateBooking.fulfilled.match(response)) {
            setIsPatching(false);

            if (response.payload?.success) {
                const messageKey = response.payload
                    ?.message as keyof typeof errorMessagesBookingFile;
                if (
                    messageKey &&
                    Object.keys(errorMessagesBookingFile).includes(messageKey)
                ) {
                    notification.error({
                        message: errorMessagesBookingFile[messageKey],
                    });
                }
                if (response.payload?.message === UPLOAD_FILE_ERROR_I18N_KEY) {
                    notification.error({
                        message: t(`guest.update.${UPLOAD_FILE_ERROR_I18N_KEY}`),
                    });
                }
                notification.success({
                    message: t('roomBooking.updateBooking.update.success'),
                });
                navigate(`/room-booking/${roomBookingId}/detail`);
                return;
            }
            notification.error({
                message: t('roomBooking.updateBooking.update.error'),
                description: response.payload?.message || '',
            });

            // show error message when uploading file
            const errorKey = response.payload
                ?.message as keyof typeof errorMessagesBookingFile;
            if (errorKey && Object.keys(errorMessagesBookingFile).includes(errorKey)) {
                notification.error({
                    message: errorMessagesBookingFile[errorKey],
                });
                return;
            }
        }
    };

    useEffect(() => {
        scrollToErrors(errors);
    }, [errors]);

    const checkAttachments = (attachmentsOfGuest: IFile[]) => {
        if (attachmentsOfGuest.length > MAX_NUMBER_OF_FILES_PER_BOOKING) {
            return {
                isError: true,
                message: t('guest.form.uploadFile.message.maxCount', {
                    maxCount: MAX_NUMBER_OF_FILES_PER_GUEST,
                }),
            };
        }
        const validFileSize = validateFileSize(
            attachmentsOfGuest,
            MAX_SIZE_OF_EACH_BOOKING_FILE,
        );
        if (validFileSize.isError) {
            return { isError: true, message: validFileSize.message };
        }
        const { isError, message } = validateFileExtension(
            attachmentsOfGuest,
            ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
        );
        if (isError) {
            return { isError: true, message };
        }
        return { isError: false, message: '' };
    };

    const submit = () => {
        handleSubmit((data) => {
            const attachmentsOfBooking = data.files || [];

            const { isError, message } = checkAttachments(attachmentsOfBooking);

            if (isError) {
                setError(
                    `files`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message,
                    },
                    { shouldFocus: true },
                );
                return;
            }

            const files = attachmentsOfBooking?.filter(
                (file: IFile) => file.status === UploadFileStatus.DONE,
            );
            const updateRoomBookingFormData = transformUpdateBookingFormData({
                isReserverTheRepresentative: data.isReserverTheRepresentative,
                representativeGuest: data.representativeGuest,
                reserverGuest: data.reserverGuest,
                marketingChannelId: data.marketingChannelId,
                birthday: data.birthday,
                gender: data.gender,
                memo: data.memo,
                otaMemo: data.otaMemo,
                members: data.members,
                files,
            });
            _updateBooking(updateRoomBookingFormData);
        })();
    };

    const { emitter } = useMitt();

    useEffect(() => {
        emitter.on(RoomBookingEvent.CHANGE_TAB, (tab: FormTab) => {
            setFormTab(tab);
        });

        return () => {
            emitter.off(RoomBookingEvent.CHANGE_TAB);
        };
    }, []);

    return (
        <Spin spinning={isFetching}>
            <Layout.Content className="edit-booking-page">
                <Card className="mr-24 ml-24">
                    <Title level={3} style={{ marginTop: 0 }}>
                        {t('roomBooking.page.guestInfo.infomation')}
                    </Title>
                    <Form
                        className="edit-booking-page-content"
                        layout="vertical"
                        ref={formRef}
                        scrollToFirstError
                        title={t('roomBooking.page.guestInfo.infomation')}
                    >
                        <GuestInfoTabPanel
                            control={control}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            getValues={getValues}
                            disabled={isFrozen}
                            marketingFromTll={
                                marketingChannel?.isPullFromTll
                                    ? marketingChannel?.name
                                    : undefined
                            }
                            additionalMarketingChannel={marketingChannel}
                        />
                    </Form>
                </Card>
            </Layout.Content>
            <Layout.Footer>
                <div className="d-flex j-center" style={{ gap: 36 }}>
                    <Button onClick={goBack} style={{ width: 200 }}>
                        {t('roomBooking.updateBooking.cancel')}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: token.colorSuccess,
                            border: 'none',
                            color: 'white',
                            width: 200,
                        }}
                        onClick={submit}
                        loading={isFetching || isPatching}
                        disabled={isFrozen}
                    >
                        {t('roomBooking.updateBooking.submit')}
                    </Button>
                </div>
            </Layout.Footer>
            <CheckinModal
                isLoadedData={true}
                onChangeBookingStatusSuccess={() => fetchData()}
            />
        </Spin>
    );
}

export default RoomBookingEditPage;
