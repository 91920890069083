import { Builder } from 'builder-pattern';
import { RoomBooking } from 'src/types/v2/room-booking/room-booking.type';
import {
    ICreateOrUpdateBookingTmp,
    IRoomBookingSchedule,
    IRoomGroupBookingSchedule,
} from '~features/room-booking/interfaces';
import {
    ICreateGroupTempRoomBooking,
    ICreateSingleTempRoomBooking,
} from '~features/room-booking/interfaces/temp-room-booking/create-temp-room-booking.interface';
import { IUpdateSingleTempRoomBooking } from '~features/room-booking/interfaces/temp-room-booking/update-temp-room-booking.interface';
export class TempRoomBookingHelper {
    static buildCreateTempRoomBookingBody(
        bookings: IRoomGroupBookingSchedule[] | IRoomBookingSchedule[],
    ): ICreateGroupTempRoomBooking {
        const tempRoomBookings: ICreateSingleTempRoomBooking[] = [];
        for (const booking of bookings) {
            const tempRoomBookingBuilder = Builder<ICreateSingleTempRoomBooking>();
            if (booking.checkInTime) {
                tempRoomBookingBuilder.checkInTime(booking.checkInTime);
            }
            if (booking.checkOutTime) {
                tempRoomBookingBuilder.checkOutTime(booking.checkOutTime);
            }
            if (booking.stayingStartDate) {
                tempRoomBookingBuilder.startDateOfStay(booking.stayingStartDate);
            }
            if (booking.stayingEndDate) {
                tempRoomBookingBuilder.endDateOfStay(booking.stayingEndDate);
            }
            if (booking.numberOfAdults) {
                tempRoomBookingBuilder.numberOfAdults(booking.numberOfAdults);
            }
            if (booking.numberOfMale) {
                tempRoomBookingBuilder.numberOfMale(booking.numberOfMale);
            }
            if (booking.numberOfFemale) {
                tempRoomBookingBuilder.numberOfFemale(booking.numberOfFemale);
            }
            if (booking.numberOfOtherGenderGuest) {
                tempRoomBookingBuilder.numberOfOtherGenderGuest(
                    booking.numberOfOtherGenderGuest,
                );
            }
            if (booking.room && booking.room.id) {
                tempRoomBookingBuilder.roomId(booking.room.id);
            }
            if ('numberOfRooms' in booking) {
                tempRoomBookingBuilder.numberOfRooms(booking.numberOfRooms);
            }
            if (booking.roomType && booking.roomType.id) {
                tempRoomBookingBuilder.roomTypeId(booking.roomType.id);
            }
            if (booking.plan && booking.plan.id) {
                tempRoomBookingBuilder.planId(booking.plan.id);
            }
            tempRoomBookingBuilder.isDayUse(booking.isDayUse);
            if (booking.children && booking.children.length) {
                tempRoomBookingBuilder.roomBookingItemChildrenTypes(
                    booking.children.map((child) => ({
                        childrenTypeId: child.typeId!,
                        quantity: child.quantity,
                    })),
                );
            }
            const tempRoomBooking = tempRoomBookingBuilder.build();
            tempRoomBookings.push(tempRoomBooking);
        }
        return {
            list: tempRoomBookings,
        };
    }

    static buildUpdateBookingData(booking: RoomBooking): ICreateOrUpdateBookingTmp {
        const updateRoomBookingData = Builder<ICreateOrUpdateBookingTmp>();
        updateRoomBookingData.id(booking.id);
        if (booking.childrenInfos && booking.childrenInfos.length) {
            updateRoomBookingData.roomBookingItemChildrenTypes(
                booking.childrenInfos.map((child) => ({
                    childrenTypeId: child.childTypeId,
                    id: child.id,
                })),
            );
        }
        return updateRoomBookingData.build();
    }

    static buildUpdateSingleTempRoomBookingBody(
        booking: IRoomBookingSchedule,
    ): IUpdateSingleTempRoomBooking {
        const updateRoomBookingData = Builder<IUpdateSingleTempRoomBooking>();
        if (booking.id) {
            updateRoomBookingData.id(booking.id);
        }

        if (booking.checkInTime) {
            updateRoomBookingData.checkInTime(booking.checkInTime);
        }
        if (booking.checkOutTime) {
            updateRoomBookingData.checkOutTime(booking.checkOutTime);
        }
        if (booking.stayingStartDate) {
            updateRoomBookingData.startDateOfStay(booking.stayingStartDate);
        }
        if (booking.stayingEndDate) {
            updateRoomBookingData.endDateOfStay(booking.stayingEndDate);
        }
        if (booking.numberOfAdults) {
            updateRoomBookingData.numberOfAdults(booking.numberOfAdults);
        }
        if (booking.numberOfMale) {
            updateRoomBookingData.numberOfMale(booking.numberOfMale);
        }
        if (booking.numberOfFemale) {
            updateRoomBookingData.numberOfFemale(booking.numberOfFemale);
        }
        if (booking.numberOfOtherGenderGuest) {
            updateRoomBookingData.numberOfOtherGenderGuest(
                booking.numberOfOtherGenderGuest,
            );
        }
        if (booking.room && booking.room.id) {
            updateRoomBookingData.roomId(booking.room.id);
        }
        if (booking.roomType && booking.roomType.id) {
            updateRoomBookingData.roomTypeId(booking.roomType.id);
        }
        if (booking.plan && booking.plan.id) {
            updateRoomBookingData.planId(booking.plan.id);
        }
        updateRoomBookingData.isDayUse(booking.isDayUse);
        if (booking.children && booking.children.length) {
            updateRoomBookingData.roomBookingItemChildrenTypes(
                booking.children.map((child) => ({
                    childrenTypeId: child.typeId!,
                    quantity: child.quantity,
                })),
            );
        }
        return updateRoomBookingData.build();
    }
}
